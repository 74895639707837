.popover {
  position: absolute;
  z-index: 1070;
  display: inline-block;//block;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  opacity: 1;
  line-break: auto;
  filter: drop-shadow(0 1px 5px @gray);

  &.bottom {
    padding: 10px 0;
    top: calc(100%);
    left: 50%;
    transform: translateX(-50%);

    .popover-arrow {
      top: 0;
      left: 50%;
      margin-left: -10px;
      border-width: 0 10px 10px;
      border-bottom-color: #FFF;
    }
  }

  &.top {
    padding: 10px 0;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);

    .popover-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -10px;
      border-width: 10px 10px 0;
      border-top-color: #FFF;
    }
  }

  &[class^="top-"], &[class*="top-"] {
    .top;
  }

  &.top-left {
    right: -10px;
    transform: none;
    left: unset;

    .popover-arrow {
      left: unset;
      right: 10px;
    }

    .popover-inner {
      border-bottom-right-radius: 0;
    }
  }

  &.right {
    padding: 0 10px;
    left: 100%;
    transform: translateY(-15%);

    .popover-arrow {
      top: 50%;
      left: 0;
      margin-top: -10px;
      border-width: 10px 10px 10px 0;
      border-right-color: #FFF;
    }
  }

  &[class^="left-"], &[class*="left-"] {
    padding: 0 5px;
    right: 100%;

    .popover-arrow {
      right: 0;
      margin-top: -10px;
      border-width: 10px 0 10px 10px;
      border-left-color: #FFF;
    }
  }

  &.left-top {
    bottom: 0;

    .popover-arrow {
      bottom: 10px;
    }

    .popover-inner {
      border-bottom-right-radius: 0;
    }
  }

  &.left-bottom {
    top: 0;

    .popover-arrow {
      top: 10px;
    }

    .popover-inner {
      border-top-right-radius: 0;
    }
  }

  .popover-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }


  .popover-inner {
    padding: 10px 15px;
    color: @gray;
    text-align: left;
    background-color: #FFF;
    border-radius: @border-radius-small;
    white-space: nowrap;
  }

  .popover-title {
    font-weight: bold;
    padding: 10px 0;
    color: @gray-dark;
  }

}

.popover-buttons {
  margin-top: 15px;
}

.popover-row-col-table-section {
  border-top: @border-default;
  font: @text-xs-regular;
  padding: 10px @spacing-4;

  .popover-row-col-table-section-title {
    color: @text-weak-color;
  }

  .popover-row-col-table-section-row {
    margin-top: 6px;
  }

  .popover-row-col-table-section-row-label {
    color: @text-color;
  }

  .popover-row-col-table-section-row-value {
    font: @text-xs-semi-bold;

    .popover-row-col-table-section-row-value-text {
      &.danger {
        color: @negative-alert-red;
      }

      &.warning {
        color: @negative-alert;
      }

      &.success {
        color: @positive-alert;
      }

      &.weak {
        color: @on-track-alert;
      }
    }

    .popover-table-section-row-explanation {
      color: @text-weak-color;

      svg {
        fill: @icon-color;
        vertical-align: middle;
        .icon-small();
      }

      &.danger {
        color: @text-danger-color;

        > svg {
          fill: @icon-danger-color;
        }
      }

      &.warning {
        color: @text-warning-dark-color;

        > svg {
          fill: @icon-warning-color;
        }
      }

      &.success {
        color: @text-success-color;

        > svg {
          fill: @icon-success-color;
        }
      }
    }
  }
}
