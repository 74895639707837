.dropzone {
  height: 100px;
  border: 2px dashed #666;
  border-radius: 5px;
  cursor: pointer;
  padding: 20px;

  &:hover {
    text-decoration: underline;
  }
}

.wide-dropzone {
  width: 100%;
}

.active-dropzone {
  background-color: #eee;
}

.reject-dropzone {
  background-color: #ffdddd;
}
