.editable-table {
  .editable-table-header {
    background-color: @gray-lighter;
    text-transform: uppercase;
    font-size: @font-size-bodyText;
    line-height: 24px;
    .bold-font;
    padding: 5px;
    border-top: @light-gray-border;
    border-bottom: @light-gray-border;
  }

  .editable-table-body {
    &.read-table {
      padding: 12px 5px;
    }

    &.edit-table {
      padding-top: 30px;

      .editable-table-body-cell {
        padding: 0 5px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .uneditable-cell {
        padding: @dropdown-selection-padding;
        line-height: @dropdown-selection-line-height;
      }
    }
  }

  .editable-table-body-row {
    .row {
      padding-bottom: 10px;
    }

    .calendar-view {
      height: 100%;
      position: relative;

      .input-with-icon {
        height: 100%;

        svg {
          margin-top: 10px;
        }
      }

      .calendar {
        top: @dropdown-height;
      }
    }

    .form-control {
      margin-top: 0;
      height: 100%;
    }

    .removable-option {
      margin-right: 0;
    }
  }

  .button {
    line-height: 22px;
    min-width: 84px;
    padding-right: 24px;
    padding-left: 24px;

    &.secondary-transparent {
      background-color: @white;

      &:hover {
        background-color: @gray6;
        color: @gray;
      }
    }
  }

  .editable-table-info {
    margin-bottom: 15px;
    margin-top: -5px;
  }

  .row-error {
    .dropdown-combo .dropdown-selection {
      .title {
        color: @dropdown-white-bg-font-color;
      }

      .info {
        color: @dropdown-white-bg-info-color;
      }
    }
  }

  .editable-table-secondary-section {
    padding-top: 20px;
    line-height: @line-height-base;

    .editable-table-help {
      display: flex;
      padding-left: 0;

      svg {
        width: @icon-medium-size;
        height: @icon-medium-size;
        fill: @text-color-default;
        margin-right: @text-with-icon-separation;
      }
    }

    .right-content {
      padding-right: 0;
    }
  }
}
