.navigation-bar {
  background-color: @navbar-bg-color;
  margin-bottom: @navbar-margin-bottom;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  min-width: @min-page-width;

  &.frozen-navigation-bar {
    position: sticky;
    z-index: @zindex-frozen-navbar;
    width: 100%;
    top: 0;
  }

  &.navigation-bar-light {
    background-color: @navbar-light-bg-color;
    box-shadow: none;

    .user-name .dropdown-toggle span {
      opacity: 1;
      text-transform: unset;
    }
  }

  .navigation-bar-content {
    height: @header-height;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    margin-right: 50px;
    vertical-align: middle;

    a {
      cursor: pointer;
      text-decoration: none;
      color: @header-font-color;
    }

    svg {
      fill: @header-font-color;
      width: @header-atrium-logo-width;
      height: @header-atrium-logo-height;
      vertical-align: middle;
    }
  }

  .container {
    position: relative;
  }

  .flex-container {
    .logo {
      padding-left: 20px;
    }
  }

  .user-name,
  .nav-tab {
    text-align: right;
    margin: auto 0;
    color: @header-font-color;
    vertical-align: middle;
    cursor: pointer;
    font-size: @header-font-size;
    display: inline-block;

    &.navigation-bar-title {
      font-size: @font-size-h2;
    }
  }

  .nav-tab {
    a {
      color: @header-font-color;
    }
  }

  .user-name {
    .button-group {
      margin-left: 20px;
      vertical-align: middle;
    }
  }

  .user-name {
    .help-button,
    .buy-button {
      display: inline-block;
      margin-right: 20px;
      text-transform: @header-text-transform;

      a {
        text-decoration: none;
        font-weight: @header-font-weight;

        span {
          vertical-align: middle;
        }
      }
    }

    .buy-button .button.secondary-blue {
      font-size: 14px;
      font-weight: @header-font-weight;
      min-width: 0;
      padding: 4px 8px;
      border: none;
    }

    .help-button a {
      color: @header-font-color;

      svg {
        margin-right: 3px;
        fill: @header-font-color;
      }
    }

    .meatball {
      display: inline-block;
      font-size: @header-meatball-font-size;
      color: @header-meatball-color;
      background-color: @header-meatball-bg-color;
      border-radius: @header-meatball-border-radius;
      line-height: @header-meatball-line-height;
      padding: @header-meatball-padding;
      margin-right: @text-with-icon-separation;
      text-align: center;
      min-width: @header-meatball-min-width;
    }

    .nav-bar-search-dropdown svg,
    .help-button {
      opacity: @header-opacity;

      &:hover {
        opacity: 1;
      }
    }
  }

  .nav-bar-search-dropdown .dropdown-option-link {
    svg {
      fill: @icon-weak-color;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  .sections {
    .navigation-buttons {
      display: flex;
      align-items: center;
    }

    .navigation-buttons > a.section-button:hover, a.section-button:active, .section-button-active {
      opacity: 1;
      text-decoration: none;
    }

    .section-button {
      opacity: @header-opacity;
    }

    .section-button, .section-button-active {
      color: @navbar-color;
      font-size: @header-font-size;
      font-weight: @header-font-weight;
      display: inline-flex;
      margin-right: 15px;
      align-items: center;
      text-transform: @header-text-transform;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 7px;
        vertical-align: middle;
        fill: @navbar-color;
      }

      .label {
        display: inline-block;
        vertical-align: middle;
        line-height: 15px;
      }

      > span {
        display: flex;
      }
    }

    .header-sections-divider {
      height: 14px;
      border: 1px solid;
      margin-right: 15px;
      display: inline-block;
    }
  }

  .navigation-buttons,
  .user-name {
    white-space: nowrap;
  }

  .nav-bar-dropdown {
    .dropdown-transparent;
    .dropdown-selection {
      padding: 18px 0;
      color: @header-font-color;

      > span {
        display: inline-block;
        max-width: 70px;
        vertical-align: middle;
        .text-ellipsis();
        font-weight: @header-font-weight;
        opacity: @header-opacity;
        text-transform: @header-text-transform;
      }
    }

    .caret {
      display: inline-block;
      margin-left: 6px;
      font-size: 5px;
      .triangle-down(@header-font-color);
      vertical-align: middle;
      margin-top: -2px;
      opacity: @header-opacity;
    }

    .nested-dropdown {
      padding-top: 0;
      right: 0;
      cursor: pointer;

      &.dropdown-menu-container {
        margin-right: -30px;
        background-color: @navbar-color;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        z-index: @zindex-tooltip; //Dropdown menu appears on rollover like the tooltips

        .dropdown-menu {
          padding: 10px 0;
          width: 350px;
          font-size: @font-size-bodyText;
          min-width: 150px;

          .dropdown-options {
            padding-top: 0;
            color: @gray1;
            font-size: 16px;
            line-height: 24px;
            .dropdown-option-container {
              color: #696969;
              text-align: left;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .dropdown-option-container:hover .dropdown-label,
          .dropdown-option-link:hover {
            background-color: @gray-lighter;
            border-radius: 0;
            margin-left: 0;
            margin-right: 0;
            color: @black;
          }
          .dropdown-label,
          .dropdown-menu-option-label {
            padding: 10px 20px;
          }

          .dropdown-menu-option {
            padding: 10px 0;
            min-width: 280px;
          };

          .dropdown-option-link {
            padding-top: 10px;
            padding-bottom: 10px;
            line-height: 24px;
          }

          .dollar-button {
            color: @actionGreen;
            svg {
              .icon-medium();
              margin-right: 6px;
              vertical-align: middle;
              fill: @actionGreen;
            }
          }
        }
      }
    }
  }

  .nav-bar-search-dropdown {
    width: @header-user-name-dropdown-width;

    .dropdown-selection {
      border-radius: @border-radius-small;
      background-color: fade(@background-white-color, 20%);
      text-align: left;
      color: @header-font-color;
      font-size: 14px;

      .info {
        color: @header-font-color;
        opacity: 0.4;
        text-transform: unset;
        font-style: italic;
        margin-left: 10px;
        vertical-align: middle;
      }
    }

    .dropdown-menu-container {
      z-index: @zindex-popover;
    }

    .dropdown-explanation {
      font-style: italic;
    }

    .dropdown-menu {
      width: 650px;

      &.scrollable-options .dropdown-options {
        max-height: 500px;
      }
    }

    .input-icon svg {
      fill: @gray2;
      opacity: 1;
    }
  }
}

.modal-active,
.top-level-modal-active,
.target-selector-active,
.target-reorder-active,
.blocking-error-active {
  .navigation-bar.frozen-navigation-bar {
    z-index: @zindex-navbar;
  }
}

@media only screen and (max-width: @page-width) {
  .navigation-bar .logo {
    margin-right: 30px;
  }
}
