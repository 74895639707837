.tag {
  display: flex;
  gap: @spacing-1;
  padding: 4px 12px;
  border-radius: @border-radius-default;
  font: @text-xs-medium;
  color: @text-weak-color;

  svg {
    fill: @icon-weak-color;
    .icon-small();
  }

  &:hover {
    color: @text-color;

    svg {
      fill: @icon-color;
    }
  }

  &.tag-filled {
    background-color: @background-medium-color;
  }
}
