.harvey-ball {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid @icon-weak-color;
  background-color: transparent;
  overflow: hidden;
  padding: 1px;

  .slice {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: transparent; /* Color of the filled portion */
    transform-origin: 50% 50%;
    border-radius: 50%;
  }

  &.harvey-ball-quarter .slice {
    background: conic-gradient(
      transparent 0% 25%,
      @negative-alert-red 25% 50%,
      transparent 50% 100%
    );
  }

  &.harvey-ball-half .slice {
    background: conic-gradient(
      @negative-alert 0% 50%,
      transparent 50% 100%
    );
  }

  &.harvey-ball-three-quarters .slice {
    background: conic-gradient(
      @positive-alert 0% 75%,
      transparent 75% 100%
    );
  }

  &.harvey-ball-full .slice {
    background: conic-gradient(
      @positive-alert 0% 100%
    );
  }

  &.harvey-ball-empty {
    border-color: @negative-alert-red;
  }
}
