.icon-green {
  fill: @positive-alert;
}

.icon-link {
  fill: @link-color;
}

.icon-warning {
  fill: @icon-warning-color;
}

.icon-positive {
  fill: @icon-marketing-green-color;
}

.icon-fuchsia {
  fill: @icon-marketing-fuchsia-color;
}

.uncolored-icon {
  fill: @icon-color;
}

.banner-notification-icon {
  border: 6px solid;
  border-radius: 28px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    vertical-align: middle;
  }

  &.success {
    border-color: @background-weak-success-color;
    background: @background-disabled-success-color;

    svg {
      fill: @background-success-color;
    }
  }

  &.info {
    border-color: @background-active-color;
    background: @background-blue-weak-color;

    svg {
      fill: @background-blue-color;
    }
  }
}
