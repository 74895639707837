.list-view {
  padding: 5px 0;

  .cross {
    padding-right: 5px;
    font-size: 17px;
    cursor: pointer;
  }
}

.thin-bullet-list-item {
  list-style-type: none;
  padding-left: 18px;
  position: relative;
  line-height: 25px;

  &:before {
    content: "";
    border: 2px @text-color-default solid;
    border-radius: 50%;
    margin-top: 10px;
    margin-left: -16px;
    position: absolute;
  }
}

.thin-bullet-list {
  padding-inline-start: 10px;

  span {
    vertical-align: middle;
  }

  > li {
    .thin-bullet-list-item;
  }

  .list-item-with-input {
    padding-top: 10px;

    &:before {
      margin-top: 15px
    }
  }

  &.spaced-list-items {
    margin-top: 20px;

    > li:not(:last-child) {
      padding-bottom: 20px;
    }
  }
}

.link-list {
  color: @blue-link;
}
