.page-content-container .page-with-table {
  display: flex;
  padding: 0 0 30px;
  position: relative;
  flex-direction: column;

  .page-content-header, .page-with-table-description, .table-intro, .row-col-table {
    margin-left: 8vw;
    margin-right: 8vw;
    &.modal-row-col-table {
      margin: 0;
    }
  }

  .page-with-table-description {
    color: @text-weak-color;
  }

  .tabs-section {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;

    .tabs-section-divider {
      top: 100%;
      height: 1px;
      width: 100%;
      position: absolute;
      background-color: @border-color;
    }
  }

  .tabs-section {
    .tab-group {
      margin-left: 8vw;
    }

    .action-buttons {
      display: flex;
      gap: @spacing-3;
      margin-right: 8vw;
    }
  }
}
