.tab-group {
  margin-top: 8px;
  position: relative;

  .tab-list {
    display: flex;
    gap: @spacing-5;

    .tab {
      cursor: pointer;
      padding: @spacing-3 0;
      transition: color 0.2s;
      color: @text-weak-color;
      font: @text-display-large;

      &.active-tab {
        color: @text-active-color;
      }
    }
  }

  .active-tab-slide {
    bottom: 0;
    height: 1px;
    position: absolute;
    transition: left 0.2s, width 0.2s;
    background-color: @background-blue-color;
  }
}
