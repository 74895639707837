.article {
  .flexbox();
  flex-wrap: nowrap;
  padding: 40px;
  margin-top: 80px;

  &.page-section-contained {
    border: none;
  }

  .rounded-img {
    width: 130px;
    margin: 0 auto 10px;
    display: block;
  }

  .logo {
    margin: 0 auto 20px;
    display: block;
  }

  .side-header {
    text-align: center;
  }

  .article-title {
    font-size: @font-size-h3;
    font-weight: @font-weight-semi-bold;
    color: @text-color-bold;
    margin: 0;
  }

  .article-subtitle {
    font-size: @font-size-h3;
    margin: 0;
  }

  .vertical-separator {
    border-right: 1px solid @gray5;
    margin: 0 40px;
  }

  .article-content {
    font-style: italic;
    line-height: 28px;

    p {
      margin: 30px 0 0;
    }

    p:first-child {
      margin-top: 0;
    }
  }
}
