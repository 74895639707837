.saved-goal-edit-modal-actions-view {
  display: flex;
  align-items: center;
  .saved-goal-edit-modal-actions-view-delete {
    color: @text-danger-color;
  }
  .saved-goal-edit-modal-actions-view-save {
    margin-left: 12px;
  }
}
