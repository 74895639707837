.filter-button {
  gap: 4px;
  height: 32px;
  max-width: 104px;
  padding: 4px 12px;
  margin-left: 12px;

  .filter-button-count {
    flex: none;
    width: 18px;
    height: 18px;
    color: @white;
    display: flex;
    align-items: center;
    border-radius: 50px;
    font: @text-2xs-medium;
    justify-content: center;
    background-color: @background-blue-color;
  }
}
