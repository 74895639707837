.record-card-section {

  .record-card-section-title-container {
    margin: @spacing-2 0 0;
    padding: @spacing-3 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: @body-bg;

    .record-card-section-action {
      color: @text-weak-color;
      text-decoration: none;

      svg {
        fill: @icon-weak-color;
      }

      &:hover {
        color: @text-active-color;

        svg {
          fill: @icon-active-color;
        }
      }
    }
  }

  .record-card-section-title {
    font: @text-small-semi-bold;
    color: @text-weak-color;
    height: @line-height-500; // Same as @text-small-semi-bold to round height pixels to prevent showing scrolled text underneath

    span {
      vertical-align: middle;
    }

    svg {
      fill: @icon-color;
      .icon-large();
      vertical-align: middle;

      & + span {
        margin-left: @text-with-icon-separation;
      }
    }
  }

  .record-card-view {
    border: @border-default;
    border-radius: @border-radius-large;
    background-color: @background-white-color;
  }

  .record-card-view-item-container {
    display: flex;
    align-items: center;
    color: @text-color;
    padding: @spacing-1 @spacing-5;

    .record-card-view-item {
      .record-card-view-item-label {
        font: @text-small-semi-bold;
        margin-top: @spacing-1;
      }

      .record-card-view-item-value {
        font: @text-small-regular;

        &.highlighted-value {
         font: @text-small-semi-bold;
        }

        &.danger {
          color: @negative-alert-red;
        }

        &.warning {
          color: @negative-alert;
        }

        &.success {
          color: @positive-alert;
        }

        &.weak {
          color: @text-darker-weak-color;
        }
      }
    }

    .record-card-related-item-icon {
      margin: 0 30px;
      fill: @icon-color;
      flex-shrink: 0;
      .icon-medium();
    }

    .record-card-view-item-explanation {
      color: @text-weak-color;

      > span {
        vertical-align: middle;
      }

      svg {
        vertical-align: middle;
        fill: @icon-color;
        .icon-small();

        &.info-icon {
          fill: @icon-weak-color;
        }
      }

      .explanation {
        height: auto;

        svg {
          fill: @icon-weak-color;
          vertical-align: text-bottom;
        }
      }

      &.danger {
        color: @text-danger-color;

        > svg {
          fill: @icon-danger-color;
        }
      }

      &.warning {
        color: @text-warning-dark-color;

        > svg {
          fill: @icon-warning-color;
        }
      }

      &.success {
        color: @text-success-color;

        > svg {
          fill: @icon-success-color;
        }
      }
    }

    &:first-child {
      padding-top: @spacing-2;
    }

    &:last-child {
      padding-bottom: @spacing-3;
    }
  }

  .record-card-view-separator {
    border-top: @border-default;
    margin: @spacing-1;
  }
}
