.setup-goal-container {
  .setup-goal-intro {
    margin: 5px 0 @spacing-6;
  }

  .control-sub-label {
    font-weight: @font-weight-semi-bold;
  }

  .form-control, .form-control-dropdown, .metrics-info-link {
    margin-top: 6px;
  }

  .metrics-info-link {
    padding: 4px 6px;
  }

  .setup-goal-container-row {
    margin-top: @spacing-6;
  }

  .wrapped-row > div {
    margin-top: @spacing-6;
  }

  .margin-top-compensator {
    margin-top: -@spacing-6;
  }

  .delete-goal {
    margin-top: 20px;
    padding-right: 17px;
  }

  .percentage {
    vertical-align: sub;
    padding-left: 4px;
  }

  .currency {
    vertical-align: sub;
    padding-right: 4px;
  }

  .inline-text {
    margin-top: 14px;

    &.removable-option {
      margin-top: 10px;
    }
  }

  .explanation {
    height: 22px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .tooltip .tooltip-inner {
    font-weight: @font-weight-thin;
    padding: 20px;
  }

  .caption-link {
    display: flex;
    margin-top: 6px;
    font-size: @font-size-caption1;

    > span {
      margin-right: @spacing-1;
    }

    a {
      display: flex;
      .uncolored-icon {
        .icon-small();
      }
    }
  }

  .setup-goal-filters-view {
    .link-style-button {
      margin-top: 10px;
      font-weight: 300;
      display: inline-block;
    }

    .dropdown:not(.auto-width) {
      width: 200px;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .dropdown-with-checkbox {
    line-height: inherit;
  }
  
  .goal-warnings-container {
    padding: 10px 20px;
    background-color: @yellowBG;
    border: 1px solid @mustard-yellow;
    border-radius: 4px;
    display: inline-block;
  }
  
  .goal-warning {
    display: inline-block;
    padding-bottom: 10px;
    border-bottom: 1px solid @mustard-yellow;
    &:last-child {
      border: 0px;
      padding-bottom: 3px;
    }
  }
  
  .goal-info-icon {
    width: 5%;
    float: left;
    svg {
      fill: @icon-active-color;
    }
  }
    
  .goal-warning-icon {
    width: 5%;
    float: left;
    svg {
      fill: @icon-light-warning-color;
    }
  }
    
  .goal-warning-message {
    width: 95%;
    float: left;
    li {
      margin-left: 40px
    }
  }
}
