/* Base for label styling */
.checkbox-element {
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    display: none;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-right: 25px;
    padding-top: 3px;
    cursor: pointer;
    vertical-align: middle;
  }

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    svg {
      width: 12px;
      height: 12px;
      margin-top: 4px;
      margin-left: 3px;
      position: absolute;
      fill: @gray1;
    }
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left:0;
    top: 1px;
    width: 18px;
    height: 18px;
    border: 1px solid @gray1;
    background: @white;
    border-radius: 2px;
  }
  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    opacity: 0.2;
    cursor: default;
  }
  [type="checkbox"]:disabled:checked + label:after {
    color: #999;
    cursor: default;
  }
  [type="checkbox"]:disabled + label {
    color: #aaa;
    cursor: default;
  }
}

/* hover style just for information */
label:hover:before {
  box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
}

.form-title {
  color: @gray-dark;
  font-size: @form-title-font-size;
  font-weight: @font-weight-semi-bold;
  margin: @form-title-margin-top 0 0.67em 0;

  .explanation {
    margin-left: 5px;
    vertical-align: text-top;
    height: 20px;
  }

  &.with-intro {
    margin-bottom: 0.2em;

    &.large {
      margin-bottom: 25px;
    }
  }

  &.large {
    font-size: 26px;
    margin-top: 30px;
  }
}

.form-info-text {
  padding-block-end: 1em;
}

.form-title-underlined {
  color: @gray-dark;
  font-size: @form-title-font-size;
  font-weight: @font-weight-semi-bold;
  border-bottom: @light-gray-border;
  padding-bottom: 8px;
  margin: @form-title-margin-top 0 0;
}

.form-group {
  margin-top: 20px;

  &:last-child {
    margin-bottom: 20px;
  }

  .section-label {
    color: @black;

    & + p {
      margin-top: 5px;
    }
  }

  hr {
    margin-top: 30px;
  }
}

.form-group-reduced {
  margin-top: 6px;

  &:last-child {
    margin-bottom: 10px;
  }

  .autocomplete-with-checkbox, .dropdown-with-checkbox {
    &.form-multi-checkbox .box-with-icon {
      margin-top: 0;
    }
  }
}

.control-label {
  display: inline-block;
  font-weight: 600;
  color: @black;

  .actions {
    margin-left: 10px;
    font-weight: 200;
  }
}

.control-more-info {
  line-height: 24px;
  margin-top: 30px;
}

.control-label-explanation {
  font-style: italic;
  color: @gray2;
}

.control-label-explanation-placeholder {
  font-style: italic;
  color: @gray7;
}

.control-label-with-tooltip {
  display: inline-block;

  .control-label {
    border-bottom: 1px dashed @gray2;
    line-height: @form-font-size;
    margin-right: 10px;
  }
}

.control-sub-label {
  display: inline-block;
  font-weight: 400;
  color: #333333;
  font-size: 16px;
}

.control-group {
  .control-label {
    margin-bottom: 15px;
  }

  .control-input {
    margin-bottom: 10px;
    display: inline-block;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 8px;
  font-size: 15px;
  line-height: 1.42857143;
  color: @gray9;
  vertical-align: middle;
  background-color: @background-white-color;
  background-image: none;
  border: @gray-border;
  border-radius: @border-radius-small;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  &.form-control-inline-text {
    display: inline-block;
    width: 85%;
  }

  &::placeholder {
    font-style: italic;
  }

  &.with-top-text {
    margin-top: 6px;
  }
}
.form-control:disabled {
  border: @gray-border;
  background-color: #F8F8F8;
}


.form-control-dropdown {
  .form-dropdown();

  &.default-size {
    max-width: 300px;
  }

  &.auto-width {
    width: auto;
  }

  .dropdown-selection {
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: @border-radius-small;
  }

  &.form-input-dropdown {
    .dropdown-selection {
      padding: 4px 6px;
      color: @gray9;
      font-weight: 100;
      border-radius: @border-radius-small;
    }
  }

  &.disabled .dropdown-selection {
    padding: 5px 10px;
  }
}

.form-control, .form-control-dropdown {
   &.form-control-with-top-label {
    margin-top: 10px;
  }
}

.form-control-textarea {
  outline: none;
  border: @gray-border;
  border-radius: @border-radius-small;
  padding: 6px 12px;

  &.full-width {
    width: 100%;
  }

  &::placeholder {
    opacity: 1;
    font: @text-base;
    color: @text-disabled-color;
  }

  &.textarea-static {
    resize: none;
  }
}

.invalid-text {
  color: @error-message-red;
  border: 1px solid #D93A4D;
}

.row-error {
  .dropdown-combo .dropdown-selection,
  input.form-control {
    border: 1px solid @error-message-red;
    color: @error-message-red;

    .caret {
      border-bottom-color: @error-message-red;
    }

    .title .info {
      color: @error-message-red;
    }
  }

  .control-label,
  .control-sub-label .bold-font,
  .control-label-explanation,
  .input-label-helper,
  .decorated-link {
    color: @error-message-red;
  }
}

.form-error {
  color: @error-message-red;
  font-style: italic;

  &.small {
    line-height: 22px;
  }

  .bold-font {
    color: @error-message-red;
  }
}

.form-dropdown {
  width: 100%;
  &.dropdown {
    margin-left: 0;
  }
  &.dropdown-combo.dropdown-with-caret .dropdown-selection > span {
    min-width: 0;
  }
  &.dropdown-combo .dropdown-selection .caret {
    float: right;
    margin-top: 12px;
  }
  &.form-input-dropdown .dropdown-selection .caret {
    margin-top: 10px;
  }
}

.form-control,
.dropzone {
  margin-top: 10px;
}

input.transparent-input {
  background-color: transparent;
  border: none;
}

.input-with-icon {
  position: @custom-dashboard-sharing-search-input-position;

  input {
    color: @custom-dashboard-sharing-input-color;
    font-size: @custom-dashboard-sharing-input-font-size;
    font-style: @custom-dashboard-sharing-input-font-style;
    height: @custom-dashboard-sharing-input-height;
  }

  .form-control {
    padding-left: 40px;
  }

  &.normalize {
    .form-control {
      padding-left: 10px;
    }

    &.with-search-icon .form-control{
      padding-left: 40px;  
    }

    input {
      font-style: normal;
      color: @text-color-default;
    }
  }

  .input-icon {
    svg {
      position: @custom-dashboard-sharing-search-icon-position;
      left: @custom-dashboard-sharing-search-icon-left;
      top: @custom-dashboard-sharing-search-icon-top;
      pointer-events: @custom-dashboard-sharing-search-icon-pointer-events;
      margin-top: @custom-dashboard-sharing-search-icon-margin-top;
      fill: @icon-weak-color;
      .icon-medium();
    }

    &.right {
      svg {
        left: auto;
        right: 15px;
        pointer-events: auto;
        cursor: pointer;
        height: 14px;
        width: 14px;
        margin-top: 12px;
      }
    }

    &:not(.right) {
      opacity: 0.6;
    }
  }
}

.radio-button-group-multi-columns {
  .radio-button-group-multi-column {
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-right: 1px dashed @gray-lighter;
    vertical-align: top;

    &:last-child {
      border-right: none;
    }

    .title {
      font-weight: 600;
      margin-bottom: 5px;

    }

    .radio-button {
      display: block;
      margin-bottom: 5px;
    }
  }

}

.radio-button {
  display: block;
  margin-bottom: 5px;

  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    display: none;
  }
  [type="radio"]:not(:checked) + div.radio-button-circle,
  [type="radio"]:checked + div.radio-button-circle {
    position: relative;
    padding-right: 25px;
    padding-top: 3px;
    cursor: pointer;
  }

  [type="radio"]:not(:checked) + div.radio-button-circle,
  [type="radio"]:checked + div.radio-button-circle {
    svg {
      width: 12px;
      height: 12px;
      margin-top: 2px;
      margin-left: 3px;
      position: absolute;
      fill: @white;
    }
  }

  &.disabled {
    color: @gray-light;
    cursor: not-allowed;
  }

  [type="radio"] + div.radio-button-circle > .radio-button-circle-inside {
    margin: 3px;
    border-radius: 50%;
    background-color: transparent;
    display: inline-block;
    width: 10px;
    height: 10px;
  }

  [type="radio"]:not(:checked) + div.radio-button-circle,
  [type="radio"]:checked + div.radio-button-circle {
    display: inline-block;
    left: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    border: 1px solid @gray5;
    background: #FFFFFF;
    border-radius: 50%;
    padding: 0;
    margin-right: 8px;
  }

  [type="radio"]:checked + div.radio-button-circle > .radio-button-circle-inside {
    background-color: @gray;
  }

  /* checked mark aspect changes */
  [type="radio"]:not(:checked) + div.radio-button-circle:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="radio"]:checked + div.radio-button-circle:after {
    opacity: 1;
    transform: scale(1);
  }

  /* disabled checkbox */
  [type="radio"]:disabled:not(:checked) + div.radio-button-circle,
  [type="radio"]:disabled:checked + div.radio-button-circle {
    box-shadow: none;
    opacity: 0.2;
  }

  [type="radio"]:disabled:checked + div.radio-button-circle:after {
    color: #999;
  }

  [type="radio"]:disabled + div.radio-button-circle {
    color: #aaa;
  }

  .radio-button-sub-label {
    font-style: italic;
    color: @text-color-light;
  }

  .radio-button-sub-label-multiline {
    margin-left: 26px;
  }
}

.radio-buttons-section-spaced {
  margin-top: 10px;

  .radio-button {
    margin-bottom: 16px;
  }
}

.radio-buttons-section {
  margin-top: 5px;
  line-height: 24px;
  display: inline-block;

  .radio-button {
    margin-bottom: 10px;
  }

  [type="radio"] + div.radio-button-circle > .radio-button-circle-inside {
    width: 12px;
    height: 12px;
  }

  [type="radio"]:not(:checked) + div.radio-button-circle,
  [type="radio"]:checked + div.radio-button-circle {
    width: 20px;
    height: 20px;
  }

  .radio-button-tooltip {
    display: inline-block;
    padding-right: 20px;

    &.tooltip-enabled  {
      .radio-button-label {
        border-bottom: 1px dashed @gray5;
        cursor: pointer;
      }
    }
  }

  .confirmation-message {
    margin-left: 30px;
    margin-right: 20px;
  }
}

.horizontal-align-group {
  vertical-align: middle;
  line-height: 33px;

  [type="checkbox"]:not(:checked) + label svg, [type="checkbox"]:checked + label svg {
    width: 12px;
    height: 12px;
    margin-top: 9px;
  }
}

.above-the-fold-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  justify-content: flex-start;
  min-height: 400px;
  box-sizing: border-box;
  flex: 0 1 auto;

  .form-header {
    flex-grow: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid @gray-lightest;
    margin-bottom: 10px;

    &.no-border-nor-spacing {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    table {
      margin-bottom: 15px;
    }

    a {
      text-decoration: underline;
    }
  }

  .form-body {
    flex-grow: 2;
    position: relative;
    //overflow-y: auto;
    overflow-x: hidden; //Added for weird scroll showing up.

    .table-body {
      padding-bottom: 120px;

      &.no-results {
        border-bottom: 1px solid @gray-lightest;

        .table-row {
          border: none;
        }

        .form-entry-explanation {
          color: @gray2;
        }
      }
    }
  }

  .form-footer {
    margin-top: 10px;
    border-top: 1px solid @gray-lightest;
    flex-grow: 0;
    padding: 20px 0;

    &.no-border {
      border-top: none;
    }
  }
}
.dark-background-form {
  .radio-button [type="radio"]:not(:checked) + div.radio-button-circle,
  .radio-button [type="radio"]:checked + div.radio-button-circle {
    background: transparent;
    border: 1px solid @gray7;
  }

  .radio-button [type="radio"]:checked + div.radio-button-circle > .radio-button-circle-inside {
    background: @gray7;
  }

  [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
    border: 1px solid @gray7;
    background: transparent;
  }

  [type="checkbox"]:not(:checked) + label svg, [type="checkbox"]:checked + label svg {
    fill: @gray7;
  }

  [type="checkbox"]:disabled:not(:checked) + label:before, [type="checkbox"]:disabled:checked + label:before,
  [type="checkbox"]:disabled:not(:checked) + label svg, [type="checkbox"]:disabled:checked + label svg {
    opacity: 0.4;
  }
}

.white-background-form {
  [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
    border: 1px solid #BBBBBB;
    background: transparent;
  }

  [type="checkbox"]:not(:checked) + label svg, [type="checkbox"]:checked + label svg {
    fill: @text-color-default;
  }

  [type="checkbox"]:disabled:not(:checked) + label:before, [type="checkbox"]:disabled:checked + label:before,
  [type="checkbox"]:disabled:not(:checked) + label svg, [type="checkbox"]:disabled:checked + label svg {
    opacity: 0.4;
  }
  .checkbox-label {
    color: @text-color-default;
    font-size: 16px;

    .checkbox-info {
      font-style: italic;
      color: @text-color-light;
    }
  }

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    padding-right: @checkbox-label-left-padding;
  }

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    svg {
      width: 13px;
      height: 13px;
      margin-top: 5px;
    }
  }

  &.checkbox-list {
    > div:not(:last-child) {
      margin-bottom: 15px;
    }

    .checkbox-label {
      display: inline-block;
      width: calc(~"100% - @{checkbox-label-left-padding}");
      vertical-align: text-bottom;
      .ellipsis();
    }
  }
}

.form-section {
  color: #666666;
  font-size: @form-font-size;
  line-height: 1.7em;
  border-top: @gray-border;
  padding: 12px 0;
  margin-top: 10px;
  position: relative;

  &.form-section-spaced {
    padding-top: 30px;
  }

  .form-control {
    margin-top: 0;
  }

  .form-control-dropdown {
    .dropdown-selection {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .buttons {
    .button {
      .button.button-short;
    }
  }

  .radio-button-group {
    line-height: @line-height-base;
  }

  &.border-bottom {
    border-bottom: @gray-border;
  }

  &.read-only {
    .edit-link {
      color: #2E79B9;
      cursor:pointer;
      margin-top: 5px;
      position: absolute;
      right: 0;
    }

    .selected-options {
      margin-top: 0;

      li {
        padding: 0;
      }
    }
  }

  .form-section-explanation {
    font-style: italic;

    .inline-confirmation {
      margin-bottom: 15px;
    }
  }

  .explanation {
    margin-left: 5px;
    vertical-align: top;
    height: 20px;
  }

  .control-sub-label .small-explanation-icon {
    vertical-align: text-top;
  }
  
  .no-editable {
    padding: 13px 20px;
    background-color: @whiteBG;
    border: 1px solid @gray-lightest;
    margin: 15px 0;
    border-radius: @border-radius-small;
  }

  .intro {
    margin: 8px 0 0;
  }

  .highlight-pill {
    line-height: 16px;
  }
}

.form-section-list {
  .form-section:not(:first-child) {
    margin-top: 0;
  }

  &.section {
    margin-top: 10px;
  }

  .editable-form-section {
    background-color: inherit;
    box-shadow: none;
    border: none;
    .form-section;

    .editable-form-section-header {
      border: none;
      padding-bottom: 0px;
    }
  }
}



body.form-above-fold-active {
  #page {
    padding-bottom: 0;
  }
}


.removable-option {
  .label {
    vertical-align: middle;
    color: @gray;
  }

  .icon {
    cursor: pointer;

    svg {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      fill: @gray-light3;
    }
  }

  .label + .icon {
    margin-left: 15px;
  }

  .icon + .label {
    margin-right: 15px;
  }

  &.inline {
    display: inline-block;
    margin-right: 10px;

    .icon {
      margin-right: 5px;
    }
  }

  &.white-bg .icon svg {
    fill: @gray7;

    &:hover {
      fill: @gray-light3;
    }
  }

  &.highlighted {
    border-radius: 20px;
    background-color: @gray-lighter;
    padding: 2px 8px;
    margin-top: 15px;

    .icon svg {
      height: 20px;
      width: 20px;
    }

    .label {
      margin-right: 0;
    }
  }
}

.selected-options {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    padding: 10px 0;

    .icon {
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        fill: @gray-light2;
      }
      margin-right: 10px;

      &.disabled {
        opacity: 0.4;
        cursor: default;
      }
    }

    .label {
      vertical-align: middle;
      color: @gray;
    }

    &.non-removable .label {
      margin-left: 30px;
    }
  }

  &.indented-section {
    li {
      margin-left: 30px;
    }
  }

  &.reduced-spacing {
    li {
      padding: 2px;
    }
  }

  &.inline-options {
    margin-top: 0;
  }
}

form {
  .dropdown-selection {
    font-weight: @font-weight-thin;
  }

  .form-entry-explanation {
    font-style: italic;
    display: inline-block;
    line-height: 1.42857143;
  }

  .form-entry-explanation-row {
    font-style: italic;
    color: @gray2;
    margin-bottom: 10px;
    margin-left: 25px;
  }

  .control-input-row {
    margin: 10px 0;

    .label {
      color: @black;
    }
  }

  .inline-error.control-aligned {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-body {
    .table-row .label {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      .text-ellipsis();
    }
  }

  .section {
    .img-button {
      margin: 5px;
      background-color: @white;
      text-align: center;
      width: 290px;
      height: 180px;
      vertical-align: top;
      border-radius: 6px;
      border: 1px solid @gray-lightest;
      cursor: pointer;
      padding-right: 20px;
      padding-left: 20px;
      align-items: center;

      &.flexbox-column {
        align-items: center;
        justify-content: center;
      }

      &.full-width {
        min-width: 97%;
        margin-bottom: 15px;

        &.medium-img {
          height: 170px;
        }

        &.small-img {
          height: 100px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        & figcaption {
          max-width: 580px;
          margin-right: auto;
          margin-left: auto;
        }
      }

      & figcaption {
        margin-top: 20px;
        min-height: 44px;
      }

      &.selected,
      &:hover {
        border: 2px solid @actionGreen;
      }

      .img-button-container.flexbox {
        justify-content: start;
        flex-wrap: nowrap;
        width: 850px;
      }

      .img-button-section {
        width: 130px;
        margin-right: 60px;
        min-height: 60px;

        &.img-button-label {
          width: 80px;
          margin-right: 10px;
          text-align: left;
          padding-top: 10px;
        }

        svg {
          height: 34px;
          width: 100px;
        }

        .caption {
          font-size: @font-size-bodyText;
          font-style: italic;
        }
      }
    }

    &.table-section {
      padding-top: 30px;

      .label {
        display: inline-block;
        margin: 10px 0;
        max-width: 100%;
        .text-ellipsis();
      }
    }
  }
}

.form-page {
  padding-bottom: 50px;
}

.hidden-fields {
  position: relative;
  margin-top: 10px;

  .display-link {
    color: #2E79B9;
    cursor: pointer;
    text-decoration: none;

    &.right {
      position: absolute;
      right: 0;
    }

    &.underline {
      text-decoration: underline;
    }
  }

  .counter {
    color: @gray2;
  }
}

.removable-dropdown-row {
  margin-bottom: 4px;
  display: flex;
  flex-wrap: nowrap;

  .dropdown-selector {
    flex-shrink: 0;
    margin-right: 18px;

    & + .removable-option {
      padding-left: 0;
    }
  }

  .dropdown-with-checkbox {
    min-width: 100px;
  }

  .removable-option {
    cursor: pointer;
    text-align: center;
    padding: 0 10px;
    flex-shrink: 0;

    svg {
      width: @icon-default-size;
      height: @icon-default-size;
      fill: @gray7;
      vertical-align: middle;
    }
  }
}

.search-checkbox-combination {
  .input-with-icon {
    display: inline-block;
    width: 80%;
    margin-right: 24px;

     input:focus {
      outline: none;
    }

    .input-icon {
      svg {
        margin-top: 20px;
      }

      &.right {
        svg {
          height: 14px;
          width: 14px;
          fill: #ACACAC;
          margin-top: 23px;

          &:hover {
            fill: @gray2;
          }
        }
      }
    }
  };

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    svg {
      top: 0;
      margin-top: 4px;
    }
  }
}

.search-wrapper {
  min-width: 640px;
  padding: 0;
}

.multi-column-checkbox-list {
  margin-top: 25px;
  height: 380px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
  overflow-y: auto;
  .white-bg-scrollbar();

  > div {
    padding-right: 50px;
    color: @text-color-default;

    .group-options-disabled-tooltip {
      padding-right: 20px;
    }

    .checkbox-element {
      display: inline-block;
      min-width: 200px;
      max-width: 100%;
      .ellipsis();
    }
  }
}

.help-section {
  border: 1px solid @gray-lightest;
  border-radius: @border-radius-small;
  background-color: @white;
  padding: 20px;
  margin-top: 30px;

  .warning-section-title {
    font-weight: @font-weight-semi-bold;
    color: @black;
    text-transform: uppercase;
    margin-bottom: 5px;

    svg {
      fill: @negative-alert;
      vertical-align: middle;
      margin-right: 10px;
    }

    span {
      vertical-align: middle;
    }
  }
}

.input-label-helper {
  padding: 0;

  span {
    vertical-align: sub;
  }
}

.buttons.buttons-error-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  .button {
    flex-shrink: 0;
  }
}

.inline-form-label {
  margin-right: 10px;

  &:not(:first-child) {
    margin-left: 30px;
  }
}

.section-with-toggle {
  border-bottom: @light-gray-dashed-border;
  padding-bottom: 10px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .toggle-container {
    text-align: right;
  }

  &.section-with-toggle-no-border {
    border-bottom: none;
  }
}

.form-header-button {
  padding-top: 30px;

  .button {
    .loud-button;
  }
}

.editable-form-section {
  .page-section-contained;
  padding: 30px 30px 20px;
  margin-top: 30px;

  .editable-form-section-header {
    border-bottom: @light-gray-border;
    padding-bottom: 10px;

    .editable-form-section-title {
      vertical-align: middle;
      .bold-font;
    }

    .button {
      vertical-align: middle;
      padding: 0;
      border: none;
    }
  }

  .editable-form-section-content {
    .spaced-list-items + hr {
      margin-top: 40px;
    }

    .form-group {
      margin-top: 30px;
    }
  }
}

.input-in-text {
  color: @black;
  font-weight: @font-weight-semi-bold;
  font-size: @body-font-size;
  line-height: 24px;
  border: @gray-border;
  border-radius: @dropdown-menu-border-radius;
  padding: 4px 12px;
  min-width: 40px;
  font-style: normal;
  text-align: center;

  span + & {
    margin-left: 10px;
  }

  & + span {
    margin-left: 10px;
  }
}
