.main-card-page {
  height: 100%;

  .page-content-container {
    background-color: @white;

    .page-main-content {
      padding-bottom: 0;
    }

    .card-section {
      .no-graph {
        padding: 60px 30px;
        text-align: center;
      }
    }
  }

  .target-selector-org-chart {
    height: 100%;
  }

  .page-actions {
    padding-bottom: 18px;
  }
  
  .card-graph-view {
    padding-bottom: 5px;

    .axis-header {
      display: none;
    }
  }

  .chart {
    justify-content: center;
  }

  .multi-column-legend {
    text-align: right;
    padding: 25px 40px 0 30px;
    font-size: 13px;
    line-height: 14px;

    .legend-title {
      display: inline;
      color: @gray;
      font-weight: 200;
      text-transform: none;
      padding-bottom: 0;
      vertical-align: middle;
      line-height: 16px;

      &:after {
        content: ':';
      }
    }

    .color-legend {
      display: inline;
      margin-left: 15px;
    }

    .color {
      height: 12px;
      width: 12px;
    }

    &.small {
      .legend-entry {
        margin-bottom: 5px;
      }
    }
  }
  
  .read-only-legend {
    font-size: 13px;
    text-align: right;
    padding-right: 40px;
    padding-top: 2px;

    &.extra-space {
      padding-top: 20px;
    }
  }

  .card-detail.table {
    .empty-table {
      .header {
        margin-top: 20px;
      }

      .card-list-no-data {
        height: 200px;
      }
    }
  }

  .saved-card-actions + div > .card-detail.table .empty-table {
    .header {
      margin-top: 0;
    }
  }
}

@media only screen and (max-height: 1000px) {
  .main-card-page {
    .saved-card-actions.light-bg {
      margin-top: 15px;
    }
  }
}

// To prevent overlapping x-axis labels on opp health emails
@media only screen and (max-width: 1200px) {
  .main-card-page {
    .horizontal-bar-chart .x-axis {
      .tick:nth-child(odd) {
        display: none;
      }
    }
  }
}
