.preview {
  position: relative;
  display: block;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.08);
  border-radius: 5px;
  border: 1px solid #DDD;
  background-color: #fff;
  margin-bottom: 10px;
  height: calc(~"100% - 20px");

  &:focus {
    text-decoration: none;
  }

  .content {
    padding: 15px;
    height: 100%;
  }

  .preview-divider {
    display: block;
    height: 1px;
    background-color: #DDD;
  }

  &.preview-with-anomaly {
    .preview-divider {
      margin-bottom: 10px;
    }

    .preview-graph-view {
      padding-top: 0;
      margin: 0 50px;

      .stacked-bar-chart.multi-target .bar-chart-x-axis {
        margin-top: -3px;
      }
    }
  }

  .header {
    display: flex;
    padding: 0 5px 10px;
    justify-content: space-between;

    &.show-sales-coach {
      padding-bottom: 0;

      .title {
        max-width: 85%;
      }
    }

    .card-preview-actions .descriptive-dropdown-menu-list {
      width: 20vw;
      max-width: 300px;
    }
  }

  .title {
    max-width: 100%;

    .name {
      font-size: 16px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      font-weight: @font-weight-regular;
    }

    .sub-display {
      color: #666666;
      font-size: @font-size-bodyText;
    }
  }

  .preview-graph-view {
    padding: 12px 0 5px;
    margin: 0 -10px 0 -10px;
  }

  .anomaly-info {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: @spacing-3;

    .anomaly-deviation {
      font-size: 45px;
      font-weight: @font-weight-semi-bold;
      display: inline-block;
      color: @gray-dark;
      line-height: @line-height-1000;

      &.missed.active-anomaly {
        color: @goal-missed-alert;
      }

      &.behind.active-anomaly {
        color: @goal-behind-pace-alert;
      }

      &.risk.active-anomaly {
        color: @goal-high-risk-alert;
      }

      &.track.active-anomaly {
        color: @goal-on-track-alert;
      }

      &.hit.active-anomaly {
        color: @goal-hit-alert;
      }

      &.negative.active-anomaly {
        color: @negative-alert;
      }

      &.positive.active-anomaly {
        color: @positive-alert;
      }

      &.neutral.active-anomaly {
        color: @neutral-alert;
      }
    }

    .timeframe {
      color: @gray-light2;
      font-size: 14px;
      font-weight: 300;
      padding-left: 10px;
      vertical-align: middle;

      > div {
        display: inline-block;
      }

      p {
        margin: 0;
      }
    }
  }

  .summary {
    font: @text-xs-regular;
    color: @text-color;
    p {
      margin-bottom: @spacing-2;
    }
  }
}

.preview-container {
  margin-bottom: 20px;
}

.preview-placeholder-wrapper {
  height: 100%;

  .preview {
    margin-bottom: 0;
  }
}

.preview {

  &:hover {
    text-decoration: none;
  }

  &.preview-placeholder {
    background-color: @white;
    border: 1px solid @gray4;

    .preview-placeholder-content {
      height: 100%;

      .button.text-only {
        font-size: 18px;
      }

      svg {
        width: 50px;
        height: 50px;
        fill: @blue-link;
      }
    }
  }
}

a.preview:hover {
  box-shadow: 0 3px 20px 0 rgba(0,0,0,0.15);
}

.preview {
  .hover-mask {
    background-color: #333;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    border-radius: 5px;
    top: 0;
    left: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .hover-actions {
    opacity: 0;
    position: absolute;
    width: 100%;
    display: none;
    top: calc(~"50% - 30px"); // TODO substitute 30 by 60 / 2
    transform: translateY(-50%);
    left: 0;
    transition: opacity 0.2s ease-in-out;
    text-align: center;

    .button {
      text-transform: uppercase;
      padding: 10px 45px;
    }
  }

  &:hover {

    .hover-mask {
      display: block;
      opacity: .8;
      transition: opacity 0.2s ease-in-out;
    }
    .hover-actions {
      display: block;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }

    .preview-shortcuts {
      background-color: #373737;

      .preview-shortcut {
        display: inline-block;
      }
    }
  }

  .rollover-actions{
    display: none;
  }

  .preview-shortcuts {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    left: 0;
    text-align: right;
    padding: 10px;
    display: block;
    background-color: transparent;
    .border-bottom-radius(5px);

    .preview-shortcut {
      line-height: 0;
      margin-top: 5px;
      display: none;
      margin-left: 10px;

      .reaction-picker .reactions-opener.active, .reaction-picker .reactions-opener:hover {
        background-color: transparent;
      }

      svg {
        fill: #A4A4A4;
        width: 30px;
        height: 30px;
        padding: 4px;
      }

      &:hover {
        cursor: pointer;
        cursor: hand;
        svg {
          background-color: transparent;
          fill: #FFF;
        }
      }

      &.preview-messages-unread:hover svg {
        fill: #AEDCBE;
      }

      &.preview-messages-unread:active,
      &:active {
        svg:active {
          //border: 1px solid #14B832;
          //background-color: #14B832;
          fill: #FFF;
        }
      }

      &.preview-messages-unread {
        display: inline-block;

        svg {
          fill: #14B832;
        }
      }
    }
  }

  .strategy-insight {

    .strategy-logo, .strategy-text {
      display: inline-block;
      vertical-align: middle;
      line-height: 0;
    }

    .strategy-logo svg {
      width: 15px;
      height: 15px;
    }

    .strategy-text {
      font-size: @font-size-bodyText;
      color: #999;
      margin-left: 5px;
      text-transform: uppercase;
      font-weight: 200;
      line-height: 15px;
    }
  }
}

.preview.scenario-preview {
  .show-sales-coach .title .name {
    margin-bottom: 15px;
  }

  .preview-divider {
    margin-bottom: 10px;
  }

  .strategy-insight {
    margin-bottom: 14px;
  }

  .scenario-preview-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .summary {
      margin-bottom: 20px;
      height: auto;
    }

    .scenario-explanation {
      flex-grow: 1;
    }
  }
}

@media only screen and (max-width:@page-width) {
  .preview {
    .hover-actions {
      .button {
        text-transform: uppercase;
        padding: 10px 10px;
      }
    }
  }
}


.preview.preview-presentation {
  .content {
    padding: 25px 25px 20px;
  }

  .title {
    padding: 0 5px 15px;

    .name {
      font-size: @font-size-h2;
    }

    .sub-display {
      font-size: 19px;
    }
  }

  .preview-graph-view {
    padding: 0;
  }
}

.card-preview-placeholder {
  display: flex;
  justify-content: center;
  // Set a min height to avoid multiple recommendation component pages from being requested on load
  // Only applies before dynamic height is calculated in CardPreviewPlaceholder
  min-height: calc(~"100vh / 3");
}

.card-preview-only {
  .preview {
    .anomaly-info .anomaly-deviation {
      font-size: @font-size-1000;
    }
  }
}