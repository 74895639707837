.target-selector-org-chart {
  position: fixed;
  width: @org-chart-width;

  .panel-children > div {
    display: inline-block;
    margin-left: 5px;
    vertical-align: top;
  }

  // TODO: Ask Iggy how I can avoid all this duplication with the base scroll-panel.less file
  .scrollable-panel {

    .scroll-up {
      &.scrolling-element-non-overlapping {

        &.scrolling-element-hidden {
          .scroll-arrow {
            .up-arrow(transparent, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);
          }
        }

        &.scrolling-element-visible {
          .scroll-arrow {
            .up-arrow(@white, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);

            &:hover {
              .up-arrow(@actionGreen, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);
            }
          }
        }
      }
    }

    .scroll-down {
      &.scrolling-element-non-overlapping {

        &.scrolling-element-hidden {
          .scroll-arrow {
            .down-arrow(transparent, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);
          }
        }

        &.scrolling-element-visible {
          .scroll-arrow {
            .down-arrow(@white, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);

            &:hover {
              .down-arrow(@actionGreen, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);
            }
          }
        }
      }
    }

    .scroll-left {
      &.scrolling-element-non-overlapping {

        &.scrolling-element-hidden {
          .scroll-arrow {
            .left-arrow(transparent, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);
          }
        }

        &.scrolling-element-visible {
          .scroll-arrow {
            .left-arrow(@white, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);

            &:hover {
              .left-arrow(@actionGreen, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);
            }
          }
        }
      }
    }

    .scroll-right {
      &.scrolling-element-non-overlapping {

        &.scrolling-element-hidden {
          .scroll-arrow {
            .right-arrow(transparent, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);
          }
        }

        &.scrolling-element-visible {
          .scroll-arrow {
            .right-arrow(@white, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);

            &:hover {
              .right-arrow(@actionGreen, @org-chart-column-arrow-size, @org-chart-column-arrow-border-width);
            }
          }
        }
      }
    }
  }

  .column {

    .children {
      padding: 7px;
      border: 1px solid transparent;

      &.select-all {
        border-color: @gray1;
        border-radius: 5px;

        .select-all-wrapper {
          margin: 2px 0;
          display: inline-block;

          .select-all-checkbox {
            font-size: @font-size-caption1;
            color: @white;
            vertical-align: top;
          }
        }
      }
    }

    .team-section-header {
      background-color: #2A588F;
      color: @white;
      font-size: @font-size-caption1;
      font-weight: @font-weight-regular;
      line-height: 14px;
      white-space: normal;
      width: 200px;
      padding: 8px 10px;
      border-radius: 2px;
      word-wrap: break-word
    }

    .target {
      position: relative;
      box-sizing: border-box;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      background-color: @lightBG1;
      border-radius: @org-chart-target-radius;
      width: @org-chart-target-width;
      height: @org-chart-target-height;
      margin-top: 1px;
      cursor: pointer;
      color: @gray1;
      font-size: @font-size-caption1;
      .clearfix();
      overflow-y: hidden;
      padding: 5px 10px;

      .target-name {
        white-space: normal;
        height: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow-y: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
      }

      .target-former-icon {
        line-height: 0;
        padding-right: 8px;

        svg {
          fill: @gray1;
          .icon-medium();
        }
      }

      &:hover, &:focus {
        outline: none;
        text-decoration: none;
      }

      &:hover {
        background-color: @lightBG2;
        .target-name {
          color: @black;
        }

        .expand {
          &:before {
            border-color: @actionGreen;
          }
        }
      }

      &:active {
        .target-name {
          color: @actionGreen;
        }
      }

      &.navigation {
        .name-section {
          display: flex;
          align-items: center;

          .target-name {
            width: 160px;

            &.target-with-icon {
              width: 140px;
            }
          }

          .explanation {
            height: unset;

            svg {
              .icon-small();
            }
          }
        }
      }

      &.expanded {
        background-color: @white;
        color: @black;
        font-weight: @font-weight-medium;

        &:after {
          font-size: 8px;
          position: absolute;
          right: -4px;
          .triangle-right(@white);
        }

        .expand {
          &:before {
            border-color: @gray3;
          }
        }

        &:focus,
        &:hover,
        &:active {
          cursor: default;

          .target-name {
            color: @gray1;
          }

          .expand {
            &:before {
              border-color: @gray3;
            }
          }
        }
      }

      .expand-container {
        line-height: 0;
        position: absolute;
        right: 0;
        padding-right: 10px;
      }

      .expand {
        text-align: right;
        font-size: 1.5rem;


        .right-arrow(@gray1, @org-chart-target-arrow-size, @org-chart-target-arrow-border-width);

        &:before {
          vertical-align: sub;
        }

      }

      .target-title {
        color: @gray2;
        font-size: @font-size-caption2;
        .text-ellipsis();
      }

      &.target-selectable {
        color: @black;
        font-weight: @font-weight-regular;

        &:hover {
          background-color: @lightgreen;
        }

        &:active {
          .name-section {
            .target-name {
                color: @actionGreen;
            }
          }
        }

        &.target-selected {
          background-color: @lightgreen;

          .icon-section {
            position: absolute;
            right: 0;
            padding-right: 8px;

            .selected-icon {
              fill: @actionGreen;
              width: 13px;
              line-height: 0;

              svg {
                height: 13px;
                width: 13px;
              }
            }
          }

          .name-section {
            width: 160px;

            .target-name {
              color: @actionGreen;
              .bold();
            }
          }

          .target-former-icon svg {
            fill: @actionGreen;
          }

          &.former .name-section {
            width: 135px;
          }
        }

        > div {
          display: inline-block;

          &.check-section {
            width: 10px;
            &:before {
              content: "";
            }

            .target-selected {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: green;

              &:before {
                content: "";
              }
            }
          }

          &.name-section {
            width: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }

  .org-chart-former-toggle {
    padding-left: 10px;
    margin-bottom: 5px;

    .toggle-button {
      vertical-align: middle;

      svg {
        width: 14px;
        height: 14px;
      }

      .toggle-button-side {
        width: 16px;
      }

      .toggle-button-center {
        margin-left: -8px;
        margin-right: -8px;
        width: 18px;
      }

      .toggle-button-side,
      .toggle-button-center {
        height: 16px;
      }
    }

    .toggle-label {
      color: white;
      font-size: @font-size-caption1;
    }

    .explanation {
      margin-left: 5px;
      height: 25px;
    }
  }

  .close-icon {
    position: fixed;
    right: 0;
    padding: 10px 20px;
    cursor: pointer;
    pointer-events: auto;

    svg {
      .icon-x-large();
      fill: @white;
      opacity: 0.4;
    }
  }

  .target-selector-user-search {
    pointer-events: auto;
    font-size: 14px;
    padding: 24px 0 16px;
    margin-right: 20px;
    border-bottom: 1px solid @black;

    .help-text {
      color: @white;
      margin-bottom: 10px;
    }

    .dropdown {
      width: 370px;

      .dropdown-selection {
        border-radius: @border-radius-small;
        padding: 5px 10px;
        opacity: 0.6;

        &:hover {
          background-color: @black;
        }

        &:active {
          opacity: 1;
        }

        svg {
          margin-right: 10px;
        }

        .info {
          font-style: italic;
          font-weight: @font-weight-thin;
          vertical-align: middle;
        }
      }

      .dropdown-menu-container {
        min-width: 100%;
      }
    }
  }

  .button-dark-mode {
    border-color: @gray2;
    color: @gray2;
    background-color: transparent;
    text-transform: uppercase;
    font: @text-2xs-medium;
    width: @org-chart-target-width;
    height: @org-chart-target-height;
    margin: 0 7px;

    &:hover {
      color: @text-on-dark-color;
      border-color: @text-on-dark-color;
    }
  }

  .org-chart-empty-groups-view {
    padding: 0 @org-chart-column-arrow-size;
    margin-top: 80px;
    color: @text-on-dark-color;
  }
}

body.dropdown-open {
  .target-selector-org-chart .scrollable-panel .row > div {
    visibility: hidden;
  }

  .target-modal .target-selector-org-chart .scrollable-panel .row > div {
    visibility: visible;
    opacity: 0.2;
    pointer-events: none;
  }
}
