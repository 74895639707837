.saved-goal-status-edit-table-column-view {
  .saved-goal-status-checkbox {
    padding: 8px;
    border-radius: @border-radius-default;
    background: @background-medium-color;
    margin-bottom: 4px;
    color: @text-color;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.saved-goal-status-checkbox-checked {
      background: @background-active-color;
    }
  }
  
}
