.saved-goal-status-edit-table {
  height: 100%;
  padding: 0 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .saved-goal-status-edit-table-header {
    top: 0;
    position: sticky;
    background: @background-white-color;

    .saved-goal-status-edit-table-header-title {
      display: flex;
      align-items: center;
      padding: 21px 16px 0;
      justify-content: space-between;
      > span {
        color: @text-weak-color;
        font: @text-small-semi-bold;
      }
    }
  }
}

.saved-goal-status-edit-header {
  display: flex;
  padding: @spacing-1 @spacing-5;
  align-items: center;
  justify-content: flex-end;
}
