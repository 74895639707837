.checkbox-element-v1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: @font-size-bodyText;
  font-weight: @font-weight-regular;
  &.checkbox-element-disabled {
    cursor: default;
  }
  [type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  [type="checkbox"] + .checkbox-element-icon {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 1px solid @border-color;
    background: @white;
    border-radius: 3px;
    margin-right: 9px;
    transition: all 150ms;
  }
  
  /* checked mark aspect changes */
  [type="checkbox"]:checked + .checkbox-element-icon {
    background: @background-blue-color;
    border-color: @background-blue-color;
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled + .checkbox-element-icon {
    background: @background-weak-color;
    border-color: @background-weak-color;
  }

  [type="checkbox"]:checked:not(:disabled):hover + .checkbox-element-icon {
    border-color: @border-active-hover-color;
    background: @background-blue-hover-color;
  } 
}
