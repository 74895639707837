.all-metric-link {
  &:hover {
    text-decoration: none;
  }
}

.action-items-container {
  .page-section-contained();
  padding: 0 20px;
  margin-top: 20px;
  color: @text-color;

  .goal-status-component,
  .goal-history-component {
    .loading-dialog {
      padding-bottom: 50px;
    }

    .empty-view {
      margin: 100px 20px;
      text-align: center;
    }
  }

  .goal-history-component {
    min-height: 300px;

    .goal-history-actions {
      margin-left: auto;
    }
  }

  .action-items-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: @spacing-5;
    border-bottom: @border-default;
    font: @text-display-large;

    .action-items-tab {
      padding: 14px 0 12px;

      &.active {
        color: @text-active-color;
        position: relative;
      }
      
      &.inactive {
        &:hover {
          color: @text-hover-color;
          cursor: pointer;
        }
      }

      .action-items-active-tab-indicator {
        position: absolute;
        top: 100%;
        height: 1px;
        width: 100%;
        background-color: @text-active-color;
      }
    }
  }

  .action-items-controls-list,
  .action-items-controls-list-read-only {
    background-color: @background-medium-color;
    border-top: @border-default;
    border-bottom: @border-default;
    padding: 10px 20px;
    font: @text-xs-medium;
    color: @text-color;
  }

  .action-items-controls-list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: @spacing-5;

    .action-items-control {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font: @text-xs-medium;
      max-width: 20%;

      &.fixed-width {
        flex-shrink: 0;
      }

      .dropdown-selection {
        color: @text-weak-color;
        font: @text-xs-medium;
        .label, .placeholder {
          .ellipsis();
        }
      }

      .dropdown-with-checkbox {
        min-width: 0;
        position: relative;
      }

      .toggle-label {
        font: @text-xs-medium;
        color: @text-color;
        white-space: nowrap;
      }
    }

    .action-items-control-label {
      color: @text-color;
      margin-right: @spacing-1;
      flex-shrink: 0;
    }
  }

  .action-items-section {
    border-bottom: @border-default;

    &:last-child {
      border-bottom: none;
      padding-bottom: @spacing-5;
    }
  }
}

.action-items, .action-items-container {
  .action-items-expanded-content:not(.action-items-expanded-undivided) {
    border-top: 1px solid @gray-lightest;
  }

  .section-controls {
    padding-top: 15px;
    padding-left: 10px;

    &.page-secondary-header {
      background-color: @background-white-color;
    }

    .section-control {
      display: inline-block;
      margin-right: 20px;
    }

    .section-control-label {
      color: @text-color-light;
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
    }

    .dropdown-with-checkbox {
      display: inline-block;
      position: relative;
    }

    .dropdown-as-title {
      text-decoration-color: @black;
      .dropdown-selection {
        .title, .placeholder, .label {
          font-size: @dropdown-menu-font-size;
        }
      }
    }
  }

  .widget-status {
    margin: 15px 0;

    .text-explanation {
      padding: 15px 0;
      font-size: @font-size-h3;
      color: @text-color-bold;
    }
  }

  .alert-activity-metric {
    margin-bottom: 10px;
  }

  .alert-box {
    display: inline-block;
    height: 25px;
    width: 27px;
    border-radius: @border-radius-small;
    text-align: center;

    &.warning-alert-count {
      border: 1px solid #EE803E;
      color: #EE803E;
      margin-right: 2px;
    }

    &.positive-alert-count {
      border: 1px solid #5DBA7D;
      color: #5DBA7D;
      margin-right: 2px;
    }
  }

  .see-more-activity {
    margin-left:10px;
  }

  .see-more {
    padding-bottom: 18px;
  }

  .action-item-title {
    padding: 20px 20px 12px;

    .action-item-tile-dropdown .dropdown-selection .title,
    h3 {
      display: inline-block;
      font: @text-display-base;
      color: @text-color;
      margin: 0;
      vertical-align: middle;
    }

    .explanation {
      display: inline-block;
      margin-left: @text-with-icon-separation;
      height: auto;

      .tooltip .tooltip-inner {
        font: @text-xs-medium;
      }

      svg {
        fill: @icon-weak-color;
        .icon-medium();
      }

      &:hover svg {
        fill: @icon-hover-color;
      }
    }
  }

  .anomaly-widget-title {
    color: @text-color;
    font-size: @font-size-300;
    font-weight: @font-weight-semi-bold;
    line-height: @line-height-500;
    padding: 14px 2px 12px;

    .title {
      vertical-align: middle;

      &.loud-title {
        font-size: @font-size-500;
      }
    }
  }

  .anomaly-widget-body{
    margin-bottom: 5px;
  }

  &.anomaly-widget .icon {
    display: inline-block;
    border-radius: 50%;
    padding: 3px;
    line-height: 0;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .icon {
    margin-right: 12px;

    &.positive {
      background-color: @positive-alert;
      fill: @icon-on-dark-color;
    }

    &.negative {
      background-color: @negative-alert;
      fill: @icon-on-dark-color;
    }

    &.strategy-insight {
      display: inline-block;
      vertical-align: text-bottom;
      margin-right: 8px;
      padding: 0;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  .loading-dialog {
    font-size: @font-size-h2;
    padding-bottom: 0;
  }

  .action-items-item-base {
    background-color: @text-on-dark-color;
    color: @gray;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 18px;
    border-top: 1px solid @gray-lightest;
  }

  .action-item-expanded-view {
    cursor: pointer;
  }

  .action-item-meta-anomaly ul {
    padding-left: 20px;
  }

  .action-items-view-all {
    text-align: center;
    margin-top: @action-items-view-all-margin-top;
    a {
      color: @action-items-view-all-color;
      font-weight: normal;
      text-decoration: underline;
    }
  }

  .anomaly-list {
    margin-bottom: 0;
    margin-top: 20px;

    li.secondary-text {
      &.thin-bullet-list-item {
        padding-left: initial;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .color {
      font-weight: bold;
    }
  }

  .all-metric-tab {
    .loading-dialog {
      width: 100%;
    }
  }

  .metrics-info-explanation {
    height: 20px;
    margin-left: @spacing-1;
    svg {
      margin-right: 8px;
    }
  }

  .action-items-selector {
    padding: 23px 10px;
    margin-right: 20px;

    .action-items-selector-control-label {
      margin-right: @spacing-2;
      color: @text-color;
      font: @text-base;
      vertical-align: text-top;
    }

    .dropdown-as-title {
      max-width: 80%;

      .dropdown-selection .title {
        font: @text-display-small;
      }
    }
  }


  .unselectedTitle {
    color: @text-color;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 30px;
  }

  .action-items-see-more,
  .action-items-item {
    .recommended-action-link {
      color: @link-color;
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .action-items-title,
  .anomaly-widget-title {
    .dropdown-container {
      .right-content;
    }

    .dropdown-more {
      text-align: right;

      &.dropdown-active .dropdown-selection {
        background: none;
      }

      .dropdown-selection {
        border: none;

        &:hover {
          background: none;
        }

        svg {
          .icon-large();
        }
      }

      .dropdown-label-svg {
        padding: 0;
      }
    }
  }

  .action-items-title {
    &.page-secondary-header {
      background-color: @background-white-color;
    }
    .dropdown-container {
      padding: 20px 5px;
    }

    .action-items-section-title {
      font: @text-display-base;
      color: @text-color;
      padding: 16px 16px 16px 0;

      &.title-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }

      span {
        vertical-align: middle;
      }

      .explanation {
        height: auto;
      }

      .link-button {
        margin-left: @spacing-4;
      }
    }
  }

  .mascot-in-hammock {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 307px;
  }

  .no-alerts {
    margin-top: 40px;
    text-align: center;
  }

  .anomaly-summary, .secondary-text {
    color: @gray;
    font-weight: @font-weight-thin;
    font-size: @body-font-size;
    line-height: 24px;

    .color {
      font-weight: bold;
    }

    .recommended-action-link {
      color: @text-color;
    }
  }

  .anomaly-summary .bold {
    color:@gray-dark;
  }

  .anomaly-action {
    margin-left: @spacing-3;
    flex-shrink: 0;
  }

  .confirmation-message {
    background-color: #FCF8E3;
    
    .recommended-action-link {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .action-item-link:hover {
    text-decoration: none;
  }

  .action-item-content {
    margin-left: 7px;
    margin-right: 7px;

    .meta-scenario-summary {
      svg {
        height: 18px;
        width: 16px;
        margin-right: 3px;
        vertical-align: top;
      }
    }

    .expanded-content {
      font-weight: normal;
    }

    .title {
      font-weight: @font-weight-bold;
    }

    .anomaly-summary {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;

      p {
        .ellipsis();
      }

      .positive {
        color: @positive-alert;
      }

      .negative {
        color: @warning-alert;
      }

      .color-bold {
        font-weight: bold;
      }

      .italic {
        font-style: italic;
      }
    }
  }

  .strategy-insight + .anomaly-summary {
    max-width: calc(~"100% - 30px");
  }

  .action-items-see-more {
    border-top: 1px solid #DDDDDD;
    padding: 30px 10px;
  }

  .alert-actions {
    visibility: hidden;
    height: 100%;
    .flexbox;
    .middle-columns;

    .alert-action-container {
      margin-right: 10px;
      position: relative;
      display: inline-block;
      line-height: 15px;

      &:last-of-type {
        margin-right: 20px;
      }

      .alert-action {
        display: inline-block;
        text-transform: uppercase;
        background-color: #777777;
        border-color: #777777;

        &:hover {
          cursor: pointer;
        }

        &.alert-action-hide:hover {
          background-color: #1E75CE;
          border-color: #1E75CE;
        }

        &.alert-action-not-useful:hover {
          background-color: #D0021B;
          border-color: #D0021B;
        }
      }
    }
  }

  .scenario-row-preview:hover {
    background-color: @background-active-color;
  }

  .action-items-item,
  .scenario-row-preview {
    &:hover {
      .alert-actions {
        visibility: visible;
      }
    }
  }

  .action-items-item {

    p {
      margin: 0;
    }

    .alert-actions {
      float: right;
    }

    .scenario {
      .strategy-actions {
        .alert-actions {
          padding-top: 0;
          height: 30%;
        }
      }

      .strategy-insight {
        text-align: center;

        .strategy-logo, .strategy-text {
          display: inline-block;
          vertical-align: middle;
          line-height: 0;
        }

        .strategy-logo svg {
          width: 15px;
          height: 15px;
        }

        .strategy-text {
          font-size: @font-size-caption1;
          color: #999;
          margin-left: 5px;
          text-transform: uppercase;
          font-weight: 200;
        }
      }
    }

    &:hover {
      background-color: #EFF5FB;

      .anomaly-summary {
        white-space: normal;
        text-overflow: clip;

        p {
          white-space: normal;
          text-overflow: clip;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .scenario-alert-icon {
      width: 30px;
      height: 30px;
    }

    .expanded-content {
      padding-top: 20px;
      font-weight: 400;

      .related-alerts {
        .bucket-strategy-logo {
          padding-right: 5px;

          svg {
            width: @bucket-strategy-logo-width;
            height: 20px;
            vertical-align: text-bottom;
          }
        }

      }
        ul li {
          line-height:1.5em;
        }
    }

    .recommended-action-section {
      padding-bottom: 20px;

      ul {
        margin-top: 0.5em;
        margin-bottom: 0;

        .display {
          font-weight: @font-weight-regular;
        }
      }
    }

    .recommended-action {
      color: #E77A3C;
    }

    .action-header {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 4px;
      text-transform: uppercase;
    }

    .recommended-action-detail {
      font-weight: normal;
    }

    .other-actions {
      color: #777777;
    }

    .anomaly-name {
      font-size: 18px;
      font-weight: bold;
      margin-left: 5px;
      color:@gray-dark;
    }

    .display-text {
      font-weight: normal;
      color: #666;
      font-size: 16px;
      display: inline-block;

      > div {
        display: inline-block;

        > p {
          display: inline-block;
        }
      }
    }

    @media only screen and (max-width:@page-width) {

      .alert-actions {
        .alert-action-container {
          margin-right: 5px;

          &:last-of-type {
            margin-right: 10px;
          }

          .alert-action {
            font-size: 11px;
          }
        }
      }
    }

    .anomaly-summary-section {
      .highlight-pill {
        font-size: 11px;
      }

      .bucket-strategy-actions {
        .alert-actions {
          padding-top: 0;
          height: 30%;
        }
      }

      .bucket-strategy-insight {
        text-align: center;

        .bucket-strategy-logo, .bucket-strategy-text {
          display: inline-block;
          vertical-align: middle;
          line-height: 0;
        }

        .bucket-strategy-logo svg {
          width: @bucket-strategy-logo-width;
          height: @bucket-strategy-logo-height;
        }

        .bucket-strategy-text {
          font-size: @font-size-caption1;
          color: @bucket-strategy-text-color;
          margin-left: @bucket-strategy-text-margin-left;
          text-transform: @bucket-strategy-text-transform;
          font-weight: @bucket-strategy-text-font-weight;
        }
      }
    }
  }
}

.action-item-expanded-view .anomaly-summary-section {
  display: flex;
  align-items: center;
}

.scenario-row-preview {
  display: block;
  color: @text-color-default;
  padding: 30px 10px;
  border-top: @light-gray-border;

  &:focus, &:hover {
    text-decoration: none;
    color: @text-color-default;
  }

  .recommended-action-link {
    color: @text-color-bold;
    text-decoration: underline;
  }

  .header {
    font-weight: @font-weight-semi-bold;
    color: @text-color-bold;
    margin-bottom: 5px;
  }

  .scenario-preview-content-container {
    max-width: @page-width;

    .anomaly-summary ~ .sales-coach-button {
      margin-top: @spacing-4;
    }
  }

  .scenario-explanation {
    padding-left: 10px;
  }

  .anomaly-deviation, .name, .alert-actions {
    vertical-align: middle;
  }

  .alert-actions {
    display: inline-block;
    margin-left: 20px;
    vertical-align: top;
  }

  @media only screen and (max-width:@page-width) {

    .alert-actions {
      .alert-action-container {
        .alert-action {
          font-size: 11px;
        }
      }
    }
  }
}

.team-icon {
  svg {
    .icon-medium();
    vertical-align: text-bottom;
    fill: @text-color-default;
    margin-right: 8px;
  }
}
