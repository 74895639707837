.custom-group-config-modal {
  .with-top-text {
    margin-top: 6px;
  }

  .descriptive-dropdown {
    margin-bottom: 12px;

    .dropdown-selection {
      border: @gray-border;
      border-radius: @border-radius-small;
    }

    .descriptive-dropdown-menu-list {
      max-height: max-content;

      .descriptive-dropdown-menu-item {
        padding: @spacing-2 @spacing-2;
      }
    }
  }

  .custom-group-content-description {
    font: @text-base;
  }

  .explanation {
    line-height: inherit;
  }

  .custom-group-content {
    .dropdown-selection {
      padding: @spacing-2 10px;
    }

    .autocomplete-multi-checkbox {
      .autocomplete-with-checkbox {
        .box-with-icon {
          display: flex;
          box-shadow: none;
          align-items: center;
          border: @gray-border;
          background-color: @background-white-color;

          .placeholder {
            font: @text-base;
          }
        }
      }

      .selected-options .removable-option {
        max-width: 100%;
        font: @text-base;
        padding: 2px @spacing-2;

        .label {
          color: @text-color-default;
        }
      }
    }
  }
}
