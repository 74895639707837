.masonry-layout {
  column-gap: 0;

  .cell {
    break-inside: avoid;
  }

  &.masonry-2-col {
    column-count: 2;
  }

  &.masonry-3-col {
    column-count: 3;
  }

  &.masonry-4-col {
    column-count: 4;
  }
}

@media only screen and (max-width:@page-width) {
  .masonry-layout {
    &.masonry-md-2-col {
      column-count: 2;
    }

    &.masonry-md-3-col {
      column-count: 2;
    }

    &.masonry-md-4-col {
      column-count: 2;
    }
  }
}

