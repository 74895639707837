//TODO move all radio button styles here
.radio-button {
  &.radio-button-small {
    font: @text-xs-regular;
    color: @text-color;

    .explanation {
      margin-left: @spacing-1;
      height: auto;
      line-height: inherit;

      svg {
        fill: @icon-weak-color;
        .icon-small();
        vertical-align: text-top;
      }
    }

    & [type="radio"]:not(:checked) + div.radio-button-circle,
    & [type="radio"]:checked + div.radio-button-circle {
      height: 16px;
      width: 16px;
    }

    & [type="radio"]:checked + div.radio-button-circle {
      background-color: @background-blue-color;
      border-color: @background-blue-color;
    }

    & [type="radio"]:checked + div.radio-button-circle > .radio-button-circle-inside {
      background-color: @background-white-color;
    }

    & [type="radio"] + div.radio-button-circle > .radio-button-circle-inside {
      height: 6px;
      width: 6px;
      margin: 4px;
    }

    & [type="radio"]:disabled:not(:checked) + div.radio-button-circle,
    & [type="radio"]:disabled:checked + div.radio-button-circle {
      background-color: @background-weak-color;
      border-color: @background-weak-color;
      opacity: 1;
    }

    div.radio-button-circle,
    .radio-button-circle-inside {
      border-radius: 30px;
    }
  }
}
