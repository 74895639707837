.fox-error-view {
  text-align: center;
  margin-bottom: 60px;

  img {
    width: 195px;
  }

  .title {
    margin-top: 20px;
    color: @black;
    font-size: @font-size-h1;
    font-weight: @font-weight-semi-bold;
  }

  .message {
    margin-bottom: 30px;

    &.secondary {
      margin-top: 30px;
    }

    a {
      text-decoration: underline;
    }

    ~ .buttons {
      .button {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
  }

  &.page-view {
    max-width: 900px;
    .center-block();

    img {
      width: 375px;

      &.medium-img {
        width: 250px;
      }
    }

    .title {
      margin-top: 50px;
      margin-bottom: 5px;
      font-size: 40px;
    }

    .message {
      font-size: @font-size-h2;
    }

    .button {
      margin-top: 25px;
      font-weight: @font-weight-regular;
    }

    .sad-fox-big {
      .center-block();
      svg {
        height: 250px;
        width: 230px;
      }
    }

    .sad-fox-huge {
      svg {
        height: 280px;
        width: 250px;
      }
    }
  }
}
