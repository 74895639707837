// These values need to be in sync with the ones in team-alert-graph-bars.js
@unit-height: 15px;
@max-count: 10;
@bottom-padding: 20px;
@max-stripped-units: 2;
@bar-width: 20px;

.no-target-path{
  padding-top: 10px;
}

.team-alert-container {
  margin-bottom: 20px;
  background-color: @white;
  border: 1px solid #DDDDDD;
  border-radius: @border-radius-small;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 10px 15px;
  margin-top: 20px;
}

.team-alerts {
  .multi-column-legend {
    position: relative;
    text-align: right;
    margin: 15px 0 30px;
    padding: 0;

    .legend-entry {
      padding-left: 30px;
    }

    .color {
      height: 16px;
      width: 16px;
      border-radius: @line-chart-legend-color-radius;
      display: inline-block;
      vertical-align: middle;

      &.hit {
        background-color: @goal-hit-alert;
      }

      &.missed {
        background-color: @goal-missed-alert;
      }

      &.high-risk {
        background-color: @goal-high-risk-alert;
      }

      &.on-track {
        background-color: @goal-on-track-alert;
      }

      &.behind-pace {
        background-color: @goal-behind-pace-alert;
      }
    }
  }

  .team-alerts-container,
  .all-metrics {
    height: 200px;
  }

  .team-alerts-container {
    overflow-y: hidden;
    overflow-x: auto;

    .team-alerts-content {
      .flexbox;
      flex-wrap: nowrap;
      position: relative;

      &.fixed {
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: @gray-lightest;
          border-radius: @border-radius-small;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: @gray-light3;
        }

        .team-alert-column {
          .flex-width(135px);
        }
      }

      .y-axis,
      .empty-space {
        height: 110px;
        top: 18px;
      }

      .empty-space:not(.graph-top-line) {
        height: 109px;
      }

      .y-axis {
        position: absolute;
        font-size: 12px;
        color: #999999;

        .bottom-axis {
          position: absolute;
          bottom: 0;
          margin-bottom: -7px;
        }

        .top-axis {
          margin-top: -7px;
        }
      }

      .empty-space {
        position: relative;
        flex-grow: 1;
        border-bottom: 1px solid #CCCCCC;
      }

      .team-alert-section {
        position: relative;
        top: 18px;

        &:first-of-type {
          margin-left: 14px;
        }
      }

      .graph-top-line {
        border-top: 1px dashed #e9ebed;
      }
    }
  }

  .team-alert-column {
    .flex-col;
    min-width: 135px;
    flex-basis: auto;
    padding-left: 15px;
    padding-right: 15px;

    &.team-alert-graph {
      height: 109px;
      border-bottom: 1px solid #CCCCCC;
      width: 100%;
    }

    .team-alert-bars {
      .centered-columns;
      .flexbox;
      flex-wrap: nowrap;

      .team-alert-bar-container {
        .flex-col;
        .flex-width(@bar-width);
        margin-right: 5px;
        padding-left: 0;
        padding-right: 0;

        &:last-child {
          margin-right: 0;
        }

        .goal-deviation {
          display: inline-block;
        }

        .metric-val {
          min-width: 45px;
          display: inline-block;
          font-weight: bold;
        }

        .metric-name {
          display: inline-block;

          a {
            color: #666666;
            font-size: 14px;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
            font-weight: 400;
          }
        }
        .team-goal-graph-tooltip {
          .tooltip-inner {
            padding: 20px;
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style: none;
              }
            }
          }
          &.track, &.risk {
            .tooltip-inner ul{
              padding: 3px 10px;
              li {
                list-style: disc;
              }
            } 
          }
        }        
      }

      .team-alert-max-striped,
      .team-alert-bar {
        .flex-width(100%);
      }

      .team-alert-max-striped,
      .team-alert-bar {
        &.hit {
          background-color: @goal-hit-alert;
        }

        &.missed {
          background-color: @goal-missed-alert;
        }

        &.risk {
          background-color: @goal-high-risk-alert;
        }

        &.track {
          background-color: @goal-on-track-alert;
        }

        &.behind {
          background-color: @goal-behind-pace-alert;
        }
      }

      .team-alert-max-striped {
        height: @max-stripped-units * @unit-height;

        > div {
          height: 7px;

          &:nth-of-type(odd) {
            background-color: @white;
          }
        }
      }

      .team-alert-bar {
        text-align: center;
        font-size: @font-size-caption1;

        &.team-alert-0 {
          border-radius: 20px;
          width: @bar-width;
          height: @bar-width;
          background-color: @white;
          padding: 1px;

          &.hit {
            color: @goal-hit-alert;
            border: 1px solid @goal-hit-alert;
          }

          &.missed {
            color: @goal-missed-alert;
            border: 1px solid @goal-missed-alert;
          }

          &.risk {
            color: @goal-high-risk-alert;
            border: 1px solid @goal-high-risk-alert;
          }

          &.track {
            color: @goal-on-track-alert;
            border: 1px solid @goal-on-track-alert;
          }

          &.behind {
            color: @goal-behind-pace-alert;
            border: 1px solid @goal-behind-pace-alert;
          }

        }

        &.team-alert {
          width: @bar-width;
          color: @white;
          .border-top-radius(2px);
        }

        &.team-alert-max {
          .team-alert-bar-creator(@max-count - @max-stripped-units);
          padding-top: (@max-count - @max-stripped-units) * @unit-height - @bottom-padding + 2;
          margin-top: 0;
          color: @white;
          .border-top-radius(0);
        }
      }
    }
  }

  .team-alerts-activity-feed-link {
    margin-top: 10px;
    display: inline-block;

    &:hover {
      svg {
        fill: @actionGreen;
      }
    }

    &:active {
      svg {
        fill: @positive-alert;
      }
    }
  }

  .team-alert-target {
    margin-top: 20px;
    padding-bottom: 15px;
    text-align: center;

    a {
      color: @gray;
    }

    .target-name {
      font-size: @font-size-bodyText;
      font-style: normal;
      line-height: 19px;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .target-individual {
      .target-name {
        white-space: pre;
      }
    }
  }
}

.team-alert-bar-creator(@score) {
  margin-top: (@max-count - @score) * @unit-height;
  padding-top: @score * @unit-height - @bottom-padding;
  height: @score * @unit-height;
}

.all-metrics {
  margin-top: 35px;
  padding-bottom: 15px;
  min-height: 203px;
  min-width: 100%;

  .all-metrics-empty {
    text-align: center;
  }

  .tier-title {
    font: @text-display-xs;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-top: 15px;

    a {
      vertical-align: middle;
    }

    .metrics-info-explanation {
      margin-left: @spacing-1;
      svg {
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }

  &.all-metrics-tiered {
    margin-top: 0;
  }
}
