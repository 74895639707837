.activity-feed-items-container {
  .activity-feed-item {
    background-color: @white;
    color: @gray;
    margin: 20px 0;
    padding: 12px 20px 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid @gray-lightest;
    border-radius: @border-radius-small;

    .header {
      margin: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid @gray-lightest;
      line-height: 22px;

      .title {
        display: flex;
        padding-left: 0;
        align-items: center;

        svg {
          height: 17px;
          margin-right: 8px;
        }

        .icon-button {
          margin-left: @spacing-2;

          svg {
            margin-right: 0;
          }
        }

        .name {
          color: @gray-dark;
          font-size: @font-size-h3;
          font-weight: @font-weight-semi-bold;
        }
      }

      .timeframe, .date {
        font-size: 14px;
        max-height: 17px;
        font: @text-xs-regular;
        color: @text-weak-color;
      }

      .timeframe {
        margin-left: 8px;

        div, p {
          display: inline-block;
          margin: 0;

          .highlight-pill {
            font-size: 13px;
            vertical-align: baseline;
            border-radius: @border-radius-large;
            padding: 2px 10px;
            margin-left: 5px;
          }
        }
      }

      .sales-coach {
        padding-right: 0;

        .sales-coach-button {
          float: right;
        }
      }
    }

    .row:not(.full-width) {
      max-width: 980px;
    }

    .data-container {
      padding-top: 16px;
      padding-bottom: 10px;
      position: relative;

      a {
        color: @link-color;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .anomaly-deviation {
      margin-right: 8px;
      font-weight: @font-weight-semi-bold;
      font-size: @font-size-h3;
    }

    .preview-container {
      height: 100%;
    }

    .preview {
      box-shadow: none;
      border: none;
      margin: 0;

      .content {
        padding: 10px 0 0 30px;
      }

      .preview-graph-view {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin: 0;
        padding: 10px 0 5px 10px;
      }
    }

    .anomaly-detail, .anomaly-summary {
      color: @gray;
      font-weight: @font-weight-thin;
      font-size: 16px;

      .bold {
        color: @gray-dark;
      }

      p {
        margin-top: 0;
      }
    }

    .anomaly-metrics {
      color: @gray;
      margin-bottom: 25px;
      margin-left: 16px;

      svg {
        height: 18px;
        width: 16px;
        margin-right: 3px;
        vertical-align: top;
      }

      .thin-bullet-list-item .highlight-pill {
        vertical-align: top;
      }
    }

    .anomaly-detail p {
      margin-top: 25px;
    }

    @media only screen and (max-width: @page-width) {
      .date {
        display: none;
      }
    }
  }
}

.activity-feed-item {
  a:hover, a:active, a:focus {
    text-decoration: none;
  }

  .scenario-explanation {
    padding-top: 20px;
    justify-content: flex-end;
    height: 100%;

    .scenario-explanation-section {
      width: 200px;
    }

    .arrow {
      margin: 0 10px;
      font-size: 12px;
    }
  }
}

.activity-feed-items-container {
  .status {
    margin: 20px 0;

    .no-data {
      color: #423300;
      margin-top: 20px;

      &.text-explanation {
        font-size: @font-size-h3;
      }
    }

    .loading-container {
      background-color: @white;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08), -1px -1px 3px rgba(0, 0, 0, 0.08);
    }
  }
}
