//
// Scaffolding
// --------------------------------------------------


// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing : border-box;
}

// Body reset

html, body {
  height: 100%;
}

html {
  font-size: @body-font-size;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: @font-family-base;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @text-color-default;
  background-color: @body-bg;
  font-weight: @font-weight-thin;
}

// Wrapper for all pages
#page {
  min-height:100%;
  position:relative;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}


// Links

a {
  color: @link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
  }

  &:focus {
    .tab-focus();
  }

  &[disabled] {
    pointer-events: none;
    opacity: .6;
  }

  &.decorated-link {
    text-decoration: underline;
    cursor: pointer;
  }

  &.hidden-link {
    color: @text-color-default;

    &:hover {
      text-decoration: underline;
    }

    &.colored:hover {
      color: @link-color;
    }
  }

  &.gray-link {
    color: @text-color-default;
    text-decoration: underline;
  }

  &.link-on-dark {
    color: @text-active-on-dark-color;

    &:hover {
      color: @text-active-on-dark-color;
    }
  }
}


// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
  margin: 0;
}


// Images

img {
  vertical-align: middle;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
  .img-responsive();
}

// Perfect circle
.img-circle {
  border-radius: 50%; // set radius in percents
}


// Horizontal rules

hr {
  margin-top:    @line-height-computed;
  margin-bottom: @line-height-computed;
  border: 0;
  border-top: 1px solid @hr-border;

  &.reduced {
    margin-top:    @line-height-computed / 2;
    margin-bottom: @line-height-computed / 2;
  }

  &.darker {
    border-top-color: @hr-darker-border;
  }
}


// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}


// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
  cursor: pointer;
}

.subtle-highlight {
  background-color: #ddd;
  padding: 5px;
}

.page-title {
  color: @gray-dark;
  font-size: 1.4rem;
  font-weight: @font-weight-semi-bold;
  margin: 1.4em 0 0.67em;

  svg {
    vertical-align: middle;
    margin-right: 15px;

    & + span {
      vertical-align: middle;
    }
  }
  
  .page-title-explanation {
    color: @gray-light2;
    font-weight: 300;
    font-style: italic;
    text-transform: lowercase;
    vertical-align: middle;
    margin-left: 10px;
  }

  .page-subtitle {
    color: @gray-light2;
    font-weight: 300;
    font-size: 1.2rem;
  }

  &.no-header {
    font-size: 28px;
    margin: 50px 0 5px;
  }
}

.page-error {
  padding: 20px;
  color: @error-color;
}

.inline-error {
  color: @error-color;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1000px;
  display: inline-block;
  overflow: hidden;
  white-space: normal;
  vertical-align: middle;
}


.page-section-contained {
  border: 1px solid #DDDDDD;
  border-radius: @border-radius-small;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  padding: 10px 30px;
  margin-top: 10px;

  .contained-section-title {
    font-size: @font-size-h2;
    font-weight: @font-weight-semi-bold;
    color: @black;
  }
}

.section {
  color: @gray;
  padding: 15px 0;

  &.table-section {
    padding-top: 30px;
  }

  .section-title {
    font-weight: @font-weight-semi-bold;
    font-size: @font-size-h3;
    color: @black;

    a {
      text-decoration: underline;
    }
  }

  h3.section-title {
    margin: 5px 0;
  }

  p {
    line-height: 24px;
  }

  .section-content {
    margin-top: 5px;

    &.img-section {
      padding: 10px 0;

      svg {
        width: 307px;
        height: 243px;
      }
    }
  }

  .page-title {
    margin: 0.5em 0;
  }
}

.form-page-container {
  padding-bottom: 50px;
}

@media only screen and (max-width: 1300px) {
  .inline-error {
    max-width: 760px;

  }
}
