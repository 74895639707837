.col(@col-width, @columns) {
  .flex-width(@col-width * @columns);
}

.grid-12-col(@columns) {
  .col(@grid-col-width, @columns);
}

.grid-16-col(@columns) {
  .col(@grid-16-col-width, @columns);
}

.grid-24-col(@columns) {
  .col(@grid-24-col-width, @columns);
}

.flex-width(@size) {
  -ms-flex-prefserred-size: @size;
  flex-basis: @size;
  max-width: @size;
}
