.page-actions {
  margin: 20px 0 0;
  padding: 8px 2px;
}

.page-with-float-actions {
  .section-header {
    margin-top: @spacing-2;
    margin-bottom: 0;
  }

  .main-actions {
    padding-top: @spacing-5;
    padding-bottom: @spacing-2;
    position: sticky;
    top: 0;
    z-index: @zindex-table-header;
    background-color: @body-bg;
  }

  .section-header-secondary-actions {
    margin-bottom: @spacing-2;
  }
}

.page-actions,
.page-with-float-actions {
  .describe-link {
    display: flex;
    line-height: 25px;
    justify-content: space-between;

    a {
      font-size: 14px;
    }

    &.right-content {
      justify-content: flex-end;
    }
  }

  .alert-describe {
    margin-top: 5px;
  }

  &.for-table {
    padding-bottom: 23px;
  }

  .main-actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &.right-content {
      justify-content: flex-end;
    }

    .explanation {
      height: max-content;
      flex-shrink: 0;

      &.for-title {
        margin-left: @spacing-4;
      }
    }

    .opp-health-title {
      display: flex;

      .dropdown {
        min-width: 0;
      }
    }
  }

  .saved-card-title {
    color: @black;
    min-width: 0; // To allow shrink if needed to fit the action buttons
  }

  .action-buttons {
    padding-left: 15px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;

    .corsac-button ~ .dropdown {
      margin-left: @spacing-1;
    }
  }
}

.action-buttons {
  .action-button,
  .dropdown-action-button {
    display: inline-block;
    font-size: 12px;
    margin-right: 5px;
    line-height: 16px;
    vertical-align: middle;

    &.last-action {
      margin-right: 0;
    }
  }

  .dropdown-modal {
    margin-right: 5px;
  }

  .dropdown-action-button {
    margin-right: 0;

    .dropdown-label-svg svg {
      width: 24px;
      height: 24px;
    }
  }
}

.header-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.selector-with-navigation {
  display: flex;
  align-items: center;
  gap: @spacing-3;
}

.target-navigation-buttons {
  display: flex;
  gap: @spacing-3;
}

.page-secondary-header {
  &.section-controls {
    justify-content: right;
    background-color: @body-bg;

    .section-control {
      display: inline-block;
      margin-left: 20px;
    }

    .section-control-label {
      color: @text-color-light;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }

    .dropdown-with-checkbox {
      display: inline-block;
      position: relative;

      .filterable-checkbox-list {
        right: 0;
      }
    }

    .dropdown-as-title {
      text-decoration-color: @black;
      .right-aligned-menu;

      .dropdown-selection {
        .title, .placeholder, .label {
          font-size: @dropdown-menu-font-size;
        }
      }
    }
  }
}

.single-button-pair-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .dropdown {
    min-width: 0;
    margin-right: @spacing-5;
  }

  .button-pair {
    flex-shrink: 0;
    margin-left: auto;

    .next-button,
    .previous-button,
    & + div .icon-button {
      svg {
        fill: @icon-color;
      }

      &:active svg {
        fill: @icon-color;
      }

      &:hover {
        background-color: @background-hover-color;
        svg {
          fill: @icon-hover-color;
        }
      }
    }
  }
}

.section-tabs {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: @spacing-7;
  border-bottom: @border-default;
  font: @text-display-large;
  width: 100%;

  .section-tab {
    padding: 14px 0 12px;
    cursor: pointer;

    .section-tab-icon {
      margin-right: @text-with-icon-separation;

      svg {
        fill: @text-color;
        vertical-align: text-bottom;
      }
    }

    &.active {
      color: @text-active-color;
      position: relative;

      .section-tab-icon svg {
        fill: @icon-active-color;
      }
    }

    &.inactive {
      &:hover {
        color: @text-hover-color;
        cursor: pointer;

        .section-tab-icon svg {
          fill: @icon-hover-color;
        }
      }
    }

    .explanation {
      height: auto;
      vertical-align: text-bottom;
      margin-left: @spacing-1;

      svg {
        .icon-large();
        fill: @icon-weak-color;
      }
    }

    .section-active-tab-indicator {
      position: absolute;
      top: 100%;
      height: 1px;
      width: 100%;
      background-color: @text-active-color;
    }
  }
}
