.corsac-button {
  .base-button();
  border-radius: @border-radius-default;
  border: none;

  &:disabled {
    cursor: none;
    opacity: 1;
  }

  &.button-large {
    height: 40px;
    padding: 8px 16px;
    font: @text-small-semi-bold;

    svg {
      .icon-medium();
    }
  }

  &.button-medium {
    height: 32px;
    padding: 4px 12px;
    font: @text-xs-medium;

    svg {
      .icon-small();
    }
  }

  &.button-small {
    height: 28px;
    padding: 2px 8px;
    font: @text-2xs-medium;

    svg {
      .icon-small();
    }
  }

  &.button-primary-success {
    color: @text-on-dark-color;
    background-color: @background-success-color;
    border: none;

    svg {
      fill: @icon-on-dark-color;
    }

    &:hover {
      background-color: @background-hover-success-color;
      box-shadow: @box-shadow-small-hover;
    }

    &:disabled {
      background-color: @background-disabled-success-color;
    }
  }

  &.button-primary-neutral {
    color: @text-on-dark-color;
    background-color: @background-blue-color;
    border: none;

    svg {
      fill: @icon-on-dark-color;
    }

    &:hover {
      background-color: @background-blue-hover-color;
      box-shadow: @box-shadow-small-hover;
    }

    &:disabled {
      background-color: @background-blue-weak-color;
    }
  }

  &.button-danger {
    color: @text-on-dark-color;
    background-color: @background-danger-color;
    border: none;

    &:hover {
      background-color: @background-hover-danger-color;
      box-shadow: @box-shadow-small-hover;
    }

    svg {
      fill: @icon-on-dark-color;
    }

    &:disabled {
      background-color: @background-disable-danger-color;
    }
  }

  &.button-secondary {
    color: @text-color;
    background-color: @background-white-color;
    box-shadow: @box-shadow-small-regular;
    border: @border-default;

    svg {
      fill: @icon-weak-color;
    }

    &:hover {
      box-shadow: @box-shadow-small-hover;
      border: @border-default-hover;

      svg {
        fill: @icon-color;
      }
    }

    &:disabled {
      color: @text-disabled-color;
      background-color: @background-medium-color;
    }
  }

  &.button-flat {
    color: @text-weak-color;
    background-color: transparent;
    border: none;

    svg {
      fill: @icon-weak-color;
    }

    &:hover {
      color: @text-color;

      svg {
        fill: @icon-color;
      }
    }

    &:disabled {
      color: @text-disabled-color;
    }
  }
}

.buttons-group {
  .corsac-button {
    vertical-align: middle;

    & ~ .corsac-button {
      margin-left: @spacing-3;
    }
  }
}

.button-as-dropdown-option {
  &.sales-coach-button,
  &.corsac-button {
    border: none;
    box-shadow: none;
    width: 100%;
    height: 28px;
    text-align: left;
    justify-content: flex-start;

    &.button-link {
      display: flex;
    }

    &.tooltip-secondary-action-button,
    &.sales-coach-button-extra-small, & {
      font: @text-base;

      svg {
        .icon-medium();
      }
    }

    &:hover {
      border: none;
      background-color: @background-active-color;
      box-shadow: none;
      color: @text-active-color;

      svg {
        fill: @icon-active-color;
      }
    }

    &.button-link.button-secondary {
      color: @text-color;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.sales-coach-button {
    background: transparent;

    svg {
      fill: @marketing-fuchsia;
    }
  }
}

.button-pair {
  display: flex;

  > div {
    &:first-child .corsac-button {
      border-radius: 8px 0 0 8px;
    }

    &:last-child .corsac-button {
      border-radius: 0 8px 8px 0;
    }
  }

  .button-pair-active-button.corsac-button {
    color: @text-marketing-fuchsia-color;
    pointer-events: none;

    svg {
      fill: @icon-marketing-fuchsia-color;
    }
  }

  .button-pair-inactive-button {
    color: @text-weak-color;
    background-color: @background-medium-color;
    &:hover {
      background-color: @background-white-color;
    }

    svg {
      fill: @icon-weak-color;
    }
  }

  .corsac-button.icon-only {
    padding: @spacing-1 @spacing-2;
    height: 28px;
  }
}

