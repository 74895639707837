.drag-and-drop-list {
  padding-inline-start: 20px;
  margin: 15px 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-x: auto;

  li {
    margin-top: 15px;
    padding-left: 10px;
  }

  .draggable-element {
    padding: 8px;
    border-radius: @dropdown-menu-border-radius;
    border: 1px solid @gray-lightest;
    width: 240px;
    height: 40px;
    cursor: move;
    display: flex;
    align-items: center;

    &.borderless {
      border: none;
      background-color: @background-medium-color;
    }

    &:hover, &:active {
      background-color: #F5F5F5;
    }

    &.element-dragging {
      visibility: hidden;
    }

    &.element-moving {
      color: @black;
      background-color: @gray-lighter;
      border: 1px solid @gray5;
      transform: translate(0, 0); //To keep the border radius on the moving target

      svg {
        fill: @gray5;
      }
    }

    span {
      vertical-align: middle;
    }

    > svg {
      width: 20px;
      height: 20px;
      fill: @gray-lightest;
      vertical-align: middle;
      margin-right: 6px;
    }

    .name-view {
      max-width: 194px;
      display: inline-block;
      .label-name {
        display: inline-block;
        vertical-align: middle;
        .text-ellipsis();
      }

      .sub-name {
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
      }
    }

    .label {
      .text-ellipsis();
    }

    .remove-item-button {
      margin-left: auto;
    }
  }
}

.long-list {
  .drag-and-drop-list {
    max-height: 750px;
  }
}
