.history-row-list {
  margin-right: @spacing-5;

  .history-column-data {
    overflow-x: auto;
    overflow-y: hidden;
    .white-bg-scrollbar();
  }

  .history-column-label,
  .history-column-data {
    align-items: center;
  }

  .history-row-label,
  .history-row-data {
    height: 21px;
    margin-bottom: @spacing-3;
  }

  .history-row-label {
    padding-left: @spacing-5;
    padding-right: @spacing-3;

    .dropdown.hidden-dropdown:hover {
      text-decoration: none;
    }

    .dropdown-selection {
      display: flex;
      align-items: center;
    }

    .dropdown-selection,
    .title {
      font: @text-xs-regular;
      color: @text-weak-color;
    }

    svg {
      .icon-medium();
      fill: @icon-color;
      vertical-align: middle;
      margin-right: @spacing-1;
    }

    &.history-row-label-read-only {
      font: @text-xs-regular;
      color: @text-weak-color;
    }
  }

  .history-column-header {
    height: @line-height-100; // To take the same space as the date label so sticky position works
  }

  .history-row-data {
    width: 846px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 2px;

    .history-row-data-entry {
      flex-shrink: 0;
      flex-grow: 1;
      border-radius: @border-radius-extra-large;
      height: 12px;

      &.weekly,
      &.history-row-summary-entry {
        width: 14px;
        height: 14px;
      }

      &.history-row-summary-entry {
        font: @text-2xs-medium;
        color: @text-weak-color;

        .history-row-summary-entry-label {
          display: inline-block;
          vertical-align: middle;
          margin-left: @spacing-1;
        }

        .harvey-ball {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .history-row-data-entry-display {
        display: flex;
        height: 100%;
        border-radius: @border-radius-extra-large;
        justify-content: center;
        align-items: center;

        .icon-placeholder,
        svg {
          .icon-x-small();
          vertical-align: middle;
        }
      }
    }

    .history-date-label {
      flex: 1;
      font: @text-2xs-medium;
      color: @text-weak-color;
    }
  }

  .status-negative {
    background-color: @goal-missed-alert;
    border: 1px solid @goal-missed-alert;

    &.updated-status {
      border: 1px dashed @background-weak-danger-color;
    }
  }

  .status-risk {
    background-color: @goal-high-risk-alert;
    border: 1px solid @goal-high-risk-alert;
  }

  .status-behind {
    background-color: @goal-behind-pace-alert;
    border: 1px solid @goal-behind-pace-alert;
  }

  .status-positive {
    background-color: @goal-hit-alert;
    border: 1px solid @goal-hit-alert;

    &.updated-status {
      border: 1px dashed @background-weak-success-color;
    }
  }

  .status-track {
    background-color: @goal-on-track-alert;
    border: 1px solid @goal-on-track-alert;
  }

  .status-no-data {
    background-color: @no-data-alert;
    border: 1px solid @no-data-alert;

    &.updated-status {
      border: 1px dashed @background-white-color;
    }
  }

  .status-future {
    background-color: @background-white-color;
    border: @border-dashed-hover;
  }

  .status-ramping {
    background-color: @background-hover-color;
    border: @border-dashed-hover;
  }

  .status-calculating {
    background-color: @background-hover-color;
    border: 1px solid @background-hover-color;
  }

  .status-empty {
    background-color: @background-white-color;
    border: @border-default-hover;
  }

  .history-rollover .tooltip-inner {
    padding: @spacing-3;
    text-align: left;
  }

  &.history-row-by-timeframe {
    .history-column-header {
      height: 48px;
      padding-top: @spacing-3;

      .action-item-title {
        padding: 0;
      }

      &.history-row-label {
        white-space: nowrap;
      }
    }

    .history-row-label {
      .dropdown-selection {
        > span {
          border-bottom: @border-dashed-hover;
        }
      }
    }
  }
}

.history-row-button {
  margin-bottom: @spacing-5;
  padding-left: @spacing-5;
  white-space: nowrap;
}
