.descriptive-dropdown {
  position: relative;

  &.full-size-width {
    width: 100%;
  }

  .dropdown-selection {
    width: 100%;
    display: flex;
    font: @text-base;
    color: @text-color;
    align-items: center;
    background-color: transparent;
    height: @dropdown-height;
    border: @descriptive-dropdown-border;
    border-radius: @descriptive-dropdown-border-radius;

    > span {
      flex: 1;
    }

    span {
      vertical-align: middle;
    }

    svg.caret {
      margin-left: @spacing-1;
      fill: @icon-color;
      .icon-small();
    }

    svg {
      &.icon-fuchsia {
        .icon-fuchsia;
      }

      &.icon-positive {
        .icon-positive;
      }
    }

    .label-with-left-icon {
      svg {
        margin-right: @spacing-1;
        margin-left: 0;
        .icon-large();
      }
    }

    &.borderless {
      padding: 0;
      border: none;
      height: max-content;
      font: @text-small-regular;

      > span {
        flex: none;
      }

      &.is-placeholder {
        > span {
          color: @text-weak-color;
        }
        svg {
          fill: @icon-weak-color;
        }

        &:hover {
          > span {
            color: @text-color;
          }
          svg {
            fill: @icon-color;
          }
        }
      }
    }
  }

  &.inline-dropdown {
    display: inline-block;
  }

  &.button-base .descriptive-dropdown-menu {
    min-width: max-content;
  }

  .nested-dropdown {
    position: absolute;

    .descriptive-dropdown-menu {
      width: @nested-menu-width;
      min-width: @nested-menu-width;
    }

    .dropdown-option-container:hover {
      background-color: transparent;
    }

    .dropdown-menu-option {
      background-color: @descriptive-dropdown-background-color;
      border: @descriptive-dropdown-border;
      border-radius: @descriptive-dropdown-border-radius;
      padding: @descriptive-dropdown-padding;
      box-shadow: @descriptive-dropdown-box-shadow;
    }

    .dropdown-option-tooltip {
      margin-bottom: @spacing-1;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.highlighted-dropdown {
    .dropdown-selection.active-dropdown {
      color: @text-active-color;

      svg {
        fill: @icon-active-color;
      }
    }
  }

  &.small-descriptive-menu {
    .descriptive-dropdown-menu {
      width: 200px;
    }
  }

  &.medium-descriptive-menu {
    .descriptive-dropdown-menu {
      width: 300px;
    }
  }

  svg.caret-up {
    transform: rotate(180deg);
  }

  .active-dropdown {
    &.icon-button-border {
      .icon-button-border-active;
    }

    &.sales-coach-icon-button {
      .sales-coach-icon-button-active;
    }
  }
}

.descriptive-dropdown-menu {
  top: 110%;
  width: 100%;
  color: @gray;
  text-align: left;
  position: absolute;
  background-color: @descriptive-dropdown-background-color;
  z-index: @zindex-dropdown;
  border: @descriptive-dropdown-border;
  border-radius: @descriptive-dropdown-border-radius;
  box-shadow: @descriptive-dropdown-box-shadow;

  &.top {
    top: 0;
    transform: translateX(0) translateY(-100%);
  }

  &.right-aligned-menu {
    right: 0;
  }

  .dropdown-option-link,
  .descriptive-dropdown-menu-list .descriptive-dropdown-menu-item {
    display: flex;
    cursor: pointer;
    padding: 3px 8px;
    text-decoration: none;
    flex-direction: column;

    &:hover {
      &:not(.disabled-option) {
        border-radius: @descriptive-dropdown-border-radius;
        background-color: @background-active-color;
      }

      .label, span {
        color: @link-color;
      }
    }

    &.disabled-option {
      pointer-events: none;

      .label, span {
        color: @text-disabled-color;
      }
    }

    .label, span {
      font: @text-base;
      color: @text-color;

      svg {
        margin-right: @spacing-1;
        vertical-align: middle;
        fill: @icon-weak-color;
        .icon-medium();
      }

      span {
        vertical-align: middle;
      }
    }
  }

  .descriptive-dropdown-menu-list {
    display: flex;
    overflow-y: auto;
    max-height: 35vh;
    padding: @descriptive-dropdown-padding;
    flex-direction: column;
    .white-bg-scrollbar();

    .descriptive-dropdown-menu-item {
      .description {
        font: @text-xs-regular;
        color: @text-disabled-color;
      }

      &.sales-coach-option {
        svg {
          fill: @icon-marketing-fuchsia-color;
        }
      }
    }

    .descriptive-dropdown-menu-divider {
      height: 1px;
      margin: @spacing-1 0;
      background-color: @border-color;
    }

    &.shortcut-options.no-padding {
      padding-bottom: 0;
    }

    &.menu-options.no-padding {
      padding-top: 0;
    }
  }

  hr {
    margin: 0;
    border-color: @gray-lighter;
  }

  .button.text-only {
    width: 100%;
    padding: 13px 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: @text-weak-color;
    transition-duration: 0.3s;
    background-color: @background-medium-color;

    &:hover {
      color: @text-color;
    }
  }
}

.on-hover-dropdown {
  .descriptive-dropdown-menu {
    top: 100%;

    &.top {
      top: 0;
    }
  }
}
