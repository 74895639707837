.sidebar {
  flex-shrink: 0;

  .expand-collapse-button {
    padding: @sidebar-expand-collapse-padding;
    border-radius: @sidebar-expand-collapse-border-radius;
    box-shadow: @sidebar-expand-collapse-box-shadow;
    position: absolute;
    top: 25px;
    right: -14px;
    cursor: pointer;
    z-index: @zindex-lcp-button;
    pointer-events: auto;

    svg {
      width: @sidebar-icon-size-small;
      height: @sidebar-icon-size-small;
    }
  }

  .sidebar-collapsed {
    width: @sidebar-collapsed-width;
  }

  .sidebar-expanded,
  .sidebar-collapsed {
    position: relative;
  }

  .sidebar-expanded, .sidebar-content {
    height: 100%;
    width: @sidebar-expanded-width;
  }

  .sidebar-content {
    overflow-y: auto;
    font-size: @sidebar-font-size;
    z-index: @zindex-left-control;
  }

  .sidebar-tabs {
    display: flex;
    padding: @sidebar-tab-top-space @sidebar-horizontal-reduced-spacing 0;

    .sidebar-tab-label,
    .sidebar-tab-label-empty {
      display: inline-block;
      padding: 10px;
      border-top: 1px solid transparent;
      text-transform: uppercase;
      font-size: @font-size-caption1;
      border-radius: @sidebar-tab-border-radius;

      &.sidebar-active-tab {
        font-weight: @sidebar-light-bg-bold-font-weight;
      }
    }

    .sidebar-tab-label:not(.sidebar-active-tab) {
      cursor: pointer;
    }

    .sidebar-tab-label-empty {
      flex-grow: 1;
    }
  }

  .sidebar-section {
    .sidebar-section-title {
      font-size: @sidebar-section-title-font;
      text-transform: uppercase;
      padding: @sidebar-section-title-padding;
      margin: 0 @sidebar-horizontal-reduced-spacing;
      cursor: pointer;
      line-height: @sidebar-section-title-line-height;

      .sidebar-section-title-text {
        display: inline-block;
        vertical-align: middle;
      }

     .sidebar-section-title-badge-tooltip {
        display: inline-block;
        padding-right: 8px;
        margin-left: 10px;        

        .sidebar-section-title-badge {          
          width: 21px;
          height: 17px;
          border-radius: 30px;     
          justify-content: center;
          align-items: center;     
          display: inline-flex;
        }
      }  
      
      .explanation {
        height: @sidebar-section-title-line-height;
        margin-left: 6px;
        padding-right: 8px;

        svg {
          height: 14px;
          width: 14px;
          vertical-align: text-top;
        }
      }

      .sidebar-section-label-icon svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        vertical-align: middle;
      }

      .sidebar-section-right-buttons {
        display: inline-block;
        float: right;
      }

      .sidebar-section-shortcut-action svg {
        width: 16px;
        height: 16px;
        margin-right: @sidebar-horizontal-reduced-spacing;
      }

      .sidebar-section-button svg {
        width: @sidebar-icon-size-small;
        height: @sidebar-section-title-line-height;
      }

      .sidebar-section-main-action-button {
        margin-right: -4px;
        svg {
          width: @sidebar-section-title-line-height;
          height: @sidebar-section-title-line-height;
        }
      }

      &.sidebar-section-link {
        &:hover, &:focus, &:active {
          text-decoration: none;
        }
      }
    }

    &::after,
    &:first-child::before,
    .sidebar-section-content::after {
      content: '';
      display: block;
      margin: 0 @sidebar-horizontal-reduced-spacing;
    }

    .sidebar-section-content {
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      & > :last-child {
        margin-bottom: 10px;
      }

      &.section-empty::after {
        border-top: none;
      }

      .close-icon {
        float: right;
        cursor: pointer;

        svg {
          width: @sidebar-icon-size-small;
          height: @sidebar-icon-size-small;
          vertical-align: middle;
        }
      }

      .radio-button-group, .group-option-values {
        padding-left: 25px;
        padding-right: @sidebar-horizontal-reduced-spacing;
      }

      .radio-button {
        margin-bottom: 2px;
      }

      .radio-button [type="radio"]:not(:checked) + div.radio-button-circle,
      .radio-button [type="radio"]:checked + div.radio-button-circle {
        top: 5px;
        width: 13px;
        height: 13px;
      }

      .radio-button [type="radio"]:checked + div.radio-button-circle > .radio-button-circle-inside,
      .radio-button [type="radio"]:not(checked) + div.radio-button-circle > .radio-button-circle-inside {
        margin: 1px 1px 6px 2px;
        width: 7px;
        height: 7px;
      }

      .group-option-control {
        [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
          width: 14px;
          height: 14px;
        }

        [type="checkbox"]:not(:checked) + label svg, [type="checkbox"]:checked + label svg {
          width: 11px;
          height: 11px;
          margin-top: 4px;
          margin-left: 2px;
        }

        [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
          padding-right: 23px;
          padding-top: 2px
        }
      }
    }

    .section-item {
      display: inline-block;
      padding: 0 10px 5px 25px;

      &.section-action {
        cursor: pointer;
        padding-top: 15px;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          vertical-align: top;
        }
      }

      &.section-trigger-container {
        padding: 5px 0 0 0;
        line-height: 20px;
      }
      
      .filter-explanation{
        padding-right: 8px;
        line-height: 24px;
        svg {
          width: 14px;
          height: 14px;
          fill: @gray7;
        }
      }
    }

    .radio-button-group,
    .autocomplete-multi-checkbox {
      padding: 0 @sidebar-horizontal-spacing;
    }

    .autocomplete-multi-checkbox {
      .filterable-checkbox-list {
        position: fixed;
      }

      .selected-options li .label {
        font-size: @sidebar-selected-options-size;
      }
    }

    .filterable-checkbox-list-options {
      max-height: 360px;
    }

    .link-style-button {
      font-weight: 500;
    }

    .sidebar-transparent-dropdown {
      .dropdown-left-aligned-options;
      
      &.dropdown-with-close {
        max-width: 80%;
      }
      
      &.dropdown-with-tooltip {
        .tooltip {
          margin-left: -5px;
        }
        
        &.dropdown-with-close .tooltip {
          margin-left: 23px;
        }
      }

      .dropdown-selection {
        padding-left: 0;
        padding-right: 8px;
        font-weight: @font-weight-thin;        

        &:hover {
          background-color: transparent;
        }

        .title {
          max-width: calc(~"100% - 16px");
          display: inline-block;
          vertical-align: middle;
          text-decoration: underline;
          .text-ellipsis();
        }

        .caret {
          margin-left: 6px;
        }
      }
    }

    .sidebar-section-item {
      padding: 6px 6px 6px 8px;
      margin: 0 @sidebar-horizontal-reduced-spacing;

      &:hover,
      &.sidebar-section-link-highlighted {
        background-color: @sidebar-light-bg-section-hover-bg-color;

        &.sidebar-section-link,
        &.sidebar-secondary-action,
        .expandable-section-label {
          .view-name {
            .bold-font;
            text-decoration: none;
          }

          svg {
            fill: @sidebar-light-bg-expanded-hover-font-color;
          }
        }
      }

      &.section-action {
        display: inline-block;

        &:hover {
          background: none;
        }
      }
    }

    .expandable-section {
      margin-left: @sidebar-horizontal-reduced-spacing;
      margin-bottom: 0;

      &:last-child {
        margin-bottom: @sidebar-expandable-section-spacing;
      }

      .expandable-section-title-label-container {
        width: 95%;
        display: inline-block;
        .ellipsis();
      }

      .expandable-section-title {
        .sidebar-section-item;
        width: @sidebar-section-width;
        margin: 0;

        .caret {
          border-bottom-color: @sidebar-light-bg-font-color;
        }

        .expandable-section-title-with-actions {
          .expandable-section-title-label-container {
            width: 86%;
          }
        }
      }

      .read-only-section {
        margin: 5px @sidebar-expandable-section-spacing;
      }
    }

    .expandable-section .sidebar-section-item {
      width: @sidebar-section-width;
      margin-left: 0;
      padding-left: @sidebar-expandable-section-spacing;
    }

    .actions-dropdown {
      .dropdown-label-svg {
        padding: 0;
        margin: 0;
        border: none;
        line-height: 0;
        height: @sidebar-section-title-line-height;
        width: @sidebar-section-title-line-height;

        svg {
          height: @sidebar-section-title-line-height;
          width: @sidebar-section-title-line-height;
          margin: 0;
          background-color: @sidebar-light-bg-section-hover-bg-color;
          border-radius: @sidebar-tab-border-radius;
        }
      }

      &.dropdown-active,
      &:hover {
        svg {
          background-color: @sidebar-light-bg-section-active-bg-color;
        }
      }
    }

    .sidebar-section-link,
    .sidebar-secondary-action {
      display: block;
      color: inherit;
      .ellipsis();

      svg {
        height: 16px;
        width: 16px;
        margin-right: 5px;
        vertical-align: text-top;
        fill: @sidebar-light-bg-light-font-color;
      }

      &:active, &:focus {
        text-decoration: none;
      }
    }
  }

  .expandable-section-empty {
    margin-left: @sidebar-horizontal-spacing;
    margin-bottom: @sidebar-expandable-section-spacing;
  }

  .read-only-section {
    font-style: italic;
    margin-right: @sidebar-expandable-section-spacing;
  }

  &.sidebar-light-bg {
    background-color: @sidebar-light-bg-color;
    box-shadow: @sidebar-light-bg-box-shadow;

    .sidebar-content {
      color: @sidebar-light-bg-font-color;
      background-color: @sidebar-light-bg-color;
      .white-bg-scrollbar();
    }

    .sidebar-section-title-badge {
      color: @white;
      background-color: @gray2;
    }

    .expand-collapse-button {
      background-color: @sidebar-light-bg-color;
      border: @sidebar-light-bg-border;

      svg {
        fill: @sidebar-light-bg-expand-collapse-color;
      }

      &:hover {
        background: @sidebar-light-bg-collapsed-hover-color;
        border-color: @sidebar-light-bg-collapsed-hover-color;

        svg {
          fill: @sidebar-light-bg-color;
        }
      }
    }

    .sidebar-tab-label,
    .sidebar-tab-label-empty {
      border-bottom: @sidebar-light-bg-section-border;
      color: @sidebar-light-bg-tabs-color;

      &.sidebar-active-tab {
        border: @sidebar-light-bg-section-border;
        border-bottom-color: transparent;
        color: @sidebar-light-bg-dark-font-color;
      }
    }

    .sidebar-section {
      &:not(:last-child)::after {
        border-bottom: @sidebar-light-bg-section-border;
      }

      &.sidebar-section-expanded::after {
        margin-top: @sidebar-expandable-section-spacing;
      }

      &.sidebar-section-expanded .sidebar-section-title {
        .sidebar-section-button svg {
          fill: @sidebar-light-bg-dark-font-color;
        }
      }

      &.sidebar-section-active .sidebar-section-link,
      .sidebar-section-title.sidebar-section-link:hover{
        background-color: @sidebar-light-bg-section-hover-bg-color;
      }

      .sidebar-section-title {
        color: @sidebar-light-bg-dark-font-color;
        font-weight: @sidebar-light-bg-bold-font-weight;

        .sidebar-section-label-icon svg {
          fill: @sidebar-light-bg-dark-font-color;
        }

        .sidebar-section-button,
        .sidebar-section-main-action-button {
          svg {
            fill: @sidebar-light-bg-section-icons-fill;
          }
        }

        .explanation{
          svg {
            fill: @sidebar-light-bg-section-icons-fill;
          }
        }
      }

      .sidebar-section-content {
        &::after {
          border-top: @sidebar-light-bg-section-border;
        }

        .close-icon svg {
          fill: @sidebar-light-bg-font-color;
        }

        .section-item.selector {
          .explanation {
            svg {
              fill: @gray5;
            }
          }
        }
      }

      .group-option-control {
        [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
          border: 1px solid @sidebar-light-bg-section-icons-fill;
        }
      }

      .selected-options li {
        .label {
          color: @sidebar-light-bg-font-color;
        }

        .icon {
          svg {
            fill: @sidebar-light-bg-section-icons-fill;
            &:hover {
              fill: @sidebar-light-bg-collapsed-hover-color;
            }
          }
          &.icon-disabled {
            svg {
              &:hover {
                fill: @sidebar-light-bg-section-icons-fill;
              }
            }
          }
        }
      }
    }

    .section-action {
      color: @link-color;

      svg {
        fill: @link-color;
      }
    }

    .sidebar-secondary-action {
      cursor: pointer;
      svg {
        fill: @sidebar-light-bg-dark-font-color;
      }
    }

    .autocomplete-with-checkbox {
      .box-with-icon {
        background-color: @body-bg;
        box-shadow: @sidebar-light-bg-input-box-shadow;
        border: @sidebar-light-bg-border;
      }
    }

    .sidebar-transparent-dropdown {
      .dropdown-transparent;

      .dropdown-selection {
        color: inherit;
        padding: 2px 8px 2px 0;

        .title {
          text-decoration-color: @gray-lightest;
        }
      }

      .caret {
        border-bottom-color: inherit;
      }
    }

    .read-only-section {
      color: @sidebar-light-bg-inactive-font-color;
    }

    .group-options-button {
      background-color: @thumbnail-bg;
      border: 1px solid @thumbnail-border;

      span {
        color: @gray1;
      }
    }
  }

  &.sidebar-dark-bg {
    background-color: @sidebar-dark-bg-color;
    box-shadow: @sidebar-dark-bg-box-shadow;
    color: @sidebar-dark-bg-font-color;

    .sidebar-section-title-badge {
      color: @black;
      background-color: @white;
    }

    .sidebar-content {
      background-color: @sidebar-dark-bg-color;
      .dark-bg-scrollbar();
    }

    .sidebar-collapsed {
      .expand-collapse-button {
        background-color: @sidebar-dark-bg-expand-button-bg-color;
      }
    }

    .sidebar-expanded {
      .expand-collapse-button {
        right: 10px;
      }
    }

    .expand-collapse-button {
      background-color: @sidebar-dark-bg-color;
      border: @sidebar-dark-bg-color;
      top: 15px;

      svg {
        fill: @sidebar-dark-bg-collapsed-color;
      }

      &:hover {
        background: @sidebar-dark-bg-collapsed-hover-bg-color;
        border-color: @sidebar-dark-bg-collapsed-hover-bg-color;

        svg {
          fill: @sidebar-dark-bg-collapsed-hover-color;
        }
      }
    }

    .sidebar-section {
      &:not(:last-child)::after {
        border-bottom: @sidebar-dark-bg-section-border;
      }

      &.sidebar-section-expanded .sidebar-section-title {
        color: @sidebar-dark-bg-active-section-color;

        .sidebar-section-button, .sidebar-section-label-icon {
          svg {
            fill: @sidebar-dark-bg-active-section-color;
          }
        }
      }

      .sidebar-section-title {
        font-weight: @sidebar-dark-bg-bold-font-weight;

        .sidebar-section-button,
        .sidebar-section-label-icon,
        .sidebar-section-main-action-button {
          svg {
            fill: @sidebar-dark-bg-section-icons-fill;
          }
        }

        .explanation{
          svg {
            fill: @gray10;
          }
        }
      }

      .sidebar-section-content {
        &::after {
          border-top: @sidebar-dark-bg-section-border;
        }

        .close-icon svg {
          fill: @sidebar-dark-bg-section-icons-fill;
        }
      }
    }

    .section-action {
      fill: @sidebar-dark-bg-section-icons-fill;

      svg {
        fill: @sidebar-dark-bg-section-icons-fill;
      }
    }

    .sidebar-transparent-dropdown {
      .dropdown-selection {
        color: inherit;

        .title {
          text-decoration-color: @gray8;
        }
      }

      .caret {
        border-bottom-color: inherit;
      }
    }

    .group-options-button {
      background-color: @grayBG;

      span {
        color: @gray5;
      }
    }
  }

  .group-options-button {
    cursor: pointer;
    margin-right: 10px;
    padding: 10px;
    border-radius: 2px;
    box-sizing: border-box;

    span {
      display: block;
      max-width: 150px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .group-options-button {
    background-color: @grayBG;
    margin-right: 10px;
    padding: 10px;
    border-radius: 2px;

    span {
      display: block;
    }
  }
}

.sidebar.sidebar-wide {
  .sidebar-expanded, .sidebar-content {
    width: @sidebar-expanded-wider-width;
  }

  .sidebar-section {
    .expandable-section-title {
      width: @sidebar-wide-section-width;
    }

    .expandable-section .sidebar-section-item {
      width: @sidebar-wide-section-width;
    }
  }
}

.sidebar-single-tab {
  .sidebar-scrollable-tab {
    padding-top: 72px;

    .sidebar-section:first-child::before {
      border-top: @sidebar-light-bg-section-border;
    }
  }
}

.sidebar-with-mask {
  .modal-mask {
    left: @sidebar-expanded-width;
  }

  &.sidebar-wide {
    .modal-mask {
      left: @sidebar-expanded-wider-width;
    }
  }

  .modal-mask.full-overlay {
    left: 0;
  }

  .expand-collapse-button {
    display: none;
  }
}

.page-content-container {
  .sidebar-with-mask {
    .sidebar-content {
      position: fixed;
      top: 0;
    }
  }
}

.modal-active {
  .sidebar .expand-collapse-button {
    visibility: hidden;
  }

  .card-page .sidebar .expand-collapse-button {// Show the button on the DCV
    visibility: visible;
  }
}

.target-reorder-active,
.top-level-modal-active {
  .sidebar .expand-collapse-button,
  .card-page .sidebar .expand-collapse-button {// Hide the button when the modals are open on top of the DCV
    visibility: hidden;
  }
}
