.page-main-content-with-header {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto hidden;
  flex-direction: column;

  .section-header-container {
    border: @border-default;
    padding: 0 @page-spacing;
    box-shadow: @box-shadow-small-regular;
    background-color: @background-white-color;

    .section-header {
      margin: 18px 0;

      &.with-subtitle {
        margin: @spacing-3 0;
      }
    }
  }

  .page-main-content {
    position: relative;

    .scroll-to-top-element {
      top: 0;
      position: absolute;
    }
  }
}

.error-modal-wrapper {
  position: absolute;
  top: 40%;
  width: 100%;
}
.error-modal {
  margin: auto;
  width: 800px;

  .error-modal-header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: @lightBG1;
    font-size: @font-size-h1;
    width: 100%;
    padding: 10px 25px;
  }

  .error-modal-body {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: @white;
    font-size: @font-size-h4;
    width: 100%;
    padding: 15px 25px;

    p {
      margin: 0 0 10px 0;
    }

    .button {
      margin: 10px 0;
    }
  }
}

.show-more {
  color: @link-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
