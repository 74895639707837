.confirm-dialog-container {
  border-radius: 6px;
  background-color: @background-white-color;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.5);
  margin-right: auto;
  margin-left: auto;
  width: 500px;
  padding: 35px 40px 40px;
  margin-top: 100px;
  text-align: center;
  white-space: normal;

  &.large {
    width: 660px;
  }

  &.medium {
    width: 600px;
  }

  .confirm-dialog-body {
    .confirm-dialog-title {
      font-weight: 500;
      font-size: 26px;
    }

    .confirm-dialog-description {
      font-size: 16px;
      color: @gray;
      margin-bottom: 25px;
      margin-top: 5px;
    }

    .buttons {

      .button {
        margin-right: 10px;
        min-width: 97px;
        line-height: 19px;
        height: 37px;

        &:last-child {
          margin-right: 0;
        }

        &.wide {
          padding-left: 25px;
          padding-right: 25px;
        }

        svg {
          fill: @icon-on-dark-color;
          vertical-align: middle;
          .icon-medium();
        }
      }
    }

    &.danger {
      .confirm-dialog-title {
        color: @text-danger-color;
      }

      .confirm-dialog-confirm-button {
        background-color: @background-danger-color;
        color: @text-on-dark-color;
        border-color: @background-danger-color;
        font-weight: @font-weight-medium;
      }

      .confirm-dialog-confirm-button:hover {
        background-color: @background-hover-danger-color;
        border-color: @background-hover-danger-color;
      }
    }

    &.confirm {
      .confirm-dialog-title {
        color: @text-color;
      }

      .confirm-dialog-confirm-button {
        background-color: @background-success-color;
        color: @text-on-dark-color;
        border-color: @background-success-color;
      }

      .confirm-dialog-confirm-button:hover {
        background-color: @background-hover-success-color;
        border-color: @background-hover-success-color;
      }
    }

    &.warning {
      .confirm-dialog-title {
        color: @warning-alert;
      }

      .confirm-dialog-confirm-button {
        background-color: @warning-alert;
        color: @white;
        border-color: @warning-alert;
      }
    }

    .sad-fox-img {
      svg {
        height: 138px;
        width: 126px;
      }
    }
  }
}

