.scenario-page {
  .page-content-container .page-main-content {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .recommendation-drawer {
    flex-shrink: 0;
    overflow-x: hidden;
  }

  .scenario-page-container {
    padding: 18px 50px 30px;
    max-width: @page-width;
    margin: 0 auto;

    &.recommendation-open {
      padding-right: 30px;
      width: calc(~'100%' - @recommendation-text-view-width);
    }
  }

  .scenario-header {
    margin-bottom: 25px; // TODO replace me with separator
    padding-top: 3px;
    font-size: 16px;

    .scenario-title {
      font-weight: @font-weight-semi-bold;
      color: @black;
      text-transform: uppercase;
      line-height: 1.2em;

      svg {
        height: 17px;
        width: 13px;
        vertical-align: text-top;
        margin-right: @text-with-icon-separation;
      }
    }

    .scenario-summary {
      margin: 8px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;

      a {
        text-decoration: underline;
        font-weight: @font-weight-semi-bold;
      }
    }
  }

  .scenario-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .scenario-storyline-group .storyline-header,
  .action .action-header {
    width: 100%;
    padding: @scenario-storyline-header-padding;
    border-top: @scenario-storyline-header-border;
    border-left: @scenario-storyline-header-border;
    border-right: @scenario-storyline-header-border;
    .border-top-radius(@scenario-storyline-header-radius);
    .flexbox();
    justify-content: space-between;
    font-weight: @font-weight-regular;
    font-size: @font-size-bodyText;
    text-align: left;
    text-transform: uppercase;
    color: @white;
  }

  .scenario-storyline-group {
    margin-bottom: 16px;
    
    &:first-child {
      margin-top: 10px;
    }

    .storyline-header {
      .explanation {
        display: block;
        height: 18px;

        svg {
          height: 16px;
          width: 16px;
          vertical-align: text-top;
          opacity: 0.4;
        }
      }

      .tooltip.small-wide-tooltip .tooltip-inner {
        padding: 15px;
      }
    }

    .storyline {
      cursor: pointer;
      box-shadow: @scenario-storyline-box-shadow;

      &:last-child .storyline-content {
        .border-bottom-radius(@scenario-storyline-header-radius);
      }

      .storyline-content {
        width: 100%;
        background-color: @scenario-storyline-causes-active-complimentary-color;
        border: @scenario-storyline-border;
        border-top: none;

        .storyline-noun {
          color: @black;
        }

        .info {
          font-size: @anomaly-font-size;
          font-weight: 500;
          text-transform: uppercase;
          .flex-width(calc(~"100% - @{scenario-storyline-arrow-col} - @{scenario-storyline-direction-col}"));
        }

        .storyline-card-info {
          margin-top: @storyline-card-info-margin-top;

          .card-section {
            width: 90%;
            margin: 0 auto;
          }

          .storyline-card-info-explanation {
            padding: 15px 20px 20px;
            font-style: italic;
            border-top: 1px solid @gray-lightest;
          }

          .card-settings {
            font-size: 15px;
            color: @black;
            font-weight: @font-weight-semi-bold;
            text-align: center;

            strong {
              font-weight: inherit;
            }
          }

          .preview {
            box-shadow: none;
            border: none;
            border-radius: 0;
            margin-bottom: 0;

            .content {
              padding: 0;
              border-radius: 0;
            }

            .preview-graph-view {
              margin: 0;
            }

            .x-large-card {
              .axis-header {
                padding: 0;
              }
            }

            .card-no-graph .card-not-loaded {
              align-self: flex-start;
            }
          }
        }
      }

      &.expanded {
        .arrow {
          .flex-width(@scenario-storyline-arrow-col);
          .down-arrow(@gray2, 8px, 2px);
        }
      }

      &.collapsed {
        .arrow {
          .flex-width(@scenario-storyline-arrow-col);
          .right-arrow(@gray2, 8px, 2px);
        }

        &:hover .storyline-content {
          background-color: @background-active-color;
        }
      }

      .storyline-info-container {
        .flexbox;
        .middle-columns;
        height: 100%;
        position: relative;
        padding: @scenario-storyline-large-padding;

      }
    }
  }

  .action {
    .action-header {
      background-color: @scenario-action-bg-color;
      font-size: 15px;
      padding: @scenario-action-header-padding;
    }

    .action-box {
      background-color: @white;
      color:@gray;
      border: 1px solid @gray-lightest;
      font-size: 16px;
      padding: @scenario-action-box-padding;
      .border-bottom-radius(@scenario-action-box-radius);


      .action-link {
        text-decoration: underline;
        font-weight: @font-weight-semi-bold;
        text-transform: capitalize;
        margin-bottom: 5px;
      }
    }
  }
}

.storyline-direction {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  text-align: center;
  margin-right: 14px;

  svg {
    fill: @white;
    margin-top: 16%;
    width: 18px;
    height: 18px;
  }
}

.causes .storyline-direction {
  background-color: @scenario-storyline-causes-active-color;
}

.controls .storyline-direction {
  background-color: @scenario-storyline-controls-active-color;
}

.contras .storyline-direction {
  background-color: @scenario-storyline-contras-active-color;
}

.neutral .results, .none .results {
  .storyline-header {
    background-color: @scenario-storyline-results-neutral-active-color;
    color: @scenario-storyline-results-active-complimentary-color;
  }

  .storyline {
    .storyline-content {
      .storyline-bubble svg {
        width: 16px;
        height: 16px;
        margin: 20% 0 0 10%;
      }
      .storyline-deviation {
        color: @scenario-storyline-results-neutral-active-color;
      }
    }

    &.expanded {
      .storyline-info-container {
        background-color: @scenario-storyline-results-neutral-expanded-bg-color;
      }
    }
  }

  .storyline-direction {
    background-color: @scenario-storyline-results-neutral-active-color;
  }
}

.negative .results {
  .storyline-header {
    background-color: @scenario-storyline-results-negative-active-color;
    color: @scenario-storyline-results-active-complimentary-color;
  }

  .storyline {
    .storyline-content {
      .storyline-deviation {
        color: @scenario-storyline-results-negative-active-color;
      }
    }

    &.expanded {
      .storyline-info-container {
        background-color: @scenario-storyline-results-negative-expanded-bg-color;
      }
    }
  }

  .storyline-direction {
    background-color: @scenario-storyline-results-negative-active-color;
  }
}

.positive .results {
  .storyline-header {
    background-color: @scenario-storyline-results-positive-active-color;
    color: @scenario-storyline-results-active-complimentary-color;
  }

  .storyline {
    .storyline-content {
      .storyline-deviation {
        color: @scenario-storyline-results-positive-active-color;
      }
    }

    &.expanded {
      .storyline-info-container {
        background-color: @scenario-storyline-results-positive-expanded-bg-color;
      }
    }
  }

  .storyline-direction {
    background-color: @scenario-storyline-results-positive-active-color;
  }
}

.scenario-storyline-group {
  &.causes {
    .storyline-header {
      background-color: @scenario-storyline-causes-active-color;
      color: @scenario-storyline-causes-active-complimentary-color;
    }

    .storyline {
      .storyline-content {
        .storyline-deviation {
          color: @scenario-storyline-causes-active-color;
        }
      }

      &.expanded {
        .storyline-info-container {
          background-color: @scenario-storyline-causes-expanded-bg-color;
        }
      }
    }
  }

  &.controls {
    .storyline-header {
      background-color: @scenario-storyline-controls-active-color;
      color: @scenario-storyline-controls-active-complimentary-color;

    }

    .storyline {
      .storyline-content {
        .storyline-deviation {
          color: @scenario-storyline-controls-active-color;
        }
      }

      &.expanded {
        .storyline-info-container {
          background-color: @scenario-storyline-controls-expanded-bg-color;
        }
      }
    }
  }

  &.contras {
    .storyline-header {
      background-color: @scenario-storyline-contras-active-color;
      color: @scenario-storyline-contras-active-complimentary-color;

    }

    .storyline {
      .storyline-content {
        .storyline-deviation {
          color: @scenario-storyline-contras-active-color;
        }
      }

      &.expanded {
        .storyline-info-container {
          background-color: @scenario-storyline-contras-expanded-bg-color;
        }
      }
    }
  }
}

.storyline-card-info-appear {
  .scale-in-ver-to-0();
}

.storyline-card-info-appear.storyline-card-info-appear-active {
  animation: scale-in-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both;
}

.storyline-card-info-exit {
  .scale-out-ver-to-0();
}

.storyline-card-info-exit.storyline-card-info-exit-active {
  animation: scale-out-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both;
}

.scenario-preview {
  .scenario-title {
    padding: 0 5px 10px;

    .scenario-name {
      font-size: 16px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
    }
  }

  .header {
    padding: 0;
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      flex-direction: column;
    }
  }

  .neutral {
    .title {
      vertical-align: middle;
      padding-bottom: 2px;
    }

    .anomaly-info {
      .timeframe {
        padding-left: 3px;
      }
    }
  }
}

.scenario-summary-preview-content {
  > a:hover {
    text-decoration: none;
  }
}

.scenario-summary-section {
  padding-left: 45px;
  margin-top: 10px;
  height: 60px;
  vertical-align: middle;
  font-size: @font-size-bodyText;
}

.scenario-alert-icon {
  line-height: 0;
  border-radius: 50%;
  text-align: center;
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;

  &.negative {
    background-color: @negative-alert;
  }

  &.neutral {
    background-color: @neutral-alert;
  }

  &.positive {
    background-color: @positive-alert;
  }

  &.exited {
    background-color: @anomaly-recently-exited-color;
  }

  svg {
    width: 80%;
    height: 100%;
    fill: @white;
    vertical-align: middle;
  }
}

.scenario-explanation {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: bold;

  &.negative {
    .arrow svg {
      fill: @negative-alert;
    }

    .cause, .contra {
      border-color: @negative-alert;
      background-color: @white;
      color: @negative-alert;
    }

    .result {
      border-color: @negative-alert;
      background-color: @negative-alert;
      color: @white;
    }
  }

  &.positive {
    .arrow svg {
      fill: @positive-alert;
    }

    .cause, .contra {
      border-color: @positive-alert;
      background-color: @white;
      color: @positive-alert;
    }

    .result {
      border-color: @positive-alert;
      background-color: @positive-alert;
      color: @white;
    }
  }

  &.neutral {
    .arrow svg {
      fill: @neutral-alert;
    }

    .cause, .contra {
      border-color: @neutral-alert;
      background-color: @white;
      color: @neutral-alert;
    }

    .result {
      border-color: @neutral-alert;
      background-color: @neutral-alert;
      color: @white;
    }
  }

  .cause, .arrow, .result {
    display: inline-block;
    vertical-align: middle;
  }

  .arrow {
    width: 28px;
    margin-left: 12px;
    margin-right: 12px;
    vertical-align: middle;
    line-height: 0;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .scenario-explanation-section {
    width: calc(~"50% - 26px");
  }

  .cause, .result, .contra {
    width: 100%;
    text-align: center;
    border: 0.15em solid;  //2.4
    padding: 0.47em; //7.04
    border-radius: 2.17em; // 34.72
    display: inline-block;
    line-height: 1.2em; // 17.28
    font-size: 13px;
    margin-top: 8px;
  }

  .cause, .result {
    &:first-child {
      margin-top: 0;
    }
  }

  .contra {
    border-style: dashed;
  }
}

.anomaly-deviation-negative {
  color: @negative-alert;
}

.anomaly-deviation-positive {
  color: @positive-alert;
}

.anomaly-deviation-neutral {
  color: @neutral-alert;
}

.anomaly-deviation-none {
  color: @gray-dark;
}
