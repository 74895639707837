.saved-goal-edit-modal-component {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: @white;
  box-shadow: 0 10px 60px rgba(0, 7, 41, 0.04), 0 3px 11px rgba(0, 7, 41, 0.06);
  border-radius: @border-radius-large 0 0 @border-radius-large;
  .saved-goal-edit-modal-component-body {
    height: 100%;
    overflow: hidden;
  }
  .saved-goal-edit-modal-component-header {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: @saved-goal-edit-modal-header-padding;

    .saved-goal-edit-modal-component-header-title {
      flex: 1;
      display: flex;
      align-items: center;
      padding-right: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      h2 {
        font-size: @text-display-base;
        color: @text-color;
        font-weight: @font-weight-semi-bold;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .saved-goal-edit-modal-component-header-info {
        display: inherit;
        margin-left: 10px;

        svg {
          .icon-small();
          fill: @icon-color;
          vertical-align: middle;
        }
      }
    }
    .saved-goal-edit-modal-component-header-actions {
      display: flex;
      flex-direction: row-reverse;
    }
    .saved-goal-edit-modal-component-header-action {
      margin-left: 12px;
    }
  }

  .saved-goal-edit-modal-component-content {
    flex: 1;
    display: flex;
    border-top: 1px solid @border-color;
    min-height: 0;
  }

  .saved-goal-edit-modal-component-status {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .saved-goal-edit-modal-component-filter {
    width: 260px;
    box-shadow: @box-inner-shadow;
  }

  .saved-goal-edit-modal-component-close-btn {
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: -48px;
    width: 48px;
    z-index: -1;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: @background-medium-color;
    border: none;
    border-radius: 12px 0 0 12px;
    box-shadow: 0 4px 8px rgba(102, 102, 121, 0.2);

    .icon {
      fill: @icon-color;
    }
  }
}

.saved-goal-view-how-it-works {
  display: flex;
  background-color: @background-weak-success-color;
  justify-content: space-between;
  padding: @spacing-3 @spacing-6;

  .bullseye-pointer-icon {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: @background-white-color;
  }

  .saved-goal-view-how-it-works-content {
    margin-left: @spacing-4;

    .saved-goal-view-how-it-works-title {
      color: @text-color;
      font: @text-display-xs;
    }

    ol {
      color: @text-color;
      margin-block-end: 0;
      font: @text-xs-medium;
      margin-block-start: @spacing-1;
      padding-inline-start: @spacing-6;

      .link-button {
        display: inline-flex;
      }
    }
  }

  .close {
    .icon-medium();
    cursor: pointer;

    svg {
      fill: @icon-color;
      .icon-medium();
    }
  }
}

.goal-filters-list-view-time-frames {
  .selected-options {
    > div {
      margin-bottom: 8px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.goal-filters-list-view-metrics {
  padding-bottom: 45px;
}
