.goal-status-widget-container {
  border: @goal-status-widget-border;
  border-radius: @goal-status-widget-border-radius;
  background-color: @goal-status-widget-background-color;
  box-shadow: @goal-status-widget-box-shadow;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
}

.goal-status-widget {
  border-bottom: @border-default;

  .title {
    font-weight: @goal-status-widget-title-font-weight;
    font-size: @goal-status-header-col-2-font-size;
    color: @black;
  }

  .info {
    font-weight: @goal-status-widget-title-subtitle-font-weight;
    font-size: @goal-status-header-col-2-font-size;
  }

  .inline-tooltip {
    display: inline;
  }

  .metric-title {
    padding-right: 4px;
    display: flex;
    align-items: flex-start;

    .edit-button {
      margin-left: @spacing-3;

      svg {
        vertical-align: middle;
        fill: @icon-weak-color;
      }

      &:hover svg {
        fill: @link-color;
      }
    }
  }

  .ramping-tooltip {
    color: @gray2;
    font-size: 16px;
    line-height: 19px;
    font-weight: 100;
    border-bottom: 1px dashed @gray2;
  }

  .goal-status-header {
    border-bottom: @goal-status-header-border-bottom;
    margin-left: @goal-status-header-margin-left;
    margin-right: @goal-status-header-margin-left;

    .dropdown-combo .dropdown-selection {
      padding: 0;
      border: none;

      .info {
        font-style: normal;
        color: @text-color-default;
        padding-left: @goal-status-widget-title-info-padding-left;
      }
    }

    .dropdown-combo.dropdown-with-caret .dropdown-selection > span {
      width: auto;
    }

    .dropdown-menu {
     font-size: 15px;
    }

    .dropdown .caret {
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-bottom: 1em solid @gray2;
    }

    .header-text {
      font-size: @goal-status-header-col-2-font-size;
      color: @goal-status-header-col-2-color;
      font-weight: @goal-status-header-font-weight;
      text-transform: uppercase;
    }

    .header {
      padding-top: @goal-status-header-padding-top;
      padding-left: @goal-status-header-padding-left;
    }

    .recommendation-status {
      padding-left: @goal-status-header-right-col-padding-left;
      font-size: @goal-status-header-right-col-font-size;
      color: @goal-status-header-right-col-color;

      .hide-header {
        display: none;
      }
    }
  }

  .goal-status-metric {
    color: @goal-status-metric-color;
    border-bottom: @goal-status-metric-border-bottom;
    margin-left: @goal-status-metric-margin-left;
    font-weight: @goal-status-metric-font-weight;
    margin-right: @goal-status-metric-margin-left;
    align-items: center;

    .goal-time-unit {
      text-transform: lowercase;
    }

    &:last-child {
      border-bottom: @goal-status-metric-border-bottom-none;
    }

    a {
      color: @goal-status-metric-color;
      text-decoration: @goal-status-metric-text-decoration;

      &:hover {
        text-decoration: underline;
        color: @link-across-the-site;
      }
    }

    .goal-status-body {
      padding: @goal-status-metric-goal-status-body-padding;

      .icon {
        svg {
          vertical-align: middle;
        }
      }
    }

    .left-col {
      padding: @goal-status-metric-goal-status-left-col-body-padding;

    }

    .recommendation {
      margin-left: @goal-status-metric-goal-status-right-col-body-padding;
      line-height: 25px;
      padding-top: 9px;
      padding-bottom: 10px;
    }

    svg {
      width: @goal-status-metric-size;
      height: @goal-status-metric-size;
    }

    .visible-link {
      text-decoration: underline;

      svg {
        fill: @white;
      }

      &:hover {
        color: @link-across-the-site;

        svg {
          fill: @link-across-the-site;
        }
      }
    }

    .positive {
      fill: @goal-hit-alert;
    }

    .negative {
      fill: @goal-missed-alert;
    }

    .risk {
      fill: @goal-high-risk-alert;
    }

    .track {
      fill: @goal-on-track-alert;
    }

    .behind {
        fill: @goal-behind-pace-alert;
    }


    .goal-status {
      position: @goal-status-widget-status-position;
      padding-left: @goal-status-widget-status-padding-left;
      font-size: @goal-status-widget-status-font-size;
      font-weight: @goal-status-widget-status-font-weight;
    }

    .progress-bar .progress-bar-base {
      width: 100px;
    }

    .val-only {
      font-weight: 500;

      > a {
        vertical-align: middle;
      }
    }

    .sales-coach-button-container {
      margin: 6px auto;
      float: right;
    }
  }
}

.goal-widget-rollover {
  &.inline-tooltip {
    display: inline-block;
  }

  .tooltip-inner {
    padding: 15px;
  }
}

.goal-widget-rollover-content {
  color: @text-color;

  .rollover-header {
    font: @text-small-semi-bold;
  }

  .rollover-timeframe {
    font: @text-2xs-regular;
  }

  .rollover-secondary-label {
    color: @text-weak-color;
  }

  .rollover-title {
    font: @text-small-regular;
    padding-bottom: @spacing-2;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    .edit-button {
      flex-shrink: 0;
    }
  }

  .goal-rollover-title {
    display: inline-block;
    color: @text-weak-color;
    margin-right: @spacing-1;
    font: @text-xs-regular;
  }

  .rollover-title-text {
    min-width: 0;
    word-wrap: break-word;
  }

  .goal-rollover-body {
    margin-top: @spacing-2;
  }

  .goal-rollover-row {
    margin-bottom: @spacing-1;

    .icon-button-inline {
      vertical-align: text-top;
      margin-left: @spacing-1;
    }
  }

  .goal-rollover-explanation {
    font: @text-xs-regular;
    color: @text-weak-color;
    margin-top: @spacing-1;
  }

  .goal-rollover-val {
    display: inline-block;
    font: @text-xs-regular;

    &.goal-rollover-val-highlighted {
      font: @text-xs-medium;
    }

    &.risk-goal {
      color: @goal-high-risk-alert;

      .colored-circle {
        background-color: @goal-high-risk-alert;
      }
    }

    &.hit-goal {
      color: @goal-hit-alert;

      .colored-circle {
        background-color: @goal-hit-alert;
      }
    }

    &.missed-goal {
      color: @goal-missed-alert;

      .colored-circle {
        background-color: @goal-missed-alert;
      }
    }

    &.track-goal {
      color: @goal-on-track-alert;

      .colored-circle {
        background-color: @goal-on-track-alert;
      }
    }

    &.behind-goal {
      color: @goal-behind-pace-alert;

      .colored-circle {
        background-color: @goal-behind-pace-alert;
      }
    }

    &.no-data-goal {
      color: @text-color;

      .colored-circle {
        background-color: @no-data-alert;
        border: 1px solid @no-data-alert;
      }
    }

    &.no-goal {
      color: @text-color;

      .colored-circle {
        background-color: @background-white-color;
        border: @border-default-hover;
      }
    }

    &.ramping-goal,
    &.calculating-goal{
      color: @text-color;

      .colored-circle {
        background-color: @background-hover-color;
      }
    }

    &.future-goal {
      color: @text-color;

      .colored-circle {
        background-color: @background-white-color;
        border: @border-dashed-hover;
      }
    }

    .colored-circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: @border-radius-rounded;
      margin-right: @spacing-1;
    }
  }

  .edit-button {
    flex-shrink: 0;
    margin-left: @spacing-3;

    svg {
      vertical-align: top;
      fill: @icon-weak-color;
      .icon-medium();

      &:hover {
        fill: @link-color;
      }
    }
  }

  .goal-rollover-link {
    white-space: nowrap;
    font: @text-xs-regular;
  }

  .sales-coach-button {
    margin-right: @spacing-5;
  }

  .goal-rollover-footer {
    margin-top: @spacing-2;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.page-with-recommendation-modal {
  .goal-widget-rollover .tooltip {
    z-index: @zindex-modal;
  }
}

.set-goal-widget-independent {
  border: @goal-status-widget-border;
  border-radius: @goal-status-widget-border-radius;
  background-color: @goal-status-widget-background-color;
  box-shadow: @goal-status-widget-box-shadow;
  padding: 20px;
  margin-top: 20px;
}

.set-goal-widget {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
}

.set-up-goal-container {
  .title {
    color: @gray-dark;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
  }

  .sub-title {
    padding-top: 10px;
    padding-bottom: 26px;
    line-height: 24px;
    margin: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .quote {
    color: @gray1;
    font-size: 16px;
    font-style: italic;
    line-height: 20px;
    padding-top: 26px;
  }

  .button {
    text-transform: uppercase;
  }
}

@media only screen and (max-width: @page-width) {
  .goal-status-widget {
    .goal-status-metric {
      svg {
        width: @goal-status-metric-media-size;
        height: @goal-status-metric-media-size;
        padding-top: @goal-status-media-padding-top;
      }

      .progress-bar .progress-bar-base {
        width: 60px;
      }
    }

    .goal-status {
      font-size: 13px;
    }

    .title {
      font-size: 14px;
      padding-right: 5px;
    }
    .info {
      font-size: 14px;
    }

    .goal-status-header {

      .header {
        line-height: 22px;
      }

      .dropdown .caret {
        border-left: 1em solid transparent;
        border-right: 1em solid transparent;
        border-bottom: 1em solid @gray2;
      }
    }
  }
}
