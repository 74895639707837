.goal-center-page {
  &.page-main-content {
    padding-bottom: 0;
  }

  .goal-center-view {
    padding: 0 20px;

    .header-action-items {
      display: flex;
      transition-duration: 0.3s;

      > :not(:last-child) {
        margin-right: @spacing-2;
      }

      &.filters-drawer-opened {
        margin-right: 240px;
      }
    }

    .confirmation-message {
      margin-top: @spacing-4;
    }

    .goal-action-items {
      display: flex;

      .corsac-button {
        margin-right: @spacing-2;

        svg {
          fill: @icon-color
        }
      }
    }

    .goal-center-table-view.edit-mode {
      .row-col-table {
        margin-top: @spacing-4;

        .row-col-table-body-row,
        .row-col-table-header {
          padding: 18px 24px;

          .table-row-indicator .indicator {
            margin-left: -15px;
          }
        }

        .row-col-table-body .row {
          align-items: center;

          .icon-button svg {
            margin-right: 0;
          }

          .row-col-table-cell {
            .goal-value-cell {
              display: flex;
              font: @text-base;
              align-items: center;

              input {
                min-width: 65px;
                max-width: 90px;
                padding: 6px 8px;
                font: @text-xs-regular;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  margin: 0;
                  -webkit-appearance: none;
                }
              }

              &.range-goal input {
                width: 30px;
              }
            }

            &:not(.table-cell-error) .placeholder {
              color: @text-weak-color;
            }

            .dropdown-selection,
            .corsac-input {
              font: @text-xs-regular;
            }

            .dropdown-options {
              max-height: 460px;
            }
          }

          .metric-controls-cell.has-error > div {
            display: flex;
            align-items: center;

            svg {
              fill: @icon-danger-color;
            }

            .corsac-button {
              color: @text-danger-color;
            }
          }
        }
      }

      .filterable-checkbox-list {
        min-width: fit-content;
        &-options {
          max-height: 340px;
        }
      }
    }

    &-footer {
      z-index: @zindex-table-header;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 75px;
      display: flex;
      position: sticky;
      align-items: center;
      justify-content: flex-end;
      background-color: @body-bg;

      .warning {
        display: flex;
        svg {
          fill: @icon-warning-color;
        }

        &.danger svg {
          fill: @icon-danger-color;
        }
      }

      .unsaved-changes-message {
        display: flex;
        align-items: center;

        span {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: @icon-warning-color;
        }
      }

      > :not(:last-child) {
        margin-right: @spacing-2;
      }
    }
  }
}

.goal-data-filters-modal {
  ul {
    margin-top: @spacing-2;
  }

  .setup-goal-container-row {
    margin-bottom: @spacing-1;
    .explanation {
      height: 22px;
    }
  }

  .removable-dropdown-row {
    align-items: center;
    margin-bottom: @spacing-4;

    .dropdown-selector {
      width: 280px;
    }
  }

  .dropdown-with-checkbox {
    line-height: inherit;
  }

  .dropdown-options {
    max-height: 312px;
  }
}

.goal-center-filters-drawer .goal-filters-list-view {
  width: 310px;
  overflow: scroll;

  .goal-filters-list-view-title {
    display: flex;
    padding: 20px 18px;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0;
      font: @text-large;
      color: @text-color;
      font-weight: @font-weight-medium;
    }
  }

  .sidebar-section .sidebar-section-title {
    text-transform: none;
    font: @text-xs-medium;
    font-weight: @font-weight-regular;

    .explanation {
      vertical-align: text-top;
    }

    .sidebar-section-right-buttons svg {
      fill: @icon-color;
    }
  }

  .removable-option .label {
    margin-right: 0;
  }
}

.overwrite-goals-modal {
  h4 {
    color: @text-color;
    margin: @spacing-3 0;
  }

  strong {
    color: @text-color;
    font-weight: @font-weight-medium;
  }

  .to-be-created {
    margin-top: @spacing-3;
  }

  .group-divider {
    height: 1px;
    width: 100%;
    margin: 15px 0;
    background-color: @border-hover-color;
  }
}

.duplicated-goals-modal {
  &-description {
    margin-bottom: @spacing-6;
  }

  .header-label {
    font: @text-xs-medium;
    color: @text-weak-color;

    &:first-child {
      padding-left: @spacing-8;
    }
  }

  .goals-group {
    font: @text-base;
    color: @text-color;

    .row {
      cursor: pointer;
    }

    input {
      margin-right: @spacing-2;
    }
  }

  .group-divider {
    height: 1px;
    margin: @spacing-6 0;
    background-color: @border-hover-color;
  }
}

.goal-center-page, .duplicated-goals-modal {
  .tooltip ul li {
    padding-top: 0;
  }
}
