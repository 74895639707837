.explanation {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  height: 28px;

  &.explanation-small {
    height: 24px;
  }

  svg {
    .icon-medium();
    fill: @icon-on-dark-color;
    vertical-align: middle;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .tooltip.left-bottom .tooltip-arrow {
    top: 12px;
  }

  .tooltip .tooltip-inner {
    text-align: left;
    padding: 10px 15px;

    span {
      margin-bottom: 0;
    }
  }

  &.explanation-light-bg {
    svg {
      fill: @gray8;
    }
  }

  &.explanation-lighter-bg {
    svg {
      fill: @gray-light2;
    }
  }

  &.explanation-lightest-bg {
    svg {
      fill: @gray5;
    }
  }

  &.explanation-darker-bg {
    svg {
      fill: @gray1;
    }
  }

  &.explanation-weak {
    svg {
      fill: @icon-weak-color;
    }
  }

  &.large-icon {
    svg {
      .icon-large();
    }
  }

  &.small-explanation-icon {
    svg {
      .icon-small();
    }
  }

  &.for-text {
    margin-left: 4px;
  }

  &.for-text-spaced {
    margin-left: 10px;
  }
}
