.loading-dialog {
  padding-top: 2em;
  padding-bottom: 2em;

  svg {
    width: 1em;
    height: 1em;
    margin-right: .5em;
    vertical-align: middle;
  }

  .text {
    font: @text-base;
    color: @text-weak-color;
    padding-top: 15px;

    & ~ .text {
      padding-top: 0;
    }

    &.highlighted-text {
      color: @black;
      font: @text-display-xs;
    }
  }

  .spinner {
    margin-right: 10px;

    svg {
      width: 28px;
      height: 28px;
      fill: @text-color-default;
    }

    &.white-bg {
      svg {
        fill: @gray7;
      }
    }
  }
}

.error-mask-dialog, .saving-dialog {
  height: 100%;
  width: 100%;
}

.saving-dialog {
  svg {
    width: 80px;
    height: 80px;
    fill: @text-color-default;
  }

  .text {
    margin-top: 10px;
    font-size: 40px;
  }
}

.error-mask-dialog {
  .title {
    margin-bottom: 10px;
    font-size: 30px;
  }

  .text {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.preloader-container {
  padding: 0 20px 0 20px;
  margin-top: 20px;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: @border-radius-small;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  min-height: 450px;
}
