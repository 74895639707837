.calendar-view {

  .input-with-icon input {
    height: 33px;
    cursor: pointer;

    &:focus {
      outline:none;
    }
  }
  .input-with-icon .input-icon {
    opacity: 1;

    svg {
      position: absolute;
      left: auto;
      right: 10px;
      top: 0;
      height: 20px;
      width: 20px;
      pointer-events: none;
      margin-top: 7px;
      fill: @gray5;
    }
  }

  .input-with-icon .form-control {
     padding-left: 10px;
  }

  .calendar {
    position: absolute;
    z-index: 1;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #969696;
  }
}

.calendar-view-with-label {
  .calendar-view .form-control {
    margin-top: 0;
  }
}

