.timer {
  color: #333333;
  text-align: center;

  &.large{
    height: 31px;
    width: 62px;
    font-size: 26px;
    line-height: 31px;
  }
}
