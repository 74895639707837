.migration-rule {
  h3 {
    color: @text-color;
    margin: 0 0 @spacing-3;
    font: @text-display-small;
  }

  .notification-list {
    margin: 0 2px @spacing-6;
  }

  .migration-rule-form {
    gap: 2px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    > div {
      width: calc(~"50% - 20px");
    }

    .dropdown-with-checkbox, > .dropdown {
      flex: 1;
    }

    &.with-bottom-warning {
      .modal-form-field {
        margin-bottom: @spacing-3;
      }

      > svg {
        margin-top: @spacing-3;
      }
    }

    svg {
      .icon-medium();
      fill: @icon-weak-color;
    }
  }
}
