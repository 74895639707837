.table {
  background-color: @table-bg-color;

  &.table-with-padding {
    padding: 0 20px;
  }

  &.table-striped .table-body {
    .row:nth-of-type(odd) {
      background-color: @gray-lighter;
    }

    a.row:hover {
      background-color: @gray;
      color: white;
      text-decoration: none;
    }
  }

  .cell {
    padding-top: @table-cell-v-padding;
    padding-bottom: @table-cell-v-padding;

    &.thin-cell {
      padding-top: @table-thin-cell-v-padding;
      padding-bottom: @table-thin-cell-v-padding;
    }

    &.primary-cell {
      .bold();
    }
  }

  .row {
    color: @table-row-color;
    font-size: @table-row-font-size;
  }

  .row-underlined {
    border-bottom: 1px solid @table-cell-border-color;
  }

  .header .cell {
    color: @table-header-color;
    font-size: @table-header-font-size;
  }

  .content .cell {
    color: @table-content-cell-color;
  }
}

.table-intro {
  margin-top: @spacing-2;
  color: @text-weak-color;
}

.table-separator {
  height: 30px;
}

.table-header {
  font-weight: @font-weight-bold;
  color: @gray-dark;
  font-size: @font-size-h4;
  padding-bottom: 10px;
  padding-top: 20px;
  border-bottom: 1px solid @gray5;
  margin-bottom: 5px;

  &.table-header-with-margin {
    margin-top: 50px;
  }
}

.table-body {
  .table-row {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .table-row-reduced {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.multi-table-section.section-header {
  margin-top: 40px;
  margin-bottom: 20px;

  .table-title {
    align-self: center;

    .section-header-sub-title {
      vertical-align: top;
    }
  }
}

.html-table {
  width: 100%;

  &.no-borders {
    border-collapse: collapse;
  }

  .table-tr {
    padding: 0 5px;
    font-size: @font-size-bodyText;

    &.header {
      color: @black;
      text-transform: uppercase;
      font-size: 13px;

      .table-cell {
        line-height: 20px;
        position: sticky;
        top: 0;
        padding: 0;
        z-index: @zindex-table-header;
        font-weight: @font-weight-semi-bold;

        .header-styled-cell {
          padding: 4px 8px 2px;
          background-color: @whiteBG;
          display: flex;
          align-items: center;

          .header-label {
            display: flex;
          }

          .column-explanation {
            display: flex;
            align-items: center;
          }

          .text {
            display: inline-block;
            vertical-align: bottom;
          }

          .explanation.for-text {
            margin-left: 6px;
          }
        }

        &:not(.actions) .header-styled-cell {
          padding-right: 30px;
          line-height: 25px;
          .ellipsis();
        }

        &.actions {
          .header-styled-cell {
            height: 33px;
          }
        }

        &.frozen-column, &.actions {
          z-index: @table-frozen-column-header-zindex;
        }
      }
    }

    &.table-body-row {
      border-bottom: 1px solid @table-cell-border-color;

      .table-cell, .loading-cell {
        padding: 9px 8px;

        &:not(.actions) {
          padding-right: 30px;
          line-height: 25px;
          .ellipsis();
        }

        &.table-cell-with-tooltip {
          padding: 0; // Padding is added on the tooltip base

          .table-cell-tooltip {
            padding: 9px 30px 9px 8px;
          }
        }
      }
    }

    &.table-body-row .table-cell,
    &.header .table-cell {
      white-space: nowrap;
      text-align: left;
    }

    .table-cell {
      .text {
        max-width: 370px;
        width: fit-content;
      }

      &:last-child {
        width: 100%;
      }
    }

    .text {
      .ellipsis();
    }
  }

  .loading-row, .error-row {
    .row-explanation {
      text-align: center;
      position: sticky;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

      svg {
        height: 16px;
        width: 16px;
        margin-right: 5px;
        vertical-align: middle;
        fill: @text-color-default;
      }

      .button.text-only.white-background {
        svg {
          fill: @link-color;
        }
      }

      span {
        vertical-align: middle;
      }
    }
  }

  .error-row {
    .row-explanation {
      line-height: 22px;
    }
  }
}

.table-cell-icon {
  svg {
    fill: @table-cell-icon-color;
    height: 18px;
    width: 18px;
    vertical-align: text-bottom;
    margin-right: 10px;
  }
}

.sort-icon-element {
  display: flex;
  padding-left: 5px;
  flex-direction: column;
  justify-content: center;

  svg {
    width: 9px;
    height: 6px;
    fill: @gray8;

    &.active {
      fill: @link-color;
    }
  }
}

.column-sorting {
  display: flex;
  cursor: pointer;

  &:hover:not(:has(.column-filter-menu:hover)) { // Not apply hover effect when hovering on column filter menu
    .sorted {
      .active {
        fill: @gray8;
      }
      .un-active {
        fill: @link-color;
      }
    }

    .unsorted svg:first-child {
      fill: @link-color;
    }
  }

  &.single-arrow-sort {
    .sort-icon-element {
      svg {
        .icon-small();
        fill: @icon-weak-color;
        transition-duration: 0.3s;
      }

      &.auto-hide svg {
        display: none;
      }

      &:not(.auto-hide) {
        &.sorted svg {
          fill: @icon-color;
        }
      }

      &.sorted svg {
        display: inline;

        &.desc {
          transform: rotate(180deg);
        }
      }
    }

    &:hover:not(:has(.column-filter-menu:hover)) { // Not apply hover effect when hovering on column filter menu
      .sorted svg {
        fill: @icon-color;
      }

      .unsorted svg {
        display: inline;
      }
    }
  }
}

.row-col-table {
  font-size: @font-size-bodyText;
  line-height: 24px;

  &.row-col-table-with-margin {
    margin-top: 30px;
  }

  &.table-frozen-header {
    .row-col-table-header {
      position: sticky;
      top: 0;
      background-color: @body-bg;
      z-index: @zindex-table-header;
    }
  }

  .row-col-table-header {
    border-bottom: @gray-border;
  }

  .row-col-table-body-row {
    display: flex;
    padding: 10px 0;
    position: relative;
    align-items: center;
    border-bottom: @lighter-gray-border;

    &.disabled .row-col-table-cell {
      opacity: 0.5;
      pointer-events: none;
    }

    &.empty-table {
      border-bottom: none;
    }

    .row-col-table-cell svg:not(.icon-button svg) {
      flex: none;
      .icon-small();
      margin-right: 5px;
      fill: @icon-weak-color;
      vertical-align: text-top;

      &.animation-spin {
        fill: @icon-active-color;
      }
    }

    > .row {
      flex: auto;
    }

    .table-row-indicator {
      .tooltip {
        margin-left: -@spacing-4;
      }

      .indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-left: -@spacing-5;

        &.success-indicator {
          background-color: @background-success-color;
        }

        &.info-indicator {
          background-color: @background-active-color;
        }

        &.warning-indicator {
          background-color: @icon-warning-color;
        }

        &.danger-indicator {
          background-color: @background-danger-color;
        }
      }
    }
  }

  .row-col-table-cell {
    .recipient-cell {
      font-size: @font-size-base;

      .recipient-email {
        word-break: break-all;
      }
    }

    .multi-rows {
      > div {
        margin: 6px 0;
      }
      > div:first-child {
        margin-top: 0;
      }
      > div:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: @text-color-default;
      display: block;
      &.inline-link {
        display: inline-block;
      }

      &:active, &:focus {
        text-decoration: none;
      }

      &:hover {
        color: @link-color;
        font-weight: @font-weight-medium;
        text-decoration: none;

        .table-cell-icon svg {
          fill: @link-color;
        }
      }

      &.decorated-link {
        text-decoration: underline;
        color: @link-color;
      }

      &.edit-link {
        align-items: center;
        display: flex;
        span {
          max-width: 90%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &:hover {
          text-decoration: underline;
          svg {
            fill: @link-color;
            visibility: visible;
          }
        }
        svg {
          margin-left: 10px;
          visibility: hidden;
        }
      }
    }

    &.ellipsis > * {
      .ellipsis();
    }

    .table-cell-tooltip .tooltip-inner {
      max-height: 180px;
      overflow-y: auto;
    }

    .centered-table-cell-content {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }
  }

  &.corsac-table {
    border: @border-default;
    padding-top: @spacing-4;
    background-color: @white;
    border-radius: @border-radius-default;

    .row-col-table-header {
      display: flex;
      padding: 18px 34px;
      border-bottom: @border-default;

      > .row {
        flex: 1;

        > div {
          display: flex;
          align-items: center;
        }
      }

      .column-filter-menu {
        padding-left: @spacing-1;

        .icon-button {
          height: @spacing-6;
          padding: @spacing-1;
        }

        &.open-menu .icon-button {
          background-color: @background-active-color;
        }

        &.active-filters .icon-button {
          background-color: @background-blue-color;

          svg {
            fill: @text-on-dark-color;
          }
        }

        .filterable-checkbox-list {
          cursor: auto;

          .filterable-checkbox-list-options {
            max-height: 210px;
          }
        }
      }
    }

    &.table-frozen-header {
      padding-top: 0;

      > .row-col-table-header {
        padding-top: 16px;
        background-color: inherit;
        border-radius: @border-radius-default @border-radius-default 0 0;
      }
    }

    .row-col-table-body {
      color: @text-color;
      &-row {
        padding: 18px 34px;
        border-bottom: @border-default;
      }
    }

    .row-col-table-cell {
      cursor: default;
      > div {
        flex: auto;
        display: flex;
        align-items: center;
      }

      .dashed-cell-content {
        width: fit-content;
        border-bottom: @border-dashed-hover;
      }

      .centered-cell-content {
        justify-content: center;
      }

      .actions-button .icon-button {
        height: @spacing-6;

        svg {
          .icon-large();
        }
      }

      &.table-cell-error {
        .placeholder, ::placeholder {
          color: @text-danger-color;
        }

        > div > svg {
          .icon-medium();
          fill: @icon-danger-color;
        }
      }

      &.ellipsis div {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .html-table .table-tr .table-cell .text {
    max-width: 310px;
  }
}

@media only screen and (min-width: 1900px) {
  .html-table .table-tr .table-cell .text {
    max-width: 330px;
  }
}

@media only screen and (min-width: 2200px) {
  .html-table .table-tr .table-cell .text {
    max-width: 350px;
  }
}
