.saved-goal-edit-modal-component-filter {
  .goal-filters-list-view {
    overflow: auto;
    height: 100%;
    background-color: @background-medium-color;
    box-shadow: @box-inner-shadow;
  }

  .goal-filters-list-view-title h3 {
    font: @text-display-small;
    color: @text-color;
    margin: 0;
    padding: 20px 18px;
  }
}
