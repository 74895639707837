//
// Utility classes
// --------------------------------------------------


.ellipsis {
  .text-ellipsis();
}

.paragraph-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

// Floats
// -------------------------

.clearfix {
  .clearfix();
}
.center-block {
  .center-block();
}

.center-content {
  text-align: center;
}

.left-content {
  text-align: left;
}

.right-content {
  text-align: right;
}

.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

.margin-top-5 {
  margin-top: 5px;
}

.full-width {
  width: 100%
}

.break-word-wrap {
  word-wrap: break-word;
}

.center-vertically {
  // Requires adding 'position: relative' to parent component
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bold-font {
  font-weight: @font-weight-semi-bold;
  color: @black;
}

.underlined-bold-font {
  text-decoration: underline;
  text-decoration-color: @gray2;
  .bold-font;
}

.loud-text {
  .bold-font;
  text-transform: uppercase;
}

.italic-font {
  font-style: italic;
}

.padding-none {
  padding: 0 !important;
}
