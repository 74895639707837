.confirmation-container {
  position: absolute;
  width: 100%;
  z-index: 1;

  &.animated {
    animation: slide-in-right-and-fade 0.5s ease forwards;
  }

  &.hide {
    animation: fade-out 0.5s ease forwards;
  }

  &.overlay {
    position: fixed;
    top: 90px;

    &.centered-overlay {
      left: 0;
      text-align: center;

      .confirmation-message {
        padding: 15px;
      }
    }
  }

  .confirmation-message {
    font-size: 16px;
    line-height: 17px;
    font-weight: lighter;
    background-color: #FCF8E3;
    border: 1px solid #E8BF78;
    border-radius: @border-radius-small;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.08);
    display: inline-block;
    position: relative;
    padding: 12px 16px;

    & .bold {
      font-weight: @font-weight-semi-bold;
    }

    & img {
      .icon-large();
      margin-right: 16px;
    }
  }

  .confirmation-message-content {
    flex-grow: 1;
  }

  .confirmation-message-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    .close {
      flex-basis: auto;
    }
  }

  .confirmation-message-icon {
    margin-right: 15px;
  }

  .close {
    margin-left: 15px;

    svg {
      .icon-small();
      fill: @icon-color;
      cursor: pointer;
    }
  }

  .confirmation-message-text {
    margin: 0;
    word-wrap: break-word
  }

  .message-section {
    display: flex;

    .description {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
    }

    .icon {
      width: 1.8rem;
      font-size: 14px;

      svg {
        width: 18px;
      }
    }

    .info {
      fill: @link-color;
      color: @link-color;
    }

    .warning {
      fill: @negative-alert;
      color: @negative-alert;
    }
  }

  &.inline-confirmation {
    text-align: left;
    position: relative;
    pointer-events: all;
    margin-top: 20px;

    .confirmation-message {
      display: block;
      max-width: 100%;
      box-shadow: none;

      .confirmation-message {
        padding: 20px;
      }
    }

    &.sm-padding {
      hr {
        margin: 10px 0;
      }

      .confirmation-message {
        padding: 6px 15px;
      }
    }
  }

  &.positive {
    .confirmation-message {
      border: 1px solid #BEE3CB;
      background-color: #F6FBF8;
      color: #4F7F5A;
    }
  }

  &.dark-bg {
    z-index: @zindex-tooltip;
    .confirmation-message {
      border-color: @black;
      background-color: @black;
      color: @white;
    }
  }

  &.white-bg {
    .confirmation-message {
      border: 1px solid #BBBBBB;
      background-color: @white;
      color: @black;
      box-shadow: none;
    }
  }

  &.form-warning {
    .confirmation-message {
      border: 1px solid @error-message-red;
      background-color: @white;
      box-shadow: none;
      font-style: italic;
      line-height: 24px;

      .confirmation-message {
        padding: 15px 20px;
      }
    }
  }

  &.floating-confirmation {
    position: fixed;
    z-index: @zindex-tooltip;
    top: 16px;
    right: 16px;
    width: auto;

    .confirmation-message {
      width: 400px;
      padding: 16px;
      background-color: @background-white-color;
      border: @border-default;
      border-radius: @border-radius-default;
      box-shadow: @box-shadow-large;

      .confirmation-message-title {
        margin: 0;
        font: @text-display-xs;
        color: @text-color;
      }
    }

    .confirmation-message-link {
      font: @text-xs-regular;
    }

    .confirmation-message-text {
      font: @text-xs-regular;
      color: @text-weak-color;
      margin-top: 8px;

      .hidden-link {
        color: @text-weak-color;

        &.colored:hover {
          color: @text-active-color;
        }
      }
    }

    &.danger svg {
      fill: @icon-danger-color;
    }
  }
}

.notification-banner {
  font: @text-xs-medium;
  color: @text-color;
  border-radius: @border-radius-default;

  &.danger-banner {
    background-color: @background-weak-danger-color;

    svg {
      fill: @icon-danger-color;
    }
  }

  &.success-banner {
    background-color: @background-weak-success-color;

    svg {
      fill: @icon-success-color;
    }
  }

  &.info-banner {
    background-color: @background-active-color;

    svg {
      fill: @icon-active-color;
    }
  }

  &.white-info-banner {
    background-color: @background-white-color;

    svg {
      fill: @icon-color;
    }
  }

  &.warning-banner {
    background-color: @background-weak-warning-color;

    svg {
      fill: @icon-warning-color;
    }
  }

  .notification-icon svg {
    .icon-medium();
  }


  &.notification-banner-small {
    .notification-item {
      padding: @spacing-1 @spacing-2;

      svg {
        .icon-small();
        vertical-align: middle;
      }
    }
  }
}

.notification-list {
  .notification-item {
    padding: @spacing-3 @spacing-4;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    svg {
      vertical-align: top;
      margin-right: @spacing-2;
    }

    .help-icon-link {
      svg {
        fill: @icon-color;
        .icon-medium();

        &:hover {
          fill: @icon-active-color;
        }
      }
    }

    .typing-indicator {
      .dot {
        margin: 0 1px;
        height: 4px;
        width: 4px;
      }
    }
  }

  &.notification-item-centered .notification-item {
    justify-content: center;
  }

  .notification-divider {
    border-top: @border-default-hover;
    margin: 0 @spacing-4;
    opacity: 0.4;
  }

  .items-list-intro {
    vertical-align: middle;
  }

  .items-list {
    margin: @spacing-2 0;
    padding-left: @spacing-3;
  }
}

.notification-group {
  .notification-list ~ .notification-list {
    margin-top: @spacing-3;
  }
}
