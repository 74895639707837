.scrollable-panel {

  .panel-container {
    .panel-children {
      position: relative;
    }
  }

  .scrolling-element-wrapper {
    text-align: center;

    &.hit-area-shape-only {
      // Intionally left blank to point out that this is simply for permutation clarity instead of relying on absense of .hit-area-entire-wrapper
    }

    &.hit-area-entire-wrapper {
      padding: 7px 0;

      &.scrolling-element-visible {
        &:hover {
          cursor: pointer;
          background-color: @scrollable-panel-triangle-wrapper-bg-color;

          &.scroll-up .scroll-triangle {
            .triangle-up(@scrollable-panel-triangle-hover-color);
          }

          &.scroll-down .scroll-triangle {
            .triangle-down(@scrollable-panel-triangle-hover-color);
          }

          &.scroll-left .scroll-triangle {
            .triangle-left(@scrollable-panel-triangle-hover-color);
          }

          &.scroll-right .scroll-triangle {
            .triangle-right(@scrollable-panel-triangle-hover-color);
          }
        }
      }
    }

    // Unique clases for scrolling elements that overlap with content
    &.scrolling-element-overlapping {
      position: relative;

      &.scrolling-element-visible {
        z-index: @zindex-wtf;
      }
    }

    .scroll-arrow {
      &:hover {
        cursor: pointer;
      }
    }

    &.scroll-up {
      &.scrolling-element-non-overlapping {
        margin-bottom: @non-overlapping-arrow-padding;

        .scroll-arrow {
          // The scroll arrow shape is rotated with CSS such that it doesn't stay "contained" within the div.  So we need to account for the "overhang" of the arrows corner by adding a margin to make sure the arrow doesn't go over the side of the div into the next element's visual space
          margin-top: @arrow-overhang;
        }

        &.scrolling-element-visible {
          .scroll-arrow {
            .up-arrow(@white, @default-arrow-size, @default-arrow-border-width);

            &:hover {
              .up-arrow(@actionGreen, @default-arrow-size, @default-arrow-border-width);
            }
          }

          .scroll-triangle {
            // TBD -- not needed yet since this permutation doesn't exist
            &:hover {
              // TBD -- not needed yet since this permutation doesn't exist
            }
          }
        }

        &.scrolling-element-hidden {
          .scroll-arrow {
            .up-arrow(transparent, @default-arrow-size, @default-arrow-border-width);
          }

          .scroll-triangle {
            // TBD -- not needed yet since this permutation doesn't exist
          }
        }
      }

      &.scrolling-element-overlapping {

        .scroll-arrow {
          // TBD -- The scroll arrow shape is rotated with CSS such that it doesn't stay "contained" within the div.  Given this section is for the overlapping design, I'm not sure yet if we need to account for the "overhang" of the arrows corner by adding a margin to make sure the arrow doesn't go over the side of the div into the next element's visual space
          // TBD Cont. -- Fill out if I need to do something to account for arrow overhang for overlapping version
        }

        &.scrolling-element-visible {
          border-bottom: 1px solid @gray2;

          .scroll-arrow {
            // TBD -- not needed yet since this permutation doesn't exist
          }

          .scroll-triangle {
            .triangle-up(@scrollable-panel-triangle-color);
          }
        }

        &.scrolling-element-hidden {
          border-bottom: 1px solid transparent;

          .scroll-arrow {
            // TBD -- not needed yet since this permutation doesn't exist
          }

          .scroll-triangle {
            .triangle-up(transparent);
          }
        }
      }
    }

    &.scroll-down {
      &.scrolling-element-non-overlapping {
        margin-top: @non-overlapping-arrow-padding;

        .scroll-arrow {
          // The scroll arrow shape is rotated with CSS such that it doesn't stay "contained" within the div.  So we need to account for the "overhang" of the arrows corner by adding a margin to make sure the arrow doesn't go over the side of the div into the next element's visual space
          margin-bottom: @arrow-overhang;
        }

        &.scrolling-element-visible {
          .scroll-arrow {
            .down-arrow(@white, @default-arrow-size, @default-arrow-border-width);

            &:hover {
              .down-arrow(@actionGreen, @default-arrow-size, @default-arrow-border-width);
            }
          }

          .scroll-triangle {
            // TBD -- not needed yet since this permutation doesn't exist
            &:hover {
              // TBD -- not needed yet since this permutation doesn't exist
            }
          }
        }

        &.scrolling-element-hidden {
          .scroll-arrow {
            .down-arrow(transparent, @default-arrow-size, @default-arrow-border-width);
          }

          .scroll-triangle {
            // TBD -- not needed yet since this permutation doesn't exist
          }
        }
      }

      &.scrolling-element-overlapping {

        .scroll-arrow {
          // TBD -- The scroll arrow shape is rotated with CSS such that it doesn't stay "contained" within the div.  Given this section is for the overlapping design, I'm not sure yet if we need to account for the "overhang" of the arrows corner by adding a margin to make sure the arrow doesn't go over the side of the div into the next element's visual space
          // TBD Cont. -- Fill out if I need to do something to account for arrow overhang for overlapping version
        }

        &.scrolling-element-visible {
          border-top: 1px solid @gray2;

          .scroll-arrow {
            // TBD -- not needed yet since this permutation doesn't exist
          }

          .scroll-triangle {
            .triangle-down(@scrollable-panel-triangle-color);
          }
        }

        &.scrolling-element-hidden {
          border-top: 1px solid transparent;

          .scroll-arrow {
            // TBD -- not needed yet since this permutation doesn't exist
          }

          .scroll-triangle {
            .triangle-down(transparent);
          }
        }
      }
    }

    &.scroll-left {
      &.scrolling-element-non-overlapping {
        margin-right: @non-overlapping-arrow-padding;

        .scroll-arrow {
          // The scroll arrow shape is rotated with CSS such that it doesn't stay "contained" within the div.  So we need to account for the "overhang" of the arrows corner by adding a margin to make sure the arrow doesn't go over the side of the div into the next element's visual space
          margin-left: @arrow-overhang;
        }

        &.scrolling-element-visible {
          .scroll-arrow {
            .left-arrow(@white, @default-arrow-size, @default-arrow-border-width);

            &:hover {
              .left-arrow(@actionGreen, @default-arrow-size, @default-arrow-border-width);
            }
          }

          .scroll-triangle {
            // TBD -- not needed yet since this permutation doesn't exist
            &:hover {
              // TBD -- not needed yet since this permutation doesn't exist
            }
          }
        }

        &.scrolling-element-hidden {
          .scroll-arrow {
            .left-arrow(transparent, @default-arrow-size, @default-arrow-border-width);
          }

          .scroll-triangle {
            // TBD -- not needed yet since this permutation doesn't exist
          }
        }
      }

      &.scrolling-element-overlapping {

        .scroll-arrow {
          // TBD -- The scroll arrow shape is rotated with CSS such that it doesn't stay "contained" within the div.  Given this section is for the overlapping design, I'm not sure yet if we need to account for the "overhang" of the arrows corner by adding a margin to make sure the arrow doesn't go over the side of the div into the next element's visual space
          // TBD Cont. -- Fill out if I need to do something to account for arrow overhang for overlapping version
        }

        &.scrolling-element-visible {
          border-right: 1px solid @gray2;

          .scroll-arrow {
            // TBD -- not needed yet since this permutation doesn't exist
          }

          .scroll-triangle {
            .triangle-left(@gray2);

            &:hover {
              .triangle-left(@dropdown-menu-color-hover);
            }
          }
        }

        &.scrolling-element-hidden {
          border-right: 1px solid transparent;

          .scroll-arrow {
            // TBD -- not needed yet since this permutation doesn't exist
          }

          .scroll-triangle {
            .triangle-left(transparent);
          }
        }
      }
    }

    &.scroll-right {
      &.scrolling-element-non-overlapping {
        margin-left: @non-overlapping-arrow-padding;

        .scroll-arrow {
          // The scroll arrow shape is rotated with CSS such that it doesn't stay "contained" within the div.  So we need to account for the "overhang" of the arrows corner by adding a margin to make sure the arrow doesn't go over the side of the div into the next element's visual space
          margin-right: @arrow-overhang;
        }

        &.scrolling-element-visible {
          .scroll-arrow {
            .right-arrow(@white, @default-arrow-size, @default-arrow-border-width);

            &:hover {
              .right-arrow(@actionGreen, @default-arrow-size, @default-arrow-border-width);
            }
          }

          .scroll-triangle {
            // TBD -- not needed yet since this permutation doesn't exist
            &:hover {
              // TBD -- not needed yet since this permutation doesn't exist
            }
          }
        }

        &.scrolling-element-hidden {
          .scroll-arrow {
            .right-arrow(transparent, @default-arrow-size, @default-arrow-border-width);
          }

          .scroll-triangle {
            // TBD -- not needed yet since this permutation doesn't exist
          }
        }
      }

      &.scrolling-element-overlapping {

        .scroll-arrow {
          // TBD -- The scroll arrow shape is rotated with CSS such that it doesn't stay "contained" within the div.  Given this section is for the overlapping design, I'm not sure yet if we need to account for the "overhang" of the arrows corner by adding a margin to make sure the arrow doesn't go over the side of the div into the next element's visual space
          // TBD Cont. -- Fill out if I need to do something to account for arrow overhang for overlapping version
        }

        &.scrolling-element-visible {
          border-left: 1px solid @gray2;

          .scroll-arrow {
            // TBD Cont. -- not needed yet since this permutation doesn't exist
          }

          .scroll-triangle {
            .triangle-right(@gray2);

            &:hover {
              .triangle-right(@dropdown-menu-color-hover);
            }
          }
        }

        &.scrolling-element-hidden {
          border-left: 1px solid transparent;

          .scroll-arrow {
            // TBD Cont. -- not needed yet since this permutation doesn't exist
          }

          .scroll-triangle {
            .triangle-right(transparent);
          }
        }
      }
    }
  }
}
