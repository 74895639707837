.sales-coach-button {
  .base-button();
  border: @border-default;
  background: @background-white-color @white-pink-regular-gradient;
  color: @text-color;
  font: @text-small-semi-bold;

  svg {
    fill: @icon-marketing-fuchsia-color;
  }

  &:hover {
    border-color: @border-marketing-fuchsia-color;
  }

  &.sales-coach-button-full-width {
    width: 100%;
    padding: @spacing-3 38px;
  }

  &.sales-coach-button-extra-small {
    padding: 3px @spacing-2;
    border-radius: @border-radius-default;
    background: @background-white-color;
    font: @text-xs-medium;
    white-space: nowrap;

    svg {
      .icon-medium();
    }
  }

  &.sales-coach-button-medium {
    white-space: nowrap;
    padding: 5px @spacing-4;
    border-radius: @border-radius-default;
  }

  &.sales-coach-button-large {
    padding: 13px 32px;
    border-radius: @border-radius-default;
    white-space: nowrap;
  }

  &.sales-coach-button-extra-large {
    padding: @spacing-3 @spacing-10;
    border-radius: @border-radius-default;
    white-space: nowrap;

    svg {
      .icon-large();
    }
  }

  &.sales-coach-button-secondary {
    padding: @spacing-2 @spacing-4;
    border-radius: @border-radius-default;
    white-space: nowrap;

    svg {
      .icon-small();
    }
  }

  &.sales-coach-button-icon-only {
    padding: @spacing-2;
    border-radius: @border-radius-default;
    background: inherit;

    svg {
      .icon-medium();
    }
  }

  &.sales-coach-button-icon-only-small {
    padding: 6px @spacing-2;
    border-radius: @border-radius-default;
    background: inherit;

    svg {
      .icon-small();
    }
  }

  &.sales-coach-button-flat {
    font-size: @font-size-600;
    color: @text-marketing-fuchsia-color;
    border: none;
    background: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.sales-coach-dropdown {
    height: auto;
    box-shadow: none;
    max-width: fit-content;
    font: @text-small-semi-bold;

    &:hover {
      box-shadow: none;
      border-color: @border-marketing-fuchsia-color;
    }

    svg:first-child {
      .icon-large();
      fill: @icon-marketing-fuchsia-color;
    }
  }
}

.sales-coach-dropdown-container.descriptive-dropdown {
  margin-right: @spacing-3;

  .dropdown-selection.borderless {
    font: @text-display-large;
  }

  .descriptive-dropdown-menu-list,
  .dropdown-menu-option {
    max-height: 40vh;
    overflow: auto;
  }

  .dropdown-options {
    overflow-x: hidden;
  }
}

.sales-coach-button-tooltip {
  flex-shrink: 0;
}
