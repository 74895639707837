.expandable-section {
  margin-bottom: 15px;

  .expandable-section-label-info {
    .italic-font;
    color: @text-color-light;
  }

  .expandable-section-title {
    > span {
      cursor: pointer;
    }

    .caret {
      font-size: 5px;
      vertical-align: middle;
      .triangle-right(#BBBBBB);
    }

    svg {
      margin: 0 10px;
      vertical-align: middle;

      &.small-icon {
        width: 16px;
        height: 16px;
        margin: 0 14px;
      }
    }

    span {
      vertical-align: middle;
    }

    .expandable-section-label {
      margin-left: 7px;
    }

    .expandable-section-title-link {
      color: inherit;
      cursor: pointer;
    }
  }

  &.expanded {
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .expandable-section-title {
      .caret {
        .triangle-down(#BBBBBB);
      }
    }
  }
}
