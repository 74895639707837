.saved-goal-status-team-user-view {
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  &-name {
    font-size: @font-size-bodyText;
    font-weight: @font-weight-medium;
    color: @text-color;
  }
  
  svg {
    flex: none;
    fill: @icon-weak-color;
    width: @icon-default-size;
    margin-right: @text-with-icon-separation;
  }
}
