.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: @border-radius-default;
  cursor: pointer;
  transition: all 0.3s ease-out;
  
  &:disabled {
    opacity: 1;
    cursor: none;
  }

  &.icon-button-large {
    height: 32px;

    svg {
      .icon-x-large();
    }
  }

  &.icon-button-medium {
    height: 32px;

    svg {
      .icon-large();
    }
  }

  &.icon-button-small {
    height: 28px;
    width: 32px;

    svg {
      .icon-small();
    }
  }

  &.icon-button-extra-small {
    height: 26px;
    width: 30px;

    svg {
      .icon-small();
    }
  }

  &.icon-button-flat {
    border: none;
    background-color: transparent;
    
    svg {
      fill: @icon-color;
    }

    &.weak-button {
      svg {
        fill: @icon-weak-color;
      }
    }

    &.icon-on-dark {
      svg {
        fill: @icon-on-dark-color;
      }
    }
    
    &:hover {
      background-color: @background-hover-color;

      svg {
        fill: @icon-hover-color;
      }
    }

    &:active {
      .icon-button-flat-active;
    }

    &:disabled,
    &.button-disabled {
      svg {
        fill: @icon-weak-color;
      }
    }

    &.icon-button-fully-flat {
      padding: 0;
      height: auto;
      width: auto;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &.icon-button-border {
    background-color: @background-white-color;
    box-shadow: @box-shadow-small-regular;
    border: @border-default;

    svg {
      fill: @icon-color;
    }

    &:hover {
      box-shadow: @box-shadow-small-hover;
      background-color: @background-hover-color;

      svg {
        fill: @icon-hover-color;
      }
    }

    &:active {
      .icon-button-border-active;
    }

    &:disabled {
      svg {
        fill: @icon-weak-color;
      }
    }
  }

  &.sales-coach-icon-button {
    svg {
      fill: @icon-marketing-fuchsia-color;
    }

    &:hover {
      .sales-coach-icon-button-active;
    }
  }

  &.feedback-button {
    &:disabled {
      opacity: 0.6;
    }

    &.icon-button-medium {
      padding: @spacing-1 @spacing-2;
      height: 32px
    }

    &.feedback-button-negative {
      svg {
        fill: @icon-marketing-fuchsia-color;
      }

      &:hover, &:focus,
      &:active, &.button-active {
        background-color: @background-marketing-fuchsia-color;
        border-color: @border-marketing-fuchsia-color;
        svg {
          fill: @icon-on-dark-color;
        }
      }
    }

    &.feedback-button-positive {
      svg {
        fill: @icon-marketing-green-color;
      }

      &:hover, &:focus,
      &:active, &.button-active {
        background-color: @background-marketing-green-color;
        border-color: @border-marketing-green-color;
        svg {
          fill: @icon-on-dark-color;
        }
      }
    }
  }
}

.icon-button-flat-active {
  background-color: @background-active-color;

  svg {
    fill: @icon-active-color;
  }
}

.icon-button-border-active {
  background-color: @background-active-color;

  svg {
    fill: @icon-active-color;
  }
}

.sales-coach-icon-button-active {
  border-color: @border-marketing-fuchsia-color;

  svg {
    fill: @icon-marketing-fuchsia-color;
  }
}

.dropdown-active {
  .icon-button-flat {
    .icon-button-flat-active;
  }

  .icon-button-border {
    .icon-button-border-active;
  }
}

.icon-button-tooltip {
  &.tooltip .tooltip-inner {
    min-width: auto;
    padding: 8px 12px;
    border-radius: @border-radius-default;
  }
}

.icon-buttons-group {
  display: flex;
  flex-shrink: 0;
  gap: @spacing-1;
}
