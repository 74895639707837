.progress-bar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .progress-bar-base {
    height: @progress-bar-height;
    width: @progress-bar-width;
    border-radius: @progress-bar-border-radius;
    position: relative;
    background-color: @white;
    border: 1px solid @gray5;
    overflow: hidden;
  }

  .progress-bar-status {
    position: absolute;
    top: -1px; // Because the border in .progress-bar-base
    left: 0;
    height: @progress-bar-height;
    border-radius: @progress-bar-border-radius;
    max-width: @progress-bar-width;

    &.positive {
      background-color: @progress-bar-positive;
    }

    &.negative {
      background-color: @progress-bar-negative;
    }

    &.neutral {
      background-color: @progress-bar-neutral;
    }

    &.on-track {
      background-color: @goal-on-track-alert;
    }

    &.behind {
      background-color: @goal-behind-pace-alert;
    }
  }

  .progress-value {
    padding-left: 10px;
    width: 50px;
    flex-basis: auto;
  }

  &.white-bg {
    .progress-bar-base {
      background-color: #EEEEEE;
      border: none;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    }

    .progress-bar-status {
      top: 0;
    }
  }

  &.small {
    .progress-bar-base {
      height :@progress-bar-small-height;
      border-radius: @progress-bar-small-border-radius;
    }

    .progress-bar-status {
      height: @progress-bar-small-height;
      border-radius: @progress-bar-small-border-radius;
    }
  }
}
