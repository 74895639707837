.goals-page-actions-view {
  display: flex;
  align-items: center;

  .saved-goal-actions-delete {
    color: @text-danger-color;
  }

  .goal-view-action {
    margin-left: 12px;
  }
}
