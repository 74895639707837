.add-to-folder-dropdown {
  position: relative;

  &.inline {
    display: inline-block;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    .inline-tooltip {
      display: flex;
      align-items: center;
    }
  }

  &.positioned-right .filterable-checkbox-list {
    right: 5px;
  }

  .filterable-checkbox-list {
    width: 500px;

    .filterable-checkbox-list-options {
      max-height: 15vh;
      min-height: 50px;
    }
  }

  .modal-dropdown-menu {
    width: 500px;
    text-align: left;

    .input-with-emoji .form-control {
      font-weight: normal;
    }
  }

  &.full-width {
    .filterable-checkbox-list, .modal-dropdown-menu {
      width: 100%;
    }
  }

  .row-error .form-error {
    white-space: normal;
  }

  .descriptive-dropdown-menu {
    width: 500px;
  }

  @media only screen and (min-height: 320px) {
    .filterable-checkbox-list .filterable-checkbox-list-options {
      max-height: 30vh;
    }
  }


  @media only screen and (min-height: 450px) {
    .filterable-checkbox-list .filterable-checkbox-list-options {
      max-height: 40vh;
    }
  }
}
