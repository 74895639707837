.pagination {
  text-align: center;
  .middle-columns();
  margin-top: 40px;

  > li.prev,
  > li.next {
    vertical-align: top;
    display: inline-block;
  }

  > li.prev {
    margin-right: @pagination-arrow-margin;

    > span,
    > a {
      .left-arrow-filled();

      &:hover {
        border-right-color:  @actionGreen;
      }

      &:active {
        border-right-color: @positive-alert;
      }
    }

    &.disabled {
      > span,
      > a {
        &:hover {
          border-right-color:  @white;
        }
      }
    }
  }

  > li.next {
    margin-left: @pagination-arrow-margin;

    > span,
    > a {
      .right-arrow-filled();

      &:hover {
        border-left-color:  @actionGreen;
      }

      &:active {
        border-left-color: @positive-alert;
      }
    }

    &.disabled {
      > span,
      > a {
        &:hover {
          border-left-color:  @white;
        }
      }
    }
  }

  > li.prev,
  > li.next {

    > span,
    > a {
      &:before {
        border-color: #FFF;
        vertical-align: text-top;
      }

      &:hover {
        cursor: pointer;
      }
    }

    > a {
      cursor: pointer;
    }

    &.disabled {
      > span,
      > a {
        opacity: 0.2;
        &:hover {
          cursor: default;
        }
      }
    }
  }


  > li.page {
    display: inline-block;
    vertical-align: top;

    &.circle > span,
    &.circle > a {
      display: inline-block;
      border-radius: 50%;
      border: @pagination-circle-border-width solid @white;
      width: @pagination-circle-width;
      height: @pagination-circle-width;
      background-color: transparent;
      margin:  0px 5px;


      &:before {
        content: "";
      }

      &:hover {
        background-color:  @actionGreen;
        cursor: pointer;
      }

      &:active {
        background-color: @positive-alert;
        border-color: @positive-alert;
        cursor: pointer;
      }


    }

    &.circle.selected > span {
      background-color: @white;

      &:hover {
        cursor: default;
      }
    }
  }
}
