.multi-target-goal-status-widget {
  margin: 25px 0 0;

  .goal-status-widget-header {
    background-color: @background-medium-color;
    border-top: @goal-status-widget-border;
    border-bottom: @goal-status-widget-border;

    .goal-status-widget-header-row {
      max-width: 100%;
    }

    .header-title {
      font-weight: 500;
      padding: 7px 0;
      font-size: 14px;
      color: @text-color;
    }

    .goal-status-widget-column {
      margin-left: 10px;
    }

    .dropdown-combo .dropdown-selection {
      background-color: @background-medium-color;
      padding: 7px 0 7px 10px;
      .title, .info {
        font: @text-xs-medium;
        color: @text-weak-color;
        font-style: normal;
      }

      .title {
        text-transform: capitalize;
      }
    }
  }

  .goal-status-widget-column {
    flex: 1;
    min-width: 0;
  }

  .goal-metric-row {
    position: relative;
    margin-bottom: @spacing-2;

    .goal-metric-row-content {
      display: flex;
      align-items: flex-start;
      background-color: @background-medium-color;
      padding: @spacing-2;
      border-radius: @border-radius-default;
      text-decoration: none;
    }

    .goal-metric-row-expanded-content {
      position: absolute;
      top: 0;
      width: 100%;
      padding: @spacing-2;
      border-radius: @border-radius-default;
      box-shadow: @box-shadow-tooltip;
      background-color: @background-white-color;
      z-index: @zindex-modal;
      text-decoration: none;
    }
  }

  .metric-name {
    font: @text-small-regular;
    width: 100%;
  }

  .metric-val {
    font: @text-small-regular;
    color: @text-color;

    .goal-status {
      font: @text-xs-semi-bold;
      color: @gray2;

      &.val-only {
        font-size: @goal-status-widget-status-font-size;
      }
    }

    svg {
      .icon-small();
      vertical-align: middle;
    }

    .status-icon {
      margin-right: @spacing-2;

      &.negative {
        fill: @goal-missed-alert;
      }

      &.risk {
        fill: @goal-high-risk-alert;
      }

      &.positive {
        fill: @goal-hit-alert;
      }

      &.behind {
        fill: @goal-behind-pace-alert;
      }
    }

    .inline-tooltip {
      display: inline-block;
    }
  }

  .fixed-width {
    width: 95px;
    flex-shrink: 0;
  }

  .full-width {
    width: 100%
  }

  .goal-status-widget-body {
    border-bottom: @goal-status-widget-border;

    .goal-status-team-col {
      border-right: @goal-status-widget-dashed-border;

      &:last-child {
        border-right: none;
      }
    }

    .goal-status-team-row-height {
      height: 100%
    }

    .goal-status-team-data {
      padding: @spacing-3 @spacing-4;
      height: 100%;

      .team-name {
        line-height: 20px;
        padding-bottom: 20px;

        .team-dropdown-label {
          line-height: 20px;

          svg {
            width: 20px;
            fill: @goal-status-metric-color;
            vertical-align: middle;
            margin-right: 8px;
          }
        }

        .title {
          font-size: @goal-status-widget-status-font-size;
          font-weight: @goal-status-metric-font-weight;
        }
      }

      .link-style-button {
        font-size: @goal-status-widget-status-font-size;
        .center-vertically();
        
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    .empty-col {
      position: relative;
    }
  }
}
