.primary-card-widget {
  border: @primary-card-border;
  background-color: @primary-card-background-color;
  box-shadow: @primary-card-box-shadow;
  margin-bottom: @primary-card-widget-margin-bottom;
  border-radius: @primary-card-border-radius;
  padding-bottom:20px;
  margin-top: 20px;

  .full-screen-card {
    border-radius: @primary-card-border-radius;
    font-size: @primary-card-font-size;
    line-height: @primary-card-line-height;
    overflow: @primary-card-overflow;

    &.card-content {
      overflow-x: hidden;
    }

    .tooltip-shape {
      width: @primary-card-tooltip-shape-width;
      height: @primary-card-tooltip-shape-height;
      border-radius: @primary-card-tooltip-shape-border-radius;
      margin: @primary-card-tooltip-shape-margin;
      padding: @primary-card-tooltip-shape-padding;
      line-height: @primary-card-tooltip-shape-line-height;

      &.negative {
        background-color: @goal-status-negative-fill;
      }

      &.positive {
        background-color: @goal-status-positive-fill;
      }

      &.neutral {
        background-color: @neutral-alert;
      }

      svg {
        width: @primary-card-icon-svg-width;
        height: @primary-card-icon-svg-height;
        fill: @primary-card-icon-svg-fill;
        padding-left: @primary-card-icon-svg-padding-left;
      }
    }

    .text-container {
      box-sizing: border-box;

      .title {
        color: @primary-card-text-container-title-color;
        font-size: @primary-card-text-container-title-font-size;
        font-weight: @primary-card-text-container-title-font-weight;
        line-height: @primary-card-text-container-title-line-height;
        text-transform: @primary-card-text-container-title-text-transform;
        text-align: @primary-card-text-container-title-text-align;

        .deviation-negative {
          color: @goal-status-negative-fill;
        }

        .deviation-positive {
          color: @goal-status-positive-fill;
        }

        .deviation-neutral {
          color: @neutral-alert;
        }
      }

      .summary-inline {
        color: @primary-card-summary-inline-color;
        font-size: @primary-card-summary-inline-font-size;
        line-height: @primary-card-summary-inline-line-height;
        text-align: @primary-card-summary-inline-text-align;
        font-weight: @primary-card-summary-inline-font-weight;
        margin-top: @primary-card-summary-inline-margin-top;

        .view-details {
          text-decoration: underline;
          color: @link-across-the-site;
        }

        div {
          display:inline;
          padding-right:5px
        }

        a {
          color: @link-across-the-site;
        }

        p {
          display: inline;
        }
        
      }
    }

    .right-col {
      text-align: @primary-card-text-align;
    }

    .card-content.axis-legend {
      text-align: @primary-card-card-content-text-align;
      margin: @primary-card-axis-legend-margin;

      .title {
        font-size: @primary-card-title-font-size;
        font-weight: @primary-card-title-font-weight;
        color: @primary-card-title-color;
        text-transform: uppercase;
        margin-right: @primary-card-axis-legend-title-margin-right;
        margin-left: @primary-card-axis-legend-title-margin-left;
      }
      .sub-title {
        font-size: @primary-card-sub-title-font-size;
        color: @primary-card-sub-title-font-color;
        text-transform: @primary-card-sub-title-font-text-transform;
      }

      .view-details {
        color: @link-across-the-site;
        font-size: @primary-card-view-detail-font-size;
        line-height: @primary-card-view-detail-line-height;
      }
    }

    .chart-container {
      max-width: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: @gray-lightest;
        border-radius: @border-radius-small;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: @gray-light3;
      }
    }

    .simple-stacked-bar-chart {

      .total {
        color: @primary-card-total-color;
        font-size: @primary-card-total-font-size;
        line-height: @primary-card-total-line-height;
        fill: @primary-card-total-fill;
        text-anchor: @primary-card-total-text-anchor;
      }

      .bar-chart-x-axis {
        margin-left: @primary-card-bar-chart-x-axis-margin-left;

        .entry {
          font-size: @primary-card-entry-font-size;
          line-height: @primary-card-entry-line-height;
          text-align: @primary-card-entry-text-align;
          color: #666666;
          font-weight: lighter;
          text-decoration: @primary-card-entry-text-decoration;

          &.no-link{
            text-decoration: none;
            cursor: auto;
          }

          a {
            color: #666666;
            white-space: nowrap;
          }
        }

      }

      .tooltip-inner {
        padding: @simple-stacked-bar-chart-tooltip-inner-padding
      }
    }
    .primary-metric-graph {
      border-bottom: @primary-card-border-bottom;
      margin-bottom: @primary-card-margin-bottom;
    }
  }
  .marker-legend-explanation {
    padding-bottom: 5px;
    width: @primary-card-tooltip-shape-width;
    margin: @primary-card-tooltip-shape-margin;
  }
}
