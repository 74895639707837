.custom-dashboard-creation {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: @zindex-modal;
  background-color: @white;
  min-height: 100%;

  .select-card {

    .modal-form-content-container {
      min-height: 450px;
    }

    .metrics-modal-header.row {
      margin-top: 30px;
      .col-5 {
        padding-left: 0;
      }
    }
  }
}

.read-view {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-dashboard-info {
  background-color: @whiteBG;
  padding-top: 20px;
  padding-bottom: 25px;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 1px 8px 0 rgba(0,0,0,0.2);

  .input-with-emoji, input {
    width: 100%;
    max-width: 1350px;
  }

  .name {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    color: @black;
    margin-bottom: 3px;

    .read-view svg {
      vertical-align: middle;
    }
  }

  .description {
    font-size: @font-size-bodyText;
    color: @gray1;

    .read-view svg {
      vertical-align: text-bottom;
    }
  }

  .read-view {
    svg {
      width: 18px;
      height: 18px;
      margin-left: 10px;
      fill: @gray2;
      opacity: .8;
    }
  }

  .name, .description {
    .placeholder {
      opacity: .8;
      font-style: italic;
    }

    > div {
      padding: 1px;
    }

    .emoji-picker-button {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  input.transparent-input {
    max-width: 100%;
    height: 35px;
    border: 1px solid @blue-link;
    border-radius: @input-border-radius;

    &::placeholder {
      opacity: .8;
      font-style: italic;
    }
  }

  .close-icon {
    cursor: pointer;
    vertical-align: middle;

    svg {
      width: 36px;
      height: 36px;
      fill: #AAA;
      vertical-align: middle;
      margin-right: -8px;
      margin-top: -4px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .primary {
      margin-left: 10px;
    }

    .autosaved {
      font-style: italic;
      margin-right: 25px;
      color: @text-color-light;
    }
  }
}

.custom-dashboards-cards {
  padding-bottom: 100px;
  .cards {
    .card-dragging {
      background-color: white;
      opacity: 0;
    }

    .card-moving {
      z-index: @zindex-popover;
    }

    .dashboard-card-creation-preview {
      position: relative;
      cursor: move;

      &:hover .card-view {
        filter: blur(1px);
        opacity: 0.6;
      }

      .card-view {
        padding-top: 20px;
        pointer-events: none;
      }

      &.preview {
        .hover-actions {
          top: 50%;

          .button {
            margin-right: 14px;

            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }

      .card-action-container {
        opacity: 0;
        text-align: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        &:hover {
          opacity: 1;
        }

        > .flexbox {
          height: 100%;
        }

        .card-action {
          margin-right: 20px;

          &.card-action-destructive:hover {
            background-color: @red;
            border: 1px solid @red;
          }

          &:last-child {
            margin-right: 0;
          }
          svg {
            opacity: 1;
          }
        }
      }
    }
  }
}

.dashboard-creation-card-detail-container {
  height: 100%;

  .custom-dashboard-info {
    display: flex;
    justify-content: flex-end;
    z-index: @zindex-navbar;
  }
}


.dashboard-creation-card-detail {
  width: 100%;
  height: 100%;
  display: flex;

  .card-body-no-drawer {
    padding: 0;
  }

  .name {
    flex-grow: 1;
    color: @black;
    max-width: 75%;
    font-size: 20px;
    font-weight: 200;
    line-height: 30px;
    text-transform: capitalize;

    .input-with-emoji .transparent-input {
      color: @black;
      font-size: 20px;
      font-weight: 200;
      line-height: 26px;
      border: 1px solid @blue-link;
      border-radius: @input-border-radius;
    }

    .emoji-picker-button svg {
      fill: @gray-light2;
      height: 24px;
      width: 24px;
      margin-top: 2px;
    }

    .read-view svg {
      fill: @gray-light2;
      vertical-align: middle;
      opacity: 0.8;
      width: 18px;
      height: 18px;
      margin-left: 15px;
    }

    .card-title-section {
      max-width: 100%;
    }

    @media only screen and (min-width: @screen-md) {
       max-width: 80%;
    }

    @media only screen and (min-width: @screen-md-lg) {
       max-width: 85%;
    }
  }
}

.alert-preferences-content {
  min-height: 340px;

  &.one-off {
    min-height: auto;
  }

  .new-distribution-what-to-distribute {
    margin-bottom: 10px;
  }

  .frequency-form, .schedule-settings {
    display: flex;
    align-items: center;
  }

  .schedule-settings {
    .medium-dropdown {
      width: 130px;
    }

    .dropdown-with-checkbox {
      display: inline-block;
      max-width: 240px;
    }

    .nth-selector {
      .dropdown-menu-container {
        width: 120px;
      }

      .dropdown-options {
        max-height: 335px;
      }
    }
  }

  .setting-question {
    margin: 5px 0 13px;
  }

  .tip-tooltip {
    margin-left: 10px;
    vertical-align: middle;

    svg {
      width: 16px;
      height: 16px;
      fill: @icon-weak-color;
      vertical-align: inherit;
    }
  }

  .schedule-filler-text {
    padding: 0 10px;
    min-width: max-content;
  }

  .uncolored-icon {
    fill: @gray5;
  }

  .emails-box {
    height: 40px;
  }

  .selected-options {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
  }

  .messaging-platform-button {
    margin: 10px 20px;
  }

  .expandable-section {
    .form-control-dropdown,
    .form-control-textarea,
    .form-control {
      margin-top: 10px;
    }

    .form-control-dropdown .dropdown-selection span {
      font-weight: @font-weight-thin;
      font-style: italic;
      color: #555;
      opacity: 0.7;
      font-size: 15px;
    }
  }
}

.target-modal-mask {
  .modal-mask {
    opacity: @target-modal-mask-opacity;
  }

  .left-control-content-component .people-section {
    .people-options {
      padding: 0;

      span, svg {
        display: none;
      }
    }
  }
}

.target-modal, .dashboard-creation-card-detail-container {
  .header-container {
    .flexbox();
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .custom-dashboard-info-message {
    line-height: @target-modal-header-line-height;
    font-size: @target-modal-header-font-size;
    color: @target-modal-header-color;
    font-weight: 500;

    svg {
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  .target-modal-warning {
    background-color: @target-modal-warning-bg-color;
    font-size: @target-modal-warning-font-size;
    font-style: @target-modal-warning-font-style;
    line-height: @target-modal-warning-line-height;
    position: fixed;
    padding: 9px 25px;
    width: 100%;
    color: @target-modal-warning-color;
    z-index: @zindex-modal;

    .bold-font {
      color: @target-modal-warning-color;
    }

    svg {
      height: 20px;
      fill: @target-modal-warning-color;
      margin-right: 10px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }

  .custom-dashboard-info {
    height: @target-modal-custom-dashboard-info-height;
    padding: 25px 35px;
    margin-bottom: 0;
  }

  .button-list {
    text-align: right;
    line-height: 19px;
  }
}

.target-modal {
  .custom-dashboard-info {
    z-index: @zindex-modal;
  }

  .reorder-target-modal {
    .modal-mask {
      z-index: @zindex-modal;
    }
  }

  .reorder-target-modal .modal-form-container,
  .modal-container {
    margin-top: 150px;
  }
}

.custom-dashboard-timeframe {
  .modal-form-header .subtitle {
    font-weight: @font-weight-semi-bold;
  }

  &.modal-form-container .modal-form-warning {
    color: @error-message-red;
    border-color: @error-message-red;
    background-color: transparent;
    border-right: none;
    border-left: none;

    .flexbox {
      flex-wrap: nowrap;
      align-items: center;
    }

    .bold-font {
      color: @error-message-red;
    }

    svg {
      fill: @error-message-red;
      height: 42px;
      width: 45px;
      margin-right: 20px;
    }
  }
}


