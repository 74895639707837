.org-update-user-page {
  font-family: @font-family-base;
  font-weight: 100;

  .title {
    font-weight: 600;
  }

  .row {
    margin-top: 5px;
    margin-bottom: 5px;

    &.dropdown-row {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .breadcrumb {
    padding-top: 35px;
    padding-bottom: 30px;
    color: #333333;
    font-size: 20px;
    line-height: 24px;

    .org-name {
      color: #666666;

      a {
        color: #666666;
        text-decoration: none;
      }

      .underline {
        border-bottom: 1px solid #333333;
        display: inline-block;
        line-height: 0.65;
      }
    }
  }

  .line-separator {
    color: #666666;
    font-size: 16px;
    line-height: 1.7em;
    border-top: 1px solid #CCCCCC;
    padding: 12px 0;

    .control-label-edit {
      color: @gray1;
      font-size: 16px;
      font-weight: normal;
    }

    .control-sub-label {
      font-weight: 400;
      color: #333333;
    }

    .edit-link {
      color: @link-color;
      float: right;
      cursor:pointer;
    }

    .control-description {
      color: #999999;
      font-size: 16px;
      font-style: italic;
    }

    .team-manager a {
      color: #666666;
      text-decoration: underline;
    }

    .dropdown-combo .dropdown-selection {
      padding: @dropdown-selection-padding;
      .title {
        color: #666666;
        font-size: 15px;
        line-height: 32px;
        font-weight: 100;
      }
    }

    .clear-calendar {
      padding-top: 15px; // Hack to make it align with the current inputs. clean when cleaning all the other styles
      display: inline-block;
    }

    .invalid-text {
      border: none;
      font-style: italic;
      padding: 2px 0;
    }

    .edit-dropdown {
      float: right;

      .dropdown-selection {
        padding: 0;
        background: none;

        .title {
          color: @link-color;
          font-weight: 100;
        }

      }
    }

    .extra-spacing-row {
      padding-top: 10px;
    }

    .input-with-icon {
      position: @custom-dashboard-sharing-search-input-position;

      input {
        font-family: @font-family-base;
        color: @text-color-default;
        font-size: 16px;
        line-height: 32px;
        font-style: normal;
        padding-top: 3px;
        padding-bottom: 3px;

        ::placeholder {
          color: @text-color-light;
          opacity: 1; /* Firefox */
        }
      }
    }

    .calendar-view {
      position: relative;

      .form-control {
        padding-left: 10px;
      }
    }

    .buttons {
      padding-top: 15px;

      .primary {
        margin-right: 10px;
      }
    }

    .modal-form-footer {
      .buttons {
        padding-top: 0;
      }
    }

    .modal-form-body {
      .form-control {
        margin-top: 0;
      }
    }

    .modal-form-content-row {
      margin-bottom: 0;
    }

    .explanation {
      height: 24px;
    }

    .team-field {
      padding-bottom: 20px;
      padding-right: 10px;
      line-height: 1.4em;
    }

    .team-members {
      line-height: 1.5em;

      a {
        color: #666666;
        text-decoration: underline;
        white-space: nowrap;
      }
    }

    .help-text {
      line-height: 20px;
      margin: 15px 0 10px;
      font-style: italic;
    }
  }

  .space-top {
    padding-top: 20px;
  }

  .team-edit {
    color: black;
    margin-bottom: 20px;

    .form-control {
      margin-top: 0;
    }
  }

  .direct-reports {
    border-top: 1px solid #DDDDDD;
    padding-top: 20px;
  }

  .row-spacing {
    padding-bottom: 7px;
  }

  .form-control-input {
    color: #666;
    font-size: 16px;
    line-height: 32px;
    font-style: normal;
    margin-top: 0;
  }

  .team-link {
    color: @link-color;
    padding-top: 20px;
    cursor: pointer;
    
    svg {
      height: 24px;
      width: 24px;
      fill: @link-color;
      vertical-align: middle;
      margin-right: 12px;
    }

    span {
      vertical-align: middle;
    }
  }

  .delete-custom-team svg {
    height: 20px;
    width: 20px;
    float: right;
    fill: #666666;
    margin-top: 44px;
    cursor: pointer;
  }

  .radio-button [type="radio"] + div.radio-button-circle > .radio-button-circle-inside {
    display: block;
  }

  .radio-button-group {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .button-short {
    svg {
      height: 25px;
      width: 25px;
      vertical-align: middle;
    }
  }

  .role-history {
    margin-top: 40px;

    .table-title {
      margin-top: 0;
      margin-bottom: 0;
    }

    .header {
      border-bottom: 1px solid #CCCCCC
    }

    .section-title {
      color: #333333;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      padding-bottom: 15px;
      margin: 0;
      display: inline-block;
    }

    .edit-link {
      float: none;
    }

    .editable-table-body-cell .inline-tooltip {
      max-width: 100%;
    }
  }

  .horizontal-line-separator {
    margin: 0 10px;
    color: @gray5;
  }

  .team-option {
    &.team-option-disabled {
      color: @gray7;
    }

    .team-option-disabled-tooltip {
      padding-right: 10px;
      display: inline-block;
    }
  }

  .editable-table {
    .row {
      margin: 0;
    }
  }
}

.employee-info-edit-container {
  padding: @modal-form-body-padding;

  .space-bottom {
    padding-bottom: 20px;
  }

  .space-top {
    padding-top: 15px;
  }

  .alert-warning {
    margin-bottom: -20px;
  }
}

.team {
  padding-right: 25px;

  .team-name {
    font-weight: @font-weight-bold;
    margin-bottom: 5px;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .team-reports {
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin-bottom: 25px;

    .team-report {
      margin-bottom: 5px;

      .link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}


.react-calendar {
  border:1px solid #dddddd;
  border-radius:4px;
  box-shadow:@dropdown-menu-box-shadow;
}


.team-update-page {
  .team-group {
    .autocomplete {
      margin-bottom: 10px;
    }

    .link-style-button {
      margin-top: 2px;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }

    .selected-options {
      li > div {
        display: inline-block;
      }
      .icon.disabled {

      }
    }
  }

  .team-group:not(:first-child) {
    margin-top: 30px;
  }
}

@media only screen and (max-width:@page-width) {
  .container{
    width: 1100px;
  }
}

