.modal-form-container {
  overflow: visible;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.5);
  margin: 40px auto;

  &.xx-large {
    width: 1100px;
  }

  &.x-large {
    width: 990px;
  }

  &.large {
    width: 950px;
  }

  &.medium {
    width: 800px;
  }

  &.small {
    width: 680px;
  }

  &.x-small {
    width: 500px;
  }

  .modal-form-header {
    background-color: @whiteBG;
    padding: 22px 30px 20px;
    font-size: 20px;
    color: @black;
    font-weight: @headings-font-weight;
    .border-top-radius(6px);

    svg {
      vertical-align: middle;

      & ~ span {
        margin-left: @text-with-icon-separation;
        vertical-align: middle;
      }
    }
  }

  .modal-form-warning {
    border: 1px solid #FAF2CC;
    background-color: @yellowBG;
    padding: 15px 30px;
    font-style: italic;
    color: #8A6D3B;
    line-height: @line-height-base;

    & ~ .input-with-icon {
      margin-top: 30px;
    }
  }

  .modal-form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    background-color: @whiteBG;
    padding: 0 30px;
    font-size: 16px;
    color: @black;
    .border-bottom-radius(6px);

    .buttons {
      float: right;

      .button {
        margin-right: 10px;
        border: 1px solid @gray5;

        &:last-child {
          margin-right: 0;
        }

        &.secondary-white {
          padding-right: @btn-horizontal-padding;
          padding-left: @btn-horizontal-padding;
        }
      }
    }

    .button {
      line-height: 22px;
    }

    .modal-form-checkbox {
      display: inline-block;
      padding-top: 9px;

      [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        border: 1px solid @gray5;
      }
    }
  }

  .modal-form-body {
    .content {
      padding: 30px;
      font-size: @body-font-size;
      color: @text-color-default;

      .button-group {
        padding-top: 20px;
      }

      > p:first-child {
        margin-top: 0;
      }
    }

    .modal-form-content-container {
      padding: @modal-form-body-padding;
    }

    .line {
      box-sizing: border-box;
      border: 1px dashed #EDEEEB;
      margin-left: 20px;
      margin-right: 20px;
    }

    .form-error {
      font-style: italic;
      margin-top: 10px;
    }
    
    hr {
      border-color: @gray-lighter;
    }

    .modal-form-groupings {
      padding-left: 30px;
      margin: 20px 0;

      .button {
        &.grouping {
          background-color: @white;
          color: @gray-dark;
          height: 34px;
          border: 1px solid @gray5;
          border-radius: 0px;
          padding: 5px !important;
          min-width: 72px;

          &:nth-child(2) {
            border-radius: 4px 0px 0px 4px;
          }

          &:last-child {
            border-radius: 0px 4px 4px 0px;
          }

        }
      }
      button:focus {
        outline: 0;
        background-color: @gray-lighter !important;
        color: #373A3C !important;
      }

      button:disabled {
        background-color: @white !important;
        color: #BBBBBB !important;
        pointer-events: none;
      }

      button:hover {
        border: 1px solid @gray5 !important;
        background-color: #F2F2F2 !important;
      }
    }

    .dropdown-combo {
      &.dropdown-active {
        .dropdown-selection {
          background-color: @whiteBG;
          color: @gray2;
        }
      }
    }

    .modal-form-content-info {
      margin-top: 15px;
      line-height: 24px;
    }

    .modal-form-content-row {
      margin-top: 25px;

      .modal-form-content-row-group {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }

    .modal-form-content-section {
      margin-top: 30px;
    }

    .modal-form-content-section-reduced {
      margin-top: 15px;
    }

    .modal-form-row-label {
      .bold-font;

      ~.calendar-view {
        .form-control {
          margin-top: 0;
          height: @dropdown-height;
        }
      }
    }

    textarea {
      display: block;
      margin-top: 10px;
    }
  }
}

.dropdown-modal {
  display: inline-block;
  position: relative;
}

.create-view-dropdown {
  display: flex;
  flex-direction: column;

  .modal-dropdown-body {
    >:first-child {
      margin-bottom: 12px;
    }

    .control-label {
      font: @text-xs-medium;
      color: @text-weak-color;
    }

    .emoji-picker-button {
      top: 1px;
      right: 12px;

      svg {
        width: 20px;
        fill: @icon-weak-color;
      }
    }
  }
}

.modal-dropdown-menu {
  position: absolute;
  width: 360px;
  margin-top: 6px;
  background-color: @white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: @border-radius-small;
  z-index: @zindex-dropdown;

  &.positioned-right {
    right: 0;
  }

  .modal-dropdown-menu-title-text {
    max-width: 95%;
    display: inline-block;
    vertical-align: middle;
    .text-ellipsis();
  }

  .modal-dropdown-warning {
    padding: 10px 20px;
    background-color: @yellowBG;
    white-space: normal;
    a {
      cursor: pointer;
    }
  }

  .modal-dropdown-body {
    padding: 12px 16px;
    font-size: 14px;
    color: @text-color-default;
  }

  .modal-dropdown-footer {
    display: flex;
    padding: 8px 16px;
    justify-content: flex-end;
    background-color: @background-medium-color;
    .border-bottom-radius(4px);

    .corsac-button:first-child {
      margin-right: 8px;
    }
  }

  .modal-dropdown-row {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-control {
    margin-top: 5px;
  }
}

.modal-dropdown-menu-header {
  line-height: 16px;
  padding: 8px 16px;
  font: @text-xs-medium;
  color: @text-weak-color;
  background-color: @background-medium-color;
  .border-top-radius(4px);

  > span {
    vertical-align: middle;
  }

  svg {
    fill: @text-color-light;
    height: @icon-small-size;
    width: @icon-small-size;
    vertical-align: middle;
  }

  .explanation {
    line-height: inherit;
    height: auto;
    margin-left: @text-with-icon-separation;
  }
}

.blocking-modal-form {
  p:first-child {
    margin-top: 0;
  }

  li {
    margin-left: 10px;

    .recommended-action-link {
      text-decoration: underline;
    }
  }
}

.button-group-label {
  display:inline;
  font-weight: 600;
  margin-right:10px;
}

.reorder-target-modal {
  pointer-events: auto;

  .modal-form-container {
    &.min-page-width {
      width: 1250px;
    }

    &.long-list {
      .min-page-width;
    }

    .modal-form-body {
      .content {
        padding: 25px 30px;
      }
    }
  }
}

.filters-selector-modal {
  .modal-form-container {
    .modal-form-content-container {
      padding-bottom: 0;
    }

    .modal-form-footer {
      margin-top: 0;
    }
  }
}
