//
// -- FONT GLOBAL TOKENS --
// List of font global tokens to be used in the font alias tokens below.
// To add a font to any UI element, use the font alias tokens.
//
// NOTE: The Proxima nova font is added in app.mu by typekit from Adobe fonts

@font-face {
  font-family: "yellix";
  font-style: normal;
  src: url(/fonts/yellix-semi-bold.woff2) format('woff2');
}

@font-face {
  font-family: "yellix";
  font-style: italic;
  src: url(/fonts/yellix-semi-bold-italic.woff2) format('woff2');
}

// Font families
@font-family-text: "proxima-nova", Arial, Helvetica, sans-serif;
@font-family-display: "yellix", Arial, Helvetica, sans-serif;

// Font sizes
@font-size-100: 12px;
@font-size-300: 14px;
@font-size-400: 15px;
@font-size-500: 16px;
@font-size-600: 18px;
@font-size-700: 20px;
@font-size-800: 22px;
@font-size-1000: 26px;

// Line heights
@line-height-100: 16px;
@line-height-300: 20px;
@line-height-500: 24px;
@line-height-600: 26px;
@line-height-900: 32px;
@line-height-1000: 34px;

// Font weights
@font-weight-semi-bold: 600;
@font-weight-medium: 500;
@font-weight-regular: 400;

// Proxima Nova font weights
// These are different because Figma renders this font family smoother than the browsers
@proxima-nova-font-weight-corrector: 100;
@font-weight-semi-bold-proxima-nova: (@font-weight-semi-bold - @proxima-nova-font-weight-corrector);
@font-weight-medium-proxima-nova: (@font-weight-medium - @proxima-nova-font-weight-corrector);
@font-weight-regular-proxima-nova: (@font-weight-regular - @proxima-nova-font-weight-corrector);


//
// -- FONT ALIAS TOKENS --
//
@text-display-xl: @font-weight-semi-bold @font-size-1000/@line-height-1000 @font-family-display;
@text-display-large: @font-weight-semi-bold @font-size-800/@line-height-900 @font-family-display;
@text-display-base: @font-weight-semi-bold @font-size-700/@line-height-900 @font-family-display;
@text-display-small: @font-weight-semi-bold @font-size-600/@line-height-900 @font-family-display;
@text-display-xs: @font-weight-semi-bold @font-size-500/@line-height-500 @font-family-display;

@text-large: @font-weight-regular-proxima-nova @font-size-600/@line-height-600 @font-family-text;
@text-base: @font-weight-regular-proxima-nova @font-size-500/@line-height-500 @font-family-text;
@text-small-regular: @font-weight-regular-proxima-nova @font-size-400/@line-height-300 @font-family-text;
@text-small-semi-bold: @font-weight-semi-bold-proxima-nova @font-size-400/@line-height-500 @font-family-text;
@text-xs-medium: @font-weight-medium-proxima-nova @font-size-300/@line-height-300 @font-family-text;
@text-xs-regular: @font-weight-regular-proxima-nova @font-size-300/@line-height-300 @font-family-text;
@text-xs-semi-bold: @font-weight-semi-bold-proxima-nova @font-size-300/@line-height-300 @font-family-text;
@text-2xs-regular: @font-weight-regular-proxima-nova @font-size-100/@line-height-100 @font-family-text;
@text-2xs-medium: @font-weight-medium-proxima-nova @font-size-100/@line-height-100 @font-family-text;
