.saved-goal-edit-modal-component-expansion {
  width: ~"calc(100% - 100px)";
}

.saved-goal-edit-modal {
  > .modal {
    overflow: hidden;
  }
}

.target-selector-modal {
  .modal-container-body {
    .input-with-icon {
      margin-bottom: 20px;
    }

    .checkbox-list {
      gap: 8px;
      display: flex;
      overflow-y: auto;
      max-height: 50vh;
      padding-top: 10px;
      flex-direction: column;

      .checkbox-element {
        overflow-x: clip;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        max-width: 100%;
        cursor: pointer;

        .checkbox-explanation {
          color: @gray-light2;
        }

        &.disabled {
          opacity: 0.4;
          cursor: default;
        }
      }
    }
  }
}
