.side-progress-bar {
  width: 240px;
  margin-right: 50px;

  .progress-bar-content {
    padding: 20px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
    background-color: @whiteBG;
    height: 100%;
    min-height: 100vh;
    font-size: @font-size-bodyText;
    text-transform: capitalize;
  }

  &.with-alert-header {
    .progress-bar-content {
      min-height: calc(~'100vh' - @header-alert-height);
    }
  }

  .title {
    padding: 30px 0 15px;

    svg {
      height: 30px;
    }
  }

  .menu {
    list-style: none;
    color: @gray2;
    padding-left: 0;
    margin: 0;

    li {
      line-height: 30px;

      svg {
        height: 17px;
        fill: @gray-lightest;
        vertical-align: text-bottom;
        margin-right: 8px;
      }

      a {
        text-decoration: none;
        color: @gray2;

        &:hover {
          color: @black;
          font-weight: @font-weight-semi-bold;
        }
      }

      .step, .sub-step {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.completed {
        svg {
          fill: @anomaly-positive-color;
        }
      }

      &.active .step, &.active .step a,
      .active.sub-step {
        color: @black;
        font-weight: @font-weight-semi-bold;
      }
    }
  }

  .sub-menu {
    padding-left: 25px;
  }

  .line-separator {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .help-link {
    svg {
      width: 20px;
      height: 20px;
      fill: @blue-link;
      vertical-align: middle;
      margin-right: 8px;
    }

    span {
      vertical-align: middle;
    }
  }
}

body.form-above-fold-active .progress-bar-content {
  min-height: calc(~'100vh - 64px');
}
