.input-with-emoji {
  position: relative;

  input {
    padding-right: 40px;

    &:focus-visible {
      outline: 0;
    }
  }

  .emoji-picker-button {
    position: absolute;
    right: @icon-emoji-button-right-margin;
    top: 0;
    cursor: pointer;

    svg {
      margin-top: 3px;
      fill: @gray8;
      height: 28px;
      width: 28px;
    }
  }

  .emoji-picker-menu {
    position: relative;

    .emoji-mart {
      position: absolute;
      z-index: @zindex-dropdown;
      top: 0;
      right: 0;
    }
  }
}

.corsac-input {
  outline: none;
  display: block;
  font: @text-base;
  padding: 6px 12px;
  border: @border-default;
  border-radius: @border-radius-default;
  background-color: @background-medium-color;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  &.full-width {
    width: 100%;
  }

  &.input-small-width {
    width: 150px;
  }

  &.with-top-text {
    margin-top: 4px;
  }

  &.white-background {
    background-color: @background-white-color;
  }

  &::placeholder {
    opacity: 1;
    font: @text-base;
    color: @text-disabled-color;
  }

  &.text-area-static {
    resize: none;
  }
}

.textarea-auto-resize {
  resize: none;
  overflow-y: hidden;
}

.textarea-with-count {
  textarea {
    padding: 10px;
    .white-bg-scrollbar();
  }

  .textarea-count {
    font: @text-2xs-medium;
    color: @text-disabled-color;
  }

  &:focus {
    border-color: @border-active-hover-color;
  }
}
