.recommendation-page,
.recommendation-modal {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0;
  box-shadow: @box-shadow-small-regular;
  color: @text-color;
  border-radius: @border-radius-large;
  margin-top: 0;
  overflow: hidden;

  .recommendation-preview {
    display: flex;
    flex-direction: column;
    background-color: @recommendation-preview-bg-color;
    .border-left-radius(@border-radius-large);
    .weak-bg-scrollbar(@recommendation-preview-bg-color);

    .preview {
      border: @border-default;
      border-radius: @border-radius-large;
      box-shadow: @box-shadow-small-regular;
      line-height: @line-height-base;
      height: auto;

      &:hover {
        box-shadow: @box-shadow-medium;
      }

      .horizontal-bar-chart .marker-text {
        vertical-align: top;
      }

      .marker-text {
        pointer-events: none;
      }
    }

    .anomaly-drawer {
      background-color: @background-white-color;
      margin-bottom: 20px;
      font: @text-small-regular;

      .header {
        .title {
          font: @text-xs-medium;
        }

        svg {
          .icon-small();
        }
      }
    }

    .anomaly-drawer-content {
      border: @border-default;
      .border-bottom-radius(@border-radius-default);
    }
  }

  .recommendation-text-view {
    background: @background-white-color @white-pink-big-gradient;
    .border-right-radius(@border-radius-large);
  }

  &.recommendation-loading {
    .recommendation-preview {
      justify-content: center;
    }
  }
}

.recommendation-drawer {
  background: @background-white-color @white-pink-big-gradient;
  box-shadow: @box-shadow-medium;
}

.recommendation-page,
.recommendation-modal,
.recommendation-drawer {

  .recommendation-preview,
  .recommendation-text-view,
  .recommendation-action-overlay {
    width: @recommendation-text-view-width;
    max-height: 100%;
    min-height: 500px;
  }

  .recommendation-preview {
    overflow-y: auto;
    overflow-x: hidden;

    .loading-dialog {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .dropdown-menu {
      max-width: 500px;
    }

    .recommendation-preview-info {
      font: @text-xs-regular;
      margin: @spacing-1 @spacing-1 @spacing-3;
    }

    .recommendation-card-section {
      .recommendation-card-section-title {
        font: @text-display-xs;
        color: @text-weak-color;
        margin: 0 -@grid-col-gutter/2;//Negative pixels to match the card preview alignment
        padding: @spacing-2 4px;
        position: sticky;
        top: 0;
        z-index: @zindex-table-header;
        background-color: @recommendation-preview-bg-color;
        display: flex;

        &.hidden-title {
          padding: 0;
          visibility: hidden;
        }

        h4 {
          margin: 0;
          .text-ellipsis();
        }

        .hidden-link {
          color: @text-weak-color;
        }

        .sales-coach-button-tooltip {
          min-width: 0;
          flex-shrink: 1;

          .sales-coach-button {
            max-width: 100%;
            margin-left: @spacing-3;
            font: @text-display-xs;

            svg {
              flex-shrink: 0;
            }

            span {
              .text-ellipsis();
            }
          }
        }
      }
    }

    .recommendation-preview-header {
      padding: 20px;
      background-color: @background-white-color;
      border-bottom: @border-default;
      border-right: @border-default;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: flex-start;

      &.single-button-pair-header .target-navigation-buttons {
        margin-left: auto;
      }

      .dropdown {
        min-width: 0;
      }

      &.with-sub-label .dropdown {
        flex: none;
        max-width: 45%;
      }
    }

    .recommendation-cards-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      padding: 10px 30px 0 @spacing-6;

      .recommendation-cards-header-title {
        display: flex;
        flex-shrink: 0;
        gap: @spacing-1;
        padding-right: 20px;
        align-items: center;
        font: @text-display-small;

        svg {
          fill: @icon-color;
        }

        &.is-filtered {
          @media only screen and (max-width: @screen-sm) {
            > div {
              display: none;
            }
          }

          @media only screen and (max-width: @screen-md) {
            > div:last-child {
              display: none;
            }
          }
        }
      }

      .recommendation-cards-actions {
        min-width: 0;
        display: flex;
        align-items: center;

        > .corsac-button {
          flex-shrink: 0;
          margin-right: @spacing-5;
        }

        .filter-count {
          width: 20px;
          height: 20px;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          border-radius: 50px;
          font: @text-2xs-medium;
          margin-left: @spacing-2;
          justify-content: center;
          background-color: @background-blue-color;
          color: @text-on-dark-color;
        }
      }

      .dropdown-with-checkbox {
        min-width: 0;
        .dropdown {
          min-width: 0;
        }

        .dropdown-selection {
          display: flex;
          align-items: center;

          .label {
            font: @text-xs-medium;
            color: @text-weak-color;
            .text-ellipsis();
          }
        }

        .filterable-checkbox-list-options {
          max-height: 340px;
        }
      }

      .explanation {
        line-height: inherit;
        height: auto;
        flex-shrink: 0;
        margin-left: @spacing-1;
        svg {
          fill: @icon-weak-color;
          .icon-small();
        }

        &:not(.filtered) {
          margin-right: @spacing-5;
        }

        + .filter-count {
          margin-right: @spacing-5;
        }
      }
    }

    .recommendation-warning {
      padding: @spacing-4 @spacing-6 @spacing-3;
    }
    .recommendation-parent-section {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: @background-white-color;
      border-right: @border-default;
      border-top: @border-default;
      text-align: center;
      padding: @spacing-4;

      .sales-coach-button {
        margin-top: @spacing-3;
      }
    }
  }

  &.recommendation-multi-card .dropdown-menu {
    max-width: 600px;
  }

  .loading-dialog.row {
    margin: 0;
  }

  .recommendation-header-container {
    margin: @spacing-3 @spacing-6 @spacing-2 @page-spacing;

    &.recommendation-header-chat-active {
      margin-bottom: 0;
    }

    .recommendation-tabs {
      .section-tab {
        &.active {
          color: @text-marketing-fuchsia-color;

          .section-tab-icon svg {
            fill: @icon-marketing-fuchsia-color;
          }
        }
      }

      .section-active-tab-indicator {
        background-color: @background-marketing-fuchsia-color;
      }
    }

    .recommendation-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .descriptive-dropdown .dropdown-selection {
        font: @text-display-small;
      }
    }

    .recommendation-actions {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: @spacing-3 @spacing-2;
      background-color: @background-medium-color;
      border: @border-default;
      .border-bottom-radius(@border-radius-large);

      &.light-bg-actions {
        background-color: @background-white-color;
      }

      &.right-actions {
        justify-content: flex-end;
      }

      .descriptive-dropdown {
        .dropdown-selection {
          background-color: transparent;
          font: @text-base;
        }

        .descriptive-dropdown-menu {
          width: auto;
          white-space: nowrap;

          svg {
            fill: @icon-weak-color;
          }
        }
      }
    }

    .right-grouped-actions,
    .left-grouped-actions {
      display: flex;
      align-items: center;
    }

    .left-grouped-actions {
      min-width: 0;

      .descriptive-dropdown {
        max-width: 100%;

        .dropdown-selection.borderless > span {
          flex: 1;
          .text-ellipsis();
        }
      }
    }

    .right-grouped-actions {
      flex-shrink: 0;
      .button-base,
      .corsac-button {
        margin-left: @spacing-2;
      }
    }

    .notification-banner {
      margin-top: @spacing-3;

      .notification-text {
        span {
          vertical-align: middle;
        }
      }
    }
  }

  .recommendation-preview-content {
    display: flex;
    overflow-y: auto;
    flex-direction: column;

    &.recommendation-preview-loading {
      flex: 1;
    }

    .recommendation-preview-text-content {
      background-color: @background-white-color;
      border-radius: @border-radius-large;
      border: @border-default;
      padding: 20px 40px;
      margin: 12px 40px;
      overflow-y: auto;
      .white-bg-scrollbar();
    }
  }

  .recommendation-preview-text-content {
    .modal-title {
      font: @text-display-large;
      margin: 0;
      white-space: nowrap;

      span {
        vertical-align: middle;
      }

      svg {
        margin-right: @text-with-icon-separation;
        vertical-align: middle;
      }
    }
  }

  .close {
    display: inline-block;
    cursor: pointer;
    svg {
      vertical-align: middle;
      .icon-x-large();
    }
  }

  .recommendation-text-view {
    color: @text-color;
    display: flex;
    flex-direction: column;
    position: relative;

    .recommendation-close-container {
      text-align: right;
      margin: @spacing-6;
    }

    .recommendation-text {
      font: @text-base;
      padding: 0 @page-spacing;
      overflow-y: auto;
      overflow-x: hidden;
      .white-bg-scrollbar();

      .formatted-text {
        strong, b {
          font-weight: @font-weight-semi-bold-proxima-nova;
        }

        li {
          margin-bottom: 10px;
        }
      }

      code {
        font: @text-base;
        white-space: normal;
      }

      &.recommendation-error {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;

        p {
          text-align: center;
        }

        svg {
          height: 100px;
        }
      }

      .link-tooltip {
        display: inline;
      }
    }

    .modal-caption {
      font: @text-base;
      color: @text-weak-color;
      text-align: center;
      margin-top: 40px;

      span, svg {
        vertical-align: middle;
      }

      strong {
        font-weight: @font-weight-semi-bold-proxima-nova;
        color: @text-marketing-fuchsia-color;
        vertical-align: middle;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }

    .loading-container ~ .modal-caption {
      margin-top: 0;
      margin-bottom: 150px;
    }

    .recommendation-error ~.modal-caption {
      margin-bottom: 150px;
    }

    .recommendation-secondary-action {
      text-align: center;
      margin-top: 20px;

      > span {
        font: @text-display-xs;
        margin: 0 0 @spacing-2;
      }

      .sales-coach-button {
        margin: 0 auto;
        text-decoration: underline;
      }
    }

    .feedback-button-group {
      margin: 40px 10px 20px;
    }

    .recommendation-chat-view {
      padding-left: @page-spacing;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .recommendation-chat-view-footer {
      height: 60px;
      border-top: @border-default;
    }
  }

  .recommendation-text-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: @spacing-5;
    padding: @spacing-3 @spacing-5;
    border-top: @border-default;
    margin-top: auto; // To push the footer to the bottom of the container
    justify-self: flex-end;
  }

  .hidden-view {
    display: none;
  }

  .recommendation-action-overlay {
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    background-color: @recommendation-overlay-bg-color;
    border-top: 4px solid @background-marketing-blue-color;
    border-left: @border-default;
    box-shadow: @box-shadow-medium;
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;

    &.active-overlay {
      left: 0;
      opacity: 1;
      display: flex;
      flex-direction: column;
    }

    .expand-collapse-button {
      position: absolute;
      top: 8px;
      left: -(@recommendation-collapse-button-size/2);
      height: @recommendation-collapse-button-size;
      width: @recommendation-collapse-button-size;
      background-color: @background-marketing-blue-color;
      border-radius: @border-radius-rounded;
      box-shadow: @box-shadow-medium;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        fill: @icon-on-dark-color;
        vertical-align: middle;
        .icon-small();
      }
    }

    .recommendation-header-container {
      margin-top: @spacing-3;
      margin-bottom: 0;
    }

    .recommendation-header {
      justify-content: flex-start;

      .explanation {
        height: inherit;
        margin-left: @text-with-icon-separation;

        svg {
          fill: @icon-weak-color;
          .icon-small();
        }
      }

      &.recommendation-header-with-buttons {
        justify-content: space-between;

        .right-grouped-actions {
          margin-left: auto; // To align the actions to the right
        }
      }
    }

    .recommendation-actions {
      border-radius: @border-radius-large;
      margin-top: @spacing-4;
      padding: 8px;
    }

    .recommendation-text,
    .recommendation-edit-overlay-body {
      .weak-bg-scrollbar(@recommendation-overlay-bg-color);
    }

    .textarea-container {
      position: relative;

      .recommendation-subject-textarea {
        padding: @spacing-2 @spacing-3 @spacing-2 70px;
      }
    }

    .recommendation-textarea {
      border: @border-default;
      background-color: @recommendation-preview-bg-color;
      border-radius: @border-radius-default;
      resize: none;
      width: 100%;
      .weak-bg-scrollbar(@recommendation-preview-bg-color);
    }

    .recommendation-body-textarea {
      margin: @spacing-1 30px 30px;

      .recommendation-textarea {
        padding: 20px;
        min-height: 300px;
      }
    }

    .recommendation-input-label {
      position: absolute;
      pointer-events: none;
      border: 1px solid transparent;
      padding: 8px 12px;
      color: @text-weak-color;
    }

    .recommendation-autocomplete {
      position: relative;
      background-color: @background-medium-color;
      border: @border-default;
      border-radius: @border-radius-default;
      margin-bottom: @spacing-2;

      .form-control {
        margin-top: 0;
        border: none;
        background-color: transparent;

        &::placeholder {
          color: @text-disabled-color;
        }
      }

      .input-with-icon {
        margin-left: 30px;
      }

      .line-separator {
        border-top: @border-default;
      }

      .selected-options {
        margin: 6px @spacing-3;
      }

      .removable-option {
        font: @text-2xs-medium;
        margin: 2px @spacing-1 2px 0;
        background-color: @background-white-color;
        border: @border-default;

        .label {
          color: @text-weak-color;
        }

        svg {
          .icon-small();
          fill: @icon-weak-color;
        }
      }
    }

    .recommendation-edit-overlay-body {
      overflow-y: auto;
    }

    &.recommendation-edit-overlay {
      .email-field-group {
        border-bottom: none;
      }
      .email-field:first-child {
        margin-top: 0;
      }
    }
  }
}

.recommendation-preview-header {
  .recommendation-preview-header-sub-label {
    font: @text-xs-medium;
    color: @text-weak-color;
    padding: 0 @spacing-3;
    border-left: @border-default;
    .text-ellipsis();
  }
}

.recommendation-single-card {
  .recommendation-preview {
    padding: 40px 40px 30px;
  }

  .preview {
    .summary {
      font: @text-small-regular;
    }
  }
}

.recommendation-multi-card .recommendation-cards,
.card-preview-only {
  .preview {
    height: calc(~"100% - @{spacing-3}");

    &.preview-with-anomaly {
      .header {
        padding-bottom: 0;
      }

      .preview-graph-view {
        margin: 0 30px;
      }
    }

    .anomaly-info .anomaly-deviation {
      font-size: @font-size-1000;
    }
  }
}

.recommendation-multi-card {
  .recommendation-preview {
    width: 690px;

    .recommendation-cards {
      padding: 0 30px 20px;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .weak-bg-scrollbar(@recommendation-preview-bg-color);

      .col-6, .col-12 {
        padding-right: 2px;
        padding-left: 2px;
      }
    }
  }

  .recommendation-text-view,
  .recommendation-action-overlay {
    width: 520px;

    .email-field-group {
      margin: @spacing-3 @page-spacing 0;
      border-bottom: @border-default-hover;

      .email-field {
        margin: @spacing-2 0;
        display: flex;
      }

      .field-label {
        color: @text-weak-color;
        margin-right: @spacing-1;
      }

      .field-content-unwrapped {
        .text-ellipsis();
      }

      .field-with-count {
        display: flex;
        overflow: hidden;

        .field-count {
          flex-shrink: 0;
        }
      }

      .typing-indicator.show {
        display: inline-block;
      }

      p {
        margin: @spacing-2 0;
      }
    }
  }
}

.recommendation-multi-card,
.recommendation-empty {
  margin-top: @recommendation-modal-multi-card-vertical-margin;
  justify-content: flex-start;

  &.modal-container-extra-large {
    width: 1210px;
  }

  .recommendation-cards-error,
  .recommendation-modal-container-body {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    text-align: center;
  }
}

.recommendation-empty {
  .recommendation-preview {
    .recommendation-preview-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: none;

      .button-pair {
        flex-grow: 1;
        margin-left: 20px;
      }

      .close {
        margin-left: auto;
        cursor: pointer;
      }

      > .dropdown {
        max-width: 85%;
      }
    }

    .modal-container-body {
      justify-content: center;
    }
  }
}

.recommendation-page {
  width: 100%;
  height: 100%;
  border-radius: unset;

  &.recommendation-multi-card {
    margin-top: 0;
  }

  .recommendation-preview {
    width: 57%;

    .recommendation-parent-section, .recommendation-preview-header {
      border-left: @border-default;

      .recommendation-target-settings-button {
        display: none;
      }

      .recommendation-preview-header-title {
        min-width: 0;

        .dropdown-selection.borderless span {
          flex: 1;
          .text-ellipsis();
        }
      }

      @media only screen and (min-width: @screen-md) {
        .recommendation-target-settings-button {
          display: block;
        }
      }
    }

    .recommendation-preview-content {
      border-left: @border-default;
      border-right: @border-default;
    }
  }

  .recommendation-text-view {
    width: 43%;
  }

  .recommendation-action-overlay {
    width: 100%;
  }

  .recommendation-preview, .recommendation-text-view {
    border-radius: unset;
  }
}

.recommendation-page-header,
.recommendation-preview-header {
  > .button-pair {
    margin-left: 20px;
  }
}

.recommendation-page-v2 {
  display: flex;
  height: 100%;
  flex-direction: column;

  .recommendation-preview {
    z-index: 1;
  }

  .recommendation-page-header {
    display: flex;
    padding: 20px;
    background-color: @background-white-color;
    border-bottom: @border-default;
    align-items: center;
    flex-shrink: 0;

    .target-navigation-buttons {
      margin-right: @spacing-3;
    }

    .recommendation-preview-header-sub-label {
      font: @text-xs-medium;
      color: @text-weak-color;
      padding: 0 @spacing-3;
      border-left: @border-default;
      .text-ellipsis();
    }

    .loading-dialog {
      padding: 0;
    }
  }

  .recommendation-page-header,
  .recommendation-text-view {
    border-left: @border-default;
  }

  .recommendation-text-view {
    background: @background-white-color;

    .recommendation-header-container {
      margin: 0;
      padding: 0 30px;

      .recommendation-header {
        border-left: none;

        .section-tab {
          display: flex;
          align-items: center;
          font: @text-display-xs;
          color: @text-weak-color;
          text-decoration: none;

          .section-tab-icon svg {
            fill: @icon-weak-color;
          }

          &.active {
            color: @text-marketing-fuchsia-color;

            .section-tab-icon svg {
              fill: @icon-marketing-fuchsia-color;
            }
          }


          @media only screen and (max-width: @screen-sm) {
            font: @text-2xs-medium;
            svg {
              width: 18px;
            }
          }
        }
      }
    }
  }

  .recommendation-preview,
  .recommendation-text-view,
  .recommendation-action-overlay {
    min-height: 450px;
  }

  .recommendation-action-overlay {
    left: -100%;
    padding-top: @spacing-3;

    .expand-collapse-button {
      z-index: 2;
      left: unset;
      right: -(@recommendation-collapse-button-size/2);
    }
  }

  .recommendation-text-footer {
    gap: @spacing-2;

    .button-primary-success.corsac-button {
      padding: @spacing-1 @spacing-10;

      @media only screen and (max-width: @screen-sm) {
        padding: @spacing-1 @spacing-7;
      }
    }
  }
}
