.org-edit-page {

  .checkbox-container {
    padding-top: 20px;
    color: #666666;
    font-weight: 100;

    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
      width: 16px;
      height: 16px;
    }

    [type="checkbox"]:not(:checked) + label svg, [type="checkbox"]:checked + label svg {
      margin-left: 2px;
    }

    [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
      padding-top: 1px;
    }
  }

  .space-right {
    padding-right: 15px;
  }

  .company-name {
    padding-top: 30px;
    padding-bottom: 24px;
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    display: inline-block;
    margin-right: @spacing-3;
  }

  .header {
    border-bottom: 1px solid #CCCCCC;
    padding-top: 30px;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;

    .sort {
      cursor:pointer;
    }

  }
  .sort-order {
    cursor: pointer;

    .sort-icon {
      padding-left: 5px;

      svg {
        height: 10px;
        width: 10px;
        vertical-align: middle;
        fill: #BBBBBB;
      }
    }
  }

  .action-links {
    display: flex;
    gap: @spacing-3;
    align-items: center;
    justify-content: flex-end;
  }

  .add-employee {
    height: 37px;
    width: 170px;
    border: 1px solid #236AB2;
    border-radius: @border-radius-small;
    background-color: #2A7DD2;
    color: white;
    float: right;
    cursor: pointer;
    text-align: center;
    padding-top: 6px;
  }

  .add-employee:hover {
    text-decoration: none;
  }

  .user-data {
    color: #666666;
    font-size: 16px;
    line-height: 1.2em;
    font-weight: 100;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid #CCCCCC;

    .user-row {
      border-bottom: 1px dotted #DDDDDD;
      padding: 8px 0;

      a {
        color: #666666;
        text-decoration: underline;
      }

      .tracking {
        color: #2E79B9;
      }

      .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media only screen and (max-width:@page-width) {
    .header {
      font-size: 14px;
    }

    .user-data {
      font-size: 14px;
    }
  }
}

