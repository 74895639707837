.card-view {
  background-color: @background-white-color;
  display: block;
  position: relative;

  .card-content {
    line-height: 0;
  }
}

.card-container {
  .dashboard-creation-card-detail-header {
    display: flex;
    padding: 20px 0;
    align-items: center;
    pointer-events: auto;
    justify-content: space-between;

    .input-with-emoji {
      width: 90%;
      max-width: 1200px;

      input {
        width: 100%;
      }
    }

    .tile-actions {
      display: flex;
      align-items: center;

      > * {
        margin-right: 15px;
      }
    }
  }
  .card-body-no-drawer {
    pointer-events: auto;
  }
}

.x-small-card > .card-content {
  height: 56px;
}


.anomaly-drawer {
  background-color: @whiteBG;
  border-radius: @card-controls-border-radius;
  position: relative;
  font-size: @anomaly-drawer-font-size;
  font-weight: @anomaly-drawer-font-weight;
  line-height: @anomaly-drawer-line-height;

  .alert-action-container {
    display: inline-block;
    position: relative;
  }

  .dismiss {
    margin-right: 6px;
  }

  .undo-link {
    text-decoration: underline;
    color: @link-color;
    cursor: pointer;
  }

  .goal-status {
    padding-top: 5px;
    padding-bottom: 10px;

    .goal-value {
      font-weight: bold;
      color: @text-color;
      padding: 0;
      white-space: nowrap;

      .explanation {
        height: 20px;
        vertical-align: top;

        svg {
          height: 18px;
          width: 18px;
        }
      }
    }

    .ramping-goal-explanation {
      .tooltip-inner {
        div {
          padding: 5px;

          p:last-child {
            padding-top:10px;
          }
        }
      }
    }
  }

  .filter-alert {
    border-top: @light-gray-border;
    padding: 20px;
    color: @gray;
    font-size: 16px;
    line-height: 22px;
  }

  .anomaly-drawer-content {
    padding: @anomaly-drawer-padding;
    color: @gray;

    .bold {
      color: @gray-dark;
    }

    a {
      font-weight: @anomaly-action-font-weight;
      color: @link-color;
      text-decoration: underline;
    }

    .concise-summary {
      padding-top: 10px;
    }

    .title {
      font-size: @anomaly-drawer-title-font-size;
      color: @anomaly-drawer-title-text-color;
      text-align: center;
      margin-bottom: 10px;
      .bold();
    }

    > p {
      color: @anomaly-drawer-body-text-color;
      margin-top: 20px;
      margin-bottom: 15px;

      strong {
        .bold();
      }
    }

    .recently-exited-image {
        fill: @gray3;
        margin: 0 auto 20px auto;
        width: 125px;
        height: 125px;

        svg {
          width: 125px;
          height: 125px;
        }
    }

    .detail > .detail-title {
      display: block;
    }

    .related-alerts {
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px dashed @gray5;
      text-align: left;

      .title {
        text-align: left;
        color: @anomaly-drawer-actions-title-color;
        font-size: @anomaly-drawer-actions-title-size;
        font-weight: @anomaly-drawer-actions-font-weight;
        margin-bottom: @anomaly-drawer-actions-margin-bottom;
        text-transform: uppercase;
      }

      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        color: @gray;
        font-weight: @font-weight-thin;

        a {
          text-decoration: underline;
        }

        li {
          padding-top: 10px;
        }

        li:first-of-type {
          padding-top: 0;
        }
      }

      .bucket-list {
        //list-style-type: none;

        div {
          display: inline-block;
        }
      }

      .bucket-strategy-logo {
        padding-right: 4px;
        display: inline-block;
        svg {
          width: @bucket-strategy-logo-width;
          height: 20px;
          vertical-align: text-bottom;
        }
      }
    }

    .anomaly-recommended-actions {
      border-top: 1px dashed @gray5;
      padding-top: 20px;
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 10px;
      }

      .title {
        text-align: left;
        text-transform: uppercase;
        color: @anomaly-drawer-actions-title-color;
        font-size: @anomaly-drawer-actions-title-size;
        font-weight: @anomaly-drawer-actions-font-weight;
        margin-bottom: @anomaly-drawer-actions-margin-bottom;
      }

      .list {
        > div {
          margin-top: 20px;
        }
      }
    }
  }

  .sales-coach-button-container {
    background-color: @background-white-color;
    .sales-coach-button {
      .border-bottom-radius(@border-radius-default)
    }
  }
}

.saved-view-save-dropdown .form-control {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 16px;
    height: 16px;
    fill: @gray8;
    transition-duration: .35s;
  }
}

.bucketed-anomaly-page {
  .card-detail-view {
    display: block;
    padding: 20px;

    .card-header {
      margin-left: 0;
      padding: 0 10px;
    }

    .card-body-with-drawer {
      width: @card-body-bucketed-anomaly-drawer-width;
    }
  }
}

.card-detail-view {
  display: flex;
  min-width: 1230px;
  height: 100%;
  width: 100%;

  .card-container {
    padding: 0 20px;
    overflow: auto;
  }

  .card-container.with-left-control {
    width: @card-container-with-left-control-width;
  }

  .card-container.no-left-control {
    width: @card-container-no-left-control-width;
  }

  .card-content {
    background-color: @background-white-color;
  }

  .card-header {
    padding: 10px 0;
    height: 76px;
    &.page-actions {
      margin: 0;
    }
  }

  .card-title-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    flex: 1;

    @media only screen and (min-width: @screen-md) {
      .title-dropdown {
        max-width: 70%;
      }

      .title-help {
        max-width: 30%;
      }
    }
  }

  .card-title-with-tag {
    .title-help {
      max-width: 40%;
    }

    .title-dropdown {
      max-width: 60%;
    }
  }

  .card-title {
    .title-help {
      max-width: 5%;
    }
    .title-dropdown {
      max-width: 95%;
    }
  }

  .card-title,
  .card-title-with-tag {
    display: flex;
    align-items: center;
    margin: @card-title-margin;
    font-size: @card-title-font-size;
    color: @card-title-color;
    font-weight: @card-title-font-weight;
    height: 28px;

    .title-help {
      display: inherit;
      margin-left: 10px;

      .tag {
        max-width: 100%;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .title {
      vertical-align: middle;
    }

    svg {
      height: 18px;
      width: 18px;
      vertical-align: middle;
      fill: @gray5;

      &:hover {
       fill: @icon-hover-color;
      }
    }

    .title-dropdown {
      .dropdown-selection {
        display: flex;
        align-items: center;
      }

      .title {
        display: inline-block;
        flex: 1;
        .text-ellipsis();

        span {
          display: inline-block;
          max-width: 100%;
          vertical-align: middle;
          .text-ellipsis();
        }
      }

      .dropdown-menu-container {
        max-width: 45ch;
      }
    }
  }

  .card-actions {
    display: block;
    line-height: @card-title-line-height;
    font-size: @card-actions-font-size;
    color: @card-close-color;
    margin-left: 40px;

    > div {
      margin-right: 5px;
    }

    .save-dropdown {
      margin-left: 5px;
      position: relative;

      .modal-dropdown-menu {
        width: 500px;

        .modal-dropdown-menu-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      textarea {
        resize: vertical;
        max-height: 110px;
      }

      .descriptive-dropdown-menu .description {
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .add-to-dashboard-menu {
        position: relative;

        .dropdown-toggle {
          cursor: pointer
        }

        .create-view-dropdown {
          width: 100%;
        }

        .filterable-checkbox-list {
          width: 100%;

          .filterable-checkbox-list-options {
            max-height: 50vh;
            min-height: 50px;
          }
        }

        .filterable-checkbox-list, .buttons-section {
          border-radius: 0 0 8px 8px;
        }

        .modal-dropdown-menu {
          margin-top: 3px;
        }

        @media only screen and (min-height: 320px) {
          .filterable-checkbox-list .filterable-checkbox-list-options {
            max-height: 30vh;
          }
        }

        @media only screen and (min-height: 450px) {
          .filterable-checkbox-list .filterable-checkbox-list-options {
            max-height: 40vh;
          }
        }
      }
    }

    .dropdown-modal {
      margin-left: 5px;
      .modal-dropdown-menu {
        width: 500px;

        .add-to-folder-dropdown .filterable-checkbox-list, .modal-dropdown-menu {
          width: 100%;
        }
      }
    }
  }

  .card-no-graph {
    background-color: white;
    padding-bottom: 20px;
  }

  .card-list-no-data {
    height: 440px;
  }

  .atrium-logo {
    position: relative;
    opacity: 0.2;

    svg {
      height: 16px;
      width: 80px;
      vertical-align: middle;
      position: absolute;
      right: 0;
      margin: 15px 10px 10px;
    }
  }

  .sidebar .sidebar-section .sidebar-section-title {
    margin: 0;
    padding: @spacing-3 @spacing-5;

    &:hover {
      background-color: @background-hover-color;
    }
  }
}

.card-detail-view, .card-section {
  .card-detail-column-customization-modal .drag-and-drop-list {
    margin-top: 0;
    column-gap: @spacing-10;
  }
}

.card-controls {
  font-size: @font-size-bodyText;
  background-color: @card-controls-background-color;
  padding: 7px 2px;
  border-radius: @card-controls-border-radius;
  min-height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.compare-all-cards {
    justify-content: flex-start;
  }

  .icon-buttons-group {
    padding: 0 6px;
  }

  .position-sla {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .sentence-text {
    display: inline-block;
    color: @text-weak-color;
    font: @text-xs-regular;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: 15px;
  }

  .timeframes, .card-control {
    display: inline-block;
  }

  .timeframes.read-only {
    vertical-align: middle;
  }

  .explanation {
    height: 20px;
  }

  .dropdown {
    text-decoration: underline;

    .dropdown-selection:hover {
      background-color: transparent;
    }
  }
}

@media only screen and (max-width:@page-width) {
  .card-controls {

    > div, span {
      .dropdown-selection {
        font-size: @font-size-bodyText;
      }
    }
  }
}

.card-content {
  overflow: hidden;
}

.card-content.card-graph-view.full-screen-card,
.card-content.presentation-card {
  background-color: @card-content-background-color;
  overflow-x: scroll;
  padding-bottom: 10px;
  .white-bg-scrollbar();
  width: 100%;

  .row {
    padding: 0 25px;
  }
}

.card-section {
  .card-section-content-container {
    background-color: @card-content-background-color;
    height: 100%;
  }

  .card-content.full-screen-card.card-detail {
    overflow: auto;
    .white-bg-scrollbar();

    .group-color-legend {
      display: inline-block;
      vertical-align: bottom;
      width: @card-content-group-width;
      height: @card-content-group-height;
      border-radius: @card-content-group-border-radius;
      margin-right: 8px;
    }

    .header .table-cell .header-styled-cell {
      border-bottom: 1px solid @gray-lightest;
      border-top: 1px solid @gray-lightest;
      background-color: @card-controls-background-color;
    }

    .actions {
      position: sticky;
      left: 0;
      background-color: @table-bg-color;

      .dropdown-label-svg {
        padding: 0;
        border-radius: @border-radius-small;
        border: @lighter-gray-border;

        svg {
          height: 23px;
          width: 28px;
          vertical-align: top;
          fill: @gray2;
        }
      }
    }

    .table-body-row .actions,
    .header .actions .header-styled-cell {
      padding-left: 13px;
      width: 51px;
    }

    .table-body-row .frozen-column,
    .header .frozen-column {
      position: sticky;
      left: 50px;
    }

    .table-body-row .frozen-column {
      background-color: @table-bg-color;
    }

    .detail-columns {
      &:hover {
        background-color: @card-content-detail-columns-hover-bg-color;

        .frozen-column, .actions {
          background-color: @card-content-detail-columns-hover-bg-color;
        }
      }

      &.selected {
        background-color: @lightest-blue;

        .frozen-column, .actions {
          background-color: @lightest-blue;
        }

        .actions {
          z-index: @zindex-wtf;
        }
      }
    }
  }
}

.card-detail .dropdown-menu-container {
  .dropdown-menu {
    padding: 5px 0;
    font-size: @font-size-bodyText;

    > li > a {
      text-align: left;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.card-detail .infinite-scroll {
  width: 100%;
  .white-bg-scrollbar();
  overflow-x: auto;

  .back-card-column-tooltip {
    .tooltip-inner {
      min-width: 500px;
      max-width: 570px;
      color: @text-color;

      p {
        margin: 0;

        &:first-child:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      li {
        margin-left: 20px;
        white-space: nowrap;
      }
    }

    & > .sales-coach-button {
      margin: 0 auto;
    }
  }

  .data-details-popover {
    color: @text-color;

    .tooltip-inner {
      padding: 0;
      width: 570px;
    }

    .data-details-popover-view {
      text-align: left;
    }

    .data-details-popover-header {
      font: @text-xs-semi-bold;
      padding: @spacing-2 @spacing-4;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .data-details-popover-header-title {
        max-width: 100%;
        .text-ellipsis();
      }

      .sales-coach-button {
        margin-left: @spacing-4;
        font: @text-xs-semi-bold;
        flex-shrink: 0;
        white-space: nowrap;
      }
    }

    .info-icon {
      fill: @icon-weak-color;
    }

    .data-details-popover-footer {
      border-top: @border-default;
      padding: @spacing-3 @spacing-4;
      display: flex;
      align-items: center;

      .sales-coach-button-secondary {
        color: @text-marketing-fuchsia-color;
        font: @text-xs-semi-bold;
        margin-right: @spacing-5;
      }
    }
  }

  .column-explanation {
    height: auto;
    vertical-align: text-bottom;
    position: static;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .opp-name {
    color: @gray1;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: @link-color;
    }
  }
}

.card-list-loading,
.card-list-no-data,
.card-list-error {
  position: absolute;
  width: 100%;
}

.card-list-loading,
.card-list-error {
  height: 250px;
}

.card-list-items {
  .card-list-item {
    padding: 10px;
    border-bottom: 1px solid @gray5;
  }
}

.card-control {
  white-space: nowrap;
  .groupings-view,
  .grouping-view {
    display: inline-block;
  }
}

.grouping-view {
  padding: 4px 8px;
  border-radius: @border-radius-default;
  vertical-align: middle;
  text-align: center;
  color: @text-color;
  background-color: @background-hover-color;
  font: @text-xs-medium;
  margin-right: @spacing-1;

  &.multi-character {
    font: @text-xs-regular;
  }

  &.available {
    cursor: pointer;

    &:hover {
      background-color: @background-hover-on-gray-color;
    }
  }

  &.non-available {
    cursor: default;
    color: @text-disabled-color;
  }

  &.selected {
    cursor: default;
    background-color: @background-dark-color;
    color: @text-on-dark-color;
  }
}

.anomaly-tray-collapse, .anomaly-tray-expand {
  display: inline-block;;
  margin: 10px auto;
  cursor: pointer;

  &:hover {
    border-bottom-color: @actionGreen;
  }

  &:active {
    border-bottom-color: @positive-alert;
  }
}

.anomaly-tray-collapse {
  .triangle-up-dynamic(@lightBG2, 0.7em);

}

.anomaly-tray-expand {
  .triangle-down-dynamic(@lightBG2, 0.7em);
}

.centered-row-collapsed-wrapper {
  position: absolute;
  bottom: -10px;
  width: 100%;
}

.centered-row {
  margin: 10px auto 0 auto;
  width: 50px;
  text-align: center;
}

.anomaly-drawer {

  .header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    font: @text-xs-semi-bold;
    color: @white;
    padding: @spacing-2 @spacing-4;
    height: 44px;
    text-transform: uppercase;
    .border-top-radius(@card-controls-border-radius);

    .title {
      svg {
        .icon-small();
        fill: @white;
        vertical-align: middle;
        margin-right: 10px;
      }

      > span {
        vertical-align: middle;
      }
    }

    &.goal-status {
      &.missed {
        background-color: @goal-missed-alert;
      }

      &.risk {
          background-color: @goal-high-risk-alert;
      }

      &.behind {
            background-color: @goal-behind-pace-alert;
      }

      &.track {
            background-color: @goal-on-track-alert;
      }

      &.hit {
            background-color: @goal-hit-alert;
      }
    }

    &.anomaly-negative {
      background-color: @anomaly-negative-color;
    }

    &.anomaly-positive {
      background-color:  @anomaly-positive-color;
    }

    &.anomaly-neutral {
      background-color: @anomaly-neutral-color;
    }

    &.anomaly-none {
      background-color:  @anomaly-positive-color;
    }

    &.related-anomaly {
      background-color: @blueGray;
    }
  }
}

.card-target-selector {
  width: @card-target-selector-width;
  margin-top: 15px;
}

@media only screen and (max-width:@page-width) {
  .card-target-selector {
    margin-top: 13px;
  }
}

.card-body-no-drawer {
  width: 100%;
}

.card-body-with-drawer {
  width: @card-container-drawer-width;
}

.card-body-expanded-messages {
  width: @card-container-expanded-conversation-width;
}

.card-drawer {
  width: @card-drawer-width;
}

.bucketed-anomaly-page .card-drawer {
  width: @card-bucketed-anomaly-drawer-width;
}

.card-body-no-drawer, .card-body-with-drawer, .card-drawer {
  padding-right: @grid-col-gutter / 2;
  padding-left: @grid-col-gutter / 2;
}

.card-page-appear {
  .slide-in-top-0();
}

.card-page-appear.card-page-appear-active {
  animation: slide-in-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both;
}

.card-page-exit {
  .slide-out-top-0();
}


.card-page-exit.card-page-exit-active {
  animation: slide-out-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both;
}

.scenario-drawer {
  background-color: @whiteBG;
  border-radius: @card-controls-border-radius;
  position: relative;
  margin-top: 15px;

  .scenario-drawer-content {
    padding:  20px 0px 20px 20px;
    width: (@card-drawer-width - @scenario-fox-icon-width + 25px);

    > p {
      font-size: @anomaly-drawer-font-size;
      color: @anomaly-drawer-body-text-color;
      margin-top: 0px;

      strong {
        .bold();
      }
    }
    > a {
      color: @actionGreen;
      text-decoration: underline;
    }


  }
  .scenario-drawer-footer {
    background-color: @darkBlueBG;
    padding: 10px 10px 10px 10px;
    .border-bottom-radius(@card-controls-border-radius);
    > span {
      color: @white;
    }
  }
  .scenario-corner {
    width: 0;
    height: 0;
    pointer-events: none;

    .scenario-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      line-height: 0;

      svg {
        width: @scenario-fox-icon-width;
        height: @scenario-fox-icon-height;
      }
    }
  }
}

.card-page  {
  height: 100%;
  min-width: 1092px;

  .card-detail-view {
    .card-container.with-left-control{
      width: @card-container-with-left-control-width;
    }

    .card-container.no-left-control{
      width: @card-container-no-left-control-width;
    }
  }

  .confirmation-container {
    pointer-events: auto;

    .confirmation-message {
      width: 560px;
      line-height: 24px;
    }
  }
}

.card-no-graph {
  color: @text-color-default;

  .card-not-loaded {
    svg {
      width: 28px;
      height: 28px;
      fill: @gray7;
      margin-bottom: 5px;
    }
  }
}
