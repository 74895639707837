body.modal-active {
  overflow: hidden;
}

body.top-level-modal-active {
  overflow: hidden;
}

.modal {
  white-space: normal;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  .modal-header {
    padding: @modal-header-padding;

    .modal-header-text {
      margin: @modal-header-text-margin;
      font-size: @modal-header-text-font-size;
      color: @modal-header-text-color;
    }

    .modal-header-actions {
      display: block;
      float: right;
      white-space: nowrap;
      line-height: @modal-header-actions-line-height;
      font-size: @modal-header-actions-font-size;
      color: @modal-header-actions-color;
      padding-top: @modal-header-actions-padding-top;
    }
  }

  .modal-subtitle {
    padding: @modal-title-padding;
    background-color: @modal-title-bgcolor;
    border-top-right-radius: @modal-border-radius;
    border-top-left-radius: @modal-border-radius;
    font-size: @modal-title-text-font-size;
    color: @modal-title-text-color;
    .bold();
  }


  .modal-body {
    background-color: @modal-body-bg;
    padding: @modal-body-padding;

    .modal-body-row {
      padding: @modal-body-row-padding;
    }

    .modal-body-CTA-wrapper {
      margin: 10px 0;
      text-align: right;
    }
  }

  .full-container {
    width: 100%;
  }

  .two-thirds-container {
    width: 100% * 2 / 3;
    margin: auto;
  }
}

.modal-mask {
  z-index: @zindex-modal-background;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: @modal-mask-opacity;
  background-color: @modal-mask-bg;

  &.white-mask {
    background-color: @modal-mask-white-bg;
  }

  &.on-top-mask {
    z-index: @zindex-modal;
  }

  &.full-mask {
    opacity: 1;
  }

  &.blurred-mask {
    backdrop-filter: @blur;
    background: rgba(211, 211, 218, 0.7);
  }
}

.top-level-modal .modal-mask {
  z-index: @zindex-nested-modal-bg;
}

.centered-modal .modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 530px;
  color: @gray1;
  font: @text-base;
  margin: @modal-top-margin-default auto 0;
  padding: @spacing-6;
  background-color: @modal-body-bg;
  border-radius: @border-radius-default;
  box-shadow: @box-shadow-large;

  .modal-container-header {
    display: flex;
    flex-direction: column;
    margin-bottom: @spacing-6;

    .modal-title-section {
      display: flex;
      justify-content: space-between;

      .modal-title {
        display: flex;
        align-items: center;
        margin-right: @spacing-5;

        .explanation {
          height: max-content;
        }

        .modal-title-label svg {
          vertical-align: middle;

          & ~ span {
            margin-left: @text-with-icon-separation;
            vertical-align: middle;
          }
        }
      }
    }

    .modal-description {
      font: @text-base;
      margin-top: @spacing-1;
      color: @text-weak-color;
    }

    .close {
      .icon-medium(); // To take the same space as the svg close icon
      cursor: pointer;

      svg {
        fill: @icon-color;
        .icon-medium();
      }
    }
  }

  .modal-title-label {
    margin: 0;
    font: @text-display-large;
    color: @text-color;
  }

  .modal-container-notification {
    margin-bottom: 20px;
  }

  .modal-container-section {
    margin-top: 30px;
  }

  .modal-container-row {
    margin-top: 25px;
  }

  .modal-row-col-table.row-col-table {
    margin: 0;
    .row-col-table-header {
      background-color: @modal-body-bg;
    }
  }

  .modal-container-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: @spacing-6;

    .related-actions {
      display: flex;
      align-items: center;

      .explanation {
        height: @spacing-6;

        svg {
          .icon-small();
        }
      }
    }

    .buttons-group {
      display: flex;

      .primary-button-tooltip {
        margin-left: @spacing-3;
      }
    }
  }

  &.modal-form {
    .modal-form-field {
      margin-bottom: 30px;

      &.modal-form-field-row-with-label {
        display: flex;
        align-items: center;

        .control-sub-label {
          white-space: nowrap;
          margin-right: @spacing-5;
        }
      }

      .modal-form-field-row {
        display: flex;
        align-items: center;

        .form-field-connector {
          margin: 0 @spacing-2;
        }
      }

      .control-sub-label {
        font-weight: @font-weight-semi-bold;
        margin-bottom: @spacing-1;
      }

      input.form-control, .dropdown-selection {
        height: @dropdown-height;
      }

      input, .dropdown-selection {
        box-shadow: @box-shadow-small-regular;
      }

      .modal-form-field-input {
        display: flex;
        align-items: center;
      }

      &.light {
        margin-bottom: @spacing-6;

        .control-sub-label {
          font: @text-xs-medium;
          color: @text-weak-color;
        }
      }

      &.field-error {
        input::placeholder, .placeholder {
          color: @text-danger-color;
        }

        svg {
          flex: none;
          fill: @icon-danger-color;
        }
      }
    }

    .notification-list + .section-title,
    .notification-list + .modal-form-field {
      margin-top: @spacing-5;
    }
  }

  &.modal-container-medium {
    width: 790px;
  }

  &.modal-container-large {
    width: 1000px;
  }

  &.modal-container-extra-large {
    width: 1200px;
  }

  &.fox-info-modal {
    text-align: center;

    .message {
      margin-bottom: 40px;
    }
  }
}

.modal-scrollable {
  .modal {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal-container {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  .modal-container-body {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 0;
    .white-bg-scrollbar();
  }
}

.page-drawer-modal {
  .modal, .modal-mask, .drawer-modal {
    top: @header-height;
  }
}

.page-with-banner .page-drawer-modal {
  .drawer-modal {
    top: calc(@header-height + @header-alert-height);
    max-height: calc(~'100vh' - (@header-height + @header-alert-height));
  }
}

.drawer-modal {
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  position: fixed;
  margin-left: auto;
  flex-direction: column;
  z-index: @zindex-modal;
}

.drawer-modal-component-expansion-appear {
  .slide-in-left-0();
}

.drawer-modal-component-expansion-appear-active {
  animation: slide-in-left 0.2s ease-in 0s 1 normal both;
}

.drawer-modal-component-expansion-exit {
  .slide-out-left-0();
}

.drawer-modal-component-expansion-exit-active {
  animation: slide-out-left 0.2s ease-in 0s 1 normal both;
}
