
/**
 * Inspired on https://github.com/kristoferjoseph/flexboxgrid
 *
 * We are making the decision, for now, to now do a fluid grid
 *
 * - We are implementing two grids:
 *   - 12 columns - Default grid. Use row and col-X
 *   - 16 columns - Use row row-16 in the container
 */

.container {
  margin-right: auto;
  margin-left: auto;
  width: @page-width - @grid-col-gutter;
}

.resizeable-container {
  margin-right: 30px;
  margin-left: 30px;
  min-width: @narrow-page-width;
}

.flex-container {
  display: flex;
  margin-right: 40px;
  min-width: @narrow-page-width;

  .content-container {
    max-width: @narrow-page-width;
  }
}

@media only screen and (max-width:@page-width) {
  .container{
    width: @narrow-page-width - @grid-col-gutter;
  }
}

.flexbox {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  &.spaced-items {
    justify-content: space-between;
  }

  &.flexbox-column {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }
}

.row {
  .flexbox;
  margin-right: -@grid-col-gutter / 2;
  margin-left: -@grid-col-gutter / 2;
}

.row-no-margin {
  .flexbox;
}

.flex-col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-13,
.col-14,
.col-15,
.col-16,
.col-offset-1,
.col-offset-2,
.col-offset-3,
.col-offset-4,
.col-offset-5,
.col-offset-6,
.col-offset-7,
.col-offset-8,
.col-offset-9,
.col-offset-10,
.col-offset-11,
.col-offset-12,
.col-offset-13,
.col-offset-14,
.col-offset-15,
.col-offset-16 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: @grid-col-gutter/2;
  padding-left: @grid-col-gutter/2;
}

.middle-columns {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.centered-columns {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.col-1 {
  .grid-12-col(1);
}

.col-2 {
  .grid-12-col(2);
}

.col-3 {
  .grid-12-col(3);
}

.col-4 {
  .grid-12-col(4);
}

.col-5 {
  .grid-12-col(5);
}

.col-6 {
  .grid-12-col(6);
}

.col-7 {
  .grid-12-col(7);
}

.col-8 {
  .grid-12-col(8);
}

.col-9 {
  .grid-12-col(9);
}

.col-10 {
  .grid-12-col(10);
}

.col-11 {
  .grid-12-col(11);
}

.col-12 {
  .grid-12-col(12);
}

@media only screen and (max-width:@page-width) {
  .col-md-1 {
    .grid-12-col(1);
  }

  .col-md-2 {
    .grid-12-col(2);
  }

  .col-md-3 {
    .grid-12-col(3);
  }

  .col-md-4 {
    .grid-12-col(4);
  }

  .col-md-5 {
    .grid-12-col(5);
  }

  .col-md-6 {
    .grid-12-col(6);
  }

  .col-md-7 {
    .grid-12-col(7);
  }

  .col-md-8 {
    .grid-12-col(8);
  }

  .col-md-9 {
    .grid-12-col(9);
  }

  .col-md-10 {
    .grid-12-col(10);
  }

  .col-md-11 {
    .grid-12-col(11);
  }

  .col-md-12 {
    .grid-12-col(12);
  }
}

@media only screen and (min-width:@wide-page-width) {
  .resizeable-container {
    margin-right: 50px;
    margin-left: 50px;
  }

  .col-lg-1 {
    .grid-12-col(1);
  }

  .col-lg-2 {
    .grid-12-col(2);
  }

  .col-lg-3 {
    .grid-12-col(3);
  }

  .col-lg-4 {
    .grid-12-col(4);
  }

  .col-lg-5 {
    .grid-12-col(5);
  }

  .col-lg-6 {
    .grid-12-col(6);
  }

  .col-lg-7 {
    .grid-12-col(7);
  }

  .col-lg-8 {
    .grid-12-col(8);
  }

  .col-lg-9 {
    .grid-12-col(9);
  }

  .col-lg-10 {
    .grid-12-col(10);
  }

  .col-lg-11 {
    .grid-12-col(11);
  }

  .col-lg-12 {
    .grid-12-col(12);
  }
}

.col-offset-1 {
  margin-left: @grid-col-width * 1;
}

.col-offset-2 {
  margin-left: @grid-col-width * 2;
}

.col-offset-3 {
  margin-left: @grid-col-width * 3;
}

.col-offset-4 {
  margin-left: @grid-col-width * 4;
}

.col-offset-5 {
  margin-left: @grid-col-width * 5;
}

.col-offset-6 {
  margin-left: @grid-col-width * 6;
}

.col-offset-7 {
  margin-left: @grid-col-width * 7;
}

.col-offset-8 {
  margin-left: @grid-col-width * 8;
}

.col-offset-9 {
  margin-left: @grid-col-width * 9;
}

.col-offset-10 {
  margin-left: @grid-col-width * 10;
}

.col-offset-11 {
  margin-left: @grid-col-width * 11;
}

.col-offset-12 {
  margin-left: @grid-col-width * 12;
}

@media only screen and (max-width:@page-width) {
  .col-offset-md-0 {
    margin-left: 0;
  }

  .col-offset-md-1 {
    margin-left: @grid-col-width * 1;
  }

  .col-offset-md-2 {
    margin-left: @grid-col-width * 2;
  }

  .col-offset-md-3 {
    margin-left: @grid-col-width * 3;
  }

  .col-offset-md-4 {
    margin-left: @grid-col-width * 4;
  }

  .col-offset-md-5 {
    margin-left: @grid-col-width * 5;
  }

  .col-offset-md-6 {
    margin-left: @grid-col-width * 6;
  }

  .col-offset-md-7 {
    margin-left: @grid-col-width * 7;
  }

  .col-offset-md-8 {
    margin-left: @grid-col-width * 8;
  }

  .col-offset-md-9 {
    margin-left: @grid-col-width * 9;
  }

  .col-offset-md-10 {
    margin-left: @grid-col-width * 10;
  }

  .col-offset-md-11 {
    margin-left: @grid-col-width * 11;
  }

  .col-offset-md-12 {
    margin-left: @grid-col-width * 12;
  }
}

.row.row-16 {
  > .col-1,
  > .col-2,
  > .col-3,
  > .col-4,
  > .col-5,
  > .col-6,
  > .col-7,
  > .col-8,
  > .col-9,
  > .col-10,
  > .col-11,
  > .col-12,
  > .col-13,
  > .col-14,
  > .col-15,
  > .col-16,
  > .col-offset-1,
  > .col-offset-2,
  > .col-offset-3,
  > .col-offset-4,
  > .col-offset-5,
  > .col-offset-6,
  > .col-offset-7,
  > .col-offset-8,
  > .col-offset-9,
  > .col-offset-10,
  > .col-offset-11,
  > .col-offset-12,
  > .col-offset-13,
  > .col-offset-14,
  > .col-offset-15,
  > .col-offset-16 {
    padding-right: @grid-16-gutter / 2;
    padding-left: @grid-16-gutter / 2;
  }

  > .col-1 {
    .grid-16-col(1);
  }

  > .col-2 {
    .grid-16-col(2);
  }

  > .col-3 {
    .grid-16-col(3);
  }

  > .col-4 {
    .grid-16-col(4);
  }

  > .col-5 {
    .grid-16-col(5);
  }

  > .col-6 {
    .grid-16-col(6);
  }

  > .col-7 {
    .grid-16-col(7);
  }

  > .col-8 {
    .grid-16-col(8);
  }

  > .col-9 {
    .grid-16-col(9);
  }

  > .col-10 {
    .grid-16-col(10);
  }

  > .col-11 {
    .grid-16-col(11);
  }

  > .col-12 {
    .grid-16-col(12);
  }

  > .col-13 {
    .grid-16-col(13);
  }

  > .col-14 {
    .grid-16-col(14);
  }

  > .col-15 {
    .grid-16-col(15);
  }

  > .col-16 {
    .grid-16-col(16);
  }

  > .col-offset-1 {
    margin-left: @grid-16-col-width * 1;
  }

  > .col-offset-2 {
    margin-left: @grid-16-col-width * 2;
  }

  > .col-offset-3 {
    margin-left: @grid-16-col-width * 3;
  }

  > .col-offset-4 {
    margin-left: @grid-16-col-width * 4;
  }

  > .col-offset-5 {
    margin-left: @grid-16-col-width * 5;
  }

  > .col-offset-6 {
    margin-left: @grid-16-col-width * 6;
  }

  > .col-offset-7 {
    margin-left: @grid-16-col-width * 7;
  }

  > .col-offset-8 {
    margin-left: @grid-16-col-width * 8;
  }

  > .col-offset-9 {
    margin-left: @grid-16-col-width * 9;
  }

  > .col-offset-10 {
    margin-left: @grid-16-col-width * 10;
  }

  > .col-offset-11 {
    margin-left: @grid-16-col-width * 11;
  }

  > .col-offset-12 {
    margin-left: @grid-16-col-width * 12;
  }

  > .col-offset-13 {
    margin-left: @grid-16-col-width * 13;
  }

  > .col-offset-14 {
    margin-left: @grid-16-col-width * 14;
  }

  > .col-offset-15 {
    margin-left: @grid-16-col-width * 15;
  }

  > .col-offset-16 {
    margin-left: @grid-16-col-width * 16;
  }

  @media only screen and (max-width:@page-width) {
    > .col-md-1 {
      .grid-16-col(1);
    }

    > .col-md-2 {
      .grid-16-col(2);
    }

    > .col-md-3 {
      .grid-16-col(3);
    }

    > .col-md-4 {
      .grid-16-col(4);
    }

    > .col-md-5 {
      .grid-16-col(5);
    }

    > .col-md-6 {
      .grid-16-col(6);
    }

    > .col-md-7 {
      .grid-16-col(7);
    }

    > .col-md-8 {
      .grid-16-col(8);
    }

    > .col-md-9 {
      .grid-16-col(9);
    }

    > .col-md-10 {
      .grid-16-col(10);
    }

    > .col-md-11 {
      .grid-16-col(11);
    }

    > .col-md-12 {
      .grid-16-col(12);
    }

    > .col-md-13 {
      .grid-16-col(13);
    }

    > .col-md-14 {
      .grid-16-col(14);
    }

    > .col-md-15 {
      .grid-16-col(15);
    }

    > .col-md-16 {
      .grid-16-col(16);
    }
  }

  @media only screen and (max-width:@page-width) {
    > .col-offset-md-0 {
      margin-left: 0;
    }

    > .col-offset-md-1 {
      margin-left: @grid-16-col-width * 1;
    }

    > .col-offset-md-2 {
      margin-left: @grid-16-col-width * 2;
    }

    > .col-offset-md-3 {
      margin-left: @grid-16-col-width * 3;
    }

    > .col-offset-md-4 {
      margin-left: @grid-16-col-width * 4;
    }

    > .col-offset-md-5 {
      margin-left: @grid-16-col-width * 5;
    }

    > .col-offset-md-6 {
      margin-left: @grid-16-col-width * 6;
    }

    > .col-offset-md-7 {
      margin-left: @grid-16-col-width * 7;
    }

    > .col-offset-md-8 {
      margin-left: @grid-16-col-width * 8;
    }

    > .col-offset-md-9 {
      margin-left: @grid-16-col-width * 9;
    }

    > .col-offset-md-10 {
      margin-left: @grid-16-col-width * 10;
    }

    > .col-offset-md-11 {
      margin-left: @grid-16-col-width * 11;
    }

    > .col-offset-md-12 {
      margin-left: @grid-16-col-width * 12;
    }

    > .col-offset-md-13 {
      margin-left: @grid-16-col-width * 13;
    }

    > .col-offset-md-14 {
      margin-left: @grid-16-col-width * 14;
    }

    > .col-offset-md-15 {
      margin-left: @grid-16-col-width * 15;
    }

    > .col-offset-md-16 {
      margin-left: @grid-16-col-width * 16;
    }
  }
}

.row.row-24 {
  > .col-1,
  > .col-2,
  > .col-3,
  > .col-4,
  > .col-5,
  > .col-6,
  > .col-7,
  > .col-8,
  > .col-9,
  > .col-10,
  > .col-11,
  > .col-12,
  > .col-13,
  > .col-14,
  > .col-15,
  > .col-16,
  > .col-offset-1,
  > .col-offset-2,
  > .col-offset-3,
  > .col-offset-4,
  > .col-offset-5,
  > .col-offset-6,
  > .col-offset-7,
  > .col-offset-8,
  > .col-offset-9,
  > .col-offset-10,
  > .col-offset-11,
  > .col-offset-12,
  > .col-offset-13,
  > .col-offset-14,
  > .col-offset-15,
  > .col-offset-16 {
    padding-right: @grid-24-gutter / 2;
    padding-left: @grid-24-gutter / 2;
  }

  > .col-1 {
    .grid-24-col(1);
  }

  > .col-2 {
    .grid-24-col(2);
  }

  > .col-3 {
    .grid-24-col(3);
  }

  > .col-4 {
    .grid-24-col(4);
  }

  > .col-5 {
    .grid-24-col(5);
  }

  > .col-6 {
    .grid-24-col(6);
  }

  > .col-7 {
    .grid-24-col(7);
  }

  > .col-8 {
    .grid-24-col(8);
  }

  > .col-9 {
    .grid-24-col(9);
  }

  > .col-10 {
    .grid-24-col(10);
  }

  > .col-11 {
    .grid-24-col(11);
  }

  > .col-12 {
    .grid-24-col(12);
  }

  > .col-13 {
    .grid-24-col(13);
  }

  > .col-14 {
    .grid-24-col(14);
  }

  > .col-15 {
    .grid-24-col(15);
  }

  > .col-16 {
    .grid-24-col(16);
  }

  > .col-17 {
    .grid-24-col(17);
  }

  > .col-18 {
    .grid-24-col(18);
  }

  > .col-19 {
    .grid-24-col(19);
  }

  > .col-20 {
    .grid-24-col(20);
  }

  > .col-21 {
    .grid-24-col(21);
  }

  > .col-22 {
    .grid-24-col(22);
  }

  > .col-23 {
    .grid-24-col(23);
  }

  > .col-24 {
    .grid-24-col(24);
  }

  > .col-offset-1 {
    margin-left: @grid-24-col-width * 1;
  }

  > .col-offset-2 {
    margin-left: @grid-24-col-width * 2;
  }

  > .col-offset-3 {
    margin-left: @grid-24-col-width * 3;
  }

  > .col-offset-4 {
    margin-left: @grid-24-col-width * 4;
  }

  > .col-offset-5 {
    margin-left: @grid-24-col-width * 5;
  }

  > .col-offset-6 {
    margin-left: @grid-24-col-width * 6;
  }

  > .col-offset-7 {
    margin-left: @grid-24-col-width * 7;
  }

  > .col-offset-8 {
    margin-left: @grid-24-col-width * 8;
  }

  > .col-offset-9 {
    margin-left: @grid-24-col-width * 9;
  }

  > .col-offset-10 {
    margin-left: @grid-24-col-width * 10;
  }

  > .col-offset-11 {
    margin-left: @grid-24-col-width * 11;
  }

  > .col-offset-12 {
    margin-left: @grid-24-col-width * 12;
  }

  > .col-offset-13 {
    margin-left: @grid-24-col-width * 13;
  }

  > .col-offset-14 {
    margin-left: @grid-24-col-width * 14;
  }

  > .col-offset-15 {
    margin-left: @grid-24-col-width * 15;
  }

  > .col-offset-16 {
    margin-left: @grid-24-col-width * 16;
  }

  > .col-offset-17 {
    margin-left: @grid-24-col-width * 17;
  }

  > .col-offset-18 {
    margin-left: @grid-24-col-width * 18;
  }

  > .col-offset-19 {
    margin-left: @grid-24-col-width * 19;
  }

  > .col-offset-20 {
    margin-left: @grid-24-col-width * 20;
  }

  > .col-offset-21 {
    margin-left: @grid-24-col-width * 21;
  }

  > .col-offset-22 {
    margin-left: @grid-24-col-width * 22;
  }

  > .col-offset-23 {
    margin-left: @grid-24-col-width * 23;
  }

  > .col-offset-24 {
    margin-left: @grid-24-col-width * 24;
  }

  @media only screen and (max-width: @page-width) {
    > .col-md-1 {
      .grid-24-col(1);
    }

    > .col-md-2 {
      .grid-24-col(2);
    }

    > .col-md-3 {
      .grid-24-col(3);
    }

    > .col-md-4 {
      .grid-24-col(4);
    }

    > .col-md-5 {
      .grid-24-col(5);
    }

    > .col-md-6 {
      .grid-24-col(6);
    }

    > .col-md-7 {
      .grid-24-col(7);
    }

    > .col-md-8 {
      .grid-24-col(8);
    }

    > .col-md-9 {
      .grid-24-col(9);
    }

    > .col-md-10 {
      .grid-24-col(10);
    }

    > .col-md-11 {
      .grid-24-col(11);
    }

    > .col-md-12 {
      .grid-24-col(12);
    }

    > .col-md-13 {
      .grid-24-col(13);
    }

    > .col-md-14 {
      .grid-24-col(14);
    }

    > .col-md-15 {
      .grid-24-col(15);
    }

    > .col-md-16 {
      .grid-24-col(16);
    }

    > .col-md-17 {
      .grid-24-col(17);
    }

    > .col-md-18 {
      .grid-24-col(18);
    }

    > .col-md-19 {
      .grid-24-col(19);
    }

    > .col-md-20 {
      .grid-24-col(20);
    }

    > .col-md-21 {
      .grid-24-col(21);
    }

    > .col-md-22 {
      .grid-24-col(22);
    }

    > .col-md-23 {
      .grid-24-col(23);
    }

    > .col-md-24 {
      .grid-24-col(24);
    }
  }

  @media only screen and (max-width: @page-width) {
    > .col-offset-md-0 {
      margin-left: 0;
    }

    > .col-offset-md-1 {
      margin-left: @grid-24-col-width * 1;
    }

    > .col-offset-md-2 {
      margin-left: @grid-24-col-width * 2;
    }

    > .col-offset-md-3 {
      margin-left: @grid-24-col-width * 3;
    }

    > .col-offset-md-4 {
      margin-left: @grid-24-col-width * 4;
    }

    > .col-offset-md-5 {
      margin-left: @grid-24-col-width * 5;
    }

    > .col-offset-md-6 {
      margin-left: @grid-24-col-width * 6;
    }

    > .col-offset-md-7 {
      margin-left: @grid-24-col-width * 7;
    }

    > .col-offset-md-8 {
      margin-left: @grid-24-col-width * 8;
    }

    > .col-offset-md-9 {
      margin-left: @grid-24-col-width * 9;
    }

    > .col-offset-md-10 {
      margin-left: @grid-24-col-width * 10;
    }

    > .col-offset-md-11 {
      margin-left: @grid-24-col-width * 11;
    }

    > .col-offset-md-12 {
      margin-left: @grid-24-col-width * 12;
    }

    > .col-offset-md-13 {
      margin-left: @grid-24-col-width * 13;
    }

    > .col-offset-md-14 {
      margin-left: @grid-24-col-width * 14;
    }

    > .col-offset-md-15 {
      margin-left: @grid-24-col-width * 15;
    }

    > .col-offset-md-16 {
      margin-left: @grid-24-col-width * 16;
    }

    > .col-offset-md-17 {
      margin-left: @grid-24-col-width * 17;
    }

    > .col-offset-md-18 {
      margin-left: @grid-24-col-width * 18;
    }

    > .col-offset-md-19 {
      margin-left: @grid-24-col-width * 19;
    }

    > .col-offset-md-20 {
      margin-left: @grid-24-col-width * 20;
    }

    > .col-offset-md-21 {
      margin-left: @grid-24-col-width * 21;
    }

    > .col-offset-md-22 {
      margin-left: @grid-24-col-width * 22;
    }

    > .col-offset-md-23 {
      margin-left: @grid-24-col-width * 23;
    }

    > .col-offset-md-24 {
      margin-left: @grid-24-col-width * 24;
    }
  }

  @media only screen and (min-width: @wide-page-width) {
    > .col-lg-1 {
      .grid-24-col(1);
    }

    > .col-lg-2 {
      .grid-24-col(2);
    }

    > .col-lg-3 {
      .grid-24-col(3);
    }

    > .col-lg-4 {
      .grid-24-col(4);
    }

    > .col-lg-5 {
      .grid-24-col(5);
    }

    > .col-lg-6 {
      .grid-24-col(6);
    }

    > .col-lg-7 {
      .grid-24-col(7);
    }

    > .col-lg-8 {
      .grid-24-col(8);
    }

    > .col-lg-9 {
      .grid-24-col(9);
    }

    > .col-lg-10 {
      .grid-24-col(10);
    }

    > .col-lg-11 {
      .grid-24-col(11);
    }

    > .col-lg-12 {
      .grid-24-col(12);
    }

    > .col-lg-13 {
      .grid-24-col(13);
    }

    > .col-lg-14 {
      .grid-24-col(14);
    }

    > .col-lg-15 {
      .grid-24-col(15);
    }

    > .col-lg-16 {
      .grid-24-col(16);
    }

    > .col-lg-17 {
      .grid-24-col(17);
    }

    > .col-lg-18 {
      .grid-24-col(18);
    }

    > .col-lg-19 {
      .grid-24-col(19);
    }

    > .col-lg-20 {
      .grid-24-col(20);
    }

    > .col-lg-21 {
      .grid-24-col(21);
    }

    > .col-lg-22 {
      .grid-24-col(22);
    }

    > .col-lg-23 {
      .grid-24-col(23);
    }

    > .col-lg-24 {
      .grid-24-col(24);
    }
  }
}
