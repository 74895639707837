.banner {
  padding: @alert-header-padding 0;
  height: @header-alert-height;
  border-bottom: 1px solid;

  &.slack-banner {
    background-color: @slackBG;
    border-color: @slackBG;
    opacity: 0.9;
    color: @white;

    a {
      color: inherit;
      text-decoration: underline;
    }

    .banner-content {
      opacity: 0.9;

      svg {
        margin-right: 15px;
      }
    }

    .close-icon {
      fill: @white;
    }
  }

  .close-icon {
    cursor: pointer;
  }

  .banner-content {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  svg, span, a {
    vertical-align: middle;
  }
}
