.autocomplete {
  position: relative;

  input {
    &:focus {
      outline: none;
    }
  }

  .autocomplete-options {
    position: absolute;
    width: 100%;
    padding: 10px 0;
    margin: 0 0;
    font-size: @dropdown-menu-font-size;
    list-style: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: @white;
    border-radius: @dropdown-menu-border-radius;
    box-shadow: @dropdown-menu-box-shadow;
    z-index: 1;

    .scrollable-options {
      max-height: 400px;
      overflow-y: auto;
      .white-bg-scrollbar();
    }

    li {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      clear: both;
      line-height: 32px;
      white-space: nowrap;
      text-align: left;
      color: @gray;
      // TODO ellipsis?

      &:not(.disabled-option) {
        cursor: pointer;
      }

      &:hover, &.active {
        &:not(.disabled-option) {
          background-color: @gray-lighter;
        }
      }

      &:focus {
        outline: none;
      }

      &.disabled-option {
        color:@gray-light2;
        pointer-events: none;
      }

      .option-explanation {
        color:@gray-light2;
      }
    }

    .dropdown-menu-divider {
      background-color: @gray-lightest;
    }
  }
}

.autocomplete-multi-selector {
  .selected-options:not(.inline-options) {
    li {
      padding: 5px 0;
    }
    
    li:first-of-type {
        padding-top: 20px;
    }
  }
}

.autocomplete-multi-selector-reduced {
  .autocomplete-multi-selector {
    .form-control {
      margin-top: 0;
    }

    .selected-options {
      li:first-of-type {
        padding-top: 0;
      }

      li {
        padding: 0;
      }
    }
  }
}

.dropdown-style {
  .autocomplete-multi-selector {
    .input-with-icon {
      input {
        height: 36px;
      }
    }
  }
}

.autocomplete-multi-checkbox {
  &.autocomplete-multi-checkbox-disabled {
    .autocomplete-with-checkbox {
      .box-with-icon {
        cursor: default;
      }
    }
    .autocomplete-multi-checkbox-clear-all {
      color: @text-disabled-color;
      span {
        cursor: default;
      }
    }
    .selected-options {
      .icon {
        cursor: default;
        svg:hover {
          &&& {
            fill: @gray8;
          }
        }
      }
    }
  }
  .autocomplete-with-checkbox {
    .dropdown-toggle {
      padding-bottom: 4px;
    }
    .box-with-icon {
      width: 100%;
      height: 24px;
      background-color: #666;
      border-radius: 2px;
      cursor: pointer;
      box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.1);

      svg {
        .icon-small();
        vertical-align: middle;
        margin: 0 8px;
      }
    }

    .placeholder {
      font-size: 14px;
      font-style: italic;
    }
  }

  .link-style-button {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    margin-top: 5px;
  }

  .selected-options {
    margin-top: 6px;

    li {
      font-size: 14px;
      padding: 3px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .label {
        color: @gray7;
      }

      .icon {
        margin-right: 10px;

        svg {
          fill: @gray-light;
          width: 12px;
          height: 12px;
        }

        &:hover svg {
          fill: @gray7;
        }
      }
    }
  }

  &.autocomplete-multi-checkbox-spaced {
    .selected-options {
      li {
        &:first-of-type {
          padding-top: 15px;
        }

        .label {
          font-size: 16px;
          color: @gray1;
        }

        .icon {
          svg {
            width: 16px;
            height: 16px;
            fill: @gray7;
          }

          &:hover svg {
            fill: @gray2;
          }
        }
      }
    }
  }
  .show-more-options {
    cursor: pointer;
    font: @text-xs-regular;
  }
}

.autocomplete-with-checkbox, .dropdown-with-checkbox {
  line-height: 20px;

  &.wide {
    .filterable-checkbox-list {
      width: 450px;
    }
  }

  &.auto-width {
    .filterable-checkbox-list {
      width: auto;
    }
  }

  &.main-dropdown {
    .filterable-checkbox-list {
      z-index: @zindex-popover;
    }
  }

  &.right-aligned-menu {
    .filterable-checkbox-list {
      right: 0;
    }
  }
}

.filterable-checkbox-list {
  position: absolute;
  width: 300px;
  color: @gray5;
  border: 1px solid @black;
  border-radius: @border-radius-small;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: @gray-darker;
  z-index: @zindex-dropdown;
  text-align: left;

  &.scrollable-options {
    .filterable-checkbox-list-options {
      max-height: 300px;
      overflow-y: auto;
    }
  }

  .filter-box {
    width: 100%;
    color: @gray5;
    font-style: normal;
    font-weight: normal;

    &:focus {
      outline: none;
    }
  }

  .input-with-icon {
    padding: 8px 16px;
    border-bottom: 1px solid @grayBG;

    &:not(.normalize) {
      .filter-box {
        padding-left: 32px;
      }
    }

    &.normalize {
      input {
        font-style: normal;
        max-width: 90%;
      }
    }

    input {
      height: 24px;
    }

    .input-icon {
      svg {
        left: 17px;
      }

      &.right {
        svg {
          width: 14px;
          height: 14px;
          left: auto;
        }
      }
    }
  }

  hr {
    border-color: @grayBG;
    margin: 0;
  }

  .filterable-checkbox-list-options {
    padding: 12px 18px;
    overflow-y: auto;
    min-height: 60px;

    .options-separator {
      margin: 8px -18px;
    }

    &.extra-option {
      padding: 6px 18px;
    }

    &.full-list {
      min-height: 175px;
      max-height: 335px;
    }

    .checkbox-option {
      height: 24px;
      display: block;
      margin-top: 8px;
      font-size: @font-size-bodyText;

      .contained-label {
        display: inline-block;
        max-width: 100%;
        cursor: pointer;
        .text-ellipsis();
      }

      .label {
        font-weight: normal;
        vertical-align: middle;
      }

      .option-explanation {
        color:@gray-light2;
      }

      [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        width: 15px;
        height: 15px;
        border-radius: 2px;
      }

      [type="checkbox"]:not(:checked) + label svg, [type="checkbox"]:checked + label svg {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }

      [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
        padding-top: 0;
        padding-right: 30px;
      }
    }

    .dark-bg-scrollbar();
  }

  .dropdown-options {
    list-style: none;
    padding-left: 0;
  }

  .disabled-option {
    font-size: @font-size-bodyText;
    font-style: italic;
    color: @gray-light;
  }

  .buttons-section {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: flex-end;
    background-color: @body-bg;

    &.footer-link {
      justify-content: space-between;

      .link-style-button {
        border: none;
      }
    }

    .related-action {
      margin-right: auto;

      a {
        display: flex;
        align-items: center;
        font: @text-small-regular;

        svg {
          fill: @icon-active-color;
          .icon-medium();
        }
      }
    }

    .button.text-only {
      font-size: 15px;
      color: @gray5;
      border-style: none;
    }

    .button.button-small {
      min-width: unset;
      padding: 3px 12px;
    }

    .primary {
      border-radius: @border-radius-small;
      margin-left: 10px;
    }

    .button.link-style-button {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &.corsac-checkbox-list {
    border: @border-default;
    border-radius: @border-radius-default;
    box-shadow: @box-shadow-small-regular;

    .buttons-section {
      border-radius: 0 0 @border-radius-default @border-radius-default;
    }
  }
}

.filterable-checkbox-list.white {
  background-color: @white;
  color: @gray;

  &:not(.corsac-checkbox-list) {
    border: 1px solid @gray5;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  }

  .input-with-icon {
    border-bottom: 1px solid @gray-lighter;
  }

  .filter-box {
    color: @gray2;
  }

  .filterable-checkbox-list-options {
    .white-bg-scrollbar();

    .filter-box {
      border-bottom: 1px solid @gray-lightest;
      color: @gray2;
    }

    .dropdown-group-title {
      color: @text-color;
    }

    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
      border: 1px solid #BBBBBB;
    }

    .checkbox-option .disabled {
      color:@gray-light2;
      pointer-events: none;
    }
  }

  hr {
    border-color: @gray-lighter;
  }

  .button.text-only {
    color: @gray1;
  }
}

.autocomplete-with-checkbox, .dropdown-with-checkbox {
  user-select: none;

  &.form-multi-checkbox {
    .box-with-icon {
      .form-control;
      height: 40px;
      color: @gray2;
      margin-top: 10px;
      vertical-align: middle;

      svg {
        margin: 0 15px 0 3px;
        vertical-align: middle;
        .icon-medium();
        fill: @icon-weak-color;
      }

      .placeholder {
        font-size: 16px;
        vertical-align: text-top;
        color: @gray7;
        font-style: italic;
      }
    }
  }

  &.dropdown-with-top-label {
    .box-with-icon {
      margin-top: 0;
    }
  }

  &.limited-height {
    position: relative;

    .filterable-checkbox-list {
      position: absolute;
      width: 100%;
      margin: 0;
    }

    &.auto-width .filterable-checkbox-list {
      width: auto;
    }
  }
}

.control-label ~ .dropdown-with-top-label {
  .box-with-icon {
    margin-top: 5px;
  }
}
