.cta-card {
  height: 275px;
  margin: 10px 0;
  padding: 20px;
  background-color: @white;
  border: @light-gray-border;
  border-radius: @border-radius-small;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  text-align: center;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: @blue-link;
  }

  h3 {
    color: @blue-link;
    font-weight: @font-weight-semi-bold;
    font-size: @font-size-h3;
    margin: 10px 0;
  }

  .cta-card-icon {
    margin: 10px 0 20px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      vertical-align: middle;
      fill: @blue-link;
      height: 60px;
      width: 60px;
    }
  }
  
  .cta-link {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &.slack-cta svg {
    height: 40px;
    width: 156px;
  }

  &.gong-cta svg {
    height: 60px;
    width: 173px;
  }

  &.google-cta {
    svg {
      height: 40px;
      width: 53px;

      & ~ svg {
        margin-left: 10px;
        width: 40px;
      }
    }
  }

  &.microsoft-cta {
    svg {
      height: 50px;
      width: 52px;

      & ~ svg {
        margin-left: 10px;
      }
    }
  }
}
