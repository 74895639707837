.saved-goals-disabled-warning-view {
  display: flex;
  padding: 10px 20px;
  background-color: @background-weak-warning-color;
  font-size: @font-size-bodyText;
  font-weight: @font-weight-medium;
  color: @text-color;
  svg {
    flex: none;
    width: 20px;
    height: 20px;
    fill: @icon-warning-color;
    margin-right: 8px;
  }
}
