.section-header {
  font-size: @font-size-h2;
  line-height: @font-size-h2;
  color: @black;
  .bold();
  margin-top: @dashboard-header-margin;
  margin-bottom: @dashboard-header-margin;

  .section-header-title {
    color: @gray-dark;
    font-size: @dashboard-title-font-size;
    font-weight: @dashboard-title-font-weight;
    line-height: @dashboard-title-line-height;

    .dropdown-as-title {
      .dropdown-selection .title span {
        font-size: @dashboard-title-font-size;
        font-weight: @dashboard-title-font-weight;
        line-height: @dashboard-title-line-height;
      }
    }

    &.section-title-dropdown-group {
      display: flex;
      gap: @spacing-5;
      align-items: center;

      .dropdown {
        min-width: 0;
      }

      & ~ .section-header-sub-title {
        margin-top: 10px;
      }

      .sales-coach-button-container {
        flex-shrink: 0;

        &.sales-coach-dropdown-container {
          width: @nested-menu-width * 2 + @descriptive-dropdown-padding * 2;
        }

        .descriptive-dropdown-menu-list {
          max-height: 50vh;
        }

        .dropdown-menu-option {
          max-height: 40vh;
          overflow: auto;
          .white-bg-scrollbar();
        }
      }
    }
  }

  .section-header-secondary-actions {
    margin-top: @spacing-2;
  }

  &.ellipsized {
    .section-header-title,
    .saved-goal-title,
    .saved-alert-title,
    .opp-health-title {
      flex: 1;
      min-width: 0;
    }
  }
}

.section-header,
.page-with-float-actions {
  .section-header-sub-title {
    font-weight: @font-weight-medium;
    font-size: @font-size-bodyText;

    &.inline {
      display: inline-block;
    }
  }

  .section-header-explanation {
    color: @text-color-default;
    font-weight: @dashboard-sub-title-font-weight-thin;
    font-size: @font-size-bodyText;
    line-height: @dashboard-explanation-line-height;
  }

  .sub-title-section{
    color: @text-weak-color;
  }

  .alert-button {
    margin-left: 15px;
  }

  .action-buttons.saved-alert {
    font-size: 16px;
  }
}

@media only screen and (max-width:@page-width) {
  .section-header {
    font-size: @font-size-h3;
    line-height: @font-size-h2;
    margin-bottom: 20px;
  }
}

.section-header-action {
  fill: @gray1;
  text-align: right;

  &.section-header-action-icon {
    padding: 10px 0;
    line-height: 0;

    svg {
      height: 16px;
      width: 16px;
    }

    &:hover {
      fill: @actionGreen;
      cursor: pointer;
    }

    &:active {
      opacity: 0.8;
    }
  }

  .dropdown-combo .dropdown-selection {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .action-button {
    display: inline-block;
    font-size: 12px;
    margin-right: 5px;
    line-height: 16px;
    vertical-align: middle;
  }
}

@media only screen and (max-width:@page-width) {
  .section-header {
    .dropdown-combo.dropdown-with-caret .dropdown-selection > span {
      display: inline-block;
      min-width: 115px;
    }
  }
}
// *********************************************************************************************************************
// Multi card page
// *********************************************************************************************************************

.multi-card-page {
  background-color: @grayBG;
  min-width: 1230px;

  .page-content-container {
    .reorder-target-modal .modal-mask {
      z-index: @zindex-modal;
    }
  }

  .multi-card-section {
    padding: 0 20px 80px;

    .link-style-button {
      float: right;
    }
  }

  .multi-card-title {
    padding: 20px 0 30px;
    color: @white;

    > div {
      display: inline-block;
    }

    .title {
      padding-top: 10px;
      font-size: @font-size-h1;
    }
  }

  .multi-card {
    margin-top: 10px;
  }

  .main-page-container {
    .error {
      color: #000;

      .error-prefix {
        display: none;
      }

      .error-message {
        display: block;
      }
    }
  }

  .cards-container {
    .row {
      margin-right: -5px;
      margin-left: -5px;

      .flex-col,
      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12,
      .col-13,
      .col-14,
      .col-15,
      .col-16,
      .col-offset-1,
      .col-offset-2,
      .col-offset-3,
      .col-offset-4,
      .col-offset-5,
      .col-offset-6,
      .col-offset-7,
      .col-offset-8,
      .col-offset-9,
      .col-offset-10,
      .col-offset-11,
      .col-offset-12,
      .col-offset-13,
      .col-offset-14,
      .col-offset-15,
      .col-offset-16 {
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }
}

// *********************************************************************************************************************
// Dashboards tv mode
// *********************************************************************************************************************

.dashboard-tv-mode {
  .navigation-bar,
  .alert-header,
  .banner {
    display: none;
  }

  .resizeable-container {
    margin: 0;
    width: 100%;
  }

  .section-header {
    margin-top: 0;
    margin-bottom: 10px;

    .buttons-section .row {
      max-width: 100%;
    }

    .section-header-title {
      padding-top: 20px;
      font-size: 30px;

      .sub-display {
        color: @gray-light2;
        font-weight: 300;
      }
    }

    .logo {
      text-align: right;

      svg {
        opacity: 0.6;
      }
    }

    .buttons-section {
      text-align: center;
      background-color: @white;
      padding-top: 16px;
      padding-bottom: 16px;
      box-shadow: 0 1px 5px 0 rgba(0,0,0,0.08);

      &.hidden {
        display: none;
      }

      .button {
        width: 140px;

        &.secondary-white {
          margin-right: 7px;
        }

      }
    }
  }

  .toggle-container {
    margin: 0 100px 10px;
  }

  .change-page-button {
    top: 50%;
    position: absolute;
    transform: translate(0, -50%);

    &.left {
      left: 25px;
    }

    &.right {
      right: 25px;
    }
  }

  .section-header-title,
  .section-body {
    margin: 0 100px;
  }

  .single-card-view .preview {
    height: auto;
  }
}

.dropdown-more .nested-dropdown {
  .divider:last-child {
    display: none;
  }
}
