.login-page {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: @body-bg;

  .login-modal {
    width: 510px;
    margin: 80px auto;
    padding: 40px 40px 48px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: @border-radius-default;
    background: @white;
  }

  .login-modal-message-row {
    color: @text-color-bold;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
  }

  .login-modal-error-message-row {
    margin-top: 10px;
    display: flex;
    background-color: @background-active-color;
    border-radius: @border-radius-default;
    padding: 12px 44px 12px 16px;

    .login-modal-error-message {
      color: @text-color;
      font-weight: 400;
      font-size: 14px;
      padding-left: 12px;

      b {
        font-weight: 700;
      }
    }
  }

  .login-modal-logo-row {
    text-align: center;
    height: 40px;

    a, svg {
      vertical-align: middle;
    }
  }

  .login-page-button-row {
    display: block;
    margin: 24px auto 0 auto;
    border: 1px solid #4285F4;
    border-radius: @border-radius-small;
    width: 280px;
    font-size: 18px;
    font-weight: @font-weight-regular;
    padding: 3px;

    &:hover, &:focus {
      text-decoration: none;
    }

    .icon {
      display: inline-block;
      height: 46px;
      width: 46px;
      background: @white;
      margin-right: 15px;
    }

    &.google-login-button {
      background-color: #4285F4;

      &:hover {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
      }

      .icon {
        padding: 10px;
      }

      svg {
        height: 27px;
        width: 27px;
        vertical-align: middle;
      }

      .button-text {
        vertical-align: middle;
        color: @white;
      }
    }

    &.microsoft-login-button {
      &:hover {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
      }

      .icon {
        padding: 1px;
      }

      svg {
        height: 48px;
        width: 48px;
        vertical-align: middle;
      }

      .button-text {
        vertical-align: middle;
        color: #4285F4;
      }
    }

    &.sfdc-login-button {
      background-color: #00A1E0;

      &:hover {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
      }

      .icon {
        padding-top: 10px;
        padding-left: 10px;
        background-color: transparent;
      }

      svg {
        height: 29px;
        width: 43px;
        vertical-align: middle;
      }

      .button-text {
        vertical-align: middle;
        color: @white;
      }
    }
  }

  .secondary-login-options {
    display: block;
    text-align: center;
    font-size: 15px;
    cursor: pointer;

    &:hover, &:focus {
      text-decoration: none;
    }

    .button-text {
      color: #2A7DD2;
    }
  }
}
