.left-control-component-expanded {
  pointer-events: auto;

  &.left-control-component-with-mask {
    .people-section .people-options {
      padding: 0;

      span, svg {
        display: none;
      }
    }
  }
}

.left-control-content-component {
  line-height: 23px;

  .section-expanded {
    overflow-y: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: @border-radius-small;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: @gray-darker;
    }

    &.scrollbar {
      &::-webkit-scrollbar {
        width: 6px;
      }
    }
  }

  .text {
    max-width: 85%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    line-height: 17px;

    .label-name {
      display: inline-block;
      vertical-align: middle;
      .text-ellipsis();
    }

    .sub-name {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  .people-section {
    .people-selected, .people-options {
      display: block;

      &:hover {
        cursor: pointer;
      }

      &:hover .label-name {
        white-space: normal;
        text-overflow: clip;
        display: inline;
      }

      .full-name-tooltip {
        display: inline-block;
      }

      .color {
        width: 14px;
        height: 14px;
        display: inline-block;
        vertical-align: sub;

        &.circle {
          border-radius: @line-chart-legend-color-radius;
          background-color: @line-chart-legend-color-default-bg-color;
        }

        &.open-square {
          border: 3px solid;
          background-color: transparent;
        }

        &.triangle {
          width: 0;
          height: 0;
          border: solid transparent;
        }
      }

      .people-selected-view {
        padding: 5px 10px 5px 20px;
        display: inline-block;
        width: 100%;
      }
    }

    .people-actions {
      display: inline-block;
      position: fixed;
      z-index: @zindex-modal;
      left: @sidebar-expanded-width;

      span {
        width: 290px;
        display: block;
        padding: 5px 10px 5px 20px;

        a {
          text-decoration: none;
          display: block;
        }
      }
    }

    .people-options {
      padding: 5px 10px 5px 20px;

      svg {
        width: 16px;
        height: 16px;
        vertical-align: sub;
      }

      .read-only {
        font-style: italic;
        opacity: 0.6;
        cursor: default;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }

  .button-group {
    gap: 10px;
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: space-between;

    .button {
      flex: 1 0 auto;
      text-transform: uppercase;
      border-radius: 2px;
      padding: 0 13px;

      &.transparent {
        border-color: @gray2;
        color: @gray2;
      }

      &:hover {
        color: @text-on-dark-color;
        border-color: @text-on-dark-color;
        background-color: transparent;
      }

      &:last-child:not(:first-child) {
        margin-left: 0;
      }
    }
  }
  
  .filters-section {
    font-size: 13px;

    .selector {
      width: 100%;

      .filter-label {
        max-width: 90%;
        display: inline-block;
        vertical-align: middle;
        .text-ellipsis();
      }

      .remove-filter-tooltip {
        display: inline-block;
        float: right;

        .tooltip.right {
          margin-left: 0;
        }
      }
    }

    .dropdown-placeholder {
      .dropdown-selection {
        font-style: italic;
      }
    }

    .group-control {
      .section-item {
        text-transform: capitalize;
      }
    }

    .group-option-disabled {
      label span {
        text-decoration: line-through;
      }
      
      &.group-option-readable {
        label span {
          text-decoration: none;
        }
      }
    }

    .contained-label {
      display: inline-block;
      max-width: 100%;

      .sub-divided-filter-value {
        max-width: 90%;
        display: inline-block;
        vertical-align: middle;
        .ellipsis();
      }
    }

    .group-options-disabled-tooltip,
    .radio-button-tooltip {
      .tooltip.right {
        padding: 0;
        margin-left: 8px;

        .tooltip-arrow {
          left: -5px;
        }
      }
    }

    .group-link {
      padding-left: 25px;
      margin-top: 3px;

      span {
        cursor: pointer;
      }
    }

    .group-explanation {
      padding-right: 30px;
      padding-left: 20px;
      font-style: italic;
      line-height: 15px;

      .decorated-link {
        color: #3BCBDF;
      }
    }
  }
}

.sidebar-dark-bg {
  .left-control-content-component {
    .section-title {
      &.expanded {
        color: @white;

        svg {
          fill: @white;
        }
      }
    }

    .people-section {
      .people-selected, .people-options {
        &:hover {
          background-color: @gray-darker;
        }
      }
    }

    .people-actions {
      span {
        background-color: @gray-darker;

        &:hover {
          background-color: @gray-dark;
        }

        a {
          color: @gray7;
        }
      }
    }

    .section-expanded {
      &::-webkit-scrollbar-thumb {
        background: @gray;
      }
    }
  }

  .filters-section .sidebar-section-content {
    .dark-background-form;
  }

  .group-option-disabled, .group-option-not-editable {
    label span {
      color: @gray8;
    }
  }

  .group-link {
    color: @sidebar-dark-bg-very-light-font-color;

    &.disabled {
      color: @gray;
    }
  }
}

.sidebar-light-bg {
  .left-control-content-component {
    .people-section {
      .people-selected, .people-options {
        &:hover {
          background-color: @sidebar-light-bg-expanded-hover-bg-color;
          color: @sidebar-light-bg-expanded-hover-font-color;

          svg {
            fill: @sidebar-light-bg-expanded-hover-font-color;
          }
        }
      }
    }

    .people-actions {
      background-color: @sidebar-light-bg-expanded-hover-bg-color;

      span, a {
        color: @sidebar-light-bg-expanded-hover-font-color;

        &:hover {
          background-color: @sidebar-light-bg-expanded-hover-active-bg-color;
        }
      }
    }

    .section-expanded.scrollbar {
      &::-webkit-scrollbar-thumb {
        background: @gray5;
      }
    }

    .people-section,
    .filters-section {
      &.sidebar-section.sidebar-section-expanded::after {
        margin: 0;
      }
    }
  }

  .group-option-disabled, .group-option-not-editable {
    label span {
      color: @sidebar-light-bg-light-font-color;
    }
  }

  .group-link {
    &.disabled {
      color: @gray;
    }
  }
}

.sidebar-wide {
  .people-section .people-actions {
    left: @sidebar-expanded-wider-width;
  }
}

.sidebar-single-tab {
  .left-control-component-expanded {
    height: 100%;
  }

  .people-section {
    padding-top: @sidebar-single-tab-padding-top;
  }
}

.card-detail-view, .target-modal-container {
  .sidebar {
    background-color: @sidebar-dark-bg-color;
  }
}

.page-content-container .target-modal-container {
  .sidebar .sidebar-content {
    top: @target-modal-custom-dashboard-info-height;
    bottom: 0;
    position: fixed;

    .people-section {
      padding-top: 10px;
    }
  }

  &.modal-with-warning .sidebar-content {
    top: @target-modal-custom-dashboard-info-height + @target-modal-warning-height;
  }
}

.multi-card-container .sidebar {
  box-shadow: @sidebar-dark-bg-dark-box-shadow;
}
