
.dropdown {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  max-width: 100%;

  .caret {
    display: inline-block;
    margin-left: 10px;
    font-size: 5px;
    .triangle-down(@white);
    vertical-align: middle;
  }

  &.dropdown-grouped {
    margin-right: 2px;
  }

  .dropdown-not-loaded {
    padding-left: 5px;
    display: inline-block;

    svg {
      width: 22px;
      height: 22px;
      fill: @gray7;
      text-align: center;
    }
  }

  &.disabled {
    > span {
      width: 100%;
    }

    .dropdown-selection {
      pointer-events: none;
      .text-ellipsis()
    }

    .caret {
      display: none;
    }
  }

  &.italic {
    .dropdown-selection {
      font-style: italic;
    }
  }

  &.main-dropdown {
    .dropdown-menu-container {
      z-index: @zindex-popover;
    }
  }
}

.corsac-dropdown {
  width: 100%;

  .dropdown-selection {
    display: flex;
    font: @text-base;
    padding: 4px 6px;
    color: @text-color;
    align-items: center;
    border: @border-default;
    justify-content: space-between;
    border-radius: @border-radius-default;

    &:before {
      content: none;
    }
    svg {
      .icon-small();
      fill: @icon-color;
    }
  }

  &.dropdown-with-caret {
    .dropdown-selection {
      > span,.label {
        max-width: calc(~"100% - 22px"); // 22px is the width of the caret icon
      }
    }
  }

  &.borderless {
    width: auto;
    text-decoration: none;

    .dropdown-selection {
      padding: 0;
      border: none;
      font: @text-small-regular;
      justify-content: flex-start;

      svg {
        margin-left: 6px;
      }
    }
  }

  .placeholder,
  span.placeholder {
    font-style: normal;
    color: @text-weak-color;
  }

  &.fixed-wide-dropdown .dropdown-menu-container {
    max-width: 600px;
  }

  .dropdown-menu-container {
    border: @border-default;
    border-radius: @border-radius-default;
    box-shadow: @box-shadow-small-regular;

    .dropdown-menu {
      border-radius: @border-radius-default;

      .dropdown-group-title {
        color: @black;
        padding-left: 10px;
      }

      .dropdown-options {
        margin: 0;
        padding: 8px;

        .dropdown-option-link {
          width: 100%;
          display: block;
          padding: 3px 8px;
          color: @text-color;

          .ellipsis();

          &:hover {
            color: @link-color;
            text-decoration: none;

            &:not(.disabled-option) {
              border-radius: @border-radius-small;
              background-color: @background-medium-color;
            }
          }
        }
      }
    }
  }
}

.dropdown-selection {
  display: block;
  background-color: @black;
  color: @white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-radius: 25px;
  font-weight: normal;

  &:hover {
    background-color: @gray-base;
  }

  &:active {
    opacity: 0.8;
  }

  &:before {
    content: "\200B";
  }

  svg {
    width: 20px;
    height: 20px;
    fill: white;
    vertical-align: middle;
  }
}

.dropdown-with-no-data {
  cursor: auto;
}

.dropdown-menu-container {
  position: absolute;
  white-space: nowrap;
  top: 100%;
  background-color: @dropdown-menu-background-color;
  border-radius: @dropdown-menu-border-radius;
  box-shadow: @dropdown-menu-box-shadow;
  z-index: @zindex-dropdown;
  float: left;
  font-weight: 200;
  &.right {
    left: auto;
    right: 0;
  }
}

.dropdown-menu {
  margin: 2px 0 0;
  font-size: @font-size-bodyText;
  list-style: none;
  color: #696969;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  &.scrollable-options .dropdown-options {
    max-height: 400px;
    overflow-y: auto;
  }

  .filter-box-container {
    margin-bottom: 5px;
  }

  .filter-box {
    display: block;
    line-height: 32px;
    white-space: nowrap;
    margin-top: 0;
    font-style: italic;
    padding: 5px 20px;
    color: white;
    box-shadow: none;
    height: auto;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .dropdown-input {
    .filter-box {
      padding: 10px 20px;
    }

    .input-icon.right svg {
      margin-top: 18px;
    }
  }

  .dropdown-title,
  .dropdown-extra-options,
  &:not(.corsac-dropdown-menu):not(.descriptive-dropdown-menu) .dropdown-options {
    list-style: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin: 0;
    padding: 0;

    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
    }

    > li .dropdown-option-link,
    > .menu-title,
    > li > .empty,
    > li > span {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      // TODO: verify it is ok that I removed padding: 3px 20px;
      clear: both;
      line-height: 32px;
      white-space: nowrap;
      text-align: center;
    }

    > li .dropdown-option-link {
      color: @dropdown-menu-link-color;

      &.dropdown-info-option {
        font-style: italic;
      }

      &[disabled] {
        opacity: @dropdown-menu-inactive-links-opacity;
      }
    }

    > li > .disabled-option {
      color: @gray-lighter;
      padding-top: 5px;
    }

    > .menu-title {
      .dropdown-header();
      border-bottom: 1px solid #fff;
      margin-bottom: 5px;
    }

    > li .dropdown-option-link {
      > span {
        display: flex;
        align-items: center;
        .text-ellipsis();

        svg {
          flex: none;
          .icon-small();
          fill: @icon-color;
          margin-right: 5px;
        }
      }

      &.sales-coach-option > span {
        svg {
          fill: @icon-marketing-fuchsia-color;
        }
      }
    }

    > li .dropdown-option-link:hover,
    > li.active .dropdown-option-link {
      font-weight: @font-weight-thin;
      padding-left: 0;
      padding-right: 0;
      text-decoration: none;
      background-color: @dropdown-menu-background-color-hover;

      > span {
        padding-left: 10px;
        margin-left: 10px;
        padding-right: 10px;
        margin-right: 10px;
      }
    }

    > li > a:active {
      color: @dropdown-menu-color-active;
      background-color: @dropdown-menu-background-color-active;
    }

    > li > a:focus {
      text-decoration: none;
    }

    > li > .empty {
      height: 26px;
      &:before {
        content: "\200B";
      }
    }

    &:focus {
      outline: none;
    }


    .label-with-icon svg {
      vertical-align: middle;
      margin-right: 6px;
    }
  }

  .dropdown-options {
    overflow-y: auto;
    .dark-bg-scrollbar();
  }

  .dropdown-extra-options {
    padding-bottom: 10px;
  }

  .dropdown-menu-option-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    fill: @dropdown-menu-link-color;
    vertical-align: middle;
  }

  .dropdown-explanation {
    color:@gray-light2;
    font-weight: @dashboard-sub-title-font-weight-thin;
  }
}

.dropdown-menu-divider {
  margin: 10px 15px;
  height: 1px;
  overflow: hidden;
  background-color: #555555;
}

.dropdown-left-aligned-options {
  .dropdown-menu {
    .dropdown-title,
    .dropdown-options,
    .dropdown-extra-options {
      > li .dropdown-option-link,
      > .menu-title,
      > li > span,
      > li > .empty {
        text-align: left;
      }
    }
  }
}

.small {
  .dropdown-selection {
  }

  .dropdown-menu {
    .dropdown-title,
    .dropdown-options,
    .dropdown-extra-options {
      > li > a,
      > .menu-title,
      > li > .empty {
      }

      > .menu-title {
        padding: 5px 10px;
      }

      > li > .empty {
        height: 26px;
      }
    }
  }
}

.x-small {
  .dropdown-menu {
    .dropdown-title,
    .dropdown-options,
    .dropdown-extra-options {
      > li > a,
      > .menu-title,
      > li > .empty {
      }

      > .menu-title {
        padding: 5px 10px;
      }

      > li > .empty {
        height: 26px;
      }
    }
  }
}

@media only screen and (min-width: 64em) {

  .multi-column-dropdown {
    .dropdown-menu {
      display: inline-block;
      margin-left: 2px;
      box-shadow: none;
      padding-left: 20px;
      padding-right: 20px;
    }

    .dropdown-menu:nth-child(1) {
      margin-left: 0;
    }
  }
}

.dropdown-group {
  .grouped-dropdown {
    margin-left: 0;
    margin-right: 0;
    .dropdown-selection {
        border-radius: 0;
    }

    &:first-child {
      .dropdown-selection {
        padding-right: 5px;
      }
    }

    &:last-child {
      .dropdown-selection {
        padding-left: 5px;
      }
    }
  }
}

.scrollable-dropdown {
  .dropdown-menu-container {

    .panel-container {
      margin-top: -13px;
    }

    .scrolling-element-wrapper {
      font-size: .5rem;
      background-color: @dropdown-menu-background-color;
      padding: 7px 0;

      &.hit-area-entire-wrapper {
        &.scrolling-element-visible {
          &:hover {
            background-color: @dropdown-menu-background-color-hover;

            &.scroll-up,
            &.scroll-down,
            &.scroll-left,
            &.scroll-right {
              .scroll-triangle {
                border-bottom-color: @dropdown-menu-color-hover;
              }
            }
          }

          &.scroll-up,
          &.scroll-down,
          &.scroll-left,
          &.scroll-right {
            .scroll-triangle {
              border-bottom-color: @gray2;
            }
          }

        }
      }
    }

    .dropdown-menu {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.btn-group .dropdown-menu-container-wrapper {
  display: none;
}

.button-group .dropdown-menu-container-wrapper {
  display: none;
}

.dropdown-toggle:hover + .dropdown-menu-container-wrapper, .dropdown-menu-container-wrapper:hover {
  display: block;
}

.dropdown-menu-container-wrapper {
  padding-top: 10px;

  .dropdown-menu-container {
    margin-top: 0;
  }
}

.dropdown-combo {

  &.full-size-width .dropdown-menu-container {
    width: 100%;
  }

  &.disabled &.borderless .dropdown-selection {
    background-color: @whiteBG;
  }

  .dropdown-selection {
    background-color: @white;
    font-size: @font-size-h4;
    text-align: left;

    .caret {
      border-bottom-color: @dropdown-combo-caret-color;
    }

    .title .info {
      color: @dropdown-white-bg-info-color;
      font-style: italic;
    }
  }

  &.loud-dropdown {
    .dropdown-selection {
      background-color: @background-medium-color;
    }
  }

  &:not(.corsac-dropdown) {
    .dropdown-selection {
      padding: 10px;
      border-radius: 3px;
      border: 1px solid @gray5;
      color: @dropdown-white-bg-font-color;

      .dropdown-label-svg {
        padding: 5px 10px;
      }

      svg {
        width: 30px;
        height: 30px;
        fill: @gray;
      }
    }

    &.dropdown-with-caret .dropdown-selection {
      padding-right: 10px;
    }
  }

  &.dropdown-with-caret {
    &.x-small-dropdown {
      max-width: 60px;
    }

    &.small-dropdown {
      max-width: 80px;
    }

    &.medium-dropdown {
      max-width: 130px;
    }

    &.x-small-dropdown,
    &.small-dropdown {
      .dropdown-selection {
        padding-left: 10px;

        span {
          max-width: calc(~"100% - 15px");
        }
      }

      .caret {
        margin-left: 5px;
      }
    }
  }

  &:not(.borderless).dropdown-active {
    .dropdown-selection {
      background-color: @gray6;

      &:hover {
        background-color: @body-bg;
      }

      .caret {
        border-bottom-color: @gray2;
      }
    }

    .dropdown-menu-container {
      border: 1px solid @gray5;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &.dropdown-disabled {
    .dropdown-selection {
      background-color: transparent;
      pointer-events: none;

      .caret {
        border-bottom-color: transparent;
      }
    }
  }

  &.dropdown-with-caret .dropdown-selection {
    white-space: nowrap;

    > span {
      display: inline-block;
      max-width: calc(~"100% - 20px");
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }

  &.dropdown-selection-without-border .dropdown-selection {
    border: none;

    > span {
      width:auto;
    }
  }

  .dropdown-menu-container {
    background-color: @white;
    min-width: 100%;

    .dropdown-menu {
      background-color: @white;
      font-size: 15px;

      .dropdown-options {
        .white-bg-scrollbar();
      }

      .dropdown-title,
      .dropdown-extra-options,
      &:not(.corsac-dropdown-menu) .dropdown-options {
        > li .dropdown-option-link {
          color: @black;

          &[disabled] {
            opacity: @dropdown-menu-inactive-links-opacity;
          }
        }

        > li.active .dropdown-option-link,
        > li .dropdown-option-link:hover {
          > span {
            background-color: @whiteBG;
            border-radius: 0;
            margin-left: 0;
            margin-right: 0;
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        > li .dropdown-option-link,
        > .menu-title,
        > li > .empty {
          text-align: left;
        }

        .dropdown-group-title {
          color: @black;
        }
      }

      .disabled-option {
        text-align: left;
      }

      .dropdown-menu-divider {
        background-color: @gray-lightest;
      }

      .dropdown-label {
        color: @black;
        .expand {
          border-right: @nested-dropdown-arrow-light-bg;
          border-top: @nested-dropdown-arrow-light-bg;
        }
      }

      .dropdown-option-container {

        &:hover {
          background-color: @whiteBG;
        }

        .dropdown-menu-option {
          background-color: white;

          .dropdown-menu-option-label {
            color: @black;

            &[disabled] {
              opacity: @dropdown-menu-inactive-links-opacity;
            }

            &:hover {
              background-color: @body-bg;
            }
          }

        }
      }
    }
  }

  .dropdown-menu .filter-box {
    color: @gray2;
    font-style: italic;
  }

  &.dropdown-title-with-info {
    .dropdown-selection {
      color: @black;

      .caret {
        border-bottom-color: @black;
      }
    }

    .info {
      color: @gray1;
      font-size: 14px;
      font-weight: 100;
      padding-left: 4px;
    }
  }

}

.expandable-option-container {

  .expandable-option {
    margin: 0 15px 20px 15px;

    &:hover {
      cursor: pointer;
    }
  }

  .expandable-option-buttons {
    float:right;
    margin: 0 15px 20px 15px;
  }
}

.left-aligned-menu-options {
  .dropdown-menu-container .dropdown-menu {
    .dropdown-label {
      text-align: left;
    }

    .dropdown-menu-option {
      left: auto;
      right: 100%;
      text-align: left;
      min-width: 223px;
    }
  }

  .nested-dropdown .dropdown-option-container .dropdown-menu-option .dropdown-menu-option-label {
    width: 100%;
  }
}

.dropdown-menu-loading {
  color: black;
  cursor: default;

  svg {
    fill: @black;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }
}

.dropdown-white-dropdown {
  &.dropdown-active {
    .dropdown-selection {
      background-color: @dropdown-white-menu-background-color-hover;
    }
  }

  .dropdown-selection {
    background-color: transparent;
    color: @black;

    .info {
      color: @gray2;
      font-weight: 100;
      text-transform: lowercase;
    }

    .caret {
      border-bottom-color: inherit;
    }
  }

  .dropdown-menu-container {
    background-color: @dropdown-white-menu-background-color;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
    border: 1px solid @gray-lightest;

    .dropdown-menu {
      background-color: @dropdown-white-menu-background-color;
      font-size: @font-size-h4;

      .dropdown-options,
      .dropdown-extra-options {
        > li .dropdown-option-link {
          color: @black;

          &[disabled] {
            opacity: @dropdown-menu-inactive-links-opacity;
          }

          &:hover > span {
            background-color: @dropdown-white-menu-background-color-hover;
            border-radius: 0;
            margin-left: 0;
            margin-right: 0;
            padding-left: 20px;
            padding-right: 20px;
            color:@black;
          }
        }

        > li .dropdown-option-link,
        > .menu-title,
        > li > span,
        > li > .empty {
          text-align: left;
        }

        > li .dropdown-option-link:hover,
        > li.active .dropdown-option-link {
          background-color: @dropdown-white-menu-background-color-hover;
        }

        .dropdown-group-title {
          color: @black;
        }
      }

      .dropdown-menu-divider {
        background-color: @gray-lightest;
      }
    }

    .filter-box {
      color: @black;
    }
  }

  .nested-dropdown {
    .dropdown-label {
      color: @black;

      .expand {
        border-right: @nested-dropdown-arrow-light-bg;
        border-top: @nested-dropdown-arrow-light-bg;
      }
    }

    .dropdown-option-container {

      &:hover {
        background-color: @dropdown-white-menu-background-color-hover;
      }

      .dropdown-menu-option {
        left: auto;
        background-color: @dropdown-white-menu-background-color;

        .dropdown-menu-option-label {
          width: auto;
          color: @black;

          &:hover {
            background-color: @dropdown-white-menu-background-color-hover;
          }
        }
      }
    }
  }

  &.scrollable-dropdown {
    .scrolling-element-wrapper {
      background-color: @dropdown-white-menu-background-color;

      &.hit-area-entire-wrapper.scrolling-element-visible {
        &:hover {
          background-color: @dropdown-white-menu-background-color-hover;
        }
      }
    }
  }

  &.dropdown-more-label {
    .dropdown-selection{
      background-color: transparent;
      padding: 0;
      &.dropdown-label-svg {
        svg {
          fill: @gray2;
        }
      }
    }
  }
}
.right-aligned-menu {
  .dropdown-menu-container {
    right: 0;
  }
}

.dropdown-scrollable {
  .dropdown-menu-container{
    max-height: 300px;
    overflow-y: auto;
  }
}

.nested-dropdown {
  min-width: @nested-menu-width;

  .dropdown-option-container {
    display: block;

    &:hover {
      background-color: #111111;
      cursor: pointer;
    }
  }

  .dropdown-menu-option {
    display: inline-block;
    position: absolute;
    left: @nested-menu-width;
    border-radius: @border-radius-small;
    background-color: @black;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

    .dropdown-menu-option-label {
      width: @nested-menu-width;
      display: block;
      padding: 5px 20px;
      color: @nested-menu-text-color;
      text-decoration: none;

      &.disabled {
        pointer-events: none;
      }

      &:hover {
        background-color: #111111;
        cursor: pointer;
      }
    }
  }

  .dropdown-label-container {
    display: inline-block;
    width: 100%;
  }

  .dropdown-label {
    padding: 5px 20px;
    color: @nested-menu-text-color;
  }

  .dropdown-label-expandable {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      .text-ellipsis();
    }

    .expand {
      width: 8px;
      height: 8px;
      border-right: @nested-dropdown-arrow-dark-bg;
      border-top: @nested-dropdown-arrow-dark-bg;
      transform: rotate(45deg);
      display: inline-block;
      flex-shrink: 0;
    }
  }
}

.expandable-option-container {
  .dropdown-label{
    display: inline-block;
    width: 100%;
    padding: 5px 20px;
    color: #CCC;
  }
}
.dropdown {
  &.smart-positioning {
    .dropdown-menu {
      position: fixed;
      z-index: @zindex-dropdown;
      background-color: @dropdown-menu-background-color;
      border-radius: @dropdown-menu-border-radius;
      box-shadow: @dropdown-menu-box-shadow;
      margin: 0;

      &.above-dropdown {
        box-shadow: @dropdown-menu-reverse-box-shadow;
      }
    }

    .dropdown-menu-container {
      position: static;
    }

    &.dropdown-combo,
    &.dropdown-white-dropdown,
    &.dropdown-transparent {
      .dropdown-menu-container {
        border: none;
      }

      .dropdown-menu {
        background-color: #FFFFFF;
        border: 1px solid #DDDDDD;
      }
    }
  }
}

.dropdown-menu-container-wrapper {
  .dropdown-menu .dropdown-options {
    padding: 0;
  }
}

.dropdown-with-checkbox {
  max-width: 100%;

  .dropdown {
    max-width: 100%;
    min-width: 150px;

    &.dropdown-as-title,
    &.corsac-dropdown.borderless {
      min-width: auto;
    }
  }

  .placeholder {
    font-style: italic;
    color: @gray2;
  }
}

.dropdown-as-title {
  .dropdown-left-aligned-options;

  &:not(.corsac-dropdown) .dropdown {
    text-decoration: underline;
    text-decoration-color: @gray-lightest;
  }

  .dropdown-selection,
  &.dropdown-active .dropdown-selection {
    background-color: transparent;
  }

  &.ellipsized-title {
    .dropdown-selection {
      display: flex;
      align-items: center;
    }

    .title {
      display: inline-block;
      flex: 1;
      .text-ellipsis();
    }
  }

  .caret {
    border-bottom: 1em solid @black;
  }

  .dropdown-selection, .dropdown-selection .title  {
    font-weight: 500;
    font-size: @font-size-h2;
    padding: 0;
    border-radius: 0;
  }

  .dropdown-explanation {
    font-style: italic;
  }

  .dropdown-menu .dropdown-extra-options {
    > li .dropdown-option-link {
      line-height: 38px;
    }
  }

  .nested-dropdown {
    line-height: 28px;
  }

  &.hidden-dropdown {
    .dropdown-selection {
      display: inline-flex;
      align-items: baseline;
    }

    .caret {
      display: none;
    }

    &.dropdown {
      text-decoration: none;
    }

    &:hover, &.dropdown-active {
      .caret {
        display: inline-block;
        border-bottom: 1em solid #000000;
        opacity: 0.5;
      }

      &.dropdown {
        text-decoration: underline;
        text-decoration-color: @gray-lightest;
      }
    }
  }
}

.dropdown-transparent {
  .dropdown-white-dropdown;

  &.dropdown-active .dropdown-selection {
    background-color: transparent;
  }

  .caret {
    border-bottom: 1em solid @text-color-light;
  }

  .dropdown-selection, .dropdown-selection .title  {
    padding: 0;
    border-radius: 0;
  }

  .dropdown-selection {
    background-color: transparent;
    color: @black;
  }

  .dropdown-menu-container {
    background-color: @background-white-color;
  }

  .dropdown-explanation {
    font-style: italic;
  }

  .default-label {
    font-style: italic;
    color: @gray2;
    font-weight: @font-weight-thin;
  }
}

.dropdown-group-title {
  font-weight: @font-weight-semi-bold;
  color: @white;
  text-transform: uppercase;
}

.dropdown, .descriptive-dropdown {
  .grouped-dropdown-menu, .nested-dropdown {
    .dropdown-group-title {
      font-size: @font-size-bodyText;
      cursor: default;
      &:first-child {
        margin-top: 10px;
      }
    }

    .options-separator {
      margin-top: 15px;
      margin-bottom: 15px;
      border-color: @gray-lighter;
    }
  }
}

.dropdown-selection .searchable-dropdown-label {
  font-style: italic;
  opacity: 0.6;
  line-height: 28px;

  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    fill: @icon-weak-color;
  }
}

.dropdown-input {
  position: relative;
  border-bottom: @dropdown-input-border-dark-bg;

  &:not(.normalize) {
    .filter-box {
      padding-left: 45px;
    }
  }

  &.normalize {
    input {
      font-style: normal;
      max-width: 90%;
    }
  }

  input {
    height: 50px;
  }

  .input-icon {
    svg {
      position: absolute;
      width: 14px;
      height: 14px;
      fill: @gray5;
      top: 0;
    }

    &.right svg {
      left: auto;
      right: 15px;
      cursor: pointer;
    }

    &.search-icon svg {
      .icon-medium();
      top: 8px;
      left: 14px;
      margin-top: 6px;
      fill: @icon-weak-color;
    }
  }
}

.dropdown-white-dropdown,
.dropdown-combo {
  .dropdown-input {
    border-bottom: @dropdown-input-border-light-bg;
  }
}

.dropdown-medium-menu-container {
  .dropdown-options {
    min-width: 200px;
  }
}

.dropdown-extra-wide-menu {
  .dropdown-menu {
    width: 600px;
  }
}

.removable-dropdown {
  .flexbox;
  flex-wrap: nowrap;
  align-items: center;

  .dropdown {
    max-width: 85%;
  }

  .removable-option {
    margin: 0 10px;
    cursor: pointer;
    flex-shrink: 0;
    width: @icon-default-size;
    height: @icon-default-size;
  }
}
.filter-dropdown-menu {
  .dropdown-options {
    li span {
      text-align: left;
    }
  }
  .dropdown-explanation {
    font-style: italic;
  }
}
.shortcut-dropdown {
  .shortcut-dropdown-option {
    color: @gray1;
    font-size: @header-font-size;
    font-weight: @header-font-weight;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 7px;
      vertical-align: middle;
      fill: @gray1;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      line-height: 15px;
    }
  }
}

.filterable-nested-dropdown-menu {
  .dropdown-menu.grouped-dropdown-menu.descriptive-dropdown-menu {
    width: auto;
  }

  .dropdown-menu .dropdown-input {
    border-color: @border-color;

    .filter-box {
      font: @text-base;
      color: @text-color;
      line-height: @spacing-6;
      padding: @spacing-2 @spacing-4;
    }

    &:not(.normalize) {
      .filter-box {
        padding-left: 45px;
      }
    }

    .input-icon.search-icon svg {
      top: @spacing-1;
    }

    .input-icon.right svg {
      margin-top: 14px;
    }
  }

  .grouped-dropdown-menu .dropdown-options {
    padding: 0;
    list-style: none;
    font: @text-base;
    margin: @spacing-1 0;

    li > span {
      white-space: nowrap;
      line-height: @spacing-8;
      padding: @spacing-2 @spacing-4;
    }

    .dropdown-group-title, .dropdown-option-link {
      white-space: nowrap;
    }

    .dropdown-group-title {
      color: @text-color;

      span {
        line-height: @spacing-8;
        padding-left: @spacing-5;
      }
    }

    .dropdown-option-link {
      padding: 0 @spacing-5;

      span {
        line-height: @spacing-8;
      }

      &:hover:not(.disabled-option) {
        border-radius: unset;
      }
    }
  }
}
