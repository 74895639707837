.footer {
  height: @footer-height;

  &.thinner-footer .footer-content {
    padding: @footer-thinner-padding;
  }

  .footer-content {
    color: @footer-color;
    font-size: @footer-font-size;
    background-color: @footer-bg-color;
    width: 100%;
    padding: @footer-padding;
    position:absolute;
    bottom:0;
    text-align: center;
    line-height: @footer-line-height;

    .container {
      height: @footer-line-height;
    }

    .decorated-link {
      display: inline-block;
      color: @text-color-default;
      text-decoration-color: @gray5;
    }

    .link-separator {
      margin: 10px;
    }

    .atrium-logo.icon-svg {
      fill: @text-color-default;
    }

    a, span {
      vertical-align: middle;
    }
  }
}
