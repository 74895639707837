.triangle(@angle, @color, @size) {
  width: 0;
  height: 0;
  border-left: @size solid transparent;
  border-right: @size solid transparent;
  border-bottom: @size solid @color;
  transform: rotate(@angle);
  display: inline-block;
}

.triangle-up(@color) {
  .triangle(0deg, @color, 1.0em);
}

.triangle-down(@color) {
  .triangle(180deg, @color, 1.0em);
}

.triangle-right(@color) {
  .triangle(90deg, @color, 1.0em);
}

.triangle-left(@color) {
  .triangle(270deg, @color, 1.0em);
}

.triangle-up-dynamic(@color, @size) {
  .triangle(0deg, @color, @size);
}

.triangle-down-dynamic(@color, @size) {
  .triangle(180deg, @color, @size);
}

.triangle-right-dynamic(@color, @size) {
  .triangle(90deg, @color, @size);
}
