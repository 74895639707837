.tooltip {
  position: fixed;
  z-index: @zindex-tooltip;
  display: inline-block;//block;
  font: @text-xs-regular;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  opacity: 1;
  line-break: auto;
  filter: drop-shadow(0 4px 15px rgba(0,0,0,0.3));

  &.bottom {
    padding: 5px 0;
    top: 100%;
    transform: translateX(-50%);

    .tooltip-arrow {
      top: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: @background-dark-color;
    }
  }

  &[class^="bottom-"], &[class*="bottom-"] {
    .bottom;
  }

  &.bottom-right {
    transform: translateX(-@tooltip-arrow-position);
    right: unset;

    .tooltip-arrow {
      right: unset;
      left: 10px;
    }

    .tooltip-inner {
      border-top-left-radius: 0;
    }
  }

  &.bottom-left {
    transform: translateX(calc(~"-100% + @{tooltip-position-correction}"));
    left: unset;

    .tooltip-arrow {
      left: unset;
      right: 10px;
    }

    .tooltip-inner {
      border-top-right-radius: 0;
    }
  }

  &.top {
    padding: 5px 0;
    transform: translateX(-50%) translateY(-100%);

    .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 5px 5px 0;
      border-top-color: @background-dark-color;
    }
  }

  &[class^="top-"], &[class*="top-"] {
    .top;
  }

  &.top-left {
    transform: translateX(calc(~"-100% + @{tooltip-position-correction}")) translateY(-100%);
    left: unset;

    .tooltip-arrow {
      left: unset;
      right: 10px;
    }

    .tooltip-inner {
      border-bottom-right-radius: 0;
    }
  }

  &.top-right {
    transform: translateY(-100%) translateX(-@tooltip-arrow-position);
    right: unset;

    .tooltip-arrow {
      right: unset;
      left: 10px;
    }

    .tooltip-inner {
      border-bottom-left-radius: 0;
    }
  }

  &.right {
    padding: 0 5px;
    transform: translateY(-50%);

    &.with-margin {
      margin-left: 4px;
    }

    .tooltip-arrow {
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-width: 5px 5px 5px 0;
      border-right-color: @background-dark-color;
    }
  }

  &[class^="right-"], &[class*="right-"] {
    .right;
  }

  &.right-bottom {
    transform: translateY(-@tooltip-arrow-position);

    .tooltip-arrow {
      top: 10px;
    }

    .tooltip-inner {
      border-top-left-radius: 0;
    }
  }

  &.right-top {
    transform: translateY(calc(~"-100% + @{tooltip-position-correction}"));

    .tooltip-arrow {
      bottom: 10px;
      top: unset;
    }

    .tooltip-inner {
      border-bottom-left-radius: 0;
    }
  }

  &.left {
    padding: 0 5px;
    transform: translate(-100%, -50%);

    .tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -5px;
      border-width: 5px 0 5px 5px;
      border-left-color: @background-dark-color;
    }
  }

  &[class^="left-"], &[class*="left-"] {
    padding: 0 5px;
    transform: translateX(-100%);

    .tooltip-arrow {
      right: 0;
      margin-top: -5px;
      border-width: 5px 0 5px 5px;
      border-left-color: @background-dark-color;
    }
  }

  &.left-top {
    transform: translateX(-100%) translateY(calc(~"-100% + @{tooltip-position-correction}"));

    .tooltip-arrow {
      bottom: 10px;
    }

    .tooltip-inner {
      border-bottom-right-radius: 0;
    }
  }

  &.left-bottom {
    transform: translateX(-100%) translateY(-@tooltip-arrow-position);
    .tooltip-arrow {
      top: 10px;
    }

    .tooltip-inner {
      border-top-right-radius: 0;
    }
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }


  .tooltip-inner {
    p:first-child {
      margin-top: 0px;
    }

    p:last-child {
      margin-bottom: 0px;
    }

    max-width: 200px;
    min-width: 150px;
    padding: 8px 12px;
    color: @text-on-dark-color;
    text-align: center;
    background-color: @background-dark-color;
    border-radius: @border-radius-default;
    word-wrap: break-word;

    a {
      cursor: pointer;
      display: inline-block;
      color: @text-active-on-dark-color;
    }
  }

  &.white {
    &[class^="bottom-"], &[class*="bottom-"], &.bottom {
      .tooltip-arrow {
        border-bottom-color: #fff;
      }
    }

    &[class^="top-"], &[class*="top-"], &.top {
      .tooltip-arrow {
        border-top-color: #fff;
      }
    }

    &[class^="right-"], &[class*="right-"], &.right {
      .tooltip-arrow {
        border-right-color: #fff;
      }
    }

    &[class^="left-"], &[class*="left-"], &.left {
      .tooltip-arrow {
        border-left-color: #fff;
      }
    }

    .tooltip-inner {
      color: @text-color;
      background-color: @background-white-color;
      font-weight: 200;
      
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.no-shadow {
    filter: none;
  }

  &.gray {
    &[class^="bottom-"], &[class*="bottom-"], &.bottom {
      .tooltip-arrow {
        border-bottom-color: #888888;
      }
    }

    &[class^="top-"], &[class*="top-"], &.top {
      .tooltip-arrow {
        border-top-color: #888888;
      }
    }

    &[class^="right-"], &[class*="right-"], &.right {
      .tooltip-arrow {
        border-right-color: #888888;
      }
    }

    &[class^="left-"], &[class*="left-"], &.left {
      .tooltip-arrow {
        border-left-color: #888888;
      }
    }

    .tooltip-inner {
      color: @text-on-dark-color;
      background-color: #888888;
    }
  }

  &.auto-width {
    .tooltip-inner {
      max-width: none;
      min-width: 0;
      width: auto;
    }
  }

  &.small-tooltip {
    .tooltip-inner {
      max-width: 250px;
      min-width: 100px;
    }
  }

  &.small-medium-tooltip {
    .tooltip-inner {
      max-width: unset;
      width: 290px;
      padding: 20px;
    }     
  }

  &.adjusted {
    .tooltip-inner {
      max-width: unset;
      min-width: unset;
      white-space: nowrap;
      padding: 5px 10px;
    }
  }

  &.small-wide-tooltip {
    .tooltip-inner {
      max-width: 350px;
      min-width: 250px;
      padding: 15px 20px;
    }
  }

  &.medium-wide-tooltip {
    .tooltip-inner {
      max-width: 400px;
      min-width: 280px;
      padding: 20px;
    }
  }

  &.wide-tooltip {
    &[class^="bottom-"], &[class*="bottom-"], &.bottom {
      padding: 8px 0;

      .tooltip-arrow {
        margin-left: -10px;
        border-width: 0 10px 10px;
      }
    }

    &[class^="top-"], &[class*="top-"], &.top {
      padding: 8px 0;

      .tooltip-arrow {
        margin-left: -8px;
        border-width: 8px 8px 0;
      }
    }

    &[class^="right-"], &[class*="right-"], &.right {
      padding: 0 8px;

      .tooltip-arrow {
        margin-top: -8px;
        border-width: 8px 8px 8px 0;
      }
    }

    &[class^="left-"], &[class*="left-"], &.left {
      padding: 0 8px;

      .tooltip-arrow {
        margin-top: -8px;
        border-width: 8px 0 8px 8px;
      }
    }

    .tooltip-inner {
      max-width: 500px;
      min-width: 350px;
      padding: 15px;
    }
  }

  &.extra-wide-tooltip {
    .tooltip-inner {
      width: 450px;
      max-width: initial;
      min-width: initial;
    }
  }

  ul {
    margin: 0 0 0 10px;
    padding: 3px 10px;

    &.tooltip-scrollable-area,
    &.tooltip-scrollable-area-small,
    &.tooltip-scrollable-area-large {
      padding: 3px @spacing-4;
    }

    li {
      text-align: left;
      padding-top: 5px;
    }
  }

  &.text-aligned-left .tooltip-inner {
    text-align: left;
    .separator {
      padding-top: 10px;
      border-top: @light-gray-border;
    }
    .filter-separator {
      padding-bottom: 10px;
    }

  }

  &.spaced-paragraph .tooltip-inner {
    p {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  img {
    padding: 15px;
  }

  .highlighted-text-block {
    font: @text-xs-regular;
    background-color: @background-medium-color;
    border-radius: @border-radius-default;
    padding: 10px;

    .block-label {
      font: @text-xs-medium;
    }

    &.highlighted-text-block-with-icon {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;

      svg {
        fill: @icon-weak-color;
        vertical-align: top;
        margin-right: @spacing-2;
      }
    }

    &~.highlighted-text-block {
      margin-top: @spacing-1;
    }
  }

  .secondary-text {
    font: @text-2xs-regular;
  }

  .text-weak {
    color: @text-weak-color;
  }

  .tooltip-notification-list {
    .notification-item {
      padding: 10px;
      font: @text-2xs-regular;

      svg {
        .icon-small();
        margin-right: @spacing-1;
      }
    }
  }

  .info-text {
    display: flex;

    svg {
      .icon-medium();
      fill: @icon-weak-color;
      margin-right: @spacing-1;
      flex-shrink: 0;
    }
  }

  .tooltip-title {
    font: @text-small-semi-bold;

    span {
      vertical-align: middle;
    }

    svg {
      .icon-medium();
      vertical-align: middle;
    }
  }

  .tooltip-scrollable-area {
    margin-top: 12px;
    max-height: 200px;
    overflow-y: auto;
    .white-bg-scrollbar();
  }

  .tooltip-scrollable-area-small {
    .tooltip-scrollable-area;
     max-height: 150px;
  }

  .tooltip-scrollable-area-large {
    .tooltip-scrollable-area;
     max-height: 250px;
  }

  .tooltip-footer-info {
    font: @text-2xs-medium;
    padding-top: 10px;

    svg {
      fill: @icon-weak-color;
      vertical-align: middle;
      margin-right: @spacing-2;
      .icon-medium();
    }
  }

  .buttons-group {
    display: flex;
    flex-direction: row;
    gap: @spacing-2;
  }

  .tooltip-secondary-action-button {
    font: @text-xs-medium;
  }
}

.data-tooltip {
  .tooltip-inner {
    font: @text-xs-regular;
    color: @text-color;
    padding: 12px;
    width: 350px;
    max-width: initial;
    min-width: initial;
  }

  &.tooltip-as-dropdown {
    .tooltip-inner {
      width: 210px;
    }

    &.wide-tooltip-as-dropdown .tooltip-inner {
      width: 300px;
    }

    .buttons-group {
      flex-wrap: wrap;

      .corsac-button {
        margin-left: 0;
        height: max-content;
        align-items: flex-start;

        svg {
          flex: none;
        }

        &.centered {
          align-items: center;
        }
      }
    }
  }
}

.tooltip .tooltip {
  display: none; // prevent showing tooltips inside tooltips
}

.inline-tooltip {
  display: inline-block;
}

.tooltip-trigger {
  border-bottom: @border-dashed-hover;
}

.tooltip-container {
  position: relative;
}

.chart-data-tooltip {
  .tooltip {
    .tooltip-inner {
      font-size: 14px;
      font-weight: @font-weight-thin;
      line-height: 20px;
      min-width: 200px;
      max-width: 450px;
      padding: 8px 12px;

      .bold {
        color: @black;
        font-weight: @font-weight-semi-bold;
      }

      .decorated-link {
        display: block;
        margin-top: 10px;
      }

      .tooltip-entry {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .decorated-link {
          margin-top: 0;
        }
      }
    }

    .chart-tooltip-header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      max-height: @line-height-500; // To prevent the tooltip text being blurry

      .tooltip-date {
        font: @text-xs-regular;
        color: @text-weak-color;
        flex-shrink: 0;
        margin-left: 20px;
        vertical-align: middle;
      }

      .tooltip-title {
        .text-ellipsis();
      }
    }

    .buttons-group {
      margin: @spacing-3 0 @spacing-2;
    }

    &.adjusted .tooltip-inner {
      white-space: normal;
    }

    &.adjusted-wide .tooltip-inner {
      min-width: 350px;
    }
  }
}

.group-options-disabled-tooltip,
.radio-button-tooltip {
  .tooltip {
    filter: drop-shadow(0 2px 8px rgba(0,0,0,0.3));

    .tooltip-inner {
      font-weight: @font-weight-thin;
      padding: 14px 15px 17px 20px;

      .bold {
        color: @black;
        font-weight: @font-weight-semi-bold;
      }
    }
  }
}

.action-button-container {
  display: inline-block;
  .tooltip.action-button-modal-tooltip {
    margin-top: 5px;
    .tooltip-inner {
      min-width: 290px;
      padding: 20px;
      
      .modal-tooltip-text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }

      .modal-tooltip-confirm-button {
        margin-top: 20px;
        padding: 5px 12px;
        min-width: 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        letter-spacing: 0.005em;
      }
    }
  }
}
