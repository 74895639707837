.add-new-employee-page {

  .page-title {
    font-size: 20px;
    line-height: 24px;
    padding-top: 30px;
  }

  .button-short svg {
    height: 25px;
    width: 25px;
    vertical-align: middle;
  }

  .row-spacing {
    margin-top: 15px;
  }

  .form-section {
    margin-top: 40px;
    padding-top: 40px;

    .form-control-dropdown .dropdown-selection {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .line-separator {
    border-top: @gray-border;
    margin-top: 40px;
  }

  .buttons-container {
    float: right;

    .button-short {
      padding: 8px 0;
    }

    .right {
      margin-right: 10px;
    }
  }

  .form-control {
    font-size: 16px;
  }

  .input-height {
    height: 40px;
  }

  .text-with-top-label {
    margin-top: 8px;
  }

  .dropdown-combo .dropdown-selection .title {
    color: @gray1;
    font-size: 15px;
  }

  .buttons-container {
    margin-bottom: 160px;
  }

  .input-with-icon input {
    font-size: 15px;
    font-weight: 400;
    line-height: 32px;
    font-style: normal;
  }

  .section-header {
    margin-top: 74px;
  }
}
