.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: spin 1s infinite steps(8);
  animation: spin 1s infinite steps(8);
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.animation-pulse {
  display: inline-block;
  -webkit-animation: spin 1s infinite steps(8);
  animation: spin 1s infinite steps(8);
}

.animation-spin {
  -webkit-animation: spin .8s infinite linear;
  animation: spin .8s infinite linear;
}
/* ----------------------------------------------
 * Generated by Animista on 2017-3-17 16:18:4
 * http://animista.net
 * T: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */

.slide-in-top-0() {
  -webkit-transform: translateY(-1000px);
  transform: translateY(-1000px);
  opacity: 0;
}

.slide-in-top-100() {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

@-webkit-keyframes slide-in-top {
  0% {
    .slide-in-top-0();
  }
  100% {
    .slide-in-top-100();
  }
}
@keyframes slide-in-top {
  0% {
    .slide-in-top-0();
  }
  100% {
    .slide-in-top-100();
  }
}

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
.slide-out-top-0() {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.slide-out-top-100() {
  -webkit-transform: translateY(-1000px);
  transform: translateY(-1000px);
  opacity: 0;
}

@-webkit-keyframes slide-out-top {
  0% {
    .slide-out-top-0();
  }
  100% {
    .slide-out-top-100();
  }
}
@keyframes slide-out-top {
  0% {
    .slide-out-top-0();
  }
  100% {
    .slide-out-top-100();
  }
}

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */

 .slide-in-left-0() {
  -webkit-transform: translateX(~"calc(100% - 100px)");
  transform: translateX(~"calc(100% - 100px)");
}

.slide-in-left-100() {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@-webkit-keyframes slide-in-left {
  0% {
    .slide-in-left-0();
  }
  100% {
    .slide-in-left-100();
  }
}
@keyframes slide-in-left {
  0% {
    .slide-in-left-0();
  }
  100% {
    .slide-in-left-100();
  }
}

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
.slide-out-left-0() {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.slide-out-left-100() {
  -webkit-transform: translateX(~"calc(100% - 100px)");
  transform: translateX(~"calc(100% - 100px)");
}

@-webkit-keyframes slide-out-left {
  0% {
    .slide-out-left-0();
  }
  100% {
    .slide-out-left-100();
  }
}
@keyframes slide-out-left {
  0% {
    .slide-out-left-0();
  }
  100% {
    .slide-out-left-100();
  }
}

/**
 * ----------------------------------------
 * animation slide-in-right-and-fade
 * ----------------------------------------
 */

.slide-in-right-and-fade-0() {
  -webkit-transform: translateX(~"calc(200% + 100px)");
  transform: translateX(~"calc(200% + 100px)");
  opacity: 0;
}

.slide-in-right-and-fade-100() {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

@-webkit-keyframes slide-in-right-and-fade {
  0% {
    .slide-in-right-and-fade-0();
  }
  100% {
    .slide-in-right-and-fade-100();
  }
}
@keyframes slide-in-right-and-fade {
  0% {
    .slide-in-right-and-fade-0();
  }
  100% {
    .slide-in-right-and-fade-100();
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */

.fade-in-0 {
  opacity: 0;
}

.fade-in-100 {
  opacity: 1;
}

@-webkit-keyframes fade-in {
  0% {
    .fade-in-0();
  }
  100% {
    .fade-in-100();
  }
}
@keyframes fade-in {
  0% {
    .fade-in-0();
  }
  100% {
    .fade-in-100();
  }
}

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */

.fade-out-0 {
  opacity: 1;
}

.fade-out-100 {
  opacity: 0;
}

@-webkit-keyframes fade-out {
  0% {
    .fade-out-0();
  }
  100% {
    .fade-out-100();
  }
}
@keyframes fade-out {
  0% {
    .fade-out-0();
  }
  100% {
    .fade-out-100();
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2017-12-20 10:32:17
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */

.scale-in-ver-to-0() {
  -webkit-transform: scaleY(0.95);
  transform: scaleY(0.95);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  opacity: 0.75;
}

.scale-in-ver-top-100() {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  opacity: 1;
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    .scale-in-ver-to-0();
  }
  100% {
    .scale-in-ver-top-100();
  }
}
@keyframes scale-in-ver-top {
  0% {
    .scale-in-ver-to-0();
  }
  100% {
    .scale-in-ver-top-100();
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2017-12-20 10:40:20
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-ver-top
 * ----------------------------------------
 */

.scale-out-ver-to-0() {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  opacity: 1;
}

.scale-out-ver-top-100() {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  opacity: 1;
}

@-webkit-keyframes scale-out-ver-top {
  0% {
    .scale-out-ver-to-0();
  }
  100% {
    .scale-out-ver-top-100();
  }
}
@keyframes scale-out-ver-top {
  0% {
    .scale-out-ver-to-0();
  }
  100% {
    .scale-out-ver-top-100();
  }
}

// Default transition to use in JS for vertical expansion (scale-in-ver-top & scale-out-ver-top)
.vertical-expansion-appear {
  .scale-in-ver-to-0();
}

.vertical-expansion-appear.vertical-expansion-appear-active {
  animation: scale-in-ver-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both;
}

.vertical-expansion-exit {
  .scale-out-ver-to-0();
}

.vertical-expansion-exit.vertical-expansion-exit-active {
  animation: scale-out-ver-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both;
}

/**
 * ----------------------------------------
 * animation shortcut-button
 * ----------------------------------------
 */
//Animations
.shortcut-button-animation {
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes shortcut-button-animation-plus {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -@shortcut-btn-round-rotate);
    transform: rotate3d(0, 0, 1, -@shortcut-btn-round-rotate);
    background-color:@link-color;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    background-color: @link-color;
  }
}

@keyframes shortcut-button-animation-plus {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    background-color:@link-color;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    background-color: @link-color;
  }
}

.shortcut-button-animation-plus {
  -webkit-animation-name: shortcut-button-animation-plus;
  animation-name: shortcut-button-animation-plus;
}


@-webkit-keyframes shortcut-button-animation-close {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    background-color: @link-color;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    background-color:@link-color;
  }
}

@keyframes shortcut-button-animation-close {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    background-color: @link-color;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    background-color:@link-color;
  }
}

.shortcut-button-animation-close {
  -webkit-animation-name: shortcut-button-animation-close;
  animation-name: shortcut-button-animation-close;
}

@-webkit-keyframes circleShape {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    background-color: @link-color;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    background-color:@link-color;
    border-radius: @shortcut-btn-round-size;
  }
}

@keyframes circleShape {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    background-color: @link-color;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    transform: rotate3d(0, 0, 1, @shortcut-btn-round-rotate);
    background-color:@link-color;
    border-radius: @shortcut-btn-round-size;
  }
}

.circleShape {
  -webkit-animation-name: circleShape;
  animation-name: circleShape;
}
