.link-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  color: @text-active-color;
  cursor: pointer;
  transition: all 0.3s ease-out;

  svg {
    fill: @icon-active-color;
  }

  &.inline {
    display: inline-flex;
  }

  &:hover {
    color: @text-hover-color;

    svg {
      fill: @icon-hover-color;
    }
  }

  &:disabled {
    cursor: none;
    color: @text-disabled-color;

    svg {
      fill: @icon-weak-color;
    }
  }

  &.dark-background {
    color: @text-active-on-dark-color;
  }

  &.link-button-large {
    font: @text-small-semi-bold;
    gap: @spacing-2;

    svg {
      .icon-medium();
    }
  }

  &.link-button-medium {
    font: @text-xs-medium;
    gap: @spacing-1;

    svg {
      .icon-small();
    }
  }

  &.link-button-small {
    font: @text-2xs-medium;
    gap: @spacing-1;

    svg {
      .icon-small();
    }
  }
}
