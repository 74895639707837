.page-content-container {
  display: flex;
  height: calc(~'100vh' - @header-height);
  min-width: @min-page-width;

  .page-main-content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 0 @page-spacing @page-spacing;

    &.no-padding {
      padding: 0;
    }

    .back-button {
      overflow: hidden;
      margin-top: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: @text-active-color;
      justify-content: flex-start;
      font: @text-small-semi-bold;

      svg {
        fill: @icon-active-color
      }

      &:hover {
        color: @text-hover-color;

        svg {
          fill: @icon-hover-color;
        }
      }
    }
  }

  .sidebar ~ .with-left-control {
    width: calc(~"100% - @{sidebar-expanded-width}");
  }

  .sidebar ~ .no-left-control {
    width: calc(~"100% - @{sidebar-collapsed-width}");
  }

  .sidebar-wide ~ .with-left-control {
    width: calc(~"100% - @{sidebar-expanded-wider-width}");
  }

  .page-secondary-header,
  .page-secondary-table-header {
    position: sticky;
    top: 0;
    z-index: @zindex-wtf;
  }

  .page-with-float-actions {
    .page-secondary-header {
      top: 64px; // To match the height of the goal page main actions element
    }
    .page-secondary-table-header {
      top: 64px + 64px; // To match the height of the goal page main actions element and secondary header
    }
  }

  .page-with-recommendation-drawer {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .page-main-content-with-header {
      min-width: 460px;
      max-width: 680px;
      flex-grow: 1;
    }

    &.hidden-drawer .page-main-content-with-header {
      max-width: 100%;

      .single-button-pair-header .button-pair {
        margin-left: inherit;
      }
    }

    .page-main-content {
      .weak-bg-scrollbar(@background-medium-color);
    }

    .ellipsized-title {
      .dropdown-menu-container {
        position: fixed;
        top: auto;
      }
    }

    .recommendation-drawer {
      min-width: 480px;
      max-width: 600px;
      height: 100%;

      .recommendation-text-view {
        width: auto;
        height: 100%;
        border-right: @border-default;
      }
    }
  }
}

.page-content-header {
  display: flex;
  align-items: center;
  margin: 34px 0 @spacing-4 0;
  justify-content: space-between;

  .page-content-header-title {
    display: flex;
    align-items: center;

    h1 {
      margin-block: 0;
      color: @text-color;
      font: @text-display-xl;
    }

    .explanation {
      display: flex;
      align-items: center;
    }
  }

  &.with-description {
    margin-bottom: 0;
  }
}

.page-content-description {
  margin-bottom: @spacing-4;
}

.page-with-banner {
  .page-content-container {
    height: calc(~'100vh' - @header-height + @header-alert-height);
  }
}

.page-main-content {
  padding-bottom: @page-spacing;
}

.overlay-page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .overlay-page-with-sidebar {
    display: flex;
    flex-direction: row;
    height: 100%;

    .overlay-main-content {
      flex-grow: 1;
      height: 100%;
      overflow-y: auto;
    }

    .overlay-page-header {
      height: 136px;
      background-color: @background-white-color;
    }
  }
}


