.text-formatter-table {

  .bold {
    font-weight: 600;
  }

  .header {
    border-top: 1px solid @table-cell-border-color-dark;
    border-bottom: 1px solid @table-cell-border-color-dark;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
  }

  tbody:before {
    content: '';
    display: block;
    height: 5px;
    width: 100%;
    margin-bottom:10px;
  }

  .relevant-data-spacing  {
    .table-tr:last-child .label{
      padding-bottom: 15px;
    }
  }
  .not-relevant-data {
    border-top: 1px solid #DDDDDD;
    color: #999999;
  }

  .label {
    .text-ellipsis();
    padding-right: 10px;
  }
}

.small-icon {
  width: 15px;
  height: 15px;
  fill: @link-color;
  vertical-align: middle;
}
