//
// Collection of small mixins. They are here to avoid creating a file per each one of these
//

.un-styled-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.base-button() {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: @spacing-1;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.dark-bg-scrollbar() {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: @gray;
    border-radius: @border-radius-small;
  }
}

.white-bg-scrollbar() {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 9px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: @border-radius-default;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: @gray5;
  }
}

.weak-bg-scrollbar(@parentBackgroundColor) {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background: @background-weak-color;
    border-radius: @border-radius-default;
    border: 2px solid @parentBackgroundColor; /* should match background, can't be transparent */
  }
}
