//
// Variables
// --------------------------------------------------

// Page
@page-width: 1300px;
@narrow-page-width: 1000px;
@wide-page-width: 1800px;
@min-page-width: 1200px;
@page-spacing: 30px;
@min-show-shortcut-button-width: 1260px;

//== Colors
//TODO: GET RID OF THE COLOR VARIABLES BELOW, use colors from color.less

// HIGHLIGHT COLORS

@actionGreen: #5DBA7D;
@actionLightGreen: #76D396;
@action-green-border: #57A672;
@highlightOrange: #FE6B25;
@action-green-hover: #58AC75;

// TEXT COLORS

@gray1: #666666;
@gray2: #999999;
@gray3: #C3C8D1;
@gray4: #E7E8EB;
@gray5: #CCCCCC;
@gray6: #E5E5E5;
@gray7: #ADADAD;
@gray8: #AAAAAA;
@gray9: #555555;
@gray10: #DADADA;

@blue-link: @link-color;
@lemon: #F9EB49;

// BACKGROUND COLORS

@darkBlueBG: #00224E;
@lightBG1: #EDF1F7;
@lightBG2: #F0F0F3;
@lightBG3: #FBFBFD;
@lightgreen: #F4FEE8;
@grayBG: #444;
@lightGrayBG: #F5F5F5;
@whiteBG: #F3F3F3;
@blackBG: #000000;
@blueGray: #8295AD;
@slackBG: #4A154B;
@yellowBG: #FCF8E3;
@mustard-yellow: #EDB492;
@icon-light-warning-color: @yellow-400;
@lightest-blue: #EEF5FC;

@anomalyBG: #09224B;

//

@error-color: #DA3549;

// ALERT COLORS

@negative-alert: #EE803E;
@negative-alert-red: #DB4B5C;
@neutral-alert: #27CCD1;
@positive-alert: #5DBA7D;
@warning-alert: #F19964;

@on-track-alert: @positive-alert;
@no-data-alert: @background-weak-color;

@goal-missed-alert: @crimson-red;
@goal-high-risk-alert: @coral-pink;
@goal-behind-pace-alert: @gold-yellow;
@goal-on-track-alert: @mint-green;
@goal-hit-alert: @emerald-green;



@blue-salesforce: #00A1E2;

// FONT WEIGHT

@font-weight-thin: 200;
@font-weight-bold: 800;

@link-across-the-site: @blue-link;


// TODO: GET RID OF THE COLOR VARIABLES BELOW, use colors from color.less
@gray-base:              #000;
@gray-darker:            lighten(@gray-base, 13.5%); // #222
@gray-dark:              lighten(@gray-base, 20%);   // #333
@gray:                   lighten(@gray-base, 40%); // #666
@gray-light:             lighten(@gray-base, 46.7%); // #777
@gray-light2:            #999999;
@gray-lighter:           lighten(@gray-base, 93.5%); // #eee
@gray-lightest:          #DDDDDD;
@gray-light3:            #898989;


// Base colors for Corsac
@blue:        #7DC7CD;
@heavy-blue:  #4A90E2;
@green:       #53B743;
@red:         #EA3323;

//Colors for Career Page
@logoorange: #f15727; //This is the orange from the atrium logo

// Body
//
// Settings for the `<body>` element.
@body-bg:               #F9F9F9;
@body-font-size:        @font-size-root;

//** Global text color on `<body>`.
@text-color-default:    @gray1;
@text-color-light:      @gray2;
@text-color-bold:       @black;

//** Global textual link color.
@link-color:            @text-active-color;
//** Link hover color
@link-hover-color:      #2A588F;
//** Link hover decoration.
@link-hover-decoration: underline;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

@font-family-base: @font-family-text;


.caps {
  text-transform: uppercase;
}

@font-size-homepage-message: 72px;
@font-size-careers-mission-text: 48px;
@font-size-careers-large-text: 35px;
@font-size-highlightText: 28px;
@font-size-h0:            28px;
@font-size-h1:            24px;
@font-size-h2:            20px;
@font-size-h3:            18px;
@font-size-h4:            16px;
@font-size-bodyText:      14px;
@font-size-caption1:      12px;
@font-size-caption2:      10px;
@font-size-caption3:      8px;
@font-size-caption4:      6px;

// TODO: REMOVE H5 AND H6 ONCE I PROVE THEY AREN'T USED
// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
@font-size-root:          16px;
@font-size-base:          16px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    inherit;
@headings-font-weight:    500;
@headings-line-height:    1.1;
@headings-color:          inherit;

@hr-border:               @gray-lightest;
@hr-darker-border:        @gray5;

//States

@state-danger-text:              #a94442;
@state-danger-bg:                #f2dede;
@state-danger-border:            darken(@state-danger-bg, 5%);

@state-warning-text:              #8a6d3b;;
@state-warning-bg:                #fcf8e3;
@state-warning-border:            darken(@state-warning-bg, 5%);

@state-demo-mode-text:              #ACAFB8;
@state-demo-mode-bg:                #4A5164;
@state-demo-mode-border:            darken(@state-demo-mode-bg, 5%);

@state-positive-text:              #4F7F5A;
@state-positive-bg:                #F6FBF8;
@state-positive-border:            #BEE3CB;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//

@zindex-navbar:            1000;
@zindex-frozen-navbar:     1200;
@zindex-dropdown:          1000;
@zindex-left-control:      1000;
@zindex-lcp-button:        1000;
@zindex-popover:           1100;
@zindex-tooltip:           1250;
@zindex-navbar-fixed:      1030;
@zindex-modal-background:  1000;
@zindex-modal:             1050;
@zindex-nested-modal-bg:   1050;
@zindex-table-header:       2;
@zindex-wtf:                1; // TODO: Used in scrollable-panel, replace
// TODO: Add target-action z-index instead of just modal + 1

// Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
@screen-xs:                  480px;

// Small screen / tablet
@screen-xs:                  768px;

// Small screen / desktop
@screen-sm:                  1280px;

// Medium screen / wide desktop
@screen-md:                  1440px;

// Medium-large screen / wide desktop
@screen-md-lg:               1720px;

// Large screen / wide desktop
@screen-lg:                  1920px;

// Extra large screen / extra wide desktop
@screen-xl:                  2560px;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm - 1);
@screen-sm-max:              (@screen-md - 1);
@screen-md-max:              (@screen-lg - 1);

// Grid system
@grid-col-width:            8.333333333333334%;
@grid-col-gutter:           20px;

// 16 cols
@grid-16-col-width:         6.25%;
@grid-16-gutter:            20px;

// 24 cols
@grid-24-col-width:         4.1666666667%;
@grid-24-gutter:            20px;

// Navbar
@navbar-bg-color:          #25365B;
@navbar-light-bg-color:    #2374CB;
@navbar-color:             #FFF;
@navbar-height:            50px;
@navbar-margin-bottom:     0;


// Navbar links
@navbar-link-color:        @navbar-color;
@navbar-link-hover-color:  darken(@navbar-color, 10%);

// Navbar brand label
@navbar-brand-color:            @navbar-link-color;
@navbar-brand-hover-color:      darken(@navbar-brand-color, 10%);
@navbar-brand-hover-bg-color:   transparent;
@navbar-brand-font-size:        @font-size-h1;

// Modals
@modal-inner-padding:         .9rem;

// Modal header
@modal-header-padding:         10px 0px;

@modal-border-radius: 5px;

@modal-header-text-margin:          0;
@modal-header-text-color:           @text-on-dark-color;
@modal-header-actions-line-height:  @line-height-base;
@modal-header-text-font-size:       @font-size-h1;
@modal-header-actions-color:        @modal-header-text-color;
@modal-header-actions-font-size:    .815rem;
@modal-header-actions-padding-top:  2px;

@modal-title-bgcolor: @lightBG1;
@modal-title-text-color: @text-color;
@modal-title-text-font-size: @font-size-h3;
@modal-title-padding: 12px 25px;

@modal-body-bg:            @background-white-color;
@modal-body-padding:       10px 25px;
@modal-body-row-padding:   10px 0;


@modal-content-sides-margin:  .9rem;
@modal-content-border-color:  rgba(0,0,0,.2);


@modal-mask-bg:           #000;
@modal-mask-white-bg:     @background-white-color;
@modal-mask-opacity:      .9;

@modal-top-margin-default: 100px;
@modal-top-margin-small: 20px;
@modal-top-margin-medium: 30px;

// Image thumbnails

//** Padding around the thumbnail image
@thumbnail-padding:           .25rem;
@thumbnail-bg:                @body-bg;
@thumbnail-border:            @gray-lightest;

// Alerts

@alert-padding: .8rem;
@alert-margin-bottom: @line-height-computed;
@alert-link-font-weight: bold;
@alert-header-padding: .5rem;
@alert-margin-bottom: 0;
@alert-border-radius: @border-radius-small;

@alert-danger-bg:             @state-danger-bg;
@alert-danger-text:           @state-danger-text;
@alert-danger-border:         @state-danger-border;

@alert-warning-bg:             @state-warning-bg;
@alert-warning-text:           @state-warning-text;
@alert-warning-border:         @state-warning-border;

@alert-demo-mode-bg:             @state-demo-mode-bg;
@alert-demo-mode-text:           @state-demo-mode-text;
@alert-demo-mode-border:         @state-demo-mode-border;

@alert-positive-bg:             @state-positive-bg;
@alert-positive-text:           @state-positive-text;
@alert-positive-border:         @state-positive-border;

// Buttons

@btn-padding:                   .6em;
@btn-border-radius:             @border-radius-small;
@btn-horizontal-padding:        24px;
//@btn-font-size:                 .87rem;
@btn-font-size:                 5.3rem;

@btn-blue:                      @link-color;
@btn-off-color:                 @gray2;

@btn-primary-color:             @white;
@btn-primary-bg-color:          @actionGreen;

@btn-large-font-size:           1rem;
@btn-small-font-size:           11px;
@btn-small-line-height:         14px;


@btn-expandable-border:            1px solid @gray9;
@btn-expandable-color:             @white;
@btn-expandable-padding:           4px 8px 4px 6px;
@btn-expandable-border-radius:     3px;
@btn-expandable-button-spacing:    3px;
@btn-expandable-button-expanded-inner-padding:  .2em;

@btn-expandable-icon-width:        16px;
@btn-expandable-icon-height:       16px;
@btn-expandable-line-height:       16px;

@btn-expandable-fill-color:  @white;
@btn-expandable-hover-text-color:  @white;
@btn-expandable-active-text-color:  @white;
@btn-expandable-active-bgcolor: @gray-base;
@btn-expandable-icon-margin-left:  .23em;

@btn-expandable-auto-expanded-bgcolor: @gray-darker;


// Dropdowns

@dropdown-mask-opacity:      .0;
@dropdown-menu-inactive-links-opacity: .4;
@dropdown-menu-background-color: @gray-darker;
@dropdown-menu-background-color-hover: @gray-base;
@dropdown-menu-background-color-active: @black;
@dropdown-menu-border: 1px solid rgba(0,0,0,.15);
@dropdown-menu-border-radius: @border-radius-small;
@dropdown-menu-box-shadow: 0px 2px 10px rgba(0,0,0,0.3);
@dropdown-menu-reverse-box-shadow: 0px -2px 10px rgba(0,0,0,0.3);
@dropdown-menu-color-hover: @black;
@dropdown-menu-color-active: @white;
@dropdown-menu-link-color: @gray5;
@dropdown-menu-font-size: @font-size-h4;
@dropdown-span-border-radius: 25px;
@dropdown-input-border-light-bg: 1px solid @gray-lighter;
@dropdown-input-border-dark-bg: 1px solid @gray9;
@dropdown-combo-caret-color: rgba(0, 0, 0, 0.54);
@dropdown-height: 40px;
@dropdown-white-bg-font-color: @text-color-default;
@dropdown-white-bg-info-color: @gray2;
@dropdown-selection-padding: 3px 10px;
@dropdown-selection-line-height: 32px;
@dropdown-white-menu-background-color: @white;
@dropdown-white-menu-background-color-hover: @whiteBG;
@descriptive-dropdown-background-color: @white;
@descriptive-dropdown-border: @border-default;
@descriptive-dropdown-border-radius: @border-radius-default;
@descriptive-dropdown-box-shadow: @box-shadow-medium;
@descriptive-dropdown-padding: 8px;


// inputs
@input-border-radius: 3px;

// Multi-selector

@multi-selector-menu-background-color: @gray-dark;
@multi-selector-color-active: @link-color;
@multi-selector-selection-color: @gray1;

// Card specifics

@card-title-margin:       @modal-header-text-margin;
@card-title-line-height:  @modal-header-actions-line-height;
@card-title-font-size:    @font-size-h3;
@card-title-color:        @text-color-bold;
@card-title-font-weight: @font-weight-medium;


@card-actions-font-size:  @modal-header-actions-font-size;
@card-close-color:        @card-title-color;
@card-header-padding:    @modal-header-padding;
@card-controls-border-radius: @border-radius-default;
@card-controls-background-color: @background-medium-color;

@card-selected-targets-width: 100px;
@card-section-width: 1350px;
@card-right-box-width: 220px;
@card-section-margin-left: 20px;
@card-section-width-without-rbox: @card-section-width + @card-section-margin-left + @card-right-box-width;

@card-content-group-width: 24px;
@card-content-group-height: 24px;
@card-content-group-border-radius: @border-radius-small;
@card-content-important-weight: 500;
@card-content-detail-columns-hover-bg-color: @lightBG3;
@card-content-background-color: @white;

// Top 4 replace card flow
@card-small-height: 160px;
@card-small-width: 288px;
@card-medium-height: 220px;
@card-medium-width: 388px;


@card-replace-bannner-height: 30px;
@card-replace-banner-bg: @gray1;
@card-replace-banner-text-color: @white;
@card-replace-banner-font-size: @font-size-caption1;
@card-replace-banner-line-height: @card-replace-banner-font-size;
@card-replace-banner-padding: (@card-replace-bannner-height - @card-replace-banner-line-height) / 2 0;
@card-replace-card-unavailable-opacity: 0.4;
@replace-medium-card-available-opacity: 0.8;
@replace-small-card-available-opacity: 0.6;

// Dashboard specific
@dashboard-card-hover-card-shadow:  0px 1px 10px @gray1;
@dashboard-card-active-card-shadow:  0px 1px 10px @black;
@dashboard-anomaly-tray-card-hover-card-shadow:  0px 1px 20px @white;
@dashboard-anomaly-tray-card-active-card-shadow:  0px 1px 20px @black;
@dashboard-title-font-size: 22px;
@dashboard-title-font-weight: @font-weight-semi-bold;
@dashboard-sub-title-font-size: @font-size-h4;
@dashboard-sub-title-font-weight: lighter;
@dashboard-title-line-height: 35px;
@dashboard-sub-title-margin-bottom: 32px;
@dashboard-sub-title-font-weight-thin: 100;
@dashboard-explanation-line-height: 25px;
@dashboard-header-margin: 30px;

// Anomaly drawer
@anomaly-drawer-padding: 0px 20px 20px 20px;
@anomaly-drawer-title-font-size: @font-size-h2;
@anomaly-drawer-title-text-color: @black;
@anomaly-drawer-body-font-size: @font-size-bodyText;
@anomaly-drawer-body-text-color: @gray1;
@anomaly-drawer-font-size: @font-size-h4;
@anomaly-drawer-font-weight: @font-weight-thin;
@anomaly-drawer-line-height: 22px;
@anomaly-drawer-radius: 5px;

@anomaly-drawer-actions-title-color: @gray-dark;
@anomaly-drawer-actions-font-size: @font-size-h4;
@anomaly-drawer-actions-title-size: 14px;
@anomaly-drawer-actions-font-weight: @font-weight-semi-bold;
@anomaly-drawer-actions-margin-bottom: 5px;

@anomaly-action-font-weight: @font-weight-thin;

@anomaly-drawer-other-actions-margin-top: 20px;
@anomaly-drawer-other-actions-list-padding-left: 20px;
@anomaly-drawer-other-actions-list-margin-bottom: 0;
@anomaly-drawer-other-actions-list-margin-top: 0;
@anomaly-drawer-other-action-entry-margin-bottom: 10px;
@anomaly-drawer-other-action-entry-line-height: 18px;


// Org chart
@org-chart-target-radius: 2px;
@org-chart-target-width: 200px;
@org-chart-target-height: 44px;
@org-chart-target-arrow-size: 11px;
@org-chart-target-arrow-border-width: 2px;
@org-chart-column-arrow-size: 20px;
@org-chart-column-arrow-border-width: 4px;
@org-chart-width: calc(~'100%' - @sidebar-expanded-width + @card-section-margin-left);

// scrollable-panel
@default-arrow-size: 12px;
@default-arrow-border-width: 2px;
@arrow-overhang: 5px;
@non-overlapping-arrow-padding: 10px;

@scrollable-panel-triangle-wrapper-bg-color: transparent;
@scrollable-panel-triangle-color: @white;
@scrollable-panel-triangle-hover-color: @actionGreen;


// Sentence filter
@grouping-view-circle-width: 28px;

// Targets
@target-plus-circle-width: 40px;  // TODO: REmove?

// Data filters
@data-filters-top-padding: 15px;
@data-filters-left-right-padding: 15px;

@inclusion-icon-width: 28px;

@settings-close-icon-height: 20px;
@settings-close-icon-width: 20px;
@settings-close-icon-fill-color: @gray1;
@settings-close-icon-hover-fill-color: @actionGreen;
@settings-close-icon-active-fill-color: @positive-alert;


@data-filter-toggle-width: 45px;
@data-filter-toggle-height: 21px;
@data-filter-toggle-fill-color: @gray1;
@data-filter-toggle-hover-fill-color: @black;

// navigation-bar
@header-atrium-logo-width: 90px;
@header-atrium-logo-height: 18px;
@header-user-name-dropdown-width: 170px;
@header-font-size: 14px;
@header-opacity: 0.5;
@header-height: 58px;
@header-alert-height: 39px;
@header-font-color: @white;
@header-text-transform: uppercase;
@header-font-weight: @font-weight-regular;
@header-meatball-font-size: 11px;
@header-meatball-line-height: 13px;
@header-meatball-color: @navbar-bg-color;
@header-meatball-bg-color: @lemon;
@header-meatball-border-radius: 30px;
@header-meatball-padding: 3px 7px;
@header-meatball-min-width: 21px;

// Text buttons`
@button-min-width: 105px;

// Icon buttons
@icon-emoji-button-right-margin: 8px;

// Icons
@card-action-icon-width: 25px;
@text-with-icon-separation: 6px;
@icon-default-size: 24px;
@icon-medium-size: 20px;
@icon-small-size: 16px;

// Pagination
@pagination-circle-width: 25px;
@pagination-circle-border-width: 2px;
@pagination-arrow-margin: 50px;

// Card
@card-target-selector-width: 80px;
@card-drawer-width: 280px;
@card-bucketed-anomaly-drawer-width: 325px;
@card-collapsed-messaging-history: @card-drawer-width;
@card-expanded-messaging-history: 550px;
@card-body-width: calc(~"100% - @{card-target-selector-width}");
@card-body-drawer-width: calc(~"100% - @{card-target-selector-width} - @{card-collapsed-messaging-history}");
@card-body-collapsed-conversation-width: calc(~"100% - @{card-target-selector-width} - @{card-drawer-width}");
@card-body-expanded-conversation-width: calc(~"100% - @{card-expanded-messaging-history}");
@card-body-bucketed-anomaly-drawer-width: calc(~"100% - @{card-bucketed-anomaly-drawer-width}");
@card-container-no-left-control-width: calc(~"100% - @{sidebar-collapsed-width}");
@card-container-with-left-control-width: calc(~"100% - @{sidebar-expanded-width}");
@card-container-with-wide-sidebar-width: calc(~"100% - @{sidebar-expanded-wider-width}");
@card-container-drawer-width: calc(~"100% - @{card-collapsed-messaging-history}");
@card-container-collapsed-conversation-width: calc(~"100% - @{card-drawer-width}");
@card-container-expanded-conversation-width: calc(~"100% - @{card-expanded-messaging-history}");

// Table

@table-bg-color:                      #FFF;
@table-cell-v-padding:                10px;
@table-thin-cell-v-padding:           5px;
@table-header-color:                  @gray2;
@table-header-font-size:              @font-size-h4;
@table-content-cell-color:            #5C6570;
@table-cell-border-color:             #EDEFF2;
@table-cell-border-color-dark:        @gray-lightest;
@table-cell-icon-color:               @gray8;
@table-row-color:                      @gray1;
@table-row-font-size:                 @font-size-bodyText;
@table-cell-detailed-column-r-padding:          20px;
@table-frozen-column-header-zindex: @zindex-table-header + 1;

// Chart

@marker-color-default:    @marker-color-black;
@marker-color-black:      @black;
@marker-color-blue:       @blue;
@marker-color-green:      @green;
@marker-color-red:        @red;

@stacked-bar-chart-total-text-color:  #484769;
@stacked-bar-chart-bar-text-color:    @text-on-dark-color;

// multi-column-legend

@multi-column-legend-margin-top: 22px;
@multi-column-legend-entry-padding-left: 30px;

@multi-column-legend-entry-color-size: 18px;
@multi-column-legend-entry-color-radius: 50%;

@multi-column-legend-entry-text-padding-left: 10px;
@multi-column-legend-entry-text-font-size: @font-size-bodyText;
@multi-column-legend-entry-text-line-height: 15px;
@multi-column-legend-entry-text-color: @gray;

// line chart legend
@line-chart-legend-text-color: @gray;

@line-chart-legend-color-radius: 50%;
@line-chart-legend-color-default-bg-color: @gray-lighter;

// bar-chart-x-axis

@bar-chart-x-axis-entry-font-size: @font-size-bodyText;
@bar-chart-x-axis-entry-line-height: 1.3em;
@bar-chart-x-axis-entry-margin-left: -10px;
@bar-chart-x-axis-entry-rotated-font-size: @font-size-caption1;

@bar-chart-x-axis-entry-font-size-large-chart: @font-size-caption1;
@bar-chart-x-axis-entry-line-height-large-chart: 1.3em;

@bar-chart-x-axis-entry-font-size-large-chart-sm-page: @font-size-caption3;
@bar-chart-x-axis-entry-line-height-large-chart-sm-page: @bar-chart-x-axis-entry-font-size-large-chart-sm-page;

@bar-chart-x-axis-entry-font-size-medium-chart: @font-size-caption1;
@bar-chart-x-axis-entry-line-height-medium-chart: 1.3em;

@bar-chart-x-axis-entry-font-size-small-chart: @font-size-caption1;
@bar-chart-x-axis-entry-line-height-small-chart: 1.3em;

// Anomaly

@anomaly-recently-exited-color: #EAE7E7;
@anomaly-negative-color:        #EE803E;
@anomaly-positive-color:        #5DBA7D;
@anomaly-neutral-color:         #27CCD1;
@anomaly-none-color:            #546070;
@anomaly-font-size: 16px;
@anomaly-margin-left: 20px;

//
// Scenarios
//

@scenario-fox-icon-width: 104px;
@scenario-fox-icon-height: 164px;

// Scenario page
@scenario-storyline-border-width: 1px;
@scenario-storyline-border-color: #DDD;
@scenario-storyline-header-radius: 4px;
@scenario-storyline-header-padding: 2px 10px 1px 15px;
@scenario-storyline-header-border: 1px solid @scenario-storyline-border-color;
@scenario-storyline-padding: 10px 8px;
@scenario-storyline-large-padding: 16px 15px 16px 20px;
@scenario-storyline-border: @scenario-storyline-border-width solid @scenario-storyline-border-color;
@scenario-storyline-box-shadow: 0 1px 5px 0 rgba(0,0,0,0.08);
@scenario-storyline-arrow-col: 8px;
@scenario-storyline-direction-col: 40px;

@scenario-storyline-causes-active-color: #2A7DD2;
@scenario-storyline-causes-active-complimentary-color: @white;
@scenario-storyline-causes-expanded-bg-color: #E8F1FA;

@scenario-storyline-controls-active-color: #8295AD;
@scenario-storyline-controls-active-complimentary-color: @white;
@scenario-storyline-controls-expanded-bg-color: #F1F3F6;

@scenario-storyline-contras-active-color: #2A588F;
@scenario-storyline-contras-active-complimentary-color: @white;
@scenario-storyline-contras-expanded-bg-color: #F1F3F6;

@scenario-storyline-results-negative-active-color: @negative-alert;
@scenario-storyline-results-negative-expanded-bg-color: #FDF1EA;
@scenario-storyline-results-neutral-active-color: @neutral-alert;
@scenario-storyline-results-neutral-expanded-bg-color: #F1FBFC;
@scenario-storyline-results-positive-active-color: @positive-alert;
@scenario-storyline-results-positive-expanded-bg-color: #F3FAF5;
@scenario-storyline-results-active-complimentary-color: @white;

@storyline-card-info-bg-color: #00224f;
@storyline-card-info-margin-top: 15px;
@storyline-card-info-color: @white;
@storyline-card-padding: 20px;

@scenario-action-bg-color: #007A8C;
@scenario-action-box-radius: 5px;
@scenario-action-box-bg-color: @white;
@scenario-action-box-padding: 18px 20px 20px;
@scenario-action-header-padding: 4px 10px 3px 15px;

// Target Modal
@target-modal-header-line-height: 24px;
@target-modal-header-font-size: @font-size-h2;
@target-modal-header-color: @black;

@target-modal-warning-bg-color: @negative-alert;
@target-modal-warning-color: @white;
@target-modal-warning-font-size: 16px;
@target-modal-warning-font-style: italic;
@target-modal-warning-line-height: 20px;
@target-modal-warning-height: 39px;

@target-modal-custom-dashboard-actions: 1060;
@target-modal-mask-opacity: 1.0;
@target-modal-custom-dashboard-info-height: 88px;

//Home page top alerts
@action-items-view-all-margin-top: 30px;
@action-items-view-all-color: @link-across-the-site;

//Goal status widget
@goal-status-widget-margin-top: 20px;
@goal-status-widget-border: @border-default;
@goal-status-widget-dashed-border: 1px dashed #DDDDDD;
@goal-status-widget-border-radius: @border-radius-small;
@goal-status-widget-background-color: @background-white-color;
@goal-status-widget-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.08);

@goal-status-widget-title-font-weight: @font-weight-bold;
@goal-status-widget-title-subtitle-font-weight: 100;
@goal-status-widget-title-subtitle-font-size: 16px;
@goal-status-widget-title-info-padding-left: 10px;

@goal-status-header-border-bottom: 1px solid #DDDDDD;
@goal-status-header-margin-left: 5px;
@goal-status-header-margin-right: 5px;
@goal-status-header-col-2-font-size: 14px;
@goal-status-header-col-2-color: @black;
@goal-status-header-font-weight: 600;

@goal-status-header-padding-top: 26px;
@goal-status-header-padding-left: 10px;

@goal-status-header-left-col-padding-top: 26px;
@goal-status-header-left-col-padding-left: 10px;
@goal-status-header-left-col-border-right: 1px solid #DDDDDD;

@goal-status-header-right-col-row-padding-left: 10px;
@goal-status-header-right-col-padding-top: 26px;
@goal-status-header-right-col-padding-left: 10px;
@goal-status-header-right-col-font-size: 14px;
@goal-status-header-right-col-color: #666666;

@goal-status-metric-color: #666666;
@goal-status-metric-border-bottom: 1px dotted #DDDDDD;
@goal-status-metric-margin-left: 5px;
@goal-status-metric-margin-right: 5px;
@goal-status-metric-font-weight: lighter;

@goal-status-metric-border-bottom-none: none;
@goal-status-metric-text-decoration: none;

@goal-status-metric-goal-status-body-padding: 10px;
@goal-status-metric-goal-status-left-col-body-padding: 10px;
@goal-status-metric-goal-status-left-col-body-border-right: 1px solid #DDDDDD;
@goal-status-metric-goal-status-right-col-body-padding: 10px;

@goal-status-metric-size: 16px;
@goal-status-metric-media-size: 14px;

@goal-status-positive-fill: #5DBA7D;
@goal-status-negative-fill: #EE803E;
@goal-status-recommendation-background: #FFFFFF;
@goal-status-padding-top: 3px;
@goal-status-media-padding-top: 1px;
@goal-status-widget-status-position: absolute;
@goal-status-widget-status-padding-left: 4px;
@goal-status-widget-status-font-size: 14px;
@goal-status-widget-status-font-weight: bolder;
@goal-status-widget-padding-top: 3px;

@saved-goal-edit-modal-header-padding: 20px 20px 20px 26px;

@primary-card-widget-margin-bottom: 20px;
@primary-card-font-size: 1rem;
@primary-card-line-height: 15px;
@primary-card-overflow: hidden;
@primary-card-text-align: right;
@primary-card-card-content-text-align: left;

@primary-card-title-font-size: 16px;
@primary-card-title-font-weight: 600;
@primary-card-title-color: #333333;

@primary-card-sub-title-font-size: 16px;
@primary-card-sub-title-font-color: #666666;
@primary-card-sub-title-font-text-transform: lowercase;

@primary-card-total-color: #666666;
@primary-card-total-font-size: 16px;
@primary-card-total-line-height: 19px;
@primary-card-total-fill: #484769;
@primary-card-total-text-anchor: middle;

@primary-card-view-detail-font-size: 16px;
@primary-card-view-detail-line-height: 19px;

@primary-card-entry-text-decoration: underline;
@primary-card-bar-chart-x-axis-margin-left: 20px;

@primary-card-entry-font-size: 14px;
@primary-card-entry-line-height: 19px;
@primary-card-entry-text-align: center;

@primary-card-border-bottom: 1px solid #CCCCCC;
@primary-card-margin-bottom: 5px;
@primary-card-height: 250px;

@primary-card-border: 1px solid #DDDDDD;
@primary-card-border-radius: @border-radius-small;
@primary-card-background-color: #FFFFFF;
@primary-card-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);

@primary-card-tooltip-shape-width: 20px;
@primary-card-tooltip-shape-height: 20px;
@primary-card-tooltip-shape-border-radius: 50%;
@primary-card-tooltip-shape-margin: 0 auto;
@primary-card-tooltip-shape-padding:2px;
@primary-card-tooltip-shape-line-height: 0px;

@primary-card-icon-svg-width: 14px;
@primary-card-icon-svg-height: 14px;
@primary-card-icon-svg-fill: #fff;
@primary-card-icon-svg-padding-left: 2px;

@primary-card-text-container-title-color: #333333;
@primary-card-text-container-title-font-size: 14px;
@primary-card-text-container-title-font-weight: bold;
@primary-card-text-container-title-line-height: 17px;
@primary-card-text-container-title-text-transform: uppercase;
@primary-card-text-container-title-text-align: left;

@primary-card-summary-inline-color: #666666;
@primary-card-summary-inline-font-size: 14px;
@primary-card-summary-inline-line-height: 20px;
@primary-card-summary-inline-text-align: left;
@primary-card-summary-inline-font-weight: 400;
@primary-card-summary-inline-margin-top: 5px;
@primary-card-axis-legend-margin: 25px 15px 0 15px;
@primary-card-axis-legend-title-margin-right: 4px;
@primary-card-axis-legend-title-margin-left: 6px;

@simple-stacked-bar-chart-tooltip-inner-padding: 16px;


@custom-dashboard-sharing-subtitle-font-weight: bolder;
@custom-dashboard-sharing-content-padding: 20px 30px;
@custom-dashboard-sharing-content-border-top: 1px solid #DDDDDD;

@custom-dashboard-sharing-search-input-position: relative;
@custom-dashboard-sharing-input-color: #999999;
@custom-dashboard-sharing-input-font-size: 16px;
@custom-dashboard-sharing-input-font-style: italic;
@custom-dashboard-sharing-input-height: 40px;

@custom-dashboard-sharing-search-icon-position: absolute;
@custom-dashboard-sharing-search-icon-left: 10px;
@custom-dashboard-sharing-search-icon-top: 0px;
@custom-dashboard-sharing-search-icon-pointer-events: none;
@custom-dashboard-sharing-search-icon-margin-top: 10px;

@custom-dashboard-sharing-targets-scroll-container-margin-right: 13px;
@custom-dashboard-sharing-target-name-padding-left: 25px;
@custom-dashboard-sharing-sub-target-name-padding-left: 45px;
@custom-dashboard-sharing-active-color: #5DBA7D;

@custom-dashboard-sharing-targets-container-overflow-y: scroll;
@custom-dashboard-sharing-targets-container-max-height: 350px;
@custom-dashboard-sharing-targets-container-margin-top: 30px;

@custom-dashboard-sharing-targets-list-border-top: 1px dashed #EEEEEE;
@custom-dashboard-sharing-targets-list-color: #666666;
@custom-dashboard-sharing-targets-list-font-size: 16px;
@custom-dashboard-sharing-targets-list-line-height: 44px;
@custom-dashboard-sharing-targets-list-font-weight: 200;

@custom-dashboard-sharing-info-icon-height: 24px;
@custom-dashboard-sharing-info-icon-width: 24px;
@custom-dashboard-sharing-info-icon-fill: @gray10;
@custom-dashboard-sharing-check-icon-svg-margin-bottom: 10px;
@custom-dashboard-sharing-modal-form-footer-margin-top: 0px;

@error-message-red: #D21E34;

@nested-menu-text-color: @dropdown-menu-link-color;
@nested-menu-width: 223px;
@nested-dropdown-arrow-light-bg: 2px solid #BBB;
@nested-dropdown-arrow-dark-bg: 2px solid @nested-menu-text-color;

  // Footer
@footer-bg-color: transparent;
@footer-color: @text-color-default;
@footer-font-size: @font-size-caption1;
@footer-line-height: 16px;
@footer-height: 80px;
@footer-padding: 32px 0;
@footer-thinner-padding: 20px 0;

@bucket-strategy-logo-width: 15px;
@bucket-strategy-logo-height: 15px;

@bucket-strategy-text-color: #999;
@bucket-strategy-text-margin-left: 5px;
@bucket-strategy-text-transform: uppercase;
@bucket-strategy-text-font-weight: 200;


 // Sidebar
@sidebar-dark-bg-dark-box-shadow: 0 2px 5px rgba(0,0,0,0.76);
@sidebar-dark-bg-color: @gray-dark;
@sidebar-dark-bg-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
@sidebar-dark-bg-expand-button-bg-color: @gray9;
@sidebar-dark-bg-font-color: @gray7;
@sidebar-dark-bg-very-light-font-color: #F9F9F9;
@sidebar-dark-bg-bold-font-weight: @font-weight-regular;
@sidebar-dark-bg-section-border: 1px solid @gray9;
@sidebar-dark-bg-section-icons-fill: @sidebar-dark-bg-font-color;
@sidebar-dark-bg-active-section-color: @white;
@sidebar-dark-bg-collapsed-color: @white;
@sidebar-dark-bg-collapsed-hover-bg-color: @blackBG;
@sidebar-dark-bg-collapsed-hover-color: @sidebar-dark-bg-active-section-color;
@sidebar-collapsed-width: 20px;
@sidebar-expanded-width: 220px;
@sidebar-expanded-wider-width: 260px;
@sidebar-horizontal-spacing: 20px;
@sidebar-horizontal-reduced-spacing: 10px;
@sidebar-expandable-section-spacing: 15px;
@sidebar-font-size: 14px;
@sidebar-section-title-font: @font-size-caption1;
@sidebar-section-title-padding: 12px 10px;
@sidebar-section-title-line-height: 20px;
@sidebar-light-bg-color: @white;
@sidebar-light-bg-font-color: @text-color-default;
@sidebar-light-bg-dark-font-color: @text-color-bold;
@sidebar-light-bg-inactive-font-color: @gray2;
@sidebar-light-bg-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
@sidebar-light-bg-border: 1px solid #EEEEEE;
@sidebar-light-bg-input-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
@sidebar-light-bg-tabs-color: @sidebar-light-bg-inactive-font-color;
@sidebar-light-bg-section-border: @light-gray-border;
@sidebar-light-bg-section-icons-fill: @sidebar-light-bg-light-font-color;
@sidebar-light-bg-section-hover-color: @sidebar-light-bg-expanded-hover-font-color;
@sidebar-light-bg-section-hover-bg-color: fade(@blueGray, 10);
@sidebar-light-bg-section-active-bg-color: fade(@blueGray, 30);
@sidebar-light-bg-light-font-color: @gray8;
@sidebar-light-bg-bold-font-weight: @font-weight-semi-bold;
@sidebar-light-bg-collapsed-hover-color: #3B434D;
@sidebar-light-bg-expanded-hover-font-color: @black;
@sidebar-light-bg-expanded-hover-bg-color: #F0F0F0;
@sidebar-light-bg-expanded-hover-active-bg-color: @gray-lightest;
@sidebar-light-bg-expand-collapse-color: @sidebar-light-bg-font-color;
@sidebar-icon-size-small: 10px;
@sidebar-expand-collapse-padding: 2px 8px;
@sidebar-expand-collapse-border-radius: 50%;
@sidebar-expand-collapse-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
@sidebar-selected-options-size: 13px;
@sidebar-single-tab-padding-top: 60px;
@sidebar-tab-border-radius: 2px;
@sidebar-tab-top-space: 30px;
@sidebar-scrollbar-width: 12px;
@sidebar-expandable-section-title-total-horizontal-margin: 2 * @sidebar-scrollbar-width;
@sidebar-section-width: @sidebar-expanded-width - @sidebar-expandable-section-title-total-horizontal-margin;
@sidebar-wide-section-width: @sidebar-expanded-wider-width - @sidebar-expandable-section-title-total-horizontal-margin;

// Modal form
@modal-form-body-padding: 20px 30px 40px;


// Progress bar
@progress-bar-width: 100%;
@progress-bar-height: 14px;
@progress-bar-small-height: 10px;
@progress-bar-border-radius: 20px;
@progress-bar-small-border-radius: 10px;
@progress-bar-on-track : @on-track-alert;
@progress-bar-positive: @positive-alert;
@progress-bar-negative: @negative-alert;
@progress-bar-neutral: @neutral-alert;

// Form
@form-font-size: @font-size-base;
@form-title-font-size: 20px;
@form-title-margin-top: 1.8em;
@checkbox-label-left-padding: 30px;

// Borders
@light-gray-border: 1px solid #DDDDDD;
@light-gray-dashed-border: 1px dashed #DDDDDD;
@lighter-gray-border: 1px solid #EEEEEE;
@gray-border: 1px solid #CCCCCC;

// Shortcut Button
@shortcut-btn-round-size:20px;
@shortcut-btn-round-rotate:45deg;
@shortcut-btn-default-color: #FFFFFF;

// Tooltips
@tooltip-arrow-position: 10px;
@tooltip-position-correction: @tooltip-arrow-position + 3px; // To align the tip of the arrow to the given position (3px is about half the arrow size)

// Recommendation:
@recommendation-text-view-width: 600px;
@recommendation-preview-bg-color: @background-medium-color;
@recommendation-overlay-bg-color: @background-active-color;
@recommendation-collapse-button-size: 30px;
@recommendation-modal-multi-card-vertical-margin: 1vh; //(100vh - 98vh) / 2
