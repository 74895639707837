.alert {
  margin-bottom : @alert-margin-bottom;
  padding: @alert-padding;

  a {
    text-decoration: underline;
  }

  &.alert-header {
    margin-bottom: @alert-margin-bottom;
    padding: @alert-header-padding;
    border-bottom: 1px solid;
    height: @header-alert-height;
  }
}

.alert {
  &.alert-danger {
    .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);

    .invisible-text {
      color: @alert-danger-bg;
    }
  }

  &.alert-warning {
    .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);

    .invisible-text {
      color: @alert-warning-bg;
    }
  }

  &.alert-demo-mode {
    .alert-variant(@alert-demo-mode-bg; @alert-demo-mode-border; @alert-demo-mode-text);

    .invisible-text {
      color: @alert-demo-mode-bg;
    }
  }

  &.alert-positive {
    .alert-variant(@alert-positive-bg, @alert-positive-border, @alert-positive-text);

    .invisible-text {
      color: @alert-positive-bg;
    }
  }
}

.container .alert {
  border: 1px solid;
  border-radius: @alert-border-radius;
}

.alert.alert-no-border {
  border:none;
  border-radius: 0;
}

.alert-modal {
  text-align: center;
  .alert-positive {
    color: @positive-alert;

    svg {
      fill: @positive-alert;
      margin-right: 10px;
    }
  }
}
