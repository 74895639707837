.view-description {
  display: flex;
  max-width: 70%;
  align-items: center;

  .section-header-sub-title {
    font-size: @font-size-bodyText;
    font-weight: @font-weight-medium;
  }

  .dot-separator {
    padding: 0 8px;
    color: @text-disabled-color;
    font-size: @font-size-bodyText;
  }

  &.flex-space {
    justify-content: space-between;
  }

  .folder-count {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    font-size: @font-size-bodyText;
    font-weight: @font-weight-medium;

    .label {
      margin: 0 6px;
    }

    svg {
      fill: @icon-weak-color;
    }

    .folder-icon, .arrow-icon {
      display: flex;
      align-items: center;
    }

    .folder-icon svg {
      width: 16px;
      height: 16px;
    }

    .arrow-icon svg {
      width: 16px;
      height: 16px;
    }
  }

  .subtitle-tooltip {
    white-space: nowrap;
  }

  &.opp-health-subtitle {
    max-width: 70%;

    .subtitle-tooltip {
      max-width: 65%;
    }
  }

  &.dashboard-subtitle,
  &.saved-goal-view-subtitle {
    max-width: 75%;

    .subtitle-tooltip {
      max-width: 75%;
    }
  }

  &.saved-alert-subtitle {
    max-width: 77%;

    .subtitle-tooltip {
      max-width: 78%;
    }
  }

  &.saved-card-subtitle {
    max-width: 100%;

    .subtitle-tooltip {
      max-width: 78%;
    }

    &.root-metric .subtitle-tooltip{
      max-width: 100%;
    }
  }

  @media only screen and (min-width: @screen-md) {
    &.saved-alert-subtitle {
      max-width: 80%;

      .subtitle-tooltip {
        max-width: 82%;
      }
    }

    &.dashboard-subtitle .subtitle-tooltip, &.saved-goal-view-subtitle .subtitle-tooltip {
      max-width: 80%;
    }

    &.saved-card-subtitle .subtitle-tooltip {
      max-width: 82%;
    }

    &.opp-health-subtitle {
      max-width: 65%;

      .subtitle-tooltip {
        max-width: 78%;
      }
    }
  }

  @media only screen and (min-width: @screen-md-lg) {
    &.saved-goal-view-subtitle,
    &.dashboard-subtitle {
      max-width: 80%;

      .subtitle-tooltip {
        max-width: 85%;
      }
    }

    &.opp-health-subtitle {
      max-width: 70%;

      .subtitle-tooltip {
        max-width: 85%;
      }
    }

    &.saved-alert-subtitle .subtitle-tooltip {
      max-width: 85%;
    }

    &.saved-card-subtitle .subtitle-tooltip {
      max-width: 88%;
    }
  }

  @media only screen and (min-width: @screen-lg) {
    &.saved-goal-view-subtitle,
    &.dashboard-subtitle {
      max-width: 80%;

      .subtitle-tooltip {
        max-width: 88%;
      }
    }

    &.saved-alert-subtitle {
      max-width: 82%;

      .subtitle-tooltip {
        max-width: 88%;
      }
    }

    &.opp-health-subtitle {
      max-width: 75%;

      .subtitle-tooltip {
        max-width: 85%;
      }
    }
  }

  @media only screen and (min-width: @screen-xl) {
    &.dashboard-subtitle, &.saved-goal-view-subtitle, &.saved-alert-subtitle, &.opp-health-subtitle, &.saved-card-subtitle {
      .subtitle-tooltip {
        max-width: 100%;
      }
    }
  }
}
