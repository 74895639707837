
// TODO: See if anyone still uses this, and if not, make this an overlapping version by removing the before treatment
.arrow(@angle, @arrow-color, @arrow-size, @arrow-border-width) {
  &:before {
    position: relative;
    display: inline-block;
    content: "";
    /* By using an em scale, the arrows will size with the font */
    width: @arrow-size;
    height: @arrow-size;
    border-right: @arrow-border-width solid @arrow-color;
    border-top: @arrow-border-width solid @arrow-color;
    transform: rotate(@angle);
    color: #fff;
  }
}

.left-arrow(@arrow-color, @arrow-size, @arrow-border-width) {
  .arrow(225deg,@arrow-color, @arrow-size, @arrow-border-width);
}

.right-arrow(@arrow-color, @arrow-size, @arrow-border-width) {
  .arrow(45deg,@arrow-color, @arrow-size, @arrow-border-width);
}

.down-arrow(@arrow-color, @arrow-size, @arrow-border-width) {
  .arrow(135deg,@arrow-color, @arrow-size, @arrow-border-width);
}

.up-arrow(@arrow-color, @arrow-size, @arrow-border-width) {
  .arrow(315deg,@arrow-color, @arrow-size, @arrow-border-width);
}

// I believe the before treatment makes this non-overlapping
.arrow-non-overlapping(@angle, @arrow-color, @arrow-size, @arrow-border-width) {
  &:before {
    position: relative;
    display: inline-block;
    content: "";
    /* By using an em scale, the arrows will size with the font */
    width: @arrow-size;
    height: @arrow-size;
    border-right: @arrow-border-width solid @arrow-color;
    border-top: @arrow-border-width solid @arrow-color;
    transform: rotate(@angle);
    color: #fff;
  }
}

.left-arrow-non-overlapping(@arrow-color, @arrow-size, @arrow-border-width) {
  .arrow-non-overlapping(225deg,@arrow-color, @arrow-size, @arrow-border-width);
}

.right-arrow-non-overlapping(@arrow-color, @arrow-size, @arrow-border-width) {
  .arrow-non-overlapping(45deg,@arrow-color, @arrow-size, @arrow-border-width);
}

.down-arrow-non-overlapping(@arrow-color, @arrow-size, @arrow-border-width) {
  .arrow-non-overlapping(135deg,@arrow-color, @arrow-size, @arrow-border-width);
}

.up-arrow-non-overlapping(@arrow-color, @arrow-size, @arrow-border-width) {
  .arrow-non-overlapping(315deg,@arrow-color, @arrow-size, @arrow-border-width);
}

// TODO: arrow-filled is the same as triangle, right? Dedup.

.arrow-filled() {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: @pagination-circle-width/2 solid transparent;
  border-bottom: @pagination-circle-width/2 solid transparent;
}

.left-arrow-filled() {
    .arrow-filled();
    border-right:@pagination-circle-width/2 solid @white;
}

.right-arrow-filled() {
    .arrow-filled();
    border-left:@pagination-circle-width/2 solid @white;
}
