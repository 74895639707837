.chart {
  position: relative;

  .chart-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.alert-in-chart {

  &.negative {
    .value {
      color: @negative-alert;
    }

    .anomaly-icon .tooltip-shape {
      background-color: @negative-alert;
    }
  }


  &.positive {
    .value {
      color: @positive-alert;
    }

    .anomaly-icon .tooltip-shape {
      background-color: @positive-alert;
    }
  }


  &.neutral {
    .value {
      color: @neutral-alert;
    }

    .anomaly-icon .tooltip-shape {
      background-color: @neutral-alert;
    }
  }

  .value {
    text-align: center;
    margin-bottom: 8px;
    font-size: @font-size-bodyText;
    line-height: @font-size-bodyText;
    white-space: nowrap;
  }

  .anomaly-icon {
    position: relative;

    .text-container {
      text-align: left;
      font-size: 14px;
      font-weight: @font-weight-thin;

      a {
        text-decoration: underline;
        color: @blue-link;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .tooltip-shape {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      padding: 3px;
      margin: 0 auto;
      line-height: 0;

      svg {
        width: 9px;
        height: 9px;
        fill: #fff;
      }
    }
  }
}

.axis-container .axis-legend {
  text-anchor: middle;
  fill: @gray;
  font-size: 13px;
}

.stacked-bar-chart {
  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    stroke-dasharray: 3, 2;
  }

  .axis path.domain {
    stroke-dasharray: none; // Solid
  }

  path.contact {
    stroke: none;
  }

  .y-axis .tick line {
    stroke: #ddd;
  }

  .y-axis .tick text {
    fill: @gray-light2;
    font-size: 11px;
  }

  &.multi-target, &.single-target {
    .x-axis .axis .tick {
      display: none;
    }
    .x-axis .axis text {
      display: none;
    }
  }

  // Hiding first line of y axis, we don't want to overlap with the x axis
  .y-axis.visible-lines .axis g:first-of-type line {
    display: none;
  }

  .total {
    fill: @stacked-bar-chart-total-text-color;
    text-anchor: middle;
    font-size: @font-size-caption1;
  }

  .column-label {
    text-transform: uppercase;
  }

  .column-label, .column-label-divider {
    font-size: 8px;
    fill: @stacked-bar-chart-total-text-color;
    text-anchor: middle;
  }

  .bar text {
    text-anchor: middle;
    fill: @stacked-bar-chart-bar-text-color;
  }
}

.waterfall-bar-chart {
  .axis path {
    stroke: @gray5;
  }
  
  .large-card &,
  .medium-card & {
    .bar-chart-x-axis .entry {
      &:not(:first-child):not(:last-child) {
        display: none;
      }
      
      &:first-child, &:last-child {
        overflow: visible;
      }
    }
  }

  .medium-card & {
    .bar-chart-x-axis .sub-label {
      font-size: 11px;
    }
  }
}

.horizontal-bar-chart {
  display: flex;

  .bar-total {
    display: flex;
    align-items: center;
    max-width: 80px;
    color: @stacked-bar-chart-total-text-color;
    pointer-events: auto;

    .anomaly-icon {
      display: inline-block;
      margin-left: 10px;
    }

    .value {
      margin-bottom: 0;
      font-size: @font-size-caption1;

      &.small-font {
        font-size: @font-size-caption2;
      }
    }
  }

  .axis path {
    fill: none;
    stroke: @gray-dark;

    &.domain {
      stroke-dasharray: none; // Solid
    }

    &.contact {
      stroke: none;
    }
  }

  .x-axis .tick line {
    stroke: #E1E1E1;
    stroke-dasharray: 3, 2;
  }

  .x-axis .tick text {
    fill: @gray-light2;
    font-size: 11px;
  }

  .y-axis .axis .tick {
    display: none;
  }

  .y-axis .axis text {
    display: none;
    padding-top: 20px;
  }

  .bar text {
    fill: @stacked-bar-chart-bar-text-color;
    font-size: 0.8em;
    text-anchor: middle;
  }

  .xy-axis.visible-y-base {
    .x-axis.hidden-lines,
    .x-axis.visible-lines {
      .axis g:first-of-type line {
        display: block;
        stroke: @gray-dark;
        stroke-dasharray: none;
        stroke-width: 2px;
      }
    }
  }

  .marker-text {
    display: inline-block;
  }

  .bar-chart-y-axis {
    .entry {
      .label {
        width: 100%;

        .full-name {
          display: inline;
        }
      }

      .label-explanation {
        display: inline-block;

        .subtitle {
          font-style: italic;
        }

        .tooltip .tooltip-inner {
          text-align: left;

          p {
            padding: 0;
            margin: 0;
          }

          a {
            color: @blue-link;
          }
        }
      }

      & .text-ellipsis {
        white-space: nowrap;

        .full-name {
          display: inline-block;

          .sub-name {
            display: inline-block;
            vertical-align: middle;
          }
        }

        .label-name {
          display: inline-block;
          vertical-align: middle;
          .text-ellipsis();
        }
      }
    }

    .anomaly-icon {
      display: inline-block;
    }
  }
}

@media only screen and (max-width:@page-width) {
  .stacked-bar-chart, .horizontal-bar-chart {
    .bar text {
      font-size: @font-size-caption2;
    }
  }

  .in-progress-marker text,
  .stacked-bar-chart .total,
  .horizontal-bar-chart .value {
    font-size: @font-size-caption1;
  }

  .card-preview-medium {
    .stacked-bar-chart .total,
    .horizontal-bar-chart .value{
        font-size: @font-size-caption2;

    }
  }

  .card-detail-view {

    .axis-legend {
      font-size: 13px;
    }

    .card-body-with-drawer {
      .y-axis, .horizontal-bar-chart .x-axis {
        .tick:nth-child(odd) {
          display: none;
        }
      }

      .stacked-bar-chart.single-target .bar-chart-x-axis .entry {
        font-size: 11px;
      }
    }
  }
}

.in-progress-marker text {
  fill: @gray1;
  text-anchor: middle;
  font-size: @font-size-caption1;
  text-transform: uppercase;
}

.x-axis > .axis .tick text {
  display: none;
}

.full-screen-card, .presentation-card {
  .line-chart-container .line-chart-box {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .horizontal-bar-chart {
    overflow-y: auto;
    overflow-x: hidden;

    .x-axis > .axis .tick text {
      display: block;
    }
  }

  .line-chart,
  .stacked-bar-chart {
    .x-axis > .axis .tick text {
      display: block;
      transform: rotate(-45deg) translate(-50px);
      font-size: 12px;
    }
  }

  .waterfall-bar-chart {
    .x-axis > .axis .tick text {
      display: none;
    }

    .xy-axis.visible-x-base .y-axis.visible-lines {
      .axis g:first-of-type line {
        stroke-width: 3px;
      }
    }

    .axis-container .axis-legend {
      display: none;
    }
  }

  .line-chart .y-axis > .axis .tick text {
    fill: @gray;
    font-size: 11px;
  }
}

.line-chart-container {
  position: relative;

  .line-chart-box {
    display: inline-block;
    overflow-x: hidden;
    overflow-y: hidden;
    .white-bg-scrollbar();
  }

  .marker line {
    stroke-dasharray: 5, 3;
  }
}

.line-chart {
  .x-axis .tick {
    display: none;
  }
  .axis .tick line {
    fill: none;
    stroke: #DDD;
    stroke-dasharray: 3, 2;
  }

  .x-axis > .axis {
    > path {
      display: none;
    }
  }

  // Fakes that the x-axis makes it to 0
  .y-axis > .axis > g:first-of-type > line {
    stroke: #000;
    stroke-dasharray: none;
  }

  .line path {
    stroke-width: 2px;

    &.dotted {
      stroke-dasharray: 3, 2;
    }
  }

  &.small-chart {
    .line > path {
      stroke-width: 1px;
    }
  }
}

.preview {
  .line-chart-legend .legend-entry {
    .color.open-square {
      border-width: 2px;
    }
    
    .label-explanation .tooltip {
      a {
       display: none; 
      }
    }

    .label {
      white-space: nowrap;
    }
  }

  .bar-chart-y-axis {
    .entry {
      .text-ellipsis {
        white-space: nowrap;

        .full-name {
          display: inline-block;
          vertical-align: middle;
          white-space: nowrap;
          .text-ellipsis();

          .label-name, .sub-name {
            display: inline;
            vertical-align: unset;
          }
        }
      }

      .label-explanation .tooltip {
        a {
          display: none;
        }
      }
    }
  }

  .marker.marker-text {
    font-size: 13px;
  }
}

.multi-column-legend {
  font-size: @multi-column-legend-entry-text-font-size;
  line-height: @multi-column-legend-entry-text-line-height;
  color: @multi-column-legend-entry-text-color;
  text-align: center;
  padding: 22px 20px 0;

  &.vertical-chart {
    padding-top: 40px;
  }

  &.reduced {
    padding-top: 25px;

    .legend-title {
      padding-bottom: 12px;
    }

    .legend-entry {
      margin-bottom: 5px;

      .legend-entry-content {
        height: 17px;
      }

      .color {
        width: 16px;
        height: 16px;
      }
    }
  }

  .legend-title {
    color: @black;
    font-weight: @font-weight-semi-bold;
    line-height: 17px;
    text-transform: uppercase;
    padding-bottom: 18px;
  }

  .legend-entry {
    display: inline-block;
    padding-left: @multi-column-legend-entry-padding-left;
    margin-bottom: 10px;

    &:first-child {
      padding-left: 0;
    }

    .color {
      width: @multi-column-legend-entry-color-size;
      height: @multi-column-legend-entry-color-size;
      border-radius: @multi-column-legend-entry-color-radius;
      display: inline-block;
      vertical-align: middle;

      &.stripe-mask {
        // This matches the mask for the svgs
        background-image: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 1) 1px, transparent 2px, transparent 3px, transparent 4px, transparent 5px, transparent 6px);
        border: 1px solid #333333;
      }
    }

    .text {
      display: inline-block;
      padding-left: @multi-column-legend-entry-text-padding-left;
      width: auto;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  &.small {
    font-size: @font-size-caption1;
    line-height: 13px;
    padding-top: 18px;

    .legend-title {
      padding-bottom: 5px;
      line-height: 16px;
    }

    .legend-entry {
      .legend-entry-content {
        height: 14px;
      }
    }

    .color {
      height: 12px;
      width: 12px;

      &.stripe-mask {
        // This matches the mask for the svgs
        background-image: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 1) 1px, transparent 2px, transparent 3px, transparent 4px, transparent 5px, transparent 4px);
      }
    }
  }
}

.read-only-legend {
  text-align: center;
  font-size: @font-size-bodyText;
  font-style: italic;
  font-weight: 300;
  color: @gray1;
  padding: 15px 15px 0;

  .group-label {
    color: @black;
    font-weight: 400;
    text-transform: capitalize;
  }

  &.small {
    padding: 0 20px;
    font-size: @font-size-caption1;

    &.extra-space {
      padding-top: 15px;
    }
  }

  &.extra-space {
    padding-top: 30px;
  }
}

.no-spotlighted {
  opacity: 0.2;
}

.line-chart-legend {
  display: inline-block;
  overflow: hidden;

  > div {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-right: 10px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: border-box; /* So the width will be 100% + 17px */

    > ul {
      .un-styled-list();
    }
  }

  .legend-entry {
    cursor: pointer;

    .color {
      display: inline-block;

      &.circle {
        border-radius: @line-chart-legend-color-radius;
        background-color: @line-chart-legend-color-default-bg-color;
      }

      &.open-square {
        border: 3px solid;
      }

      &.triangle {
        width: 0;
        height: 0;
        border: solid transparent;
        vertical-align: super;
      }
    }

    .label {
      display : inline-block;
      vertical-align: text-top;

      &.multiline {
        .label-name {
          display: inline-block;
          .text-ellipsis();
        }

        .sub-name {
          display: inline-block;
          vertical-align: top;
        }
      }
    }

    .name-container {
      display: inline-block;
      vertical-align: middle;
      .text-ellipsis();
    }

    span.text {
      cursor: default;
      color: @line-chart-legend-text-color;
    }

    a.text {
      cursor: pointer;
      color: @line-chart-legend-text-color;
    }

    .label-explanation {
      display: inline-block;
      vertical-align: middle;

      &.block {
        display: block;
      }

      .subtitle {
        display: inline-block;
        font-style: italic;
        color: @gray-light2;
        font-weight: 300;
        vertical-align: top;
      }

      .tooltip .tooltip-inner {
        text-align: left;

        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .alert-in-chart {
    &.small .tooltip-shape {
      width: 8px;
      height: 8px;
      padding: 1px;
      vertical-align: middle;

      svg {
        width: 6px;
        height: 6px;
      }
    }

    &.medium .tooltip-shape {
      width: 12px;
      height: 12px;
      padding: 2px;
      vertical-align: top;

      svg {
        width: 8px;
        height: 8px;
      }
    }

    &.large .tooltip-shape {
      width: 14px;
      height: 14px;
      padding: 2px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.full-screen-card .line-chart-legend,
.presentation-card .line-chart-legend {
  > div {
    padding-right: 15px;
  }
}

.bar-chart-x-axis {
  .entry {
    display: inline-block;
    cursor: pointer;
    color: @gray;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;

    &.diagonal-label {
      white-space: nowrap;
    }

    a {
      color: @gray;
    }

    .line-chart-box &, .stacked-bar-chart.single-target & {
      font-size: @bar-chart-x-axis-entry-rotated-font-size;
    }

    .waterfall-bar-chart & {
      font-size: 13px;
    }

    > div {
      .ellipsis();
    }
  }

  .label {
    color: @gray;
    display: inline-block;
    position: absolute;
    font-size: @bar-chart-x-axis-entry-font-size;
    line-height: @bar-chart-x-axis-entry-line-height;
    text-align: center;
    cursor: pointer;
  }

  .sub-label {
    white-space: nowrap;
  }
}

.bar-chart-y-axis {
  .entry {
    width: 100%;
    display: flex;
    text-align: right;
    color: @gray-dark;
    align-items: center;
    justify-content: flex-end;

    a {
      color: @gray-dark;
    }
  }

  .label.link {
    cursor: pointer;
  }

  .subtitle {
    display: inline-block;
  }
}

.bar-chart-x-axis, .bar-chart-y-axis {
  position: relative;

  .entry {
    position: absolute;
    font-size: @bar-chart-x-axis-entry-font-size;
    line-height: @bar-chart-x-axis-entry-line-height;
  }

  .subtitle {
    color: @gray-light2;
    font-weight: 300;
    font-size: 13px;
  }
}

.large-card {
  .bar-chart-x-axis, .bar-chart-y-axis {
    .entry, .subtitle {
      font-size: @bar-chart-x-axis-entry-font-size-large-chart;
      line-height: @bar-chart-x-axis-entry-line-height-large-chart;
    }
  }

  .bar-chart-x-axis {
    .text-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media only screen and (max-width:@page-width) {
  .large-card {
    .bar-chart-y-axis {
      .entry, .subtitle {
        font-size: @font-size-caption2;
        line-height: @font-size-caption2;
      }
    }
  }
}

.medium-card {
  .bar-chart-x-axis, .bar-chart-y-axis {
    .entry, .subtitle {
      font-size: @bar-chart-x-axis-entry-font-size-medium-chart;
      line-height: @bar-chart-x-axis-entry-line-height-medium-chart;
    }
  }
}

.small-card {
  .bar-chart-x-axis, .bar-chart-y-axis {
    .entry, .subtitle {
      font-size: @bar-chart-x-axis-entry-font-size-small-chart;
      line-height: @bar-chart-x-axis-entry-line-height-small-chart;
    }
  }
}

.marker {
  color: @marker-color-default;

  line {
    stroke: @marker-color-default;
    stroke-dasharray: 3, 2;
    stroke-width: 2px;
  }

  text {
    fill: @marker-color-default;
  }


  &.marker-text {
    pointer-events: auto;

    .marker-title {
      font-size: 0.65em;
      line-height: 1em;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
    }

    .marker-value {
      font-weight: 700;
      font-size: 0.8em;
      line-height: 1em;
      text-align: center;
    }

    .marker-legend-container {
      line-height: .9em;
      padding-right: .3em;

      .marker-legend {
        font-size: 0.7em;
        color: @gray;
        display: inline;
      }

      .marker-legend-explanation {
        margin-left: .2em;
        line-height: .5em;
        height: .8em;
        svg {
          fill: @gray;
          height: 1em;
          width: 1em;
        }
      }
    }


  }

  &.black {
    line {
      stroke: @marker-color-black;
      stroke-dasharray: 3, 2
    }

    &.marker-text {
      color: @marker-color-black;
    }
  }

  &.green {
    line {
      stroke: @marker-color-green;
    }

    &.marker-text {
      color: @marker-color-green;
    }
  }

  &.red {
    line {
      stroke: @marker-color-red;
    }

    &.marker-text {
      color: @marker-color-red;
    }
  }

  &.blue {
    line {
      stroke: @marker-color-blue;
    }

    &.marker-text {
      color: @marker-color-blue;
    }
  }
}

.x-small-chart,
.small-chart,
.medium-chart,
.large-chart {
  .marker.marker-text {
    .marker-title,
    .marker-legend-container {
      display: none;
    }
  }
}

.line-chart,
.stacked-bar-chart,
.horizontal-bar-chart {
  .x-axis.hidden-labels,
  .y-axis.hidden-labels {
    .axis .tick text {
      display: none;
    }
  }
}

.x-axis.hidden-lines,
.y-axis.hidden-lines {
  .axis .tick line {
    display: none;
  }
}

.line-chart,
.stacked-bar-chart,
.horizontal-bar-chart {
  .x-axis.hidden-labels,
  .y-axis.hidden-labels {
    .axis .tick text {
      display: none;
    }
  }
}

.xy-axis.hidden-x-base .x-axis,
.xy-axis.hidden-y-base .y-axis {
  .axis .domain {
    display: none;
  }
}

// x axis base line is tricky to display because of the padding, displaying the first line of the y-axis as a solid line
// to create the illusion of the x-axis base line
.xy-axis.visible-x-base {
  .y-axis.hidden-lines,
  .y-axis.visible-lines {
    .axis g:first-of-type line {
      display: block;
      stroke: #000;
      stroke-dasharray: none;
    }
  }
}

.card-content.axis-legend {
  text-align: center;
  color: @gray;
  padding-bottom: 10px;
}

.card-content.axis-title {
  text-align: center;
  color: @black;

  a {
    color: @black;
   
    &:hover {
      text-decoration: underline;
    }
  }
}

.card-content.axis-description {
  text-align: center;
}

.x-small-card,
.small-card,
.medium-card{
  .axis-header {
    display: none;
  }

  .ta-info {
    display: none;
  }
}

.large-card {
  .card-content.axis-legend {
    font-size: .8rem;
    line-height: 12px;
    margin-bottom: 5px;
  }

  .card-content.axis-title {
    display: none;
  }

  .ta-info {
    display: none;
  }

  .horizontal-bar-chart {
    overflow: hidden;
  }

  .multi-column-legend,
  .multi-column-legend.small {
    padding: 15px 10px 0;

    .legend-title {
      display: inline;
      font-size: 10px;
      line-height: 12px;
    }

    .color-legend {
      display: inline;
      font-size: 11px;

      .legend-entry {
        margin-bottom: 4px;
        padding-left: 7px;
        color: @black;

        .color {
          height: 10px;
          width: 10px;
        }

        .text {
          padding-left: 5px;
        }
      }
    }
  }

  .read-only-legend,
  .read-only-legend.small {
    font-size: 11px;
    padding: 3px 10px 0;
    line-height: 13px;

    &.extra-space {
      padding-top: 15px;
    }

    > div {
      display: inline;
    }

    .group-label {
      font-weight: @font-weight-semi-bold;
    }
  }

  .in-progress-marker text {
    font-size: 11px;
  }
}

.x-large-card {
  .axis-header {
    padding-top: 12px;
  }

  .card-content.axis-legend {
    font-size: 13px;
    line-height: 12px;
  }

  .card-content.axis-title {
    display: none;
  }

  .ta-info {
    display: none;
  }

  .marker-text {
    font-size: 13px;
  }

  .bar-chart-x-axis, .bar-chart-y-axis {
    .entry {
      font-size: 12px;
    }
  }
  .axis-container .axis-legend {
    display: none;
  }

  .read-only-legend {
    font-size: 12px;
    padding: 10px 0;
  }

  .multi-column-legend {
    font-size: 12px;
    padding: 10px 0 0;

    .legend-title {
      padding-bottom: 8px;
    }

    .legend-entry {
      padding-left: 20px;
      margin-bottom: 5px;

      &:first-child {
        padding-left: 0;
      }

      .color {
        width: 12px;
        height: 12px;
      }

      .text {
        padding-left: 6px;
      }
    }
  }

  .horizontal-bar-chart {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.full-screen-card, .presentation-card {
  .axis-header {
    padding-top: 15px;
  }

  .card-content.axis-legend {
    font-size: 13px;
    line-height: 17px;
    color: @gray;
  }

  .card-content.axis-title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin: 0 100px 5px;
  }
}

.bucketed-anomaly-page {
  .axis-header {
    padding-bottom: 20px;
  }

  .multi-column-legend {
    padding-top: 40px;
  }

  .read-only-legend {
    padding-top: 15px;
  }
}


.presentation-card {
  .card-content {
    &.axis-legend {
      font-size: 17px;
    }

    &.axis-title {
      font-size: 18px;
    }
  }

  .axis-header {
    padding-top: 25px;
  }

  .line-chart .y-axis > .axis .tick text {
    font-size: 13px;
  }

  .bar-chart-x-axis,
  .bar-chart-y-axis {
    .entry {
      font-size: 16px;
    }
  }

  .horizontal-bar-chart {
    .x-axis .tick text {
      font-size: 14px;
    }

    .bar-total .value {
      font-size: 16px;
    }

    .bar text {
      font-size: 1em;
    }
  }

  .line-chart,
  .stacked-bar-chart {
    .x-axis > .axis .tick text {
      font-size: 13px;
    }
  }

  .stacked-bar-chart {
    .y-axis .tick text {
      font-size: 13px;
    }

    .total {
      font-size: 14px;
    }

    &.single-target .bar-chart-x-axis .entry {
      font-size: 16px;
    }
  }

  .bar-chart-x-axis .subtitle,
  .bar-chart-y-axis .subtitle {
    font-size: 15px;
  }

  .line-chart-legend {
    .legend-entry {
      font-size: 16px;
    }

    .alert-in-chart.large .tooltip-shape {
      width: 16px;
      height: 16px;
      padding: 3px;
    }
  }

  .marker.marker-text {
    .marker-title {
      font-size: 1.0em;
      line-height: 1.2em;
    }

    .marker-value {
      font-size: 1.2em;
      line-height: 1.3em;
    }
  }


  .multi-column-legend {
    font-size: 16px;
    padding-top: 30px;

    .legend-title {
      padding-bottom: 14px;
    }

    .legend-entry {
      margin-bottom: 16px;
    }

    &.small {
      .color {
        height: 16px;
        width: 16px;
      }
    }
  }

  .read-only-legend {
    padding: 9px 15px 0;

    &.small {
      font-size: 15px;
      padding: 0 20px;
    }

    &.extra-space {
      padding: 30px 20px 0;
    }
  }
}


// Styles for downloading image
.full-screen-card.card-graph-view.static-img {
  padding: 10px 0 10px;
  position: relative;
  overflow: hidden;

  .horizontal-bar-chart,
  .line-chart-container .line-chart-box {
    overflow-y: hidden;
  }

  .line-chart-legend > div {
    overflow-y: hidden;
  }

  .axis-header {
    padding-top: 0;
    line-height: 24px;
  }

  .axis-title.top-title {
    padding-top: 10px;
    padding-bottom: 5px;
    margin: 0 150px 5px;
  }

  .axis-description {
    padding-bottom: 5px;
  }

  .atrium-logo {
    margin: 5px 40px 0;
    position: absolute;
    right: 0;
    opacity: 1;

    svg {
      width: 80px;
    }
  }

  .axis-container .axis-legend {
    display: none;
  }
}
