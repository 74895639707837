.saved-goal-status-edit-table-row-view {
  flex: 1 0 0;
  border-bottom: 1px dashed @border-color;

  &:last-of-type {
    border-bottom: none;
  }
  &-status-content {
    height: 100%;
    > .row {
      height: 100%;
    }
  }
  &-cell {
    padding: 12px 16px 0;
    border-left: 1px dashed @border-color;

    &.team-cell {
      padding-left: 26px;

      .select-all-tooltip {
        width: max-content;
      }
    }
  }
}

.saved-goal-status-edit-table-row-view-cell,
.goal-status-team-data {
  .link-button-row {
    padding: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    visibility: hidden;
  }
}

.saved-goal-status-edit-table-row-view-cell,
.goal-status-team-data {
  min-height: 77px;

  &:hover {
    .link-button-row {
      visibility: visible;
    }
  }
}

.empty-row {
  .saved-goal-status-edit-table-row-view-cell,
  .goal-status-team-data {
    .link-button-row {
      visibility: visible;
    }
  }
}
