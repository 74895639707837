//
// -- ICON SIZES --
// To add an icon size, use the icon size utils below to apply width and height at the same time
//
@icon-size-x-small: 12px;
@icon-size-small: 16px;
@icon-size-medium: 20px;
@icon-size-large: 24px;
@icon-size-x-large: 28px;
@icon-size-xx-large: 30px;


// Icon size utils
.icon-x-small() {
  width: @icon-size-x-small;
  height: @icon-size-x-small;
}

.icon-small() {
  width: @icon-size-small;
  height: @icon-size-small;
}

.icon-medium() {
  width: @icon-size-medium;
  height: @icon-size-medium;
}

.icon-large() {
  width: @icon-size-large;
  height: @icon-size-large;
}

.icon-x-large() {
  width: @icon-size-x-large;
  height: @icon-size-x-large;
}

.icon-xx-large() {
  width: @icon-size-xx-large;
  height: @icon-size-xx-large;
}
