.sharing-preferences {
  .modal-form-container, .modal-container-body {
    .subtitle {
      font-weight: @custom-dashboard-sharing-subtitle-font-weight;
    }

    .buttons {
      .primary {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .content {
      padding: @custom-dashboard-sharing-content-padding;

      .targets-scroll-container {
        margin-right: @custom-dashboard-sharing-targets-scroll-container-margin-right;
      }

      .info-tooltip {
        margin-left: 10px;

        svg {
          vertical-align: middle;
          width: @custom-dashboard-sharing-info-icon-width;
          height: @custom-dashboard-sharing-info-icon-height;
          fill: @custom-dashboard-sharing-info-icon-fill;
        }
      }

      .sub-target-name {
        padding-left: @custom-dashboard-sharing-sub-target-name-padding-left;
      }

      .targets-container {
        overflow-y: @custom-dashboard-sharing-targets-container-overflow-y;
        max-height: @custom-dashboard-sharing-targets-container-max-height;
        margin-top: @custom-dashboard-sharing-targets-container-margin-top;
        .white-bg-scrollbar();
      }

      .target-list {
        border-top: @custom-dashboard-sharing-targets-list-border-top;
        color: @custom-dashboard-sharing-targets-list-color;
        font-size: @custom-dashboard-sharing-targets-list-font-size;
        line-height: @custom-dashboard-sharing-targets-list-line-height;
        font-weight: @custom-dashboard-sharing-targets-list-font-weight;

        &:first-child {
          border-top: none;
        }

        .manager-name {
          color: @gray-light2;
        }
      }

      .expanded-content .target-list, .target-list.shared-via-team {
        cursor: default;
        background-color: @white;
      }
    }
    .modal-form-footer {
      margin-top: @custom-dashboard-sharing-modal-form-footer-margin-top;
    }
    .permission-link {
      text-decoration: underline;
    }
  }

  .modal-container-body .content {
    padding: 0;
  }
}
