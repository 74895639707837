// This styles are deprecated, please use elements/button.less

.button {
  font-size: @font-size-h4;
  display: inline-block;
  padding: 8px;
  text-align: center;
  border-radius: @border-radius-small;
  background-color: @black;
  color: @white;
  border: 1px solid transparent;
  cursor: pointer;
  min-width: @button-min-width;

  &.full-width {
    width: 100%
  }

  &.wide {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.medium-width {
    padding-left: 24px;
    padding-right: 24px;
  }

  &:hover {
    text-decoration: none;
    background-color: @positive-alert;
    border: 1px solid @actionGreen;
  }

  &:active {
    opacity: 0.8;
    text-decoration: none;
  }

  &.primary {
    background-color: @actionGreen;
    color: @white;
    border: 1px solid @action-green-border;
    font-weight: 500;

    svg {
      fill: @white;
    }

    &:hover {
      background-color: @action-green-hover;
    }
  }

  &.transparent {
    background-color: transparent;
    color: @white;
    border-color: @white;

    &:hover {
      background-color: rgba(255,255,255,0.08);
    }

    &.transparent-destructive:hover {
      color: @red;
      border-color: @red;
    }
  }

  &.secondary-white {
    background-color: @white;
    color: @gray;
    border: 1px solid @gray5;
    font-weight: 500;

    &:hover {
      background-color: @gray6;
      color: @gray;
    }
  }

  &.secondary-transparent {
    background-color: transparent;
    color: @text-color-default;
    border: 1px solid @gray5;
    font-weight: 500;

    &:hover {
      background-color: @white;
    }
  }

  &.blue-salesforce {
    background-color: @blue-salesforce;

    &:hover {
      background-color: lighten(@blue-salesforce, 10%);
      color: white;
    }
  }

  &.blue-salesforce-inverted {
    border-color: @blue-salesforce;
    color: @blue-salesforce;
    background-color: white;

    &:hover {
      border-color: darken(@blue-salesforce, 10%);
      color: darken(@blue-salesforce, 10%);
    }
  }

  &.secondary-orange {
    background-color: @logoorange;
    color: @white;
    border: 1px solid @logoorange;

    &:hover {
      background-color: @highlightOrange;
      color: @white;
    }
  }

  &.secondary-blue {
    background-color: @link-color;
    color: @white;
    border: 1px solid @link-color;

    &:hover {
      background-color: #336BAF;
      color: @white;
      border: 1px solid #336BAF;
    }
  }

  &.secondary-red {
    background-color: #D0021B;
    color: @white;
    border: 1px solid #D0021B;

    &:hover {
      background-color: #BA0118;
      border: 1px solid #BA0118;

      svg {
        fill: #D0021B;
      }
    }
  }

  &.secondary-green {
    background-color: #5DBA7D;
    color: @white;
    border: 1px solid #5DBA7D;

    &:hover {
      background-color: #629C6D;
      border: 1px solid #629C6D;
    }
  }

  &.secondary-danger {
    .secondary-white;
    color: @negative-alert-red;

    &:hover {
      background-color: @negative-alert-red;
      border: 1px solid @negative-alert-red;
      color: @white;
    }
  }

  &.secondary-danger-highlighted {
    .secondary-danger;
    border: 1px solid @negative-alert-red;
  }

  &.secondary-danger-white {
    .secondary-white;

    &:hover {
      background-color: @negative-alert-red;
      border: 1px solid @negative-alert-red;
      color: @white;
    }
  }

  &.button-small {
    font-size: @font-size-bodyText;
    padding: 5px 10px;
  }

  &.button-short {
    font-size: 16px;
    padding: 3px 20px;
    min-width: 85px;
  }

  &.button-medium {
    min-width: 118px;
  }

  &.button-x-small {
    font-size: @font-size-caption1;
    padding: 1px 4px;
    font-weight: 400;
    min-width: unset;
  }

  &.button-x-small-light-gray {
    font-size: @font-size-caption1;
    padding: 1px 4px;
    font-weight: 400;
    min-width: unset;
    background-color: #EEEEEE;;
    color: #666666;
    border: 1px solid #CCCCCC;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
  }

  &.dropdown-toggle {
    min-width: 0px;
    vertical-align: top;
    line-height: 19px;

    .fa {
      height: 20px;
      padding-top: 3px;
    }
  }

  &.text-only {
    text-decoration: none;
    border-color: transparent;
    background-color: transparent;

    &.white-background {
      color: @link-color;
    }

    &.dark-background {
      color: @white;
    }

    &.loud {
      .button-text {
        border-bottom: 1px solid @link-color;
      }
    }

    &.adjusted-width {
      padding-right: 0;
      padding-left: 0;
      min-width: auto;
    }

    &:hover {
      border: none;
      cursor: pointer;
      background-color: transparent;
      .button-text {
        border-bottom: 2px solid @link-color;
      }
    }

    &:active {
      color: @link-color;
    }

    &:focus {
      outline: none;
    }

  }

  &.loading {
    fill: @white;
    line-height: 0;

    svg {
      height: 20px;
      width: 20px;
      vertical-align: middle;
    }
  }

  &.auth-button {
    .secondary-blue;
    font-size: @font-size-bodyText;
    padding: 0 15px;
    line-height: 24px;
    min-width: auto;

    &:disabled {
      border-color: @gray5;
      background-color: @lightGrayBG;
      font-weight: @font-weight-semi-bold;
      color: @text-color-default;
      opacity: 1;
    }
  }
}

button:disabled {
  pointer-events: none;
  opacity: .6;

  &:hover, &:active, &:focus, &:visited {
    cursor: default;
  }
}

.button-group {
  position: relative;
  display: inline-block;
  white-space: nowrap;

  > .button:first-child:not(:last-child) {
    .border-right-radius(0)
  }

  > .button:not(:first-child) {
    .border-left-radius(0)
  }

  > .button:not(:first-child) {
    margin-left: -5px;
  }

  > .button:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
  }

}

.expandable-btn-container {
  padding-right: @btn-expandable-button-spacing;
  padding-left: @btn-expandable-button-spacing;
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
  }

  .expandable-btn {
    border: @btn-expandable-border;
    padding: @btn-expandable-padding;
    border-radius: @btn-expandable-border-radius;
    display: inline-block;
    line-height: @btn-expandable-line-height;
    font-size: @font-size-caption1;
    text-transform: uppercase;

    .icon {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      fill: @btn-expandable-fill-color;
      line-height: 0;

      svg {
        width: @btn-expandable-icon-width;
        height: @btn-expandable-icon-height;
      }
    }

    .text {
      color: @btn-expandable-color;
      vertical-align: middle;
      display: none;
    }
  }

  &:hover {

    .expandable-btn {

      .text {
        display: inline-block;
        margin-left: 3px;
      }
    }
  }

  &:active {

    .expandable-btn {
      background-color: @btn-expandable-active-bgcolor;

      .text {
        color: @btn-expandable-active-text-color;
      }

      .icon {
        fill: @btn-expandable-active-text-color;
      }


    }
  }

  &.auto-expanded {

    .expandable-btn {

      .text {
        margin-left: 3px;
        display: inline-block;
      }
    }

    &:hover {

      .expandable-btn {
        background-color: @btn-expandable-auto-expanded-bgcolor;
      }
    }

    &:active {

      .expandable-btn {
        background-color: @btn-expandable-active-bgcolor;

        .text {
          color: @btn-expandable-active-text-color;
        }

        .icon {
          fill: @btn-expandable-active-text-color;
        }


      }
    }
  }
}

.toggle {
  display: inline-flex;
  cursor: pointer;

  .toggle-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    line-height: 0;

    svg {
      width: 16px;
      height: 16px;
    }

    .toggle-button-side {
      border-radius: 50%;
      width: 17px;
    }

    .toggle-button-center {
      border-radius: 0;
      margin-left: -9px;
      margin-right: -9px;
      width: 20px;
    }

    .toggle-button-side,
    .toggle-button-center {
      border-width: 1px;
      border-style: solid;
      height: 18px;
      display: inline-block;
    }

    .toggle-icon {
      position: absolute;
      top: 1px;
      border-radius: 50%;
      background-color: white;
      display: inline-block;
    }
  }

  &.blue-toggle {
    &.toggle-on {
      .toggle-state-label {
        color: @btn-blue;
      }

      .toggle-button-side,
      .toggle-button-center {
        border-color: @btn-blue;
        background-color: @btn-blue;
      }

      .toggle-side-on {
        background-color: @white;
      }
    }

    &.toggle-off {
      .toggle-state-label {
        color: @btn-off-color;
      }

      .toggle-button-side,
      .toggle-button-center {
        border-color: @btn-off-color;
        background-color: @btn-off-color;
      }

      .toggle-side-off {
        background-color: @white;
        position: relative;
      }
    }
  }

  &.toggle-on {
    &.toggle-disabled {
      .toggle-button-side,
      .toggle-button-center {
        border-color: @background-weak-color;
        background-color: @background-weak-color;
        cursor: default;
      }

      .toggle-side-on {
        background-color: @background-white-color;
      }
    }

    .toggle-button-side,
    .toggle-button-center {
      border-color: #42B324;
      background-color: #42B324;
    }

    .toggle-icon {
      right: 1px;
      svg {
        fill: #42B324;
        padding: 2px;
      }
    }
  }

  &.toggle-off {
    &.toggle-disabled {
      .toggle-button-side,
      .toggle-button-center {
        border-color: @background-weak-color;
        background-color: @background-weak-color;
        cursor: default;
      }

      .toggle-side-off {
        background-color: @background-white-color;
      }
    }
    .toggle-button-side,
    .toggle-button-center {
      border-color: #C2C9D1;
      background-color: #C2C9D1;
    }

    .toggle-icon {
      left: 1px;
      svg {
        fill: #55616E;
      }
    }
  }

  .toggle-label {
    margin-left: 5px;
    font-size: @font-size-caption1;
    vertical-align: middle;
  }

}

.toggle-container {
  .toggle-state-label {
    margin-right: 5px;
    font-size: 13px;
    vertical-align: middle;
  }

  .toggle-button {
    vertical-align: middle;
  }
}

.button-list {
  > .button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.buttons {
  white-space: nowrap;
  .button {
    margin-right: 10px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
    }
  }
}

.download-button {
  padding: 5px 10px;
  border: 1px solid @link-color;
  border-radius: @border-radius-small;

  svg {
    vertical-align: text-bottom;
    fill: @link-color;
    margin-right: 10px;
  }

  &:hover, &:focus {
    border: 1px solid @link-hover-color;
    text-decoration: none;

    svg {
      fill: @link-hover-color;
    }
  }
}

.link-style-button {
  padding: 0;
  border: 0;
  background: none;
  font-weight: @font-weight-thin;
  text-transform: uppercase;
  color: @blue-link;
  text-align: right;

  span {
    cursor: pointer;
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
    fill: @blue-link;
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }

  &.link-style-loud-button {
    color: @blue-link;
    font-weight: @font-weight-medium;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &.normalized {
    text-transform: unset;
    text-align: unset;
  }

  &.dark-bg {
    color: @white;

    svg {
      fill: @white;
    }
  }

  &.darker-bg {
    color: #2E79B9;

    svg {
      fill: #2E79B9;
    }
  }
}

.decorated-link-button {
  color: @blue-link;
  text-decoration: underline;
  cursor: pointer;
}

.action-button {
  padding: 5px 8px;

  &.highlighted {
    border: 1px solid @blue-link;
    color: @blue-link;
    border-radius: @border-radius-small;
    padding: 5px 10px;

    &:hover {
      border: 1px solid @blue-link;
      color: @white;
      background-color: @blue-link;
    }
  }

  &.primary {
    background-color: @actionGreen;
    border: 1px solid @actionGreen;
    color: @white;
    &:hover {
      background-color: @action-green-hover;
      border: 1px solid @actionGreen;
      color: @white;
    }

    &.dropdown-modal-active {
      background-color: @action-green-hover
    }
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.action-button,
.dropdown-action-button.dropdown-white-dropdown .dropdown-selection,
.dropdown-action-button.dropdown-combo .dropdown-selection {
  text-transform: uppercase;
  font-weight: @font-weight-semi-bold;
  color: @gray1;
  background-color: @white;
  border: 1px solid @gray5;
  border-radius: 3px;
  cursor: pointer;

  a {
    color: @gray1;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  svg {
    fill: @gray1;
    vertical-align: top;
    margin-right: 2px;
  }

  &:hover {
    color: @white;
    background-color: @gray1;
    border: 1px solid @gray5;

    a {
      color: @white;
    }

    svg {
      fill: @white;
    }
  }
  &.dropdown-label-svg {
    padding: 1px 10px;
  }
}

.dropdown-action-button {
  &.dropdown-active {
    .dropdown-selection {
      &:hover {
        background-color: @gray1;
      }
    }
  }
  &.dropdown-no-border {
    .dropdown-selection {
      border: 1px solid transparent;
    }
  }
}

.messaging-platform-button {
  background-color: @white;
  color: @text-color-default;
  border: 1px solid @gray5;
  font-weight: 600;
  padding: 8px 24px 8px 14px;

  &:hover {
    background-color: @white;
    border-color: @gray5;
  }

  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
}

.loud-button {
  font-weight: 600;
  font-size: 20px;
  color: @black;
}

.alert-button {
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 2px;
  font-size: @font-size-bodyText;
  line-height: 17px;

  &.negative {
    background-color: @negative-alert;
    border: 1px solid @negative-alert;
  }

  &.positive {
    background-color: @positive-alert;
    border: 1px solid @positive-alert;
  }

  &.neutral {
    background-color: @neutral-alert;
    border: 1px solid @neutral-alert;
  }
}

.default-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 6px;

  background: @background-white-color;
  color: @text-color;
  border-radius: @border-radius-default;
  font-weight: @font-weight-medium;
  font-size: @font-size-bodyText;

  svg {
    width: @icon-small-size;
    height: @icon-small-size;
    fill: @icon-color;
  }

  &:hover {
    box-shadow: @box-shadow-small-hover;
  }
}

.default-button-primary-success {
  border: none;
  background-color: @background-success-color;
  color: @text-on-dark-color;
  &:hover {
    background-color: @background-hover-success-color;
  }
  &:disabled {
    background-color: @background-disabled-success-color;
  }

  svg {
    fill: @icon-on-dark-color;
  }
}

.default-button-secondary {
  border: 1px solid @border-color;
  box-shadow: @box-shadow-small-regular;
  background-color: @background-white-color;
  color: @text-color;
  &:hover {
    border: 1px solid @border-hover-color;
    svg {
      fill: @icon-color;
    }
  }
  &:disabled {
    background-color: @background-medium-color;
    color: @text-disabled-color;
  }

  svg {
    fill: @icon-weak-color;
  }
}

.shortcut-button {
  .btn-round {
    width:@shortcut-btn-round-size;
    height:@shortcut-btn-round-size;
    border-radius:@shortcut-btn-round-size;
    background-color: @link-color;
  }

  .close{
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    top: 2px;
    left: 4px;
    overflow: hidden;
    color: @shortcut-btn-default-color;

    &::before, &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: @shortcut-btn-default-color;
    }
    &::before {
      transform:rotate(0deg);
      }
    &::after {
      transform:rotate(90deg);
    }
  }
}
