.modal-nag-mode {
  .modal-mask {
      z-index: 1050;
  }
}

.modal-nag-mode-container {
  width: 660px;
  overflow: visible;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;

  .modal-nag-mode-header {
    padding-top: 37px;
    padding-bottom: 15px;

    .modal-nag-mode-title {
      color: #333333;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
    }
  }


  .modal-nag-mode-body {
    color: #666666;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 20px 50px;

    .timer {
      display: inline-block;
      padding-top: 20px;
      margin-bottom: 40px;
    }

    .nag-mode-tip {
      padding-top: 20px;
      padding-bottom: 20px;
      border: 1px solid #5DBA7D;
      border-radius: @border-radius-small;

      .nag-mode-tip-title {
        color: #333333;
        font-weight: 500;
      }

      .nag-mode-tip-sentence {
        padding-right: 6px;
      }
    }

    .contact-link {
      text-decoration: underline;
    }
  }

  .modal-nag-mode-body {
    color: #666666;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .modal-nag-mode-footer{
    text-align: center;
    padding-bottom: 40px;
    padding-top: 10px;

    .buttons {

      .button {
        padding: 10px 20px;
      }
    }

  }
}
