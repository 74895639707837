.highlight-pill {
  font-size: 11px;
  border: 1px solid red;
  padding: 2px 7px;
  border-radius: 15px;
  vertical-align: middle;
  text-transform: uppercase;
  color: white;
  font-weight: normal;
  margin-right: 2px;

  &.custom {
    display: inline-block;
    border: none;
    font-size: 14px;
    margin-right: 5px;
  }

  &.squared {
    border-radius: @border-radius-small;
    padding: 2px 10px;
  }

  &.warning {
    background-color: @negative-alert;
  }

  &.small {
    padding: 2px 6px;
    font-size: 12px;
  }

  &.right-align {
    margin-left: 10px;
  }

  &.tip-pill {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 13px;
    font-weight: @font-weight-regular;

    span {
      display: inline-block;
      padding: 4px 0;
    }
  }
}

.text-highlight {
  font: @text-display-xs;
  font-size: @font-size-600;

  &.green {
    color: @positive-alert;
  }

  &.orange {
    color: @negative-alert;
  }

  &.turquoise {
    color: @neutral-alert;
  }
}
